import { AdminTypeDBModel } from "app/core/cache/cache.types";
import { ClientService } from "app/core/client/client.service";
import { EntityTypeEnum } from "app/core/enums/physio-enums";
import { ClientByNameModel } from "app/core/models/clients-by-name.model";
import { UtilService } from "app/core/services/util.service";
import { forkJoin, Observable, of, Subject } from "rxjs";
import { debounceTime, map, takeUntil } from "rxjs/operators";

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { AutocompleteDataObject } from "@components/autocomplete-field/autocomplete-field.types";
import { EditCardStatus } from "@components/card/card.types";

import { ClientDetailInitialData } from "../../../client.types";
import { ReferredByDialogComponent, ReferredByDialogData } from "../../../../../components/referred-by-dialog/referred-by-dialog.component";
import { ClientPatchModel } from "app/core/client/models/client-patch.model";
import { ClientDetailsPatchModel } from "app/core/client/models/client-details-patch.model";
import { DebtorPatchModel } from "app/core/models/appointment/debtor-patch.model";
import { ClientModel } from "app/core/client/models/client.model";
import { ClientPersonalDetailsModel } from "app/core/client/models/client-personal-details.model";
import { DebtorModel } from "app/core/models/appointment/debtor.model";
import { compare } from "fast-json-patch";
import { EntityTypeModel } from "app/core/models/organisation/entity-type.model";
import { DebtorService } from "app/core/services/debtor.service";
import { MatOptionSelectionChange } from "@angular/material/core";
import { PatchClientResponseModel } from "app/core/client/models/patch-client-response.model";
import { DialogType } from "@components/dialog/dialog.types";
import { DialogComponent } from "@components/dialog/dialog.component";
import { DialogOutput } from "@components/table/table-column-filter/table-serverside-column-filter.service";
import { LoadingService } from "app/core/services/loading.service";
import { DateTimeService } from "app/core/services/datetime.service";
import { ClientVaxStatusData, ClientVaxStatusInitialData } from "../client-vax-status-dialog/client-vax-status-dialog.types";
import { ClientVaxStatusDialogComponent } from "../client-vax-status-dialog/client-vax-status-dialog.component";
import { AppointmentService } from "app/core/services/appointment.service";

@Component({
  selector: "app-client-other-information",
  styleUrls: ["./client-other-information.component.scss"],
  templateUrl: "client-other-information.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientOtherInformationComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostBinding("class") classes = "app-client-other-information flex flex-col";
  status: EditCardStatus;

  chargeToFC: FormControl;
  medicalCentreFC: FormControl;
  gpFC: FormControl;
  specialistFC: FormControl;
  internalRefFC: FormControl;
  nhiNumberFC: FormControl;
  diabetesRiskTypeFC: FormControl;
  diabetesRiskDateFC: FormControl;
  referralSourceFC: FormControl;
  whyChooseUsFC: FormControl;
  referredByFC: FormControl;

  clientDetailInitialData: ClientDetailInitialData;
  clients: AutocompleteDataObject[] = [];
  referrers: AutocompleteDataObject[] = [];
  clinics: AutocompleteDataObject[] = [];
  referralSources: AutocompleteDataObject[] = [];
  whyChooseUsItems: AutocompleteDataObject[] = [];
  diabetesRiskTypes: AdminTypeDBModel[];

  showInternalRef: boolean;
  showDiabetesRiskType: boolean;

  fetchClientData = this.getDataForClients.bind(this);

  isLoading: boolean;

  clientForm: FormGroup;
  clientDetailsForm: FormGroup;
  debtorForm: FormGroup;

  patchClient: ClientPatchModel;
  patchClientDetails: ClientDetailsPatchModel;
  patchDebtor: DebtorPatchModel;

  referredByEntity: EntityTypeModel;

  untouchedClientFormData: any;
  untouchedClientDetailsFormData: any;
  untouchedDebtorFormData: any;

  clientVaxStatusData: ClientVaxStatusData;
  clientVaxStatus: string;

  isGPMNZ: boolean;

  public destroySubscriptions: Subject<boolean> = new Subject<boolean>();

  @Output() cardBeingEdited = new EventEmitter<boolean>();
  @Input() canEdit: boolean;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _loadingService: LoadingService,
    private _dateTimeService: DateTimeService,
    private _activatedRoute: ActivatedRoute,
    private _clientService: ClientService,
    private _debtorService: DebtorService,
    public referredByDialog: MatDialog,
    public clientVaxStatusDialog: MatDialog,
    private _formBuilder: FormBuilder,
    public utilService: UtilService,
    private _dialog: MatDialog,
    private _appointmentService: AppointmentService
  ) {
    this.status = EditCardStatus.NORMAL;

    this.innitFormControls();

    this.isGPMNZ = this.utilService.isGPMNZ();
  }

  private innitFormControls() {
    this.chargeToFC = new FormControl("");
    this.medicalCentreFC = new FormControl("");
    this.gpFC = new FormControl("");
    this.specialistFC = new FormControl("");
    this.internalRefFC = new FormControl("");
    this.nhiNumberFC = new FormControl("");
    this.diabetesRiskTypeFC = new FormControl("");
    this.diabetesRiskDateFC = new FormControl("");
    this.referralSourceFC = new FormControl();
    this.whyChooseUsFC = new FormControl("");
    this.referredByFC = new FormControl("");

    this.clientForm = this._formBuilder.group({
      chargeToClient: this.chargeToFC,
      referralSource: this.referralSourceFC,
      whyChooseUs: this.whyChooseUsFC,
      referredBy: this.referredByFC,
    });

    this.clientDetailsForm = this._formBuilder.group({
      medicalCentre: this.medicalCentreFC,
      gp: this.gpFC,
      specialist: this.specialistFC,
      nhiNumber: this.nhiNumberFC,
      diabetesRiskType: this.diabetesRiskTypeFC,
      diabetesRiskDate: this.diabetesRiskDateFC,
    });
  }

  ngOnInit() {
    this._loadingService.visible$.subscribe((response) => {
      this.isLoading = response;
    });

    this._activatedRoute.data.subscribe((data: any) => {
      this.clientDetailInitialData = data.initialData as ClientDetailInitialData;
      const vendorApptDetail = this.clientDetailInitialData.VendorApptDetails.find(
        (vad) => vad.vendorId === this.clientDetailInitialData.ClientDetails.Client.VENDOR_ID
      );

      this.showInternalRef = this.clientDetailInitialData.Vendors.find(
        (v) => v.id === this.clientDetailInitialData.ClientDetails.Client.VENDOR_ID
      )?.showInternalRef;
      if (this.showInternalRef) {
        this.debtorForm = this._formBuilder.group({
          internalRef: this.internalRefFC,
        });
      }

      this.showDiabetesRiskType = vendorApptDetail.showDiabetesRisk && this.utilService.isGPMNZ();
      if (vendorApptDetail.isReferralSourceMandatory) {
        this.referralSourceFC.setValidators(Validators.required);
        this.referralSourceFC.updateValueAndValidity();
      }

      // Load and filter admin types
      this.whyChooseUsItems = this.clientDetailInitialData.WhyChooseUsItems.filter((wcu) => !wcu.deleted && wcu.active).sort((a, b) => a.name.localeCompare(b.name));
      this.referralSources = this.clientDetailInitialData.ReferralSources.filter((rs) => !rs.deleted && rs.active).sort((a, b) => a.name.localeCompare(b.name));
      this.diabetesRiskTypes = this.clientDetailInitialData.DiabetesRiskTypes.filter((drt) => !drt.deleted && drt.active).sort((a, b) => a.name.localeCompare(b.name));
      const nullOption: AdminTypeDBModel = {
        id: null,
        deleted: false,
        active: true,
        name: "-",
        description: "=",
        adminTypeType: null,
      };
      this.diabetesRiskTypes.unshift(nullOption);

      this.referrers = this.clientDetailInitialData.Referrers.filter((r) => r.active && !r.deleted).map((r) => {
        let autoCompleteObject: AutocompleteDataObject = {
          name: this.utilService.getFullName(r.firstName, r.lastName),
          id: r.id,
        };
        return autoCompleteObject;
      }).sort((a, b) => a.name.localeCompare(b.name));

      this.clinics = this.clientDetailInitialData.Clinics.map((c) => {
        let autoCompleteObject: AutocompleteDataObject = {
          name: c.NAME,
          id: c.ID,
        };
        return autoCompleteObject;
      });

      this.setInitialClientData();

      this.setPatchClient(this.clientDetailInitialData.ClientDetails.Client);
      this.setPatchClientDetails(this.clientDetailInitialData.ClientDetails.PersonalDetails);
      this.setPatchDebtor(this.clientDetailInitialData.ClientDetails.Debtor);
    });
  }

  ngAfterViewInit(): void {
    this.untouchedClientFormData = this.clientForm.value;
    this.untouchedClientDetailsFormData = this.clientDetailsForm.value;
    this.untouchedDebtorFormData = this.debtorForm.value;

    this.clientForm.valueChanges.pipe(debounceTime(200)).subscribe((data) => {
      if (JSON.stringify(this.untouchedClientFormData) === JSON.stringify(data)) {
        this.clientForm.markAsPristine();
      }
    });

    this.clientDetailsForm.valueChanges.pipe(debounceTime(200)).subscribe((data) => {
      if (JSON.stringify(this.untouchedClientDetailsFormData) === JSON.stringify(data)) {
        this.clientDetailsForm.markAsPristine();
      }
    });

    this.debtorForm.valueChanges.pipe(debounceTime(200)).subscribe((data) => {
      if (JSON.stringify(this.untouchedDebtorFormData) === JSON.stringify(data)) {
        this.debtorForm.markAsPristine();
      }
    });
  }

  private setPatchClient(client: ClientModel): void {
    let patchClient: ClientPatchModel = {
      CHARGE_TO_CLIENT_ID: client.CHARGE_TO_CLIENT_ID,
      REFERRAL_SOURCE_ID: client.REFERRAL_SOURCE_ID,
      WHY_CHOOSE_US_ID: client.WHY_CHOOSE_US_ID,
      REFERRED_BY_ID: client.REFERRED_BY_ID,
      REFERRED_BY_TYPE: client.REFERRED_BY_TYPE,
      VAX_SET_BY_USER_ID: client.VAX_SET_BY_USER_ID,
      VAX_SET_DATE: client.VAX_SET_DATE,
      VAX_STATUS_ID: client.VAX_STATUS_ID,
      VAX_STATUS_DATE: client.VAX_STATUS_DATE,
      VAX_NOTES: client.VAX_NOTES,
    };

    this.patchClient = patchClient;
  }

  private setPatchClientDetails(personalDetails: ClientPersonalDetailsModel): void {
    let patchClientDetails: ClientDetailsPatchModel = {
      MEDICAL_CENTRE_ID: personalDetails.MEDICAL_CENTRE_ID,
      GP_ID: personalDetails.GP_ID,
      SPECIALIST_ID: personalDetails.SPECIALIST_ID,
      NHI_NUMBER: personalDetails.NHI_NUMBER,
      DIABETES_RISK_TYPE: personalDetails.DIABETES_RISK_TYPE,
      DIABETES_RISK_DATE: personalDetails.DIABETES_RISK_DATE,
    };

    this.patchClientDetails = patchClientDetails;
  }

  private setPatchDebtor(debtor: DebtorModel): void {
    let patchDebtor: DebtorPatchModel = {
      INTERNAL_REF: debtor.INTERNAL_REF,
    };

    this.patchDebtor = patchDebtor;
  }

  private setInitialClientData() {
    const client = this.clientDetailInitialData.ClientDetails.Client;
    const personalDetails = this.clientDetailInitialData.ClientDetails.PersonalDetails;

    // Set Charge To
    if (client.CHARGE_TO_CLIENT_ID && this.clientDetailInitialData.ClientDetails.ChargeToClient) {
      const chargeToClient = this.clientDetailInitialData.ClientDetails.ChargeToClient;
      let value: AutocompleteDataObject = {
        name: this.utilService.getFullName(chargeToClient.FIRST_NAME, chargeToClient.LAST_NAME),
        id: chargeToClient.CLIENT_ID,
      };
      this.chargeToFC.setValue(value);
    }

    // Set Medical Centre
    if (personalDetails.MEDICAL_CENTRE_ID) {
      let clinic = this.clinics.find((c) => c.id === personalDetails.MEDICAL_CENTRE_ID);
      if (clinic) {
        this.medicalCentreFC.setValue(clinic);
      }
    }

    // Set GP
    if (personalDetails.GP_ID) {
      let gp = this.referrers.find((r) => r.id === personalDetails.GP_ID);
      if (gp) {
        this.gpFC.setValue(gp);
      }
    }

    // Set Specialist
    if (personalDetails.SPECIALIST_ID) {
      let specialist = this.referrers.find((r) => r.id === personalDetails.SPECIALIST_ID);
      if (specialist) {
        this.specialistFC.setValue(specialist);
      }
    }

    // Set Internal Ref
    if (this.clientDetailInitialData.ClientDetails.Debtor.INTERNAL_REF) {
      this.internalRefFC.setValue(this.clientDetailInitialData.ClientDetails.Debtor.INTERNAL_REF);
    }

    // Set NHI Number
    if (personalDetails.NHI_NUMBER) {
      this.nhiNumberFC.setValue(personalDetails.NHI_NUMBER);
    }

    // Set Diabetes Risk Type
    if (personalDetails.DIABETES_RISK_TYPE) {
      this.diabetesRiskTypeFC.setValue(this.diabetesRiskTypes.find((drt) => drt.id === personalDetails.DIABETES_RISK_TYPE));
    }

    // Set Diabetes Risk Date
    if (personalDetails.DIABETES_RISK_DATE) {
      const localeDate = this._dateTimeService.convertToLocale(personalDetails.DIABETES_RISK_DATE);
      this.diabetesRiskDateFC.setValue(new Date(localeDate));
    }

    // Set Referral Source
    if (client.REFERRAL_SOURCE_ID) {
      this.referralSourceFC.setValue(this.referralSources.find((rs) => rs.id === client.REFERRAL_SOURCE_ID));
    }

    // Set Why Choose Us
    if (client.WHY_CHOOSE_US_ID) {
      this.whyChooseUsFC.setValue(this.whyChooseUsItems.find((wcu) => wcu.id === client.WHY_CHOOSE_US_ID));
    }

    // Set Referred By
    if (client.REFERRED_BY_ID && client.REFERRED_BY_TYPE && this.clientDetailInitialData.ClientDetails.ReferredByEntity) {
      this.referredByEntity = { ...this.clientDetailInitialData.ClientDetails.ReferredByEntity };
      this.referredByFC.setValue(`${this.referredByEntity.EntityTypeDetail}: ${this.referredByEntity.EntityTypeDescription}`);
    }

    let vaxStatusData: ClientVaxStatusData = {
      setDate: client.VAX_SET_DATE,
      setBy: client.VAX_SET_BY_USER_ID,
      status: this.clientDetailInitialData.VaxStatuses.find((vs) => vs.id === client.VAX_STATUS_ID),
      statusDate: client.VAX_STATUS_DATE,
      notes: client.VAX_NOTES,
    };
    this.setVaxDetails(vaxStatusData);
    this.clientVaxStatusData = vaxStatusData;
  }

  private getDataForClients(searchTerm: string = ""): Observable<AutocompleteDataObject[]> {
    return this._clientService.getClientsByName(searchTerm).pipe(
      debounceTime(300),
      takeUntil(this.destroySubscriptions),
      map((clientsFound: ClientByNameModel[]) => {
        return clientsFound.map((client: ClientByNameModel) => {
          const fullName = this.utilService.getFullName(client.FIRST_NAME, client.LAST_NAME);
          const clientFound: AutocompleteDataObject = {
            id: client.CLIENT_ID,
            name: fullName,
          };
          return clientFound;
        });
      })
    );
  }

  onSave(): void {
    let patchClientObservable: Observable<PatchClientResponseModel>;
    let patchClientDetailsObservable: Observable<ClientPersonalDetailsModel>;
    let patchDebtorObservable: Observable<DebtorModel>;

    if (this.clientForm.touched) {
      let clientData: ClientPatchModel = {
        CHARGE_TO_CLIENT_ID: (this.chargeToFC.value as AutocompleteDataObject)?.id || null,
        REFERRAL_SOURCE_ID: (this.referralSourceFC.value as AutocompleteDataObject)?.id || null,
        WHY_CHOOSE_US_ID: (this.whyChooseUsFC.value as AutocompleteDataObject)?.id || null,
        REFERRED_BY_ID: this.referredByEntity?.EntityTypeId || null,
        REFERRED_BY_TYPE: this.referredByEntity?.EntityType || null,
        VAX_SET_BY_USER_ID: this.clientVaxStatusData.setBy || null,
        VAX_SET_DATE: this.clientVaxStatusData.setDate
          ? this._dateTimeService.convertToISOUTC(new Date(this.clientVaxStatusData.setDate))
          : null,
        VAX_STATUS_ID: this.clientVaxStatusData.status?.id || null,
        VAX_STATUS_DATE: this.clientVaxStatusData.statusDate
          ? this._dateTimeService.convertToISOUTC(new Date(this.clientVaxStatusData.statusDate))
          : null,
        VAX_NOTES: this.clientVaxStatusData.notes || null,
      };

      const clientPatchOperations = compare(this.patchClient, clientData, true);

      patchClientObservable =
        clientPatchOperations?.length > 0
          ? this._clientService.patchClient(this.clientDetailInitialData.ClientDetails.Client.ID, clientPatchOperations)
          : null;
    }

    if (this.clientDetailsForm.touched) {
      let diabetesRiskType = (this.diabetesRiskTypeFC.value as AdminTypeDBModel)?.id;
      let clientDetailsData: ClientDetailsPatchModel = {
        MEDICAL_CENTRE_ID: (this.medicalCentreFC.value as AutocompleteDataObject)?.id || null,
        GP_ID: (this.gpFC.value as AutocompleteDataObject)?.id || null,
        SPECIALIST_ID: (this.specialistFC.value as AutocompleteDataObject)?.id || null,
        NHI_NUMBER: this.nhiNumberFC.value || null,
        DIABETES_RISK_TYPE: diabetesRiskType || null,
      };

      clientDetailsData.DIABETES_RISK_DATE =
        this.diabetesRiskDateFC.value && diabetesRiskType
          ? this._dateTimeService.convertToISOUTC(this.diabetesRiskDateFC.value as Date)
          : null;

      const clientDetailsPatchOperations = compare(this.patchClientDetails, clientDetailsData, true);

      patchClientDetailsObservable =
        clientDetailsPatchOperations?.length > 0
          ? this._clientService.patchClientDetails(this.clientDetailInitialData.ClientDetails.Client.ID, clientDetailsPatchOperations)
          : null;
    }

    if (this.debtorForm.touched) {
      let debtorData: DebtorPatchModel = {
        INTERNAL_REF: this.internalRefFC.value || null,
      };

      const debtorPatchOperations = compare(this.patchDebtor, debtorData, true);

      patchDebtorObservable =
        debtorPatchOperations?.length > 0
          ? this._debtorService.patchDebtor(this.clientDetailInitialData.ClientDetails.Client.DEBTOR_ID, debtorPatchOperations)
          : null;
    }

    if (patchClientObservable || patchClientDetailsObservable || patchDebtorObservable) {
      this.clientForm.disable();
      this.clientDetailsForm.disable();
      this.debtorForm.disable();

      forkJoin({
        PatchClientResponse: patchClientObservable || of(null),
        ClientDetails: patchClientDetailsObservable || of(null),
        Debtor: patchDebtorObservable || of(null),
      }).subscribe({
        next: (response) => {
          if (response) {
            if (response.PatchClientResponse) {
              const patchClientResponse = response.PatchClientResponse as PatchClientResponseModel;
              if (this._clientService.selectedClient) {
                this._clientService.selectedClient.Client = patchClientResponse.Client;
                this._clientService.selectedClient.ReferredByEntity = patchClientResponse.ReferredByEntity;
              }

              this.referredByEntity = patchClientResponse.ReferredByEntity;
              this.untouchedClientFormData = this.clientForm.value;
              this.setPatchClient(patchClientResponse.Client);
            }

            if (response.ClientDetails) {
              if (this._clientService.selectedClient) {
                this._clientService.selectedClient.PersonalDetails = response.ClientDetails;
              }

              this.untouchedClientDetailsFormData = this.clientDetailsForm.value;
              this.setPatchClientDetails(response.ClientDetails);
            }

            if (response.Debtor) {
              if (this._clientService.selectedClient) {
                this._clientService.selectedClient.Debtor = response.Debtor;
              }

              this.untouchedDebtorFormData = this.debtorForm.value;
              this.setPatchDebtor(response.Debtor);
            }

            this.status = EditCardStatus.NORMAL;
            this.cardBeingEdited.emit(false);
            this.clientForm.enable();
            this.clientDetailsForm.enable();
            this.debtorForm.enable();
          }
        },
        error: (err) => {
          console.log(err);
          this.clientForm.enable();
          this.clientDetailsForm.enable();
        },
      });
    }
  }

  onCancel(): void {
    if (
      JSON.stringify(this.untouchedClientFormData) !== JSON.stringify(this.clientForm.value) ||
      JSON.stringify(this.untouchedClientDetailsFormData) !== JSON.stringify(this.clientDetailsForm.value) ||
      JSON.stringify(this.untouchedDebtorFormData) !== JSON.stringify(this.debtorForm.value)
    ) {
      const config = new MatDialogConfig();
      config.data = {
        title: "Changes unsaved",
        content: "You have changes unsaved. Are you sure you want to leave?",
        type: DialogType.YES_NO,
      };
      const dialogRef = this._dialog.open(DialogComponent, config);

      dialogRef.afterClosed().subscribe((result: DialogOutput) => {
        if (!result) {
          return;
        }

        this.close();
      });
    } else {
      this.close();
    }
  }

  onEdit(): void {
    if (!this.canEdit) {
      return;
    }

    this.status = EditCardStatus.EDIT;
    this.cardBeingEdited.emit(true);
  }

  close(): void {
    this.clientForm.reset();
    this.clientDetailsForm.reset();
    this.debtorForm.reset();
    this.innitFormControls();
    this.setInitialClientData();

    this.status = EditCardStatus.NORMAL;
    this.cardBeingEdited.emit(false);
  }

  openSetReferredByDialog(): void {
    let dialogData: ReferredByDialogData = {
      clientId: this.clientDetailInitialData.ClientDetails.Client.ID,
      clientName: this.utilService.getFullName(
        this.clientDetailInitialData.ClientDetails.PersonalDetails.FIRST_NAME,
        this.clientDetailInitialData.ClientDetails.PersonalDetails.LAST_NAME
      ),
      referrers: this.referrers,
      referredByType: this.referredByEntity || null,
    };

    const dialogRef = this.referredByDialog.open(ReferredByDialogComponent, { data: dialogData });

    dialogRef.afterClosed().subscribe((result: EntityTypeModel) => {
      if (result && result.EntityType !== EntityTypeEnum.NONE && result.EntityTypeId) {
        this.referredByEntity = result;
        this.referredByFC.setValue(`${this.referredByEntity.EntityTypeDetail}: ${this.referredByEntity.EntityTypeDescription}`);
      } else if (this.referredByEntity) {
        this.referredByEntity.EntityType = EntityTypeEnum.NONE;
        this.referredByEntity.EntityTypeId = null;

        this.referredByFC.setValue(null);
      }

      this.clientForm.markAsDirty();
      this.clientForm.markAsTouched();
    });
  }

  onDiabetesRiskTypeSelect(event: MatOptionSelectionChange): void {
    if (event && event.isUserInput && event.source.value.id) {
      this.diabetesRiskDateFC.setValue(new Date());
    } else if (event && event.isUserInput && !event.source.value.id) {
      this.diabetesRiskDateFC.setValue(null);
    }
    this._changeDetectorRef.markForCheck();
  }

  openSetClientVaxStatus(): void {
    const personalDetails = this.clientDetailInitialData.ClientDetails.PersonalDetails;
    let dialogData: ClientVaxStatusInitialData = {
      ...this.clientVaxStatusData,
      vaxStatuses: this.clientDetailInitialData.VaxStatuses,
      systemUser: this.clientDetailInitialData.SystemUser,
      clientName: this.utilService.getFullName(personalDetails.FIRST_NAME, personalDetails.LAST_NAME),
      clientDOB: personalDetails.DOB?.toString(),
    };

    const dialogRef = this.clientVaxStatusDialog.open(ClientVaxStatusDialogComponent, { data: dialogData });

    dialogRef.afterClosed().subscribe((result: ClientVaxStatusData) => {
      if (result) {
        this.clientVaxStatusData = result;

        this.setVaxDetails(this.clientVaxStatusData);

        this.clientForm.markAsDirty();
        this.clientForm.markAsTouched();
        this._changeDetectorRef.markForCheck();
      }
    });
  }

  private setVaxDetails(vaxStatusData: ClientVaxStatusData): void {
    this.clientVaxStatus = "";
    let vaxDetails = "";

    if (vaxStatusData.statusDate) vaxDetails += this._dateTimeService.FormatDate(vaxStatusData.statusDate) + ": ";

    if (vaxStatusData.status?.id)
      vaxDetails += this.clientDetailInitialData.VaxStatuses.find((vs) => vs.id === vaxStatusData.status.id).name + " ";

    if (vaxStatusData.setDate && vaxStatusData.setBy)
      vaxDetails +=
        " (Set By: " +
        this.clientDetailInitialData.SystemUser.displayName +
        " " +
        this._dateTimeService.FormatDate(vaxStatusData.setDate) +
        ")";

    this.clientVaxStatus = vaxDetails;
  }

  ngOnDestroy(): void {
    this.destroySubscriptions.complete();
  }

  getNextClientInternalRefNo(){
    this._appointmentService.getNextClientInternalRefNo().subscribe( (ref: number) => {
      this.internalRefFC.setValue(`A${ref}`);
    });
  }
}
