import { Component, HostBinding, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormControl } from "@angular/forms";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from "@angular/router";
import { DateTimeService } from "app/core/services/datetime.service";

@Component({
    selector: 'advanced-search-dialog',
    templateUrl: './advanced-search-dialog.component.html',
    styleUrls: ['./advanced-search-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class AdvancedSearchDialogComponent implements OnInit, OnDestroy {
    @HostBinding('class') classes = 'advanced-search-dialog';
    
    firstNameFC: FormControl;
    lastNameFC: FormControl;
    dobFC: FormControl;
    nhiNumberFC: FormControl;
    phoneFC: FormControl;
    mobileFC: FormControl;
    emailFC: FormControl;
    internalRefFC: FormControl;
    constructor(
        public dialogRef: MatDialogRef<AdvancedSearchDialogComponent>,
        private _router: Router,
        private _dateTimeService: DateTimeService
    ) {}

    ngOnInit(): void {
        this.dialogRef.updatePosition({
            top: '10px',
            right: '55px'
        });

        this.firstNameFC = new FormControl();
        this.lastNameFC = new FormControl;
        this.dobFC = new FormControl;
        this.nhiNumberFC = new FormControl;
        this.phoneFC = new FormControl;
        this.mobileFC = new FormControl;
        this.emailFC = new FormControl;
        this.internalRefFC = new FormControl;
    }

    ngOnDestroy(): void {
    }

    close(): void {
        this.dialogRef.close();
    }

    onSearch(){
        let searchParameters = "";
        if(this.firstNameFC.value){
            searchParameters += "&firstName="+this.firstNameFC.value;
        }
        if(this.lastNameFC.value){
            searchParameters += "&lastName="+this.lastNameFC.value;
        }
        if(this.dobFC.value){
            searchParameters += "&dob="+ this._dateTimeService.FormatDate(this.dobFC.value,"MM/DD/YYYY");
        }
        if(this.nhiNumberFC.value){
            searchParameters += "&nhiNumber="+this.nhiNumberFC.value;
        }
        if(this.phoneFC.value){
            searchParameters += "&phone="+this.phoneFC.value;
        }
        if(this.mobileFC.value){
            searchParameters += "&mobile="+this.mobileFC.value;
        }
        if(this.emailFC.value){
            searchParameters += "&email="+this.emailFC.value;
        }
        if(this.internalRefFC.value){
            searchParameters += "&internalRef="+this.internalRefFC.value;
        }
        // need to reload the page (with the filter change) even if you are on the same page
        this._router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this._router.navigateByUrl("/clients?" + searchParameters)
        );
        
        this.close();
    }
}