import { IndexedDBService } from "app/core/cache/indexed-db.service";
import { ClientService } from "app/core/client/client.service";
import { EntityTypeDetailEnum, EntityTypeEnum } from "app/core/enums/physio-enums";
import { DebtorByNameModel } from "app/core/models/appointment/debtor-by-name.model";
import { EmployerByNameModel } from "app/core/models/appointment/employer-by-name.model";
import { ClientByNameModel } from "app/core/models/clients-by-name.model";
import { CompanyByNameModel } from "app/core/models/organisation/company-by-name.model";
import { EntityTypeModel } from "app/core/models/organisation/entity-type.model";
import { SystemUserByNameModel } from "app/core/models/organisation/system-user-by-name.model";
import { CompanyService } from "app/core/services/company.service";
import { DebtorService } from "app/core/services/debtor.service";
import { EmployerService } from "app/core/services/employer.service";
import { SystemUserService } from "app/core/services/system-user.service";
import { UtilService } from "app/core/services/util.service";
import { Observable, Subject } from "rxjs";
import { debounceTime, map, switchMap, takeUntil } from "rxjs/operators";

import { Component, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatRadioChange } from "@angular/material/radio";
import { AutocompleteDataObject } from "@components/autocomplete-field/autocomplete-field.types";
import { LoadingService } from "app/core/services/loading.service";
import { ReferredByTypes } from "./referred-by-dialog.types";

export interface ReferredByDialogData {
  clientId: number;
  clientName: string;
  referrers: AutocompleteDataObject[];
  referredByType?: EntityTypeModel;
}

const _debounceTime = 500;

@Component({
  selector: "app-referred-by-dialog",
  templateUrl: "referred-by-dialog.component.html",
})
export class ReferredByDialogComponent implements OnInit {
  options = ReferredByTypes;
  referredByFC: FormControl;
  isLoading: boolean;
  fetchItems: any;
  items: AutocompleteDataObject[] = [];

  selectedReferrerType: EntityTypeModel = {
    EntityType: 0,
    EntityTypeDetail: "",
    EntityTypeId: 0,
    EntityTypeDescription: "",
  };

  showReferredByField: boolean;
  initialReferredByEntity: EntityTypeModel;

  public destroySubscriptions: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ReferredByDialogData,
    private _dialogRef: MatDialogRef<ReferredByDialogComponent>,
    private _loadingService: LoadingService,
    private _systemUserService: SystemUserService,
    private _indexedDBService: IndexedDBService,
    private _employerService: EmployerService,
    private _companyService: CompanyService,
    private _clientService: ClientService,
    private _debtorService: DebtorService,
    private _utilService: UtilService
  ) {
    this.referredByFC = new FormControl("");
  }

  ngOnInit(): void {
    this._loadingService.visible$.subscribe((result) => {
      this.isLoading = result;
    });

    this.initialReferredByEntity = { ...this.data.referredByType };

    if (
      this.data.referredByType &&
      this.data.referredByType.EntityType !== EntityTypeEnum.NONE &&
      this.data.referredByType.EntityType !== EntityTypeEnum.CLIENT_SELF
    ) {
      this.showReferredByField = true;

      const value: AutocompleteDataObject = {
        name: this.data.referredByType.EntityTypeDescription,
        id: this.data.referredByType.EntityTypeId,
      };

      this.selectedReferrerType = this.data.referredByType;

      this.referredByFC.setValue(value);
    } else {
      this.showReferredByField = false;
    }
  }

  referrerTypeSelected(event: MatRadioChange): void {
    switch (event.value) {
      case EntityTypeEnum.NONE:
        this.selectedReferrerType.EntityType = EntityTypeEnum.NONE;
        this.selectedReferrerType.EntityTypeDetail = EntityTypeDetailEnum.NONE;

        this.selectedReferrerType.EntityTypeId = null;
        this.showReferredByField = false;
        this.fetchItems = null;
        this.items = [];
        this.referredByFC.reset();
        break;
      case EntityTypeEnum.CLIENT_SELF:
        this.selectedReferrerType.EntityType = EntityTypeEnum.CLIENT_SELF;
        this.selectedReferrerType.EntityTypeDetail = EntityTypeDetailEnum.CLIENT_SELF;

        this.selectedReferrerType.EntityTypeId = null;
        this.showReferredByField = false;
        this.fetchItems = null;
        this.items = [];
        this.referredByFC.reset();
        break;
      case EntityTypeEnum.CLIENT:
        this.selectedReferrerType.EntityType = EntityTypeEnum.CLIENT;
        this.selectedReferrerType.EntityTypeDetail = EntityTypeDetailEnum.CLIENT;

        this.selectedReferrerType.EntityTypeId = null;
        this.items = [];
        this.referredByFC.reset();
        this.showReferredByField = true;
        this.fetchItems = this.getDataForClients.bind(this);
        break;
      case EntityTypeEnum.COMPANY:
        this.selectedReferrerType.EntityType = EntityTypeEnum.COMPANY;
        this.selectedReferrerType.EntityTypeDetail = EntityTypeDetailEnum.COMPANY;

        this.selectedReferrerType.EntityTypeId = null;
        this.items = [];
        this.referredByFC.reset();
        this.showReferredByField = true;
        this.fetchItems = this.getDataForCompanies.bind(this);
        break;
      case EntityTypeEnum.DEBTOR:
        this.selectedReferrerType.EntityType = EntityTypeEnum.DEBTOR;
        this.selectedReferrerType.EntityTypeDetail = EntityTypeDetailEnum.DEBTOR;

        this.selectedReferrerType.EntityTypeId = null;
        this.items = [];
        this.referredByFC.reset();
        this.showReferredByField = true;
        this.fetchItems = this.getDataForDebtors.bind(this);
        break;
      case EntityTypeEnum.EMPLOYER:
        this.selectedReferrerType.EntityType = EntityTypeEnum.EMPLOYER;
        this.selectedReferrerType.EntityTypeDetail = EntityTypeDetailEnum.EMPLOYER;

        this.selectedReferrerType.EntityTypeId = null;
        this.items = [];
        this.referredByFC.reset();
        this.showReferredByField = true;
        this.fetchItems = this.getDataForEmployers.bind(this);
        break;
      case EntityTypeEnum.SYSTEM_USER:
        this.selectedReferrerType.EntityType = EntityTypeEnum.SYSTEM_USER;
        this.selectedReferrerType.EntityTypeDetail = EntityTypeDetailEnum.SYSTEM_USER;

        this.selectedReferrerType.EntityTypeId = null;
        this.items = [];
        this.referredByFC.reset();
        this.showReferredByField = true;
        this.fetchItems = this.getDataForSystemUsers.bind(this);
        break;
      case EntityTypeEnum.REFERRER:
        this.selectedReferrerType.EntityType = EntityTypeEnum.REFERRER;
        this.selectedReferrerType.EntityTypeDetail = EntityTypeDetailEnum.REFERRER;

        this.selectedReferrerType.EntityTypeId = null;
        this.showReferredByField = true;
        this.fetchItems = null;
        this.items = this.data.referrers;
        this.referredByFC.reset();
        break;
      default:
        this.selectedReferrerType.EntityTypeId = null;
        this.showReferredByField = false;
        this.fetchItems = null;
        this.items = [];

        console.error("Please pass a valid EntityType enum value to the control");
        break;
    }
  }

  getDataForClients(searchTerm: string = ""): Observable<AutocompleteDataObject[]> {
    return this._clientService.getClientsByName(searchTerm).pipe(
      debounceTime(_debounceTime),
      takeUntil(this.destroySubscriptions),
      map((clientsFound: ClientByNameModel[]) => {
        return clientsFound.map((client: ClientByNameModel) => {
          const fullName = this._utilService.getFullName(client.FIRST_NAME, client.LAST_NAME);
          const clientFound: AutocompleteDataObject = {
            id: client.CLIENT_ID,
            name: fullName,
          };
          return clientFound;
        });
      })
    );
  }

  getDataForCompanies(searchTerm: string = ""): Observable<AutocompleteDataObject[]> {
    return this._companyService.getCompaniesByName(searchTerm).pipe(
      debounceTime(_debounceTime),
      takeUntil(this.destroySubscriptions),
      map((companiesFound: CompanyByNameModel[]) => {
        return companiesFound.map((company: CompanyByNameModel) => {
          const companyFound: AutocompleteDataObject = {
            id: company.ID,
            name: company.NAME,
          };
          return companyFound;
        });
      })
    );
  }

  getDataForDebtors(searchTerm: string = ""): Observable<AutocompleteDataObject[]> {
    return this._debtorService.getDebtorsByName(searchTerm).pipe(
      debounceTime(_debounceTime),
      takeUntil(this.destroySubscriptions),
      map((debtorsFound: DebtorByNameModel[]) => {
        return debtorsFound.map((debtor: DebtorByNameModel) => {
          const debtorFound: AutocompleteDataObject = {
            id: debtor.ID,
            name: debtor.NAME,
          };
          return debtorFound;
        });
      })
    );
  }

  getDataForEmployers(searchTerm: string = ""): Observable<AutocompleteDataObject[]> {
    return this._employerService.getEmployersByName(searchTerm).pipe(
      debounceTime(_debounceTime),
      takeUntil(this.destroySubscriptions),
      map((employersFound: EmployerByNameModel[]) => {
        return employersFound.map((employer: EmployerByNameModel) => {
          const employerFound: AutocompleteDataObject = {
            id: employer.ID,
            name: employer.NAME,
          };
          return employerFound;
        });
      })
    );
  }

  getDataForSystemUsers(searchTerm: string = ""): Observable<AutocompleteDataObject[]> {
    return this._indexedDBService.getAllActiveSystemUsers().pipe(
      switchMap((response) => {
        return this._systemUserService.getSystemUsersByName(searchTerm, response[0].isSupport).pipe(
          debounceTime(_debounceTime),
          takeUntil(this.destroySubscriptions),
          map((sysUsersFound: SystemUserByNameModel[]) => {
            return sysUsersFound.map((sysUser: SystemUserByNameModel) => {
              const sysUserFound: AutocompleteDataObject = {
                id: sysUser.ID,
                name: sysUser.NAME,
              };
              return sysUserFound;
            });
          })
        );
      })
    );
  }

  save(): void {
    const referredByValue = this.referredByFC.value as AutocompleteDataObject;
    if (referredByValue) {
      this.selectedReferrerType.EntityTypeId = referredByValue.id;
      this.selectedReferrerType.EntityTypeDescription = referredByValue.name;
    }

    if (this.selectedReferrerType.EntityType === EntityTypeEnum.CLIENT_SELF) {
      this.selectedReferrerType.EntityTypeId = this.data.clientId;
      this.selectedReferrerType.EntityTypeDescription = this.data.clientName;
    }

    this._dialogRef.close(this.selectedReferrerType);
  }

  close(): void {
    this._dialogRef.close(this.initialReferredByEntity);
  }
}
