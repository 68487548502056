<div class="w-full h-full" *ngIf="clientDetailsData">

    <app-title-header pageTitle={{pageTitle}}></app-title-header>

    <div class="workspace">

        <div class="flex flex-col sm:flex-row gap-y-5 sm:gap-x-4">
        
            <div class="col-left flex flex-col sm:basis-1/2 md:basis-auto w-full md:w-80 gap-y-5">
                <app-card-info-comp class="base-summary client-detail-summary max-sm:hidden" [title]="'Client Details'"
                    [expandTooltip]="'Go to client detail'" (expandEvent)="onNavigateToClientDetail()">
                    <div>
                        <div class="detail-info info-section">
                            <div class="item-section">
                                <div class="title mt-2">Home Site</div>
                                <div class="info mt-2" data-cy='summary-client-home-site'>{{ clientHomeSite.name }}</div>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="contact-info info-section">
                            <div class="item-section">
                                <div class="title">Primary contact details</div>
                                <div class="flex flex-col info mt-2">
                                    <div data-cy='summary-client-email'>
                                        <span>E:</span>
                                        <span class="ml-2">
                                            {{ clientDetailsData.HomeContactDetails.EMAIL ?
                                            clientDetailsData.HomeContactDetails.EMAIL : 'No e-mail address registered
                                            yet'}}
                                        </span>
                                    </div>
                                    <div data-cy='summary-client-phone'>
                                        <span>P:</span>
                                        <span class="ml-2">
                                            {{ clientDetailsData.HomeContactDetails.PHONE ?
                                            clientDetailsData.HomeContactDetails.PHONE : 'No phone number registered yet' }}
                                        </span>
                                    </div>
                                    <div data-cy='summary-client-mobile'>
                                        <span>M:</span>
                                        <span class="ml-2">
                                            {{ clientDetailsData.HomeContactDetails.MOBILE ?
                                            clientDetailsData.HomeContactDetails.MOBILE : 'No mobile number registered yet'
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="item-section mt-4" data-cy='summary-client-primary-address'>
                                <div class="title">Primary address</div>
                                <div class="info mt-2">
                                    {{ clientHomeAddress || 'No address set yet' }}
                                </div>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="setting-info info-section">
                            <div class="item-section">
                                <span>Remind of client appointments: {{clientDetailsData.Client.SEND_REMINDERS_IND |
                                    formatYesNo}}</span>
                            </div>
                            <div class="item-section">
                                <span>Include in mailouts: {{clientDetailsData.PersonalDetails.INCLUDE_IN_MAILOUTS |
                                    formatYesNo}}</span>
                            </div>
                            <div class="item-section">
                                <span>Exclude from statement batches: {{clientDetailsData.Debtor.EXCLUDE_FROM_STMT_BATCH_IND
                                    | formatYesNo}}</span>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <!-- Hide until functionality is implemented -->
                        <div class="actions-info info-section" *ngIf="false">
                            <div class="action-link">New Payment</div>
                            <div class="action-link mt-8">Create Invoice</div>
                            <div class="action-link mt-8">New Appointment</div>
                        </div>
                    </div>
                </app-card-info-comp>

                <app-card-info-comp [title]="'Actions'" [navigationVisible]="false">
                    <div class="setting-info info-section">
                        <div class="client-detail-summary">
                            <span class="action-link" mat-raised-button (click)="onDeleteClient()"
                                data-cy='delete-client-button'>Delete
                                Client</span>
                        </div>
                    </div>
                </app-card-info-comp>
            </div>

            <div class="flex flex-1 flex-col md:flex-row sm:basis-1/2 md:basis-0 gap-y-5 md:gap-x-4"
                *ngIf="clientSummaryStatus === 'CLIENT_SUMMARY'">
                <!-- Appointments, conditions, events & full history -->
                <div class="col1 flex flex-col gap-y-5 flex-1">
                    <!-- Last Appt Card -->
                    <app-card-info-comp [title]="'Appointments'" [expandTooltip]="'Go to appointments'"
                        class="base-summary appointment-summary" (expandEvent)="onNavigateToClientAppointments()">
                        <ng-container *ngIf="lastAppointment;else noLastAppt">
                            <div class="item-row">
                                <span class="highlight">{{ latestAppointmentDate < today ? "Last:" : "Next:" }} {{
                                        lastAppointment.START_DATETIME | formatDateTimeWithDay }}</span>
                                        <span> at {{ lastAppointment.SITE_NAME }}, {{ lastAppointmentStatus }}, {{
                                            lastAppointment.CLAIM_NO ? lastAppointment.CLAIM_NO : 'No Claim number
                                            associated' }}</span>
                            </div>
                        </ng-container>
                        <ng-template #noLastAppt>
                            <div class="item-row">
                                <span>This client has no appointments yet.</span>
                            </div>
                        </ng-template>
                    </app-card-info-comp>

                    <!-- Last Condition Card -->
                    <app-card-info-comp [navigationVisible]="true" [title]="'Conditions'"
                        [expandTooltip]="'Go to Conditions'" class="base-summary condition-summary"
                        (expandEvent)="onNavigateToClientConditions()">
                        <ng-container *ngIf="lastCondition;else noLastCondition">
                            <div class="item-row flex flex-col">
                                <div class="flex">
                                    <span>Description: </span>
                                    <span class="ml-2">
                                        {{ lastCondition.InjuryDescription }}
                                    </span>
                                </div>
                            </div>
                            <div class="item-row flex">
                                <span class="highlight">Date of Injury: {{ lastCondition.DateOfInjury | formatDate }}</span>
                            </div>
                        </ng-container>
                        <ng-template #noLastCondition>
                            <div class="item-row">
                                <span>This client has no conditions yet.</span>
                            </div>
                        </ng-template>
                    </app-card-info-comp>

                    <!-- Last Event Card -->
                    <app-card-info-comp [navigationVisible]="true" [title]="'Events'" [expandTooltip]="'Go to Events'"
                        class="base-summary event-summary" (expandEvent)="onNavigateToClientEvents()">
                        <ng-container *ngIf="lastEvent;else noLastEvent">
                            <div class="item-row flex flex-col">
                                <span class="highlight" *ngIf="lastEvent.DATE_DUE">Due: {{ lastEvent.DATE_DUE | formatDate
                                    }}</span>
                                <span class="highlight" *ngIf="lastEvent.COMPLETED_IND === 1">Completed: {{
                                    lastEvent.DATE_COMPLETED | formatDate }}</span>
                                <div class="flex">
                                    <span>Subject:</span>
                                    <span class="ml-2">{{ lastEvent.SUBJECT }}</span>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #noLastEvent>
                            <div class="item-row">
                                <span>There are no upcoming events.</span>
                            </div>
                        </ng-template>
                    </app-card-info-comp>

                    <!-- Healthlink -->
                    <app-card-info-comp [title]="'Healthlink Messages'" [expandTooltip]="'Go to Healthlink'"
                        class="base-summary letter-upload-summary" (expandEvent)="onNavigateToHealthlink()">
                        <ng-container *ngIf="lastHlMessage;else noLastHlMessage">
                            <div class="item-row flex flex-col">
                                <span>Date : {{ lastHlMessage.MESSAGE_DATE | formatDate }}</span>
                            </div>
                            <div class="item-row flex flex-col">
                                <span> From: {{ lastHlMessage.FROM_NAME }}, To: {{ lastHlMessage.TO_NAME }}</span>
                                <span> </span>
                                <span> Subject: {{ lastHlMessage.SUBJECT }}</span>
                                <span> Internal Notes: {{ lastHlMessage.INTERNAL_NOTES }}</span>
                            </div>
                        </ng-container>
                        <ng-template #noLastHlMessage>
                            <div class="item-row">
                                <span>There are no recent healthlink messages.</span>
                            </div>
                        </ng-template>
                    </app-card-info-comp>

                    <!-- Letters and Uploads -->
                    <app-card-info-comp [title]="'Letters and uploads'" [expandTooltip]="'Go to Letters and uploads'"
                        class="base-summary letter-upload-summary" (expandEvent)="onNavigateToClientDocuments()">
                        <ng-container *ngIf="lastLetter;else noLastLetter">
                            <div class="item-row flex flex-col">
                                <span class="highlight">Last Letter: {{ lastLetter.DISPLAY_NAME }}</span>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="lastUpload;else noLastUpload">
                            <div class="item-row flex flex-col">
                                <span class="highlight">Last Upload: {{ lastUpload.ORIGINAL_FILENAME }}</span>
                            </div>
                        </ng-container>
                        <ng-template #noLastLetter>
                            <div class="item-row">
                                <span>There are no recent letters.</span>
                            </div>
                        </ng-template>
                        <ng-template #noLastUpload>
                            <div class="item-row">
                                <span>There are no recent uploads.</span>
                            </div>
                        </ng-template>
                    </app-card-info-comp>

                </div>

                <!-- Invoices and Payments, Memberships, Letters & Uploads -->
                <div class="col2 flex flex-col gap-y-5 flex-1">
                    <!-- Last Invoice and Payment -->
                    <app-card-info-comp [title]="'Accounts'" [expandTooltip]="'Go to Accounts'"
                        class="base-summary account-summary" (expandEvent)="onNavigateToClientAccounts()">
                        <!-- Last Invoice -->
                        <ng-container *ngIf="lastInvoice;else noLastInvoice">
                            <div class="item-row">
                                <span class="highlight">Invoice #{{ lastInvoice.INVOICE_NO }} </span>
                                <span>due {{ lastInvoice.DUE_DATE | formatDate }} </span>
                                <span class="bold" *ngIf="lastInvoiceDueDate && lastInvoiceDueDate > today">Overdue!</span>
                            </div>
                        </ng-container>

                        <!-- Last Payment -->
                        <ng-container *ngIf="lastPayment;else noLastPayment">
                            <div class="item-row">
                                <span class="highlight">Payment #{{ lastPayment.ID }} </span>
                                <span>recieved {{ lastPayment.DATE_RECEIPTED | formatDate }}</span>
                            </div>
                        </ng-container>

                        <ng-template #noLastInvoice>
                            <div class="item-row">
                                <span>There are no invoices registered for this client.</span>
                            </div>
                        </ng-template>

                        <ng-template #noLastPayment>
                            <div class="item-row">
                                <span>There are no payments registered for this client.</span>
                            </div>
                        </ng-template>
                    </app-card-info-comp>

                    <!-- Last Membership Card -->
                    <app-card-info-comp [navigationVisible]="true" [title]="'Memberships'"
                        [expandTooltip]="'Go to Memberships'" class="base-summary membership-summary"
                        (expandEvent)="onNavigateToMemberships()">
                        <ng-container *ngIf="lastMembership;else noLastMembership">
                            <div class="flex">
                                <span>Membership Type:</span>
                                <span class="ml-2">{{ lastMembership.MEMBERSHIP_TYPE_NAME }}</span>
                            </div>
                            <div class="item-row flex">
                                <span class="highlight"> {{ membershipStartDate < today ? "Started On:" : "Starts On:"
                                        }}</span>
                                        <span class="ml-2">{{ lastMembership.START_DATE | formatDate }}</span>
                            </div>
                            <div class="item-row flex">
                                <span class="highlight">{{ membershipEndDate < today ? "Expired On:" : "Expires On:"
                                        }}</span>
                                        <span class="ml-2">{{ lastMembership.EXPIRES_ON | formatDate }}</span>
                            </div>
                        </ng-container>
                        <ng-template #noLastMembership>
                            <div class="item-row">
                                <span>There are no recorded memberships.</span>
                            </div>
                        </ng-template>
                    </app-card-info-comp>

                    <!-- Relationships -->
                    <app-card-info-comp [title]="'Relationships'" [expandTooltip]="'Go to Relationships'"
                        class="base-summary letter-upload-summary" (expandEvent)="onNavigateToRelationships()">
                        <ng-container *ngIf="relationships;else noRelationships">
                            <div class="item-row flex flex-col">
                                <span> Name: {{ relationships.RELATION_NAME }}</span>
                                <span> Mobile: {{ relationships.RELATED_MOBILE_PHONE }}</span>
                            </div>
                        </ng-container>
                        <ng-template #noRelationships>
                            <div class="item-row">
                                <span>There are no recent relationships.</span>
                            </div>
                        </ng-template>
                    </app-card-info-comp>

                    <!-- Full History -->
                    <app-card-info-comp [title]="'Full History'" [expandTooltip]="'Go to full history'"
                        class="base-summary full-history-summary" (expandEvent)="onNavigateToFullHistory()">
                        <ng-container *ngIf="clientHistories && clientHistories.length > 0 ;else noClientHistories">
                            <div *ngFor="let history of clientHistories">
                                <div class="item-row mb-1">
                                    <span class="highlight">{{history.ITEM_DATE | formatDate}}</span>
                                    <span>, {{ history.PERSON_NAME }} - {{ history.TYPE_NAME }} {{ history.AMOUNT | currency
                                        }}</span>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #noClientHistories>
                            <div class="item-row">
                                <span>There are no histories available.</span>
                            </div>
                        </ng-template>
                    </app-card-info-comp>

                    <!-- App Integrations -->
                    <!-- Hide until functionality is implemented -->
                    <app-card-info-comp [title]="'App integrations'" [expandTooltip]="'Go to App integrations'"
                        class="base-summary app-integration-summary" *ngIf="false">
                        <div class="item-row">
                            <img src="assets/images/gmp_app_icon_logo.png" class="gmp_app_icon_agg mr-2">
                            <img src="assets/images/gmp_app_icon_plus.png" class="gmp_app_icon_plus mr-2">
                            <img src="assets/images/gmp_app_icon_agg.png" class="gmp_app_icon_agg mr-2">
                        </div>
                    </app-card-info-comp>

                    <!-- Full History -->
                    <!-- Hide until functionality is implemented -->
                    <app-card-info-comp [title]="'Full history'" [expandTooltip]="'Go to App History'"
                        class="base-summary history-summary" *ngIf="false">
                        <div class="item-row">
                            <span class="highlight">21/04/21 03:59 AM </span>
                            <span>Support, Statement</span>
                        </div>
                        <div class="item-row">
                            <span class="highlight">21/04/21 03:59 AM </span>
                            <span>Support, Statement</span>
                        </div>
                        <div class="item-row">
                            <span class="highlight">20/04/21 11:05 AM </span>
                            <span>QA Clinician Appointment</span>
                        </div>
                        <div class="item-row">
                            <span class="highlight">20/04/21 11:05 AM </span>
                            <span>Support, Statement</span>
                        </div>
                    </app-card-info-comp>

                </div>

            </div>

            <app-client-detail-info class="w-full" *ngIf="clientSummaryStatus === 'CLIENT_DETAIL'"
                (closeEvent)="onNavigateToClientSummary()"></app-client-detail-info>

        </div>

    </div>

</div>