import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UtilService } from "app/core/services/util.service";
import { ClientDetailsSideMenuActions } from "../client.types";
import { ClientRelationshipFilterColumnService, ClientRelationshipsServerSideTableService } from "./client-relationships-table.service";

@Component({
  selector: "app-client-relationships",
  templateUrl: "client-relationships.component.html",
})
export class ClientRelationshipsComponent implements OnInit {
  clientId: number;
  pageTitle: string;

  ClientDetailsSideMenuActions = ClientDetailsSideMenuActions;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _utilService: UtilService,
    public tableService: ClientRelationshipsServerSideTableService,
    public filterColumnService: ClientRelationshipFilterColumnService
  ) {
    const params = this._activatedRoute.snapshot.params;
    const clientId = params["clientId"];
    this.clientId = +clientId;
    this.tableService.clientId = clientId;
  }

  ngOnInit(): void {
    this._activatedRoute.data.subscribe((data: any) => {
      const clientPersonalDetails = data.initialData;
      // Page Title
      this.pageTitle = this._utilService.getFullNameDob(clientPersonalDetails.FIRST_NAME, clientPersonalDetails.LAST_NAME, clientPersonalDetails.DOB);
    });
  }

  navigateToPage(url: string): void {
    this._router.navigate([url]);
  }
}
