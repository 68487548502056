import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { AdminTypeDialogComponent } from "./admin-type/admin-type-dialog.component";

import { DialogComponent } from './dialog.component';

@NgModule({
    imports: [MatDialogModule, MatButtonModule, CommonModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule],
    exports: [DialogComponent],
    declarations: [DialogComponent, AdminTypeDialogComponent],
    providers: [],
})
export class DialogModule {}
