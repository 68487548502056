import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateTimeService } from 'app/core/services/datetime.service';
import { DialogInput } from '../table-serverside-column-filter.service';

@Component({
	selector: 'app-date-dialog-component',
	templateUrl: './date-dialog.component.html',
})

export class DateDialogComponent implements OnInit {
	tableServerSideColumnFilterFormGroup: any;

	constructor(
		private datetimeService: DateTimeService,
		public matDialogRef: MatDialogRef<DateDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogInput
	) {

	}
	ngOnInit(): void {
		const date = this.data.column && this.data.column.filterValue
			? this.data.column.filterValue.filterDate : null;

		this.tableServerSideColumnFilterFormGroup = new FormGroup({
			filterValueDate: new FormControl(date)
		});
	}

	applyFilter() {
		const filterDateVal = this.tableServerSideColumnFilterFormGroup.controls.filterValueDate.value;

		const filterDate = filterDateVal ? this.datetimeService.toStartOfDay(filterDateVal) : null;

		this.matDialogRef.close({
			item: this.data.column,
			filterValue: {
				filterDate
			}
		});
	}
}
