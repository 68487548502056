import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TableActionType, TableItemAction } from "@components/table/mat/mat-table-wrapper.service";
import { AppStateDBModel } from "app/core/cache/cache.types";
import { UtilService } from "app/core/services/util.service";
import { ClientDetailsSideMenuActions } from "../client.types";
import { ClientConditionsFilterColumnService, ClientConditionsServerSideTableService } from "./client-conditions-table.service";

@Component({
  selector: "app-client-conditions",
  templateUrl: "client-conditions.component.html"
})
export class ClientConditionsComponent implements OnInit {
  clientId: number;
  pageTitle: string;

  ClientDetailsSideMenuActions = ClientDetailsSideMenuActions;

  constructor(
    public filterColumnService: ClientConditionsFilterColumnService,
    public tableService: ClientConditionsServerSideTableService,
    private _utilService: UtilService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {
    const params = this._activatedRoute.snapshot.params;
    const clientId = params["clientId"];
    this.clientId = +clientId;
    this.tableService.clientId = clientId;
  }

  ngOnInit() {
    this._activatedRoute.data.subscribe((data: any) => {
      const clientPersonalDetails = data.initialData;
      // Page Title
      this.pageTitle = this._utilService.getFullNameDob(clientPersonalDetails.FIRST_NAME, clientPersonalDetails.LAST_NAME, clientPersonalDetails.DOB);
    });
  }

  navigateToPage(url: string): void {
    this._router.navigate([url]);
  }

  onActionEvent(item: { rowItem: any; action: TableItemAction }): void {
    if (item.action.actionType === TableActionType.EDIT) {
      this._router.navigate([`/condition-details/${item.rowItem.Id}/summary`]);
    }
  }
}
