import { Injectable } from "@angular/core";
import { CellConfigProvider } from "@components/table/mat/mat-table-cell-content.service";
import { SummaryItemDefinition } from "@components/table/mat/mat-table-summary-service";
import { TableItemAction, ColumnItem, ColumnGroupConfig, RowItemConfig } from "@components/table/mat/mat-table-wrapper.service";
import { FilterColumnService, FilterItem } from "@components/table/table-column-filter/table-serverside-column-filter.service";
import { ServerSideTableService } from "@components/table/table-serverside.service";
import { ClientService } from "app/core/client/client.service";
import { RelationshipType } from "app/core/enums/relationship-type.enum";
import { map, Observable } from "rxjs";
import { ClientRelationships } from "../client.types";

const CLIENT_RELATIONSHIPS = {
  RELATION_NAME: "RELATION_NAME",
  RELATIONSHIP: "RELATIONSHIP_TYPE_NAME",
  HOME_PHONE: "RELATED_HOME_PHONE",
  WORK_PHONE: "RELATED_WORK_PHONE",
  MOBILE: "RELATED_MOBILE_PHONE",
  EMAIL: "RELATED_EMAIL",
};

@Injectable({ providedIn: "root" })
export class ClientRelationshipsServerSideTableService implements ServerSideTableService {
  public clientId: string;

  constructor(private _clientService: ClientService) {}

  getActionList(): TableItemAction[] {
    return null;
  }

  getColumnDefinitions(): ColumnItem[] {
    return [
      {
        fieldName: CLIENT_RELATIONSHIPS.RELATION_NAME,
        displayName: "Relation Name",
      },
      {
        fieldName: CLIENT_RELATIONSHIPS.RELATIONSHIP,
        displayName: "Relationship",
      },
      {
        fieldName: CLIENT_RELATIONSHIPS.HOME_PHONE,
        displayName: "Home Phone",
      },
      {
        fieldName: CLIENT_RELATIONSHIPS.WORK_PHONE,
        displayName: "Work Phone",
      },
      {
        fieldName: CLIENT_RELATIONSHIPS.MOBILE,
        displayName: "Mobile",
      },
      {
        fieldName: CLIENT_RELATIONSHIPS.EMAIL,
        displayName: "E-mail",
      },
    ];
  }

  getData(selectedFilterList: FilterItem[]): Observable<ClientRelationships[]> {
    return this._clientService.getClientRelarionships(+this.clientId).pipe(
      map((response: ClientRelationships[]) => {
        response.forEach(x => {
          x.RELATIONSHIP_TYPE_NAME = RelationshipType[x.RELATIONSHIP_TYPE];
        });
        return response;
      })
    );
  }

  getDisplayColumnConfig?(): ColumnGroupConfig {
    return null;
  }
}

@Injectable({ providedIn: "root" })
export class ClientRelationshipFilterColumnService implements FilterColumnService {
  constructor() {}

  getAllowedFilterFields(): Array<FilterItem> {
    return [];
  }

  getSelectDataForFilterItem(item: FilterItem, params: any): Observable<any[]> {
    let results: Observable<any[]>;
    switch (item.fieldName) {
    }

    return results;
  }

  getDataButtonText(): string {
    return "";
  }
  getPageTitle(): string {
    return "Relationships";
  }

  getFilterServiceUniqueName(): string {
    return "Client_Relationships_Filter";
  }
}