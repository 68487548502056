<form class="address-search-form">
    <mat-form-field class="w-full" appearance="outline" floatLabel="always">
      <mat-label>Address</mat-label>
      <input
        type="text"
        placeholder="Search for address"
        aria-label="Number"
        matInput
        [formControl]="myControl"
        [matAutocomplete]="auto"
        (keyup) = "getAddresses($event)">
      <mat-autocomplete
          [panelWidth]="400"
          autoActiveFirstOption
          #auto="matAutocomplete"
          (optionSelected) = "optionSelected($event.option.value)">
          <mat-option
          *ngFor="let item of filteredItems | async"
          [value]="item">
          {{item.DisplayAddress}} 
          </mat-option>
      </mat-autocomplete>
    </mat-form-field>    
</form>