import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddNewClientDialogComponent } from './add-new-client-dialog.component';

export enum PositionX {
    LEFT, // in the left side of the element
    RIGHT, // in the right side of the element
}

export enum PositionY {
    TOP, // at the top of the element
    BOTTOM, // at the bottom of the element
}

@Injectable()
export class AddNewClientDialogService {
    constructor(public dialog: MatDialog) {}

    openDialogWithElement(element: HTMLElement, posX: PositionX, posY: PositionY): MatDialogRef<AddNewClientDialogComponent> {
        const widthOfDialog = 320;
        const heightOfDialog = 340;

        const boundary: DOMRect = element.getBoundingClientRect();

        let left: number = posX === PositionX.LEFT ? boundary.left : boundary.left + boundary.width - widthOfDialog;
        const top: number = posY === PositionY.BOTTOM ? boundary.top : boundary.top + boundary.height - heightOfDialog;

        left = left - 10;

        return this.dialog.open(AddNewClientDialogComponent, {
            panelClass: 'add-new-client-dialog-panel',
            backdropClass: 'add-new-client-dialog-backdrop',
            disableClose: true,
            closeOnNavigation: true,
            data: {},
            restoreFocus: false,
            position: {
                top: `${top}px`,
                left: `${left}px`,
            },
            maxWidth: '100vw',
        });
    }
}
