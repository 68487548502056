import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { environment } from "@environments/environment";
import { BehaviorSubject, from } from "rxjs";
import { UtilService } from "../services/util.service";

export interface AppUpdate {
  data: {
    version: string;
  };
}

@Injectable({ providedIn: "root" })
export class UpdateService {
  public updating: boolean;
  private applicationUpdate: BehaviorSubject<AppUpdate> = new BehaviorSubject({} as AppUpdate);

  constructor(private _utilService: UtilService, private _updates: SwUpdate) {}

  public updateApp(version: string): void {
    if (environment.checkForUpdates) {
      this.updating = true;
      this._utilService.setGPMVersion(version);

      this.applicationUpdate.next({ data: { version: version } });

      from(this._updates.checkForUpdate()).subscribe({
        next: () => {
          this._updates.activateUpdate().then(() => {
            setTimeout(() => {
              this.updating = false;
            }, 2000);
          });
        },
      });
    }
  }
}
