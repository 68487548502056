import { Platform } from "@angular/cdk/platform";
import { ComponentPortal } from "@angular/cdk/portal";
import { Component, ComponentRef, ElementRef, Inject, Injectable, InjectionToken, Injector, OnDestroy, OnInit, StaticProvider } from "@angular/core";
import { LoadingService } from "app/core/services/loading.service";
import { CacheService } from "app/core/cache/cache.service";
import { CacheableItems, SystemUserDBModel } from "app/core/cache/cache.types";
import { IndexedDBService } from "app/core/cache/indexed-db.service";
import { EmailSuggestContextType } from "app/core/enums/email.enums";
import { EntityTypeEnum } from "app/core/enums/physio-enums";
import { SystemUserModel } from "app/core/models/organisation/system-user.model";
import { EmailService, TemplateType } from "app/core/services/email.service";
import { forkJoin, Observable, of, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { EmailData, EmailMasterData, EmailSuggestionRequestModel } from "../email.types";

export const EMAIL_COMPOSE_DATA = new InjectionToken<any>('APP_EMAIL_COMPOSE_DATA');

export interface EmailConfigInput {
  recipientEmail: string,
  entityType: EntityTypeEnum,
  templateTypes: Array<TemplateType>,
  siteId: number,
  clientId: number,
  emailSuggestionEntityId: number,
  emailSuggestionSubEntityId: number
}

@Component({
  selector: "app-email-compose-container",
  templateUrl: "./email-compose-container.component.html",
})
export class EmailComposerContainerComponent implements OnInit, OnDestroy {
  public destroySubscriptions: Subject<boolean> = new Subject<boolean>();

  public currentUser: SystemUserDBModel;
  public masterEemailData: EmailMasterData = null;
  public isDataReady: boolean = null;

  public emailDataList: Array<EmailData>;
  constructor(
    private _platform: Platform,
    private _loadingService: LoadingService,
    private _emailService: EmailService, 
    private _cacheService: CacheService,
    private _indexedDbService: IndexedDBService,
    @Inject(EMAIL_COMPOSE_DATA) public data: EmailConfigInput
  ) {
    this.emailDataList = [];
    this.masterEemailData = {
      emailSignature: null,
      currentUser: null,
      emailTemplates: null,
      emailSenders: null,
      attachmentTypes: null,
      suggestedEmails: null
    };
  }

  ngOnInit() {
    if (!this.isDataReady) {
      this._loadingService.show();
      const systemUserId = this._cacheService.get(CacheableItems.GPMSystemUserId);
      forkJoin({
        currentUser: this._indexedDbService.getDBActiveSystemUserById(systemUserId),
        emailSignature: this.getEmailSignature(),
        emailTemplates: this.getTemplates(),
        emailSenders: this.getSenderEmails()
      }).subscribe(response => {
        console.log("Response", response);
        this.masterEemailData.currentUser = response.currentUser;
        this.masterEemailData.emailSignature = response.emailSignature;
        this.masterEemailData.emailTemplates = response.emailTemplates;
        this.masterEemailData.emailSenders = response.emailSenders;

        // const attachmentResponse: any = results[3];
        // this.masterEemailData.attachmentTypes = attachmentResponse;

        // const emailSuggestionResponse: any = results[4];
        // this.masterEemailData.suggestedEmails = emailSuggestionResponse.emailList;

        this.emailDataList.forEach(item => item.MessageBody = this.masterEemailData.emailSignature);

        this._loadingService.hide();

        this.isDataReady = true;
      });
    }
  }

  public addNewEmailData() {
    const isMobile = this._platform.IOS || this._platform.ANDROID;
    if (isMobile && this.emailDataList.length === 1) {
      return;
    }

    const defaultEmailData: EmailData = {
      SiteId: this.data.siteId,
      ClientId: this.data.clientId,
      RecipientEmail: this.data.recipientEmail,
      AppointmentId: null,
      MessageBody: this.masterEemailData.emailSignature,
      EmailsTo: [],
      EmailsCc: [],
      EmailsBcc: [],
      Attachments: [],
      SelectedEmailFrom: null,
      Subject: "",
      IsMinimize: false,
    };
    this.emailDataList = this.emailDataList.concat([defaultEmailData]);
  }

  private getEmailSignature(): Observable<any> {
    return this._emailService
      .getEmailSignature(this.data.siteId)
      .pipe(takeUntil(this.destroySubscriptions))
  }

  private getTemplates(): Observable<any> {
    const templateTypes: number[] = [];
    this.data.templateTypes.forEach(type => {
      templateTypes.push(type.id);
    });
    return this._emailService
      .getEmailTemplates(templateTypes)
      .pipe(takeUntil(this.destroySubscriptions))
  }

  private getSenderEmails(): Observable<any> {
    return this._emailService
      .getEmailSenders()
      .pipe(takeUntil(this.destroySubscriptions))
  }


  // private getEmailSuggestions(): Observable<any>{
  //   const emailSuggestionRequestModel : EmailSuggestionRequestModel = { EmailSuggestContextType: EmailSuggestContextType.ClientDetails , EntityId: this.data.emailSuggestionEntityId, SubEntityId: this.data.emailSuggestionSubEntityId} 
  //   return this.emailService.getEmailSuggestions(emailSuggestionRequestModel)
  //   .pipe(takeUntil(this.destroySubscriptions));
  // }

  removeEmailForm(data: EmailData): void {
    const index = this.emailDataList.indexOf(data);
    if (index > -1) {
      this.emailDataList.splice(index, 1);

    }
  }

  ngOnDestroy() {
    console.log("Destroy");
  }
}
