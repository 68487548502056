<app-edit-card-comp class="flex-1 personal-info info-section" [status]="status" (editEvent)="onEdit()"
    (saveEvent)="onSave()" (cancelEvent)="onCancel()"
    [disableSave]="clientForm.pristine && clientDetailsForm.pristine && debtorForm.pristine"
    [disableActions]="isLoading">
    <div class="flex md:flex-row flex-col" (click)="onEdit()">
        <mat-card-subtitle>Other Information</mat-card-subtitle>
        <!-- Edit -->
        <div class="grid sm:grid-cols-2 grid-cols-1 gap-4 flex-1 w-full" *ngIf="status === 'EDIT'">
            <!-- First Column -->
            <div class="w-full flex flex-col gap-4">
                <!-- Charge To -->
                <mat-form-field class="w-full" appearance="outline">
                    <mat-label>Charge To</mat-label>
                    <app-autocomplete-field [formControl]="chargeToFC" [items]="clients"
                        [fetchDataFrom]="fetchClientData" (isLoading)="isLoading = $event"
                        (onOptionSelected)="chargeToFC.setValue($event.option?.value || $event); clientForm.markAsDirty()">
                    </app-autocomplete-field>
                </mat-form-field>

                <!-- Medical Center -->
                <mat-form-field class="w-full" appearance="outline">
                    <mat-label>Medical Center</mat-label>
                    <app-autocomplete-field [formControl]="medicalCentreFC" [items]="clinics"
                        (isLoading)="isLoading = $event"
                        (onOptionSelected)="medicalCentreFC.setValue($event.option?.value || $event); clientDetailsForm.markAsDirty()">
                    </app-autocomplete-field>
                </mat-form-field>

                <!-- GP -->
                <mat-form-field class="w-full" appearance="outline">
                    <mat-label>GP</mat-label>
                    <app-autocomplete-field [formControl]="gpFC" [items]="referrers" (isLoading)="isLoading = $event"
                        (onOptionSelected)="gpFC.setValue($event.option?.value || $event); clientDetailsForm.markAsDirty()">
                    </app-autocomplete-field>
                </mat-form-field>

                <!-- Specialist -->
                <mat-form-field class="w-full" appearance="outline">
                    <mat-label>Specialist</mat-label>
                    <app-autocomplete-field [formControl]="specialistFC" [items]="referrers"
                        (isLoading)="isLoading = $event"
                        (onOptionSelected)="specialistFC.setValue($event.option?.value || $event); clientDetailsForm.markAsDirty()">
                    </app-autocomplete-field>
                </mat-form-field>

                <!-- Internal Ref -->
                <ng-container *ngIf="showInternalRef">
                    <div class="flex items-center">
                        <mat-form-field class="w-full" appearance="outline">
                            <mat-label>Internal Ref</mat-label>
                            <input matInput [formControl]="internalRefFC" />
                        </mat-form-field>
                        <button class="ml-2" mat-button (click)="getNextClientInternalRefNo()" [color]="'primary'"
                            [disabled]="isLoading">
                            Set
                        </button>
                    </div>
                </ng-container>

                <!-- Vaccination Status -->
                <ng-container *ngIf="isGPMNZ">
                    <div class="flex items-center">
                        <mat-form-field class="w-full" appearance="outline">
                            <mat-label>Vaccination Status</mat-label>
                            <input readonly matInput [value]="clientVaxStatus" />
                        </mat-form-field>
                        <button class="ml-2" mat-button (click)="openSetClientVaxStatus()" [color]="'primary'"
                            [disabled]="isLoading">
                            Set
                        </button>
                    </div>
                </ng-container>
            </div>

            <!-- Second Column -->
            <div class="w-full flex flex-col gap-4">
                <!-- NHI Number -->
                <ng-container *ngIf="isGPMNZ">
                    <mat-form-field class="w-full" appearance="outline">
                        <mat-label>NHI Number</mat-label>
                        <input matInput [formControl]="nhiNumberFC" />
                    </mat-form-field>
                </ng-container>

                <!-- Diabetes Risk -->
                <div class="flex items-center" *ngIf="showDiabetesRiskType">
                    <!-- Diabetes Risk Type -->
                    <mat-form-field class="max-w-40" appearance="outline">
                        <mat-label>Diabetes Risk</mat-label>
                        <mat-select [formControl]="diabetesRiskTypeFC">
                            <mat-option *ngFor="let diabetesRiskType of diabetesRiskTypes" [value]="diabetesRiskType"
                                (onSelectionChange)="onDiabetesRiskTypeSelect($event)">
                                {{diabetesRiskType.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!-- Diabetes Risk Date -->
                    <ng-container *ngIf="diabetesRiskTypeFC.value?.id">
                        <mat-form-field appearance="outline" floatLabel="auto" class="ml-2 max-w-30">
                            <input matInput [matDatepicker]="diabetesRiskDatePicker" [formControl]="diabetesRiskDateFC">
                            <mat-datepicker-toggle matSuffix [for]="diabetesRiskDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #diabetesRiskDatePicker></mat-datepicker>
                        </mat-form-field>
                    </ng-container>
                </div>

                <!-- Referral Source -->
                <mat-form-field appearance="outline" floatLabel="auto" class="w-full">
                    <mat-label>Referral Source</mat-label>
                    <app-autocomplete-field [formControl]="referralSourceFC" [items]="referralSources"
                        (isLoading)="isLoading = $event"
                        (onOptionSelected)="referralSourceFC.setValue($event.option?.value || $event); clientForm.markAsDirty()">
                    </app-autocomplete-field>
                </mat-form-field>

                <!-- Why Choose Us -->
                <mat-form-field appearance="outline" floatLabel="auto" class="w-full">
                    <mat-label>Why Choose Us</mat-label>
                    <app-autocomplete-field [formControl]="whyChooseUsFC" [items]="whyChooseUsItems"
                        (isLoading)="isLoading = $event"
                        (onOptionSelected)="whyChooseUsFC.setValue($event.option?.value || $event); clientForm.markAsDirty()">
                    </app-autocomplete-field>
                </mat-form-field>

                <!-- Referred By -->
                <div class="flex items-center">
                    <mat-form-field class="w-full" appearance="outline">
                        <mat-label>Referred By</mat-label>
                        <input readonly matInput [formControl]="referredByFC" />
                    </mat-form-field>
                    <button class="ml-2" mat-button (click)="openSetReferredByDialog()" [color]="'primary'"
                        [disabled]="isLoading">
                        Set
                    </button>
                </div>
            </div>
        </div>

        <!-- View -->
        <div class="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 flex-1" *ngIf="status !== 'EDIT'">
            <div class="w-full element">
                <div class="title">Charge To</div>
                <div class="info">{{ chargeToFC.value?.name || '-'}}</div>
            </div>

            <div class="w-full element">
                <div class="title">Medical Centre</div>
                <div class="info">{{ medicalCentreFC.value?.name || '-' }}</div>
            </div>

            <div class="w-full element">
                <div class="title">GP</div>
                <div class="info">{{ gpFC.value?.name || '-' }}</div>
            </div>

            <div class="w-full element">
                <div class="title">Specialist</div>
                <div class="info">{{ specialistFC.value?.name || '-' }}</div>
            </div>

            <ng-container *ngIf="showInternalRef">
                <div class="w-full element">
                    <div class="title">Internal Ref</div>
                    <div class="info">{{ internalRefFC.value || '-' }}</div>
                </div>
            </ng-container>

            <ng-container *ngIf="isGPMNZ">
                <div class="w-full element">
                    <div class="title">NHI Number</div>
                    <div class="info">{{ nhiNumberFC.value || '-' }}</div>
                </div>
            </ng-container>

            <ng-container *ngIf="showDiabetesRiskType">
                <div class="flex items-center">
                    <div class="w-full element">
                        <div class="title">Diabetes Risk</div>
                        <div class="info">
                            <span>
                                {{ diabetesRiskTypeFC.value?.name || '-' }}
                            </span>
                            <ng-container *ngIf="diabetesRiskDateFC.value && diabetesRiskTypeFC.value">
                                <span> - {{ diabetesRiskDateFC.value | formatDate }}</span>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="w-full element">
                <div class="title">Referral Source</div>
                <div class="info">{{ referralSourceFC.value?.name || '-' }}</div>
            </div>

            <div class="w-full element">
                <div class="title">Why Choose Us</div>
                <div class="info">{{ whyChooseUsFC.value?.name || '-' }}</div>
            </div>

            <div class="w-full element">
                <div class="title">Referred By</div>
                <div class="info">{{ referredByFC.value || '-' }}</div>
            </div>

            <ng-container *ngIf="isGPMNZ">
                <div class="w-full element">
                    <div class="title">Vaccination Status</div>
                    <div class="info">{{ clientVaxStatus || '-' }}</div>
                </div>
            </ng-container>
        </div>
    </div>
</app-edit-card-comp>