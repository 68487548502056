<div class="mat-dialog-header">
    <h2 mat-dialog-title>Duplicate clients found</h2>
    <button mat-icon-button class="mat-dialog-close" (click)="close(null)">
        <span class="sr-only">Close dialog</span>
        <mat-icon svgIcon="gpm_close"></mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div class="dc-container grid sm:grid-cols-2">

        <div class="dc-group">
            <div class="dc-group__content dc-group__content--new">
                <h4 class="dc-group__title text-lg">New Client Details</h4>
                <dl class="dc-list">
                    <div class="dc-list__item">
                        <dt class="caption mb-1">First Name</dt>
                        <dd>{{ClientInfo.FirstName}}</dd>
                    </div>
                    <div class="dc-list__item">
                        <dt class="caption mb-1">Surname</dt>
                        <dd>{{ClientInfo.LastName}}</dd>
                    </div>
                    <div class="dc-list__item" *ngIf="ClientInfo.DateOfBirth !== null">
                        <dt class="caption mb-1">Date of Birth</dt>
                        <dd>{{ClientInfo.DateOfBirth.toString() | formatDate}}</dd>
                    </div>
                </dl>
            </div>
            <button mat-stroked-button (click)="CreateClient()" color="primary">Create a new Client</button>
        </div>

        <div class="dc-group">
            <div class="dc-group__content">
                <h4 class="dc-group__title text-lg">Existing Client Details</h4>
                <p>We've found the following clients with the same details:</p>
                <div class="dc-existing-item" *ngFor="let client of Duplicates">
                    <span (click)="selectClientOnHeader(client)" class="dc-existing-item__link text-lg cursor-pointer">{{client.FIRST_NAME}} {{client.LAST_NAME}}</span>
                    <div class="grid grid-cols-2 gap-4">
                        <span>{{client.DOB?.toString() | formatDate}}</span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</mat-dialog-content>