import { PipeTransform, Pipe } from "@angular/core";
import { environment } from "@environments/environment";
import { UtilService } from "../services/util.service";

@Pipe({
  name: "formatCurrency"
})
export class CurrencyFormatterPipe implements PipeTransform {
  constructor(private _utilService: UtilService) {}

  transform(toConvert: number) {
    if (!toConvert) {
      toConvert = 0;
    }

    const locale = this._utilService.getLocale();
    const currentCurrency = this._utilService.getCurrencyFromLocale();

    const formatter = new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currentCurrency,
      currencyDisplay: "symbol",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });

    return formatter.format(toConvert);
  }
}
