<div class="mat-dialog-header">
    <h2 mat-dialog-title>Add Client</h2>
    <button mat-icon-button class="mat-dialog-close" (click)="close()" data-cy='CloseCreateClient'>
        <span class="sr-only">Close dialog</span>
        <mat-icon svgIcon="gpm_close"></mat-icon>
    </button>
</div>
<mat-dialog-content>
    <form [formGroup]="createClientForm">
        <mat-form-field class="w-full mb-4" appearance="outline" floatLabel="auto">
            <mat-label>First Name</mat-label>
            <input matInput formControlName ="firstNameFC" required data-cy='FirstNameInput'/>
            <mat-icon matSuffix *ngIf="isFormSubmitted && createClientForm.get('firstNameFC').hasError('required')" svgIcon="error_icon"></mat-icon>
        </mat-form-field>
        <mat-form-field class="w-full mb-4" appearance="outline" floatLabel="auto">
            <mat-label>Surname</mat-label>
            <input matInput formControlName="surNameFC" required data-cy='SurNameInput'/>
            <mat-icon matSuffix *ngIf="isFormSubmitted && createClientForm.get('surNameFC').hasError('required')" svgIcon="error_icon"></mat-icon>
        </mat-form-field>
        <mat-form-field class="w-full mb-4" appearance="outline" floatLabel="auto">
            <mat-label>Date of Birth</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="dobFC">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="w-full mb-2" appearance="outline"  floatLabel="auto">
            <mat-label>Select Site</mat-label>
            <mat-select placeholder="Site" formControlName="homeSiteFC" required data-cy='SelectSiteDropdown'>
                <mat-optgroup label="Site">
                    <mat-option *ngFor="let site of sites" [value]="site">
                        {{ site.name }}
                    </mat-option>
                </mat-optgroup>
            </mat-select>
            <mat-icon matSuffix *ngIf="isFormSubmitted && createClientForm.get('homeSiteFC').hasError('required')" svgIcon="error_icon"></mat-icon>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" [disabled]="CreatingClient" (click)="validateBeforeCreate()" data-cy='BtnCreateNewClient'>Create</button>
</mat-dialog-actions>