import { Injectable } from '@angular/core';
import { UploadFileType } from "@components/letters-and-uploads/letters-uploads.types";
import { Observable } from 'rxjs';
import { APIService } from '../api/api.service';
import { LetterTemplateModel } from "../models/organisation/letter-template.model";

@Injectable({ providedIn: 'root' })
export class UploadService {
    constructor(private _apiService: APIService) {}

    public getUploadFileTypes(): Observable<UploadFileType[]> {
        return this._apiService.get(`/uploads?command=getFileTypes`);
    }
}

