import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';

import {
  AbstractServersideTableService, ServerSideTableService, ServerSideTableStatus
} from './table-serverside.service';
import { AbstractFilterColumnServer, CustomFilterDialogService, FilterColumnService, FilterItem, FilterItemHelper } from './table-column-filter/table-serverside-column-filter.service';
import { ColumnGroupConfig, ColumnItem, RowItemConfig, TableDataChangeEvent, TableItemAction } from './mat/mat-table-wrapper.service';
import { MatTableWrapperComponent } from './mat/mat-table-wrapper.component';
import { SummaryItemDefinition } from './mat/mat-table-summary-service';
import { CellConfigProvider } from './mat/mat-table-cell-content.service';
import { SpinnerService } from 'app/core/services/spinner.service';
import { CacheService } from "app/core/cache/cache.service";
import { BrowserStorageCache } from "@aws-amplify/cache";
import { LoadingService } from "app/core/services/loading.service";

@Component({
  selector: 'app-serverside-table',
  templateUrl: './table-serverside.component.html',
  styleUrls: ['./table-serverside.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  // encapsulation: ViewEncapsulation.None
})

/**
 * Purpose:
 * - The component used for displaying table data whose structure is passed from the parent..
 * - It contains a filter component which allows user to see data based on filter data.
 * - It has a service named ServerSideTableService which acts as the data provider for the components.
 * Usage:
 * - Parents need to implement ServerSideTableService to provides the data structure, and how to retrieve data for the table.
 * - If the parent component wants to use the filter feature of the TableServerSide component, it needs to provide FilterColumnService
 * which provides the filter columns data and all additional data for it. For example, Select dialog needs data to display in the dropdown
 * - Parents need to inject those services above so that the TableServerside and TableColumnFilter components can use.
 * - The component triggers ActionEvent when users click on the action item in the action list.
 */
export class TableServerSideComponent implements OnInit, OnChanges, AfterViewInit {
  // all configuration of component
  // is Filter component hidden
  @Input() hideFilter = false;
  @Input() isNoBorder = false;

  @Input() editable = false;
  public editItemList: Array<any>;
  @Output() dataChanged: EventEmitter<TableDataChangeEvent> = new EventEmitter();

  // summary
  summaryDefs: Array<SummaryItemDefinition> = [];

  // cascading for wrapper table
  @Input() isNoPaginator = false;
  @Input() isMultipleSelection = false;
  @Output() actionEvent = new EventEmitter<{
    rowItem: any;
    action: TableItemAction;
  }>();
  @Output() selectEvent = new EventEmitter<{
    selectedList: Array<any>;
    actionElement: any;
  }>();

  // input for filter component
  @Input() customFilterDialogService: CustomFilterDialogService;
  @Input() serversideFilterService: FilterColumnService;

  // property for tables
  public columns: Array<ColumnItem> = [];
  public actionList: Array<TableItemAction> = [];
  public cellConfigProvider: CellConfigProvider;
  public rowConfig: RowItemConfig;

  protected originalData: Array<any>;
  public data: Array<any> = [];

  public initialSelectedData: Array<any> = [];

  @Input() displayColumnConfig: ColumnGroupConfig;

  @Input() serversideTableService: ServerSideTableService;

  public selectedFilterList: Array<FilterItem>;


  // instance of wrapper-table
  @ViewChild(MatTableWrapperComponent) matTableWrapperComponent: MatTableWrapperComponent;

  // table status
  tableStatus: ServerSideTableStatus;

  constructor(
    protected spinnerService: SpinnerService,
    protected changeDetector: ChangeDetectorRef,
    protected loadingService: LoadingService,
    protected _cacheService : CacheService
  ) {

  }

  ngOnInit() {
    // check if the services are available, if not then assign the default one
    this.updateService(this.serversideTableService, this.serversideFilterService);

    this.editItemList = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    const serversideTableService: ServerSideTableService = (changes.serversideTableService && !changes.serversideTableService.firstChange) ? changes.serversideTableService.currentValue : null;
    const serversideFilterService: FilterColumnService = (changes.serversideFilterService && !changes.serversideFilterService.firstChange) ? changes.serversideFilterService.currentValue : null;

    if (serversideTableService || serversideFilterService) {
      this.updateService(serversideTableService, serversideFilterService);
    }
  }

  ngAfterViewInit(): void {

  }

  private async updateService(serversideTableService: ServerSideTableService, serversideFilterService: FilterColumnService) {
    this.serversideTableService = serversideTableService || new AbstractServersideTableService();
    this.serversideFilterService = serversideFilterService || new AbstractFilterColumnServer();

    // get data from services
    this.columns = this.serversideTableService.getColumnDefinitions();
    this.actionList = this.serversideTableService.getActionList();
    this.cellConfigProvider = this.serversideTableService.getCellConfigProvider ? this.serversideTableService.getCellConfigProvider() : null;
    this.rowConfig = this.serversideTableService.getRowConfig ? this.serversideTableService.getRowConfig() : null;

    if (this.serversideTableService.getDisplayColumnConfig) {
      this.displayColumnConfig = this.serversideTableService.getDisplayColumnConfig();
    }

    // retrieve selectedFilter from the begining to update of table at the first time
    const allowedFilterFields = this.serversideFilterService.getAllowedFilterFields();

    const selectedFilter: Array<FilterItem> = FilterItemHelper.retrieveSelectedFilterList(allowedFilterFields);

    await this.applyFilter(selectedFilter);

    this.defaultFilterOfflineData();

    // retrieve summary info
    this.summaryDefs = this.serversideTableService.getSummaryDefinition ? this.serversideTableService.getSummaryDefinition() : [];
  }

  // do clientside filter for the initial creation
  protected defaultFilterOfflineData(): void {

  }

  // event handlers for filter columns component
  public async applyFilter(filterItemList: Array<FilterItem>) {
    this.loadingService.show();

    this.tableStatus = ServerSideTableStatus.FETCHING;

    // // check if we have any filter saved in the cache for the current filter service
    const selectedFiltersInTheCache = BrowserStorageCache.getItem(this.serversideFilterService.getFilterServiceUniqueName());
    if(selectedFiltersInTheCache){
      selectedFiltersInTheCache.forEach(filter => {
        filterItemList.push(filter);
      })
    }
    this.originalData = await this.serversideTableService.getData(filterItemList).toPromise();
    this.data = this.originalData;

    this.tableStatus = ServerSideTableStatus.NORMAL;

    if (this.data?.length === 0) this.tableStatus = ServerSideTableStatus.NO_DATA;

    if (this.serversideTableService.getInitialSelectedData) {
      this.initialSelectedData = await this.serversideTableService.getInitialSelectedData(this.originalData, filterItemList).toPromise();
      // set editItemList
      this.editItemList = this.initialSelectedData;
    }

    this.changeDetector.detectChanges();

    this.loadingService.hide();
  }

  public async selectedFilterHandler(data: {
    currentSelectedFilter: FilterItem;
    selectedFilterList: Array<FilterItem>;
  }) {
    const { currentSelectedFilter, selectedFilterList } = data;

    if(data.selectedFilterList && data.selectedFilterList.length > 0){
      const filterServiceName = this.serversideFilterService.getFilterServiceUniqueName(); 

      const customFilters = data.selectedFilterList.filter(f => f.config?.isCustom === true);
      if(customFilters){
        let customFiltersForCache : FilterItem[] = [];
        customFilters.forEach((filter : FilterItem) => {         
        let customFilter : FilterItem = {
          fieldName : filter.fieldName, 
          displayName: filter.displayName,
          config: {
            isCustom: filter.config?.isCustom
          },
          filterValue: {
            filterText: filter.filterValue?.filterText,
            filterValue : filter.filterValue?.filterValue
          }
        }
        customFiltersForCache.push(customFilter);
      });
      BrowserStorageCache.setItem("Custom-"+ filterServiceName, customFiltersForCache);
    }
      
      let normalFilters = data.selectedFilterList.filter(f => f.config?.isCustom !== true);
      
      // check if we already have saved filter for this filter service
      const storedFilters = BrowserStorageCache.getItem(filterServiceName);
      // if so add those to the new list
      if (storedFilters && storedFilters.length > 0) {
        storedFilters.forEach((storedFilter: FilterItem) => {
          let isFilterCached = normalFilters.map((nf) => nf.displayName).includes(storedFilter.displayName);
          if (!isFilterCached) {
            normalFilters.push(storedFilter);
          }
        });
      }
      BrowserStorageCache.setItem(filterServiceName, normalFilters);
         
    }      

    // right now, we actively update data when any changes happens in the filter
    await this.applyFilter(data.selectedFilterList);

    // check if the service implements filterOfflineData method
    if (this.serversideTableService.filterOfflineData) {
      this.data = this.serversideTableService.filterOfflineData(
        currentSelectedFilter,
        selectedFilterList,
        this.originalData
      );

      // this.changeDetector.detectChanges();
    }
  }

  // wrapper table to set editItemList by the selected one.
  selectRow(data: {
    selectedList: Array<any>;
    actionElement: any;
  }): void {
    this.editItemList = data.selectedList;

    this.selectEvent.emit(data);
  }

  // allows to unselect checkboxes from parent
  public resetSelectedItems() {
    this.matTableWrapperComponent.resetSelectedItems();
  }

  requestNewUpdate() {
    // only update summary definition
    this.summaryDefs = this.serversideTableService.getSummaryDefinition ? this.serversideTableService.getSummaryDefinition() : [];
  }

  // allows to request get data from the consuming class
  public requestGetData(selectedFilterList: Array<FilterItem>){
    this.applyFilter(selectedFilterList);
  }
}


