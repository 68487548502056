import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BreadcrumbsService } from "@components/breadcrumbs/breadcrumbs.service";
import { ClientPersonalDetailsModel } from "app/core/client/models/client-personal-details.model";
import { UtilService } from "app/core/services/util.service";
import { ClientDetailsSideMenuActions } from "../../client.types";

@Component({
  selector: "app-client-details-side-menu",
  templateUrl: "client-details-side-menu.component.html"
})
export class ClientDetailsSideMenuComponent implements OnInit {
  @Output() onOptionClick = new EventEmitter<ClientDetailsSideMenuActions>();

  ClientDetailsSideMenuActions = ClientDetailsSideMenuActions;
  @Input() option: ClientDetailsSideMenuActions;
  @Input() clientId: number;
  isMobile  = false;
  
  constructor(private _breakpointObserver: BreakpointObserver,
    private _breadCrumbService: BreadcrumbsService,
    private _activatedRoute: ActivatedRoute, 
    private _utilService: UtilService,
    private _router: Router) {
      this.isMobile = this._breakpointObserver.isMatched(Breakpoints.XSmall);
    }

  ngOnInit() {}

  _onOptionClick(option: ClientDetailsSideMenuActions): void {
    if (option) {
        let url: string = "";
        switch (option) {
          case ClientDetailsSideMenuActions.ClientSummary:
            url = `/client-details/${this.clientId}/client`;
            break;
          case ClientDetailsSideMenuActions.ClientDetail:
            url = `/client-details/${this.clientId}/detail`;
            break;
          case ClientDetailsSideMenuActions.LettersUploads:
            url = `/client-details/${this.clientId}/documents`;
            break;
          case ClientDetailsSideMenuActions.Healthlink:
            url = `/client-details/${this.clientId}/healthlink`;
            break;
          case ClientDetailsSideMenuActions.Account:
            url = `/client-details/${this.clientId}/accounts`;
            break;
          case ClientDetailsSideMenuActions.Appointments:
            url = `/client-details/${this.clientId}/appointments`;
            break;
          case ClientDetailsSideMenuActions.Events:
            url = `/client-details/${this.clientId}/events`;
            break;
          case ClientDetailsSideMenuActions.Conditions:
            url = `/client-details/${this.clientId}/conditions`;
            break;
          case ClientDetailsSideMenuActions.FullHistory:
            url = `/client-details/${this.clientId}/full-history`;
            break;
          case ClientDetailsSideMenuActions.Memberships:
            url = `/client-details/${this.clientId}/memberships`;
            break;
          case ClientDetailsSideMenuActions.Relationships:
            url = `/client-details/${this.clientId}/relationships`;
            break;
          default:
            url = null;
            break;
        }
        if (url) {
          this._router.navigate([url]);
        }
        
    }
  }
}
