import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TableItemAction } from "@components/table/mat/mat-table-wrapper.service";
import { ClientWriteOffsFilterColumnService, ClientWriteOffsServersideTableService } from "./client-writeoffs-table.service";

@Component({
  selector: "app-client-writeoffs",
  templateUrl: "./client-writeoffs.component.html",
  providers: [ClientWriteOffsServersideTableService, ClientWriteOffsFilterColumnService],
})
export class ClientWriteOffsComponent implements OnInit {
  clientId: string;
  constructor(
    private _activatedRoute: ActivatedRoute,
    public tableService: ClientWriteOffsServersideTableService,
    public filterColumnService: ClientWriteOffsFilterColumnService
  ) {
    const params = this._activatedRoute.snapshot.params;
    this.clientId = params['clientId'];

    this.tableService.clientId = this.clientId;
  }

  ngOnInit(): void {

  }

  onActionHandler(item: { rowItem: any; action: TableItemAction }): void {

  }
}
