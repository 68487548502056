import { Injectable, PipeTransform, Pipe } from "@angular/core";
import { DateTimeService } from "../services/datetime.service";


@Injectable()
@Pipe({
  name: "formatDateTime"
})
export class DateTimeFormatterPipe implements PipeTransform {
  constructor(private _datetimeService: DateTimeService) {}

  transform(toConvert: string) {
    if (!toConvert) return;

    return this._datetimeService.FormatToDateTime(toConvert);
  }
}
