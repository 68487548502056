import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from "@angular/core";
import { animations } from "@shared/animations";
import { EditCardStatus } from "../card.types";

@Component({
  selector: "app-edit-card-comp",
  templateUrl: "./edit-card.component.html",
  styleUrls: ["./edit-card.component.scss"],
  animations: animations,
})
export class EditCardComponent implements OnInit {
  @HostBinding("class") classes = "app-edit-card";

  @Input() status: EditCardStatus;
  @Input() disableSave?: boolean;
  @Input() disableActions?: boolean;

  @Output() editEvent: EventEmitter<void> = new EventEmitter();
  @Output() saveEvent: EventEmitter<void> = new EventEmitter();
  @Output() cancelEvent: EventEmitter<void> = new EventEmitter();
  public expanded = false;

  constructor() {}

  ngOnInit(): void {
    this.status = this.status || EditCardStatus.NORMAL;
  }

  selectEdit(): void {
    if (this.status !== EditCardStatus.EDIT) {
      this.editEvent.emit();
    }
  }

  selectSave(): void {
    this.saveEvent.emit();
  }

  selectCancel(): void {
    this.cancelEvent.emit();
  }
}
