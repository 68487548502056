import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  Input
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import {
  FilterType,
  FilterItem,
  DisplayFilterItem,
  FilterItemHelper,
  DialogOutput,
  FilterColumnService,
  CustomFilterDialogService
} from './table-serverside-column-filter.service';

import { SelectDialogComponent } from './dialogs/select-dialog.component';
import { TextDialogComponent } from './dialogs/text-dialog.component';
import { DateRangeDialogComponent } from './dialogs/date-range-dialog.component';
import { AutoCompleteDialogComponent } from './dialogs/autocomplete-dialog.component';
import { CheckDialogComponent } from './dialogs/check-dialog.component';
import { DateDialogComponent } from './dialogs/date-dialog.component';
import { GeneralFilterDialogService } from './dialogs/general-filter-dialog.component';
import { GeneralFilterDialogOutput } from './dialogs/general-filter-dialog.service';
import { BrowserStorageCache } from "@aws-amplify/cache";

/**
 * Purpose:
 * 	- The components used for users to select the filters. The column filter data is dynamically passed from the parent.
 * 	- It contains various dialog components to allow the user to input the value for the filter.
 * 	- It contains a service named FilterColumnService which acts as the data provider for the components.
 * 	- It triggers an event that contains the selected filter with its data.
 * Usage:
 * 	- Parent components need to provide FilterColumnService and inject it into the TableColumnFilter component.
 */
@Component({
  selector: 'app-table-serverside-column-filter',
  templateUrl: './table-serverside-column-filter.component.html',
})
export class TableServerSideColumnFilterComponent implements OnInit {
  @Input() tableServersideFilterService: FilterColumnService;
  @Input() customFilterDialogService: CustomFilterDialogService;
  @ViewChild('rootElement') rootElement: ElementRef<HTMLElement>;
  @ViewChild('addFilter') addFilterBtn: ElementRef<HTMLElement>;

  @Output() applyEvent = new EventEmitter<Array<FilterItem>>();
  @Output() selectedFilterEvent = new EventEmitter<{
    currentSelectedFilter: FilterItem;
    selectedFilterList: Array<FilterItem>;
  }>();

  removable = true;
  selectable = true;

  filterOrderNo = 0; // this property is for sorting order when adding column.

  public getDataButtonText = '';
  public getPageTitle = '';
  public filterColumns: Array<DisplayFilterItem> = [];
  public availableColumns: Array<FilterItem> = []; // the non-selected filter list
  public allowedFilterFields: Array<FilterItem> = []; // original filter item list
  public cachedFilters : any;

  isUsingIndividualFilterDialog: boolean = false; // indicate to use individual dialog for each filter

  filterType = FilterType;

  constructor(
    protected generalFilterDialog: GeneralFilterDialogService,
    protected changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog
  ) {
    this.filterOrderNo = 0;
  }

  ngOnInit(): void {
    this.getDataButtonText = this.tableServersideFilterService.getDataButtonText();
    this.getPageTitle = this.tableServersideFilterService.getPageTitle();
    this.allowedFilterFields = this.tableServersideFilterService.getAllowedFilterFields();
    // check if we have any filter saved in the cache for the current filter service other than healthlink filter as we deal with it seprately on hl component itself
    if(this.tableServersideFilterService.getFilterServiceUniqueName() !== "Healthlink_Filter"){
      this.cachedFilters = BrowserStorageCache.getItem(this.tableServersideFilterService.getFilterServiceUniqueName());
      if(this.cachedFilters){
        this.cachedFilters.forEach(filter => {
          // apply the selected filter value for allowed filters to display it correctly
          let allowedFilter = this.allowedFilterFields.find(f => f.fieldName === filter.fieldName);
          // convert data type correctly for date range
          if (allowedFilter.config?.isDateRange === true) {
            if (filter.filterValue?.filterFrom) {
              filter.filterValue.filterFrom = new Date(filter.filterValue.filterFrom);
            }

            if (filter.filterValue?.filterTo) {
              filter.filterValue.filterTo = new Date(filter.filterValue.filterTo);
            }
          }
          allowedFilter.filterValue = filter.filterValue;
          
        })
        this.cachedFilters = this.cachedFilters.map(this.getDisplayItemFromFilterItem.bind(this));
        this.cachedFilters.forEach(filter => {
          if(filter.filterType !== FilterType.SINGLE_CHECK_FILTER || (filter.filterType === FilterType.SINGLE_CHECK_FILTER && filter.displayValue !== "false")){
            this.filterColumns.push(filter);     
          }
               
        })
      }
    }
    
    this.setupFilterData();
  }

  public displayText(item: any): string {
    return 'displayText';
  }

  openDialog(item: FilterItem): void {
    const top: number = this.addFilterBtn.nativeElement.getBoundingClientRect().top;
    const left: number = this.addFilterBtn.nativeElement.getBoundingClientRect().left;

    this.openDialogWithPos(item, top, left);
  }

  openGeneralFilterDialog(): void {
    const elem = this.rootElement.nativeElement;
    const dialogSubscription = this.generalFilterDialog.displayFilterDialog(elem, {
        filterConfigs: this.allowedFilterFields,
        serviceCallback: this.callBackForGeneralFilter,
        filterServiceUniqueName: this.tableServersideFilterService.getFilterServiceUniqueName()
    }).subscribe((dialogRef: MatDialogRef<any>) => {
        const sub = dialogRef.afterClosed().subscribe((result: GeneralFilterDialogOutput) => {
            dialogSubscription.unsubscribe();
            sub.unsubscribe();

            if (result) {
                this.selectFilterSuccessFromGeneralDialog(result);
            }
        });
    });
  }

  openCustomFilterDialog(): void {
    const elem = this.rootElement.nativeElement;
    const dialogSubscription = this.customFilterDialogService.displayFilterDialog(elem, {
        filterConfigs: this.allowedFilterFields,
        serviceCallback: this.callBackForGeneralFilter,
    }).subscribe((dialogRef: MatDialogRef<any>) => {
        const sub = dialogRef.afterClosed().subscribe((result: GeneralFilterDialogOutput) => {
            dialogSubscription.unsubscribe();
            sub.unsubscribe();

            if (result) {
                this.selectFilterSuccessFromGeneralDialog(result);
            }
        });
    });
  }

    public callBackForGeneralFilter = (item: FilterItem, params: any): Observable<Array<any>> => {
        const selectedFilterList: Array<FilterItem> = this.allowedFilterFields.filter(
            (filterItem: FilterItem) => !!filterItem.filterValue
        );
        // add selectedFilterList into the parameters list
        const newParams: any = {
            ...params,
            selectedFilters: selectedFilterList
        };

        return this.tableServersideFilterService
            .getSelectDataForFilterItem(item, newParams);
    };

    // sucess selected filter from general-filter-dialog
    selectFilterSuccessFromGeneralDialog(result: GeneralFilterDialogOutput): void {
        // provide customization for the output from genaeralFilterDialog
        if (this.tableServersideFilterService.customOutputForDialog) {
          result = this.tableServersideFilterService.customOutputForDialog(result);
        }
      
        // update the filterItem
        result.filterDataList.forEach((item) => {
            const filterItem: FilterItem = item.config;
            // assign filterValue to item
            filterItem.filterValue = item.filterValue;
            filterItem.filterSortIndex = this.filterOrderNo;

            this.filterOrderNo++; // update filterOrderNo
            // if the filter type is single check and the filterValue is null,
            // then we don't need to show the filter at the top
            // and have to remove the filter from the filterColumns
            if(filterItem.config?.isSingleCheck){
              if(this.filterColumns){
                const removedCheckFilterIndex = this.filterColumns.findIndex( f => f.fieldName === filterItem.fieldName && filterItem.filterValue === null);
                if(removedCheckFilterIndex > -1){
                  this.filterColumns.splice(removedCheckFilterIndex, 1);
                }
              }
              
              // do the same from the cached list
              if(this.cachedFilters){
                const removedCheckFilterIndexFromCache = this.cachedFilters.findIndex( f => f.fieldName === filterItem.fieldName && filterItem.filterValue === null);
                if(removedCheckFilterIndexFromCache > -1){
                  this.cachedFilters.splice(removedCheckFilterIndexFromCache, 1);
                  BrowserStorageCache.setItem(this.tableServersideFilterService.getFilterServiceUniqueName(),this.cachedFilters );
                }
              }              
            }
            
        });

        // update displayFilterItem list and all data before rendering
        this.setupFilterData();

        // trigger the event to parent component to used when needed
        const selectedFilterList: Array<FilterItem> = FilterItemHelper.retrieveSelectedFilterList(this.allowedFilterFields);
        this.dispatchEventToUpdateFilter(null, selectedFilterList);
    }

  // open dialog for certain filter
  openDialogWithPos(item: FilterItem, top: number, left: number): void {
    const componentType = this.retrieveDialog(item);
    const dialogRef = this.dialog.open(componentType, {
      panelClass: 'column-filter-dialog',
      backdropClass: 'column-filter-backdrop',
      disableClose: true,
      closeOnNavigation: true,
      data: {
        column: item,
        serviceCallback: this.onServiceCallback
      },
      restoreFocus: false,
      position: {
        top: `${top}px`,
        left: `${left}px`
      }
    });

    dialogRef.afterClosed().subscribe((result: DialogOutput) => {
      if (!result)
        // user clicking to close the modal, but not select anything
        {return;}

      this.selectFilterSuccess(result);
    });
  }

  // method for retrieving data from dialog.
  selectFilterSuccess(result: DialogOutput): void {
    // update filterItem base on DialogOutput data
    const item: FilterItem = result.item as FilterItem;
    this.convertDialogDataToFilterItem(result);

    // update displayFilterItem list and all data before rendering
    this.setupFilterData();

    // trigger the event to parent component to used when needed
    const selectedFilterList: Array<FilterItem> = FilterItemHelper.retrieveSelectedFilterList(this.allowedFilterFields);
    this.dispatchEventToUpdateFilter(item, selectedFilterList);
  }

  // method to dispatch event to outside to do something else
  dispatchEventToUpdateFilter(currentItem: FilterItem, selectedFilterList: Array<FilterItem>): void {
    this.selectedFilterEvent.emit({
      currentSelectedFilter: currentItem,
      selectedFilterList: selectedFilterList
    });
  }

  // convert DialogOutput to FilterItem
  convertDialogDataToFilterItem(result: DialogOutput): void {
    const item: FilterItem = result.item as FilterItem;
    // assign filterValue to item
    item.filterValue = result.filterValue;
    item.filterSortIndex = this.filterOrderNo;

    this.filterOrderNo++; // update filterOrderNo
  }

  // convert filter item to displayFilterItem. DisplayFilterItem is used in html template to render.
  getDisplayItemFromFilterItem(item: FilterItem): DisplayFilterItem {
    return FilterItemHelper.getDisplayItemFromFilterItem(item);
  }

  // event handler when user remove the selected filter
  removeFilter(displayItem: DisplayFilterItem): void {
    const item: FilterItem = this.allowedFilterFields.find(
      s => s.fieldName === displayItem.fieldName
    );
    if (item) {
      // reset filterValue
      item.filterValue = null;

      const removedItemFilterIndex = this.filterColumns.findIndex(f => f.fieldName === displayItem.fieldName);
      this.filterColumns.splice(removedItemFilterIndex, 1);

      if(item.config?.isCustom){
        // check if we have any custom filter saved in the cache for the current filter service
        let selectedCustomFiltersInTheCache = BrowserStorageCache.getItem("Custom-" + this.tableServersideFilterService.getFilterServiceUniqueName());
        if(selectedCustomFiltersInTheCache){
          const removedItemIndex = selectedCustomFiltersInTheCache.findIndex(f => f.fieldName === displayItem.fieldName);
          selectedCustomFiltersInTheCache.splice(removedItemIndex, 1);
          BrowserStorageCache.setItem("Custom-" + this.tableServersideFilterService.getFilterServiceUniqueName(),selectedCustomFiltersInTheCache );
        }
      }
      else{
        // check if we have any filter saved in the cache for the current filter service
        this.cachedFilters =  BrowserStorageCache.getItem(this.tableServersideFilterService.getFilterServiceUniqueName());
        if(this.cachedFilters){
          const removedItem = this.cachedFilters.find(f => f.fieldName === displayItem.fieldName);
          if(removedItem){
            const removedItemIndex = this.cachedFilters.findIndex(f => f.fieldName === displayItem.fieldName);
            this.cachedFilters.splice(removedItemIndex, 1);                  
            BrowserStorageCache.setItem(this.tableServersideFilterService.getFilterServiceUniqueName(),this.cachedFilters );
          }
        }
      }
    
      this.setupFilterData();

      // trigger the event to parent component to be used when needed
      const selectedFilterList: Array<FilterItem> = FilterItemHelper.retrieveSelectedFilterList(this.allowedFilterFields);

      // dispatch event to outsider
      this.dispatchEventToUpdateFilter(item, selectedFilterList);
    }
  }

  // calculate selectedFilter list, non-selected filter list, DisplayFilterItem list
  setupFilterData(): void {
    this.availableColumns = FilterItemHelper.retieveNonSelectedFilterList(this.allowedFilterFields).sort((a, b) => FilterItemHelper.sortFilterItem(a, b));

    const selectedFilterList : DisplayFilterItem[] = FilterItemHelper.retrieveSelectedFilterList(this.allowedFilterFields)
    .map(this.getDisplayItemFromFilterItem.bind(this));
    selectedFilterList.forEach((filter) => {
      let filterApplied = this.filterColumns.find((f) => f.fieldName === filter.fieldName);
      if (!filterApplied) {
        this.filterColumns.push(filter);
      } else if (filterApplied.displayValue !== filter.displayValue) {
        this.filterColumns.splice(
          this.filterColumns.findIndex((f) => f.fieldName === filter.fieldName),
          1,
          filter
        );
      }
    });  
    
    this.filterColumns = this.filterColumns.sort(
      (a: DisplayFilterItem, b: DisplayFilterItem) => a.sortIndex - b.sortIndex
    );
  }

  // retrieve dialog type base on the filter item
  retrieveDialog(item: FilterItem): any {
    const filterType: FilterType = this.checkFilterType(item);
    const type: any =
      filterType === FilterType.DATE_RANGE_FILTER
      ? DateRangeDialogComponent
        : filterType === FilterType.DATE_FILTER
        ? DateDialogComponent
        : filterType === FilterType.SELECT_FILTER
        ? SelectDialogComponent
        : filterType === FilterType.CHECKBOX_GROUP_FILTER
        ? CheckDialogComponent
        : filterType === FilterType.AUTOCOMPLETE_FILTER
        ? AutoCompleteDialogComponent
        : TextDialogComponent;

    return type;
  }

  // convert item filter data to FilterType. FilterType should be unique and used to determine dialog type to display.
  checkFilterType(item: FilterItem): FilterType {
    return FilterItemHelper.getFilterTypeFromFilterItem(item);
  }

  onGetData(): void {
    const selectedFilterList: Array<FilterItem> = FilterItemHelper.retrieveSelectedFilterList(this.allowedFilterFields);

    this.applyEvent.emit(selectedFilterList);
  }

  // service callback for all dialog for retrieving required data
  public onServiceCallback = (
    item: FilterItem,
    params: any
  ): Observable<Array<any>> => {
    const selectedFilterList: Array<FilterItem> = this.allowedFilterFields.filter(
      (filterItem: FilterItem) => !!filterItem.filterValue
    );
    // add selectedFilterList into the parameters list
    const newParams: any = {
      ...params,
      selectedFilters: selectedFilterList
    };
    return this.tableServersideFilterService
      .getSelectDataForFilterItem(item, newParams);
  };

    // edit selected filter item
    public editSelectedFilterItem(event: Event, displayItem: DisplayFilterItem): void {
        if (!this.isUsingIndividualFilterDialog) {
          // check if the has the custom filter dialog
          if (this.customFilterDialogService) {
            return this.openCustomFilterDialog();
          } else { // use default filter dialog which defined under dialogs folder
            return this.openGeneralFilterDialog();
          }
        }

        const item: FilterItem = this.allowedFilterFields.find(
            filterItem => filterItem.fieldName === displayItem.fieldName
        );
        if (item) {
            const element: HTMLElement = event.target as HTMLElement;
            const top: number = element.getBoundingClientRect().top;
            const left: number = element.getBoundingClientRect().left;
            this.openDialogWithPos(item, top, left);
        }
    }

  setFilterItemsHandler(): void {
    if (!this.isUsingIndividualFilterDialog) {
      // check if the has the custom filter dialog
      if (this.customFilterDialogService) {
        return this.openCustomFilterDialog();
      } else { // use default filter dialog which defined under dialogs folder
        return this.openGeneralFilterDialog();
      }
    }
  }

  getDisplayText(filterColumn: DisplayFilterItem): string {
    return `${filterColumn.displayName}: "${filterColumn.displayValue}"`;
  }

}
