export enum CognitoErrors {
    NotAuthorizedException = 'NotAuthorizedException',
    UserNotFoundException = 'UserNotFoundException',
    UserNotConfirmedException = 'UserNotConfirmedException',
    UsernameExistsException = 'UsernameExistsException',
    MPUserExistsException = 'MPUser already exists',
    ExpiredCodeException = 'ExpiredCodeException',
    InvalidParameterException = 'InvalidParameterException',
    LimitExceededException = 'LimitExceededException',
}
