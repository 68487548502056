import {
    Component,
    EventEmitter,
    HostBinding,
    OnInit,
    Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from "app/core/services/util.service";
import { ClientDetailsSideMenuActions } from '../client.types';
import {
    ClientHealthLinkFilterColumnService,
    ClientHealthLinkServersideTableService,
} from './client-healthlink-table.service';

@Component({
    selector: 'app-client-healthlink',
    templateUrl: './client-healthlink.component.html',
    providers: [
        ClientHealthLinkFilterColumnService,
        ClientHealthLinkServersideTableService,
    ],
})
export class ClientHealthlinkComponent implements OnInit {
    @HostBinding('class') classes = 'app-client-healthlink w-full block h-full';
    @Output() closeEvent: EventEmitter<void> = new EventEmitter();

    clientId: number;
    pageTitle: string;

    ClientDetailsSideMenuActions = ClientDetailsSideMenuActions;

    constructor(
        private _router: Router,
        private _utilService: UtilService,
        public tableService: ClientHealthLinkServersideTableService,
        public filterService: ClientHealthLinkFilterColumnService,
        private _activatedRoute: ActivatedRoute
    ) {
        const params = this._activatedRoute.snapshot.params;
        this.clientId = params['clientId'];
    }

    ngOnInit(): void {
        this.tableService.clientId = this.clientId;

        this._activatedRoute.data.subscribe((data: any) => {
            const clientPersonalDetails = data.initialData;
            // Page Title
            this.pageTitle = this._utilService.getFullNameDob(clientPersonalDetails.FIRST_NAME, clientPersonalDetails.LAST_NAME, clientPersonalDetails.DOB);
          });
    }

    close(): void {
        this.closeEvent.emit();
    }
    
    navigateToPage(url: string): void {
        this._router.navigate([url]);
    }
   
}
