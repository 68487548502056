import { Injectable } from "@angular/core";
import { CellAlignment, TextTheme } from "@components/table/mat/mat-table-cell-content.service";
import { ColumnGroupConfig, ColumnItem, MASTER_GROUP_ID, TableActionType, TableItemAction } from "@components/table/mat/mat-table-wrapper.service";
import { FilterColumnService, FilterItem } from "@components/table/table-column-filter/table-serverside-column-filter.service";
import { ServerSideTableService } from "@components/table/table-serverside.service";
import { ClientService } from "app/core/client/client.service";
import { VfDebtorChargesModel } from "app/core/models/organisation/vf-debtor-charges.model";
import { DateTimeService } from "app/core/services/datetime.service";
import { Observable, of, Subject } from "rxjs";

const CLIENT_CHARGES_FIELDS = {
  DATE: "item_date",
  SHOW_THIRD_PARTY: "third_party"
};

const retrieveFilterValueForNameItem = (name: string, filterItemList: Array<FilterItem>): any | null => {
  const itemFilter: FilterItem = filterItemList.find((item) => item.fieldName === name);
  return itemFilter ? itemFilter.filterValue : null;
};

@Injectable()
export class ClientChargesTableService implements ServerSideTableService {
  public clientId: string;
  public destroySubscriptions: Subject<boolean> = new Subject<boolean>();
  constructor(private _clientService: ClientService, private _dateTimeService: DateTimeService) {}

  getActionList(): TableItemAction[] {
    return [ ];
  }
  getColumnDefinitions(): ColumnItem[] {
    return [
      { fieldName: "ITEM_DATE", displayName: "Item Date", 
        config: {
          isDate: true,
        }
      },
      { fieldName: "ITEM_TYPE_NAME", displayName: "Type", width: 15 },
      { fieldName: "THIRD_PARTY_IND", displayName: "Third Party?", width: 10,
        config:{
          isYesNo: true,
        },
      },           
      { fieldName: "DETAILS", displayName: "Details",
        textStyle: {
          theme: TextTheme.RUBY,
          isUnderline: true,
          alignment: CellAlignment.LEFT,
          displayTooltip: true,
        },
      },
      { 
        fieldName: "AMOUNT", 
        displayName: "Charge",
        config: {
          isCurrency: true,
        }        
      },
      { 
        fieldName: "PAID_WO_AMOUNT", 
        displayName: "Paid WO",
        config: {
          isCurrency: true,
        } 
      },
      { 
        fieldName: "OWING", 
        displayName: "Owing",
        config: {
          isCurrency: true,
        }  
      },     
    ];
  }

  
  getData(selectedFilterItem: FilterItem[]): Observable<VfDebtorChargesModel[]> {
    const dateRangeFilterValue: any = retrieveFilterValueForNameItem(CLIENT_CHARGES_FIELDS.DATE, selectedFilterItem);

    const dateFrom: string = dateRangeFilterValue && dateRangeFilterValue.filterFrom ? this._dateTimeService.FormatDate(dateRangeFilterValue.filterFrom, 'MM/DD/yyyy') : "";
    const dateTo: Date | string = dateRangeFilterValue && dateRangeFilterValue.filterTo ? this._dateTimeService.FormatDate(dateRangeFilterValue.filterTo, 'MM/DD/yyyy') : "";

    const showThirdPartyFilter: any = retrieveFilterValueForNameItem(CLIENT_CHARGES_FIELDS.SHOW_THIRD_PARTY, selectedFilterItem);
    const showThirdPartyCharges = showThirdPartyFilter ? showThirdPartyFilter.filterValue : false;


    return this._clientService.getClientCharges(this.clientId, dateFrom, dateTo, showThirdPartyCharges);
  }
}

@Injectable()
export class ClientChargesFilterColumnService implements FilterColumnService {

  getAllowedFilterFields(): Array<FilterItem> {
    return [
      {
        fieldName: CLIENT_CHARGES_FIELDS.DATE,
        displayName: "Item Date",
        config: {
          isDateRange: true,
          label:"Item Date"
        },
      },
      {
        fieldName: CLIENT_CHARGES_FIELDS.SHOW_THIRD_PARTY,
        displayName: "Show third party charges",
        config: {
          isSingleCheck: true,
          label: "Show third party charges",
          value: true
        }
      },
    ];
  }

  getSelectDataForFilterItem(item: FilterItem, params: any): Observable<Array<any>> {
    let results: Observable<any> = of([]);
    const { selectedFilters } = params;
    switch (item.fieldName) {
      case CLIENT_CHARGES_FIELDS.SHOW_THIRD_PARTY:
        results = this.getObservableDataForUnpaid();
        break;
    }

    return results;
  }

  getObservableDataForUnpaid(): Observable<Array<any>> {
    return of([{
      name: "Show third party charges",
      id: "FILTER_CHECK_THIRD_PARTY"
    }]);
  }

  getDataButtonText(): string {
    return "";
  }

  getPageTitle(): string {
    return "Charges";
  };

  getFilterServiceUniqueName(): string {
    return "Client_Charges_Filter";
  }
}
