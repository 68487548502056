import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpinnerService } from 'app/core/services/spinner.service';

import { Observable } from 'rxjs';

import { DialogInput } from '../table-serverside-column-filter.service';
import { AutoCompleteSelectItem } from './autocomplete-dialog.service';

@Component({
	selector: 'app-autocomplete-dialog-component',
	templateUrl: './autocomplete-dialog.component.html',
})
export class AutoCompleteDialogComponent implements OnInit {
	selectedItem: AutoCompleteSelectItem;
	tableServerSideColumnFilterFormGroup: any;
	public autocompleteItems: Array<AutoCompleteSelectItem>;
	public isLoadingAutocomplete = false;

	@ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

	constructor(
		private spinnerService: SpinnerService,
		public matDialogRef: MatDialogRef<AutoCompleteDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogInput
	) {
	}

	ngOnInit(): void {
		const filterValue: number = this.data.column && this.data.column.filterValue
			? Number(this.data.column.filterValue.filterValue) : undefined;
		const filterText: string = this.data.column && this.data.column.filterValue
			? this.data.column.filterValue.filterText : '';
		this.selectedItem = new AutoCompleteSelectItem(filterValue, filterText);

		this.tableServerSideColumnFilterFormGroup = new FormGroup({
			autocompleteTextbox: new FormControl(this.selectedItem),
		});

		this.tableServerSideColumnFilterFormGroup
			.get('autocompleteTextbox')
			.valueChanges.subscribe(value => this.performAutocomplete(value));
	}

	public performAutocomplete = (text: string): void => {
		if (text && text.length > 2) {
			this.isLoadingAutocomplete = true;
			this.spinnerService.show();
			const dataObservable: Observable<Array<any>> = this.data.serviceCallback(this.data.column, {
				text,
			});
			dataObservable.subscribe((results: Array<AutoCompleteSelectItem>) => {
				this.autocompleteItems = results ? results : [];
				this.spinnerService.hide();
				this.isLoadingAutocomplete = false;
				this.autocomplete.openPanel();
			});
		}
	};

	public onAutoCompleteSelectionChanged = (
		event: MatAutocompleteSelectedEvent
	) => {
		this.selectedItem = event.option.value as AutoCompleteSelectItem;
	};

	public displayAutocompleteItem = (item: AutoCompleteSelectItem) => item.displayText;

	applyFilter() {
		if (this.selectedItem && this.selectedItem.id) {
			const filterText: string = this.selectedItem.displayText;
			const filterValue: string = this.selectedItem.id.toString();
			this.matDialogRef.close({
				item: this.data.column,
				filterValue: {
					filterText,
					filterValue,
				}
			});
		}

	}
}
