import { Injectable } from "@angular/core";
import { ColumnGroupConfig, ColumnItem, TableActionType, TableItemAction } from "@components/table/mat/mat-table-wrapper.service";
import { FilterColumnService, FilterItem } from "@components/table/table-column-filter/table-serverside-column-filter.service";
import { ServerSideTableService } from "@components/table/table-serverside.service";
import { ClientService } from "app/core/client/client.service";
import { ClientConditions } from "app/modules/healthlink/healthlink.types";
import { Observable } from "rxjs";

const CLIENT_CONDITIONS_COLUMNS = {
  DATE_CREATED: "DateCreated",
  ACC_CLAIM_NO: "AccClaimNo",
  START_FROM_DATE: "StartFromDate",
  DATE_OF_INJURY: "DateOfInjury",
  INJURY_DESCRIPTION: "InjuryDescription",
  STATUS: "Value",
};

@Injectable({ providedIn: "root" })
export class ClientConditionsServerSideTableService implements ServerSideTableService {
  public clientId: string;

  constructor(private _clientService: ClientService) {}

  getActionList(): TableItemAction[] {
    return [
      {
        actionType: TableActionType.EDIT,
        label: "Edit",
      },
    ];
  }

  getColumnDefinitions(): ColumnItem[] {
    return [
      {
        fieldName: CLIENT_CONDITIONS_COLUMNS.DATE_CREATED,
        displayName: "Created",
        config: {
          isDate: true,
        },
      },
      { fieldName: CLIENT_CONDITIONS_COLUMNS.ACC_CLAIM_NO, displayName: "Claim No", width: 10 },
      {
        fieldName: CLIENT_CONDITIONS_COLUMNS.START_FROM_DATE,
        displayName: "Start Date",
        config: {
          isDate: true,
        },
      },
      {
        fieldName: CLIENT_CONDITIONS_COLUMNS.DATE_OF_INJURY,
        displayName: "DOI",
        config: {
          isDate: true,
        },
      },
      { fieldName: CLIENT_CONDITIONS_COLUMNS.INJURY_DESCRIPTION, displayName: "Injury Details" },
      { fieldName: CLIENT_CONDITIONS_COLUMNS.STATUS, displayName: "Status", width: 10 },
    ];
  }

  getDisplayColumnConfig(): ColumnGroupConfig {
    return null;
  }

  getData(selectedFilterItem: FilterItem[]): Observable<ClientConditions[]> {
    return this._clientService.getClientConditions(+this.clientId);
  }
}

@Injectable({ providedIn: "root" })
export class ClientConditionsFilterColumnService implements FilterColumnService {
  constructor() {}

  getAllowedFilterFields(): Array<FilterItem> {
    return [];
  }

  getSelectDataForFilterItem(item: FilterItem, params: any): Observable<any[]> {
    let results: Observable<any[]>;
    switch (item.fieldName) {
    }

    return results;
  }

  getDataButtonText(): string {
    return "";
  }
  getPageTitle(): string {
    return "Conditions";
  };

  getFilterServiceUniqueName(): string {
    return "Client_Conditions_Filter";
  }
}
