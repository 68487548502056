import { Injectable } from "@angular/core";
import { CellAlignment, TextTheme } from "@components/table/mat/mat-table-cell-content.service";
import { ColumnItem, TableActionType, TableItemAction } from "@components/table/mat/mat-table-wrapper.service";
import { FilterColumnService, FilterItem } from "@components/table/table-column-filter/table-serverside-column-filter.service";
import { ServerSideTableService } from "@components/table/table-serverside.service";
import { ClientService } from "app/core/client/client.service";
import { VfWriteoffModel } from "app/core/models/organisation/vf-writeoffs.model";
import { DateTimeService } from "app/core/services/datetime.service";
import { Observable, of } from "rxjs";

const CLIENT_WRITEOFFS_FIELDS = {
  WRITE_OFF_DATE: "WRITE_OFF_DATE",
};

const retrieveFilterValueForNameItem = (name: string, filterItemList: Array<FilterItem>): any | null => {
  const itemFilter: FilterItem = filterItemList.find((item) => item.fieldName === name);
  return itemFilter ? itemFilter.filterValue : null;
};

@Injectable()
export class ClientWriteOffsServersideTableService implements ServerSideTableService {
  public clientId: string;
  constructor(private _clientService: ClientService, private _dateTimeService: DateTimeService) {}

  getActionList(): TableItemAction[] {
    return [

    ];
  }

  getColumnDefinitions(): ColumnItem[] {
    return [
      {
        fieldName: "DATE_CREATED",
        displayName: "Date created",
        config: {
          isDate: true,
        },
      },      
      {
        fieldName: "WRITE_OFF_DATE",
        displayName: "Write-Off Date",
        config: {
          isDate: true,
        },
      },
      { 
        fieldName: "TOTAL_AMOUNT", 
        displayName: "Amount",
        config: {
          isCurrency: true,
        }
      },
      { fieldName: "DETAILS", displayName: "Details",
        textStyle: {
          alignment: CellAlignment.LEFT,
          theme: TextTheme.RUBY,
          isUnderline: true,
          displayTooltip: true,
        } 
      },
    ];
  }
  getData(selectedFilterItem: FilterItem[]): Observable<VfWriteoffModel[]> {
    const dateRangeFilterValue: any = retrieveFilterValueForNameItem(CLIENT_WRITEOFFS_FIELDS.WRITE_OFF_DATE, selectedFilterItem);

    const dateFrom: string = dateRangeFilterValue && dateRangeFilterValue.filterFrom ? this._dateTimeService.FormatDate(dateRangeFilterValue.filterFrom, 'MM/DD/yyyy') : "";

    const dateTo: Date | string = dateRangeFilterValue && dateRangeFilterValue.filterTo ? this._dateTimeService.FormatDate(dateRangeFilterValue.filterTo, 'MM/DD/yyyy') : "";

    return this._clientService.getClientWriteOffs(this.clientId, dateFrom, dateTo);
  }
}

@Injectable()
export class ClientWriteOffsFilterColumnService implements FilterColumnService {
  getAllowedFilterFields(): Array<FilterItem> {
    return [
      {
        fieldName: CLIENT_WRITEOFFS_FIELDS.WRITE_OFF_DATE,
        displayName: "Write-offs Date",
        config: {
          isDateRange: true,
        },
      },
    ];
  }

  getSelectDataForFilterItem(item: FilterItem): Observable<Array<any>> {
    return of([]);
  }

  getDataButtonText(): string {
    return "Get Client WriteOffs";
  }

  getPageTitle(): string {
    return "Write Offs";
  };
  
  getFilterServiceUniqueName(): string {
    return "Client_WriteOffs_Filter";
  }
}
