import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, HostBinding, Inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from "@angular/material/select";
import { BrowserStorageCache } from "@aws-amplify/cache";
import { LoadingService } from "app/core/services/loading.service";
import { DateTimeService } from 'app/core/services/datetime.service';
import { forkJoin, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CustomFilterConfig, FilterItem, FilterItemHelper, FilterType, SelectDateFilterSelectObject, SelectDateFilterValue } from '../table-serverside-column-filter.service';
import { AutoCompleteSelectItem } from './autocomplete-dialog.service';
import { FilterOutput, FormItemData, GeneralFilterDialogInput, GeneralFilterDialogOutput } from './general-filter-dialog.service';

export interface FilterFormControl {
    control: FormControl;
    additionalControl?: FormControl;
    secondAdditionalControl?: FormControl;
}

@Injectable()
export class GeneralFilterDialogService {
    constructor(
      public dialog: MatDialog, private _loadingService: LoadingService,
      private _breakpointObserver: BreakpointObserver
    ) {}

    displayFilterDialog(containerElement: HTMLElement, inputData: GeneralFilterDialogInput): Observable<MatDialogRef<GeneralFilterDialogComponent>> {
        const isMobile = this._breakpointObserver.isMatched(Breakpoints.XSmall);
        this._loadingService.show();
        return this.retrieveObservableForRequiredFields(inputData).pipe(
            map((item: any) => {
                const data = inputData;
                this._loadingService.hide();
                const boundary: DOMRect = containerElement.getBoundingClientRect();
                const top = boundary.top;
                const left = boundary.left;
                const width = boundary.width;

                if (isMobile) {
                  return this.dialog.open(GeneralFilterDialogComponent, {
                    panelClass: 'general-filter-dialog-panel',
                    backdropClass: 'general-filter-dialog-backdrop',
                    disableClose: true,
                    data,
                    width: "100%",
                    maxWidth: "100%"
                  });
                }
                return this.dialog.open(GeneralFilterDialogComponent, {
                    panelClass: 'general-filter-dialog-panel',
                    backdropClass: 'general-filter-dialog-backdrop',
                    disableClose: true,
                    data,
                    maxWidth: '628px',
                    width: `${width}px`,
                    position: {
                        top: `${top}px`,
                        left: `${left}px`,
                    },
                });
            })
        );
    }

    private retrieveObservableForRequiredFields(inputData: GeneralFilterDialogInput): Observable<any> {
        // assign default select
        const filterList: Array<FilterItem> = inputData.filterConfigs.filter((item: FilterItem) => {
            const type = FilterItemHelper.getFilterTypeFromFilterItem(item);
            return type === FilterType.SELECT_FILTER || type === FilterType.AUTOCOMPLETE_FILTER
              || type === FilterType.CHECKBOX_GROUP_FILTER;
        });

        const observableList = filterList.map((item) => {
            const type = FilterItemHelper.getFilterTypeFromFilterItem(item);
            return inputData.serviceCallback(item, {}) || of(null);
        });

        if (observableList && observableList.length === 0) return of([]);

        return forkJoin(observableList).pipe(
            tap((result: Array<any>) => {
                for(let index = 0; index < result.length; index ++) {
                    filterList[index].config.selectData = result[index];
                }
            })
        );
    }
}

@Component({
	selector: 'app-general-filter-dialog-component',
	templateUrl: './general-filter-dialog.component.html',
})

export class GeneralFilterDialogComponent implements OnInit, OnDestroy {
    @HostBinding('class') classes = 'general-filter-dialog-component';

    filterType = FilterType;

    formDataList: Array<FormItemData>;

    selectAllValue: string = "";

    showSelectDateFilterDateRange: boolean = true;
    selectDateFilterValue: SelectDateFilterValue;
    filterServiceUniqueName: string = "";

    constructor(
        private datetimeService: DateTimeService,
        public dialogRef: MatDialogRef<GeneralFilterDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: GeneralFilterDialogInput,
    ) {
      this.selectAllValue = "selectAll";
    }

    getDataList(filterItemList: FilterItem[]): FormItemData[] {
        const result = filterItemList.map((config: FilterItem) => {
            const type = FilterItemHelper.getFilterTypeFromFilterItem(config);
            let formControl: FilterFormControl = null;
            switch(type) {
                case FilterType.TEXT_FILTER:
                    formControl = this.getControlForText(config);
                break;
                case FilterType.SELECT_FILTER:
                    formControl = this.getControlForSelect(config);
                break;
                case FilterType.DATE_FILTER:
                    formControl = this.getControlForDate(config);
                break;
                case FilterType.DATE_RANGE_FILTER:
                    formControl = this.getControlForDateRange(config);
                break;
                case FilterType.AUTOCOMPLETE_FILTER:
                    formControl = this.getControlForAutoComplete(config);
                break;
                case FilterType.CHECKBOX_GROUP_FILTER:
                  formControl = this.getControlForCheckBoxGroup(config);
                break;
                case FilterType.SINGLE_CHECK_FILTER:
                  formControl = this.getControlForSingleCheck(config);
                break;
                case FilterType.SELECT_DATE_FILTER:
                    formControl = this.getControlForSelectDate(config);
                    break;
                case FilterType.CUSTOM_FILTER:
                  formControl = this.getControlForCustomFilter(config);
                  break;
            }

            let subscription = null;
            let additionalSubscription = null;
            let secondAdditionalSubscription = null;

            if (formControl && type !== FilterType.CUSTOM_FILTER) {
              subscription = formControl.control.valueChanges.subscribe((value: any) => {
                this.onDataChange(config, value);
              });

              additionalSubscription = formControl.additionalControl ? formControl.additionalControl.valueChanges.subscribe((value: any) => {
                  this.onDataChange(config, value);
              }) : null;

              secondAdditionalSubscription = formControl.secondAdditionalControl ? formControl.secondAdditionalControl.valueChanges.subscribe((value: any) => {
                this.onDataChange(config, value);
            }) : null;
            }

            return {
                control: formControl?.control,
                additionalControl: formControl?.additionalControl,
                secondAdditionalControl: formControl?.secondAdditionalControl,
                config,
                subscription,
                additionalSubscription,
                secondAdditionalSubscription,
                type,
                isSelectAll: false, // for check filter only
                selectedCaption: "", // for check filter ony
            };
        });

        return result;
    }

    getControlForText(config: FilterItem): any {
        return {
            control: new FormControl(config.filterValue?.filterValue),
            additionalControl: null
        };
    }

    getControlForSelect(config: FilterItem): any {
        return {
            control: new FormControl(config.filterValue?.filterValue),
            additionalControl: null
        };
    }

    getControlForDate(config: FilterItem): any {
        return {
            control: new FormControl(config.filterValue?.filterDate),
            additionalControl: null
        };
    }

    getControlForDateRange(config: FilterItem): any {
        return {
            control: new FormControl(config.filterValue?.filterFrom),
            additionalControl: new FormControl(config.filterValue?.filterTo),
        };
    }

    getControlForAutoComplete(config: FilterItem): any {
        const data: AutoCompleteSelectItem = config.filterValue
            ? new AutoCompleteSelectItem(config.filterValue?.filterValue, config.filterValue?.filterText)
            : null;

        return {
            control: new FormControl(data),
            additionalControl: new FormControl(),
        };
    }

    onDataChange(config: FilterItem, value: any): void {
        const type = FilterItemHelper.getFilterTypeFromFilterItem(config);
        if (type === FilterType.AUTOCOMPLETE_FILTER) {
            this.onDataChangeForAutoComplete(config, value);
        }
    }

    onDataChangeForAutoComplete(config: FilterItem, text: any): void {
        const isTextAsObj = (typeof text) === 'object';
        if (isTextAsObj) { return; }

        const dataObservable: Observable<Array<any>> = this.data.serviceCallback(config, {
            text,
        });

        // dataObservable can be null. Outsider can determine that nothing happens when typing.
        // For example, outsider can have a logic only return data if length of searched text is greater than 3
        if (dataObservable) {
            const subScription = dataObservable.subscribe((results: Array<AutoCompleteSelectItem>) => {
				config.config.selectData = results ? results : [];
                setTimeout(() => {
                    subScription.unsubscribe();
                });
			});
        }
    }

    ngOnInit(): void {
      this.filterServiceUniqueName = this.data.filterServiceUniqueName;
        this.formDataList = this.getDataList(this.data.filterConfigs);

        // set caption for each check filter
        const checkFilterFormDataList = this.formDataList.filter(item => item.type === FilterType.CHECKBOX_GROUP_FILTER);
        checkFilterFormDataList.forEach(item => this.setSelectedCaptionForCheckBoxGroupFilter(item));
    }

    ngOnDestroy(): void {
        this.formDataList.forEach((dataItem: FormItemData) => {
            dataItem.subscription?.unsubscribe();
            dataItem.additionalSubscription?.unsubscribe();
            dataItem.secondAdditionalSubscription?.unsubscribe();
        });
    }

    cancel(): void {
        this.dialogRef.close();
    }

    retrieveOutputDataForText(control: FormControl, config: FilterItem): FilterOutput {
        const filterValue = control.value;
        return filterValue ? {
            config,
            filterValue: {
              filterValue,
            },
        } : null;
    }

    retrieveOutputDataForSelect(control: FormControl, config: FilterItem): FilterOutput {
        const value = control.value;
        if (!value) {
            return null;
        }

        const selectList: Array<any> = config.config.selectData || [] as Array<any>;
        const valueObj = selectList.find(item => item[config.config.valueField] === value);
        const filterText: string = valueObj ? valueObj[config.config.textField] : '';

        return {
            config: config,
            filterValue: {
                filterText,
                filterValue: value,
            },
        };
    }

    retrieveOutputDataForDate(control: FormControl, config: FilterItem): FilterOutput {
        const filterDateVal = control.value;
        if (!filterDateVal) {
            return null;
        }

        const filterDate = filterDateVal ? this.datetimeService.toStartOfDay(filterDateVal) : null;
        return {
            config,
            filterValue: {
                filterDate,
            }
        };
    }

    retrieveOutputDataForDateRange(startControl: FormControl, endControl: FormControl, config: FilterItem): FilterOutput {
        const filterFromVal = startControl.value;
		    const filterToVal = endControl.value;

        if (!filterFromVal && !filterToVal) {
            return null;
        }

        const filterFrom = filterFromVal ? this.datetimeService.toStartOfDay(filterFromVal) : null;
        const filterTo = filterToVal ? this.datetimeService.toEndOfDay(filterToVal) : null;

        return {
            config,
            filterValue: {
                filterFrom,
				filterTo,
            }
        };
    }

    retrieveOutputDataForSelectDate(
        dateTypeControl: FormControl,
        dateFrom: FormControl,
        dateTo: FormControl,
        config: FilterItem
      ): FilterOutput {
        const dateType = dateTypeControl.value;
        const filterFromVal = dateFrom.value;
        const filterToVal = dateTo.value;
    
        if (!dateType && !filterFromVal && !filterToVal) {
          return null;
        }
    
        const filterFrom = filterFromVal ? this.datetimeService.toStartOfDay(new Date(filterFromVal)) : null;
        const filterTo = filterToVal ? this.datetimeService.toEndOfDay(new Date(filterToVal)) : null;
    
        const filterValue: SelectDateFilterValue = {
          dateFrom: filterFrom,
          dateTo: filterTo,
          dateType: dateType,
        };
    
        return {
          config,
          filterValue: filterValue,
        };
    }

    retrieveOutputForAutoComplete(control: FormControl, config: FilterItem): FilterOutput {
        const selectedData = control.value;
        if (!selectedData) {
            return null;
        }

        const filterText: string = selectedData ? selectedData.displayText : null;
        const filterValue: string = selectedData ? selectedData.id.toString() : null;

        return {
            config,
            filterValue: {
                filterText,
                filterValue,
            }
        };
    }

    convertToDataOutput(formDataList: Array<FormItemData>): GeneralFilterDialogOutput {
        const filterDataList = formDataList.map((formItemData: FormItemData) => {
            const type = FilterItemHelper.getFilterTypeFromFilterItem(formItemData.config);
            let result: FilterOutput = null;
            switch(type) {
                case FilterType.TEXT_FILTER:
                    result = this.retrieveOutputDataForText(formItemData.control, formItemData.config);
                break;
                case FilterType.SELECT_FILTER:
                    result = this.retrieveOutputDataForSelect(formItemData.control, formItemData.config);
                break;
                case FilterType.DATE_FILTER:
                    result = this.retrieveOutputDataForDate(formItemData.control, formItemData.config);
                break;
                case FilterType.DATE_RANGE_FILTER:
                    result = this.retrieveOutputDataForDateRange(formItemData.control, formItemData.additionalControl, formItemData.config);
                break;
                case FilterType.AUTOCOMPLETE_FILTER:
                    result = this.retrieveOutputForAutoComplete(formItemData.control, formItemData.config);
                break;
                case FilterType.CHECKBOX_GROUP_FILTER:
                  result = this.retrieveDataForCheckBoxGroupFilter(formItemData.control, formItemData.config);
                break;
                case FilterType.SINGLE_CHECK_FILTER:
                  result = this.retrieveDataForSingleCheckFilter(formItemData.control, formItemData.config);
                break;
                case FilterType.SELECT_DATE_FILTER:
                    result = this.retrieveOutputDataForSelectDate(formItemData.control, formItemData.additionalControl, formItemData.secondAdditionalControl, formItemData.config);
                  break;
                case FilterType.CUSTOM_FILTER:
                  result = this.retrieveDataForCustomFilter(formItemData.control, formItemData.config);
                break;
            }

            return result;
        }).filter(item => !!item);
        return {
            filterDataList,
        };
    }

    apply(): void {
        const output = this.convertToDataOutput(this.formDataList);
        this.dialogRef.close(output);
    }

    public displayAutocompleteItem = (item: AutoCompleteSelectItem): string => item ? item.displayText: '';

  // for check filter
  getControlForCheckBoxGroup(config: FilterItem): any {
    const selectableItems: Array<any> = config.config.selectData;
    const inputSelectedItems: Array<any> = config.filterValue?.filterValue || [];
    const result: Array<any> = inputSelectedItems.map((item) => {
      const foundItem = selectableItems.find(elem => elem[config.config.valueField] === item[config.config.valueField]);
      return foundItem;
    });

    if (result.length === selectableItems.length) {
      result.push(this.selectAllValue);
    }

    return {
      control: new FormControl(result),
      additionalControl: null,
    };
  }

  // for single check filter
  getControlForSingleCheck(config: FilterItem): any {  
    return {
      control: new FormControl(config.filterValue?.filterValue),
      additionalControl: null,
    };
  }


  getControlForSelectDate(filterItem: FilterItem): FilterFormControl {
    const selectableItems: SelectDateFilterSelectObject[] = filterItem.config.selectData;
    this.selectDateFilterValue = filterItem.config.initialValue as SelectDateFilterValue;

    let defaultSelection: SelectDateFilterSelectObject = this.selectDateFilterValue?.dateType || null;

    let filterValue: SelectDateFilterValue = filterItem.filterValue || null;

    const selectedFiltersInTheCache = BrowserStorageCache.getItem(this.filterServiceUniqueName) as FilterItem[]

    let cachedSelectDateFilter = selectedFiltersInTheCache?.find(cf => cf.config.isSelectDate) || null;

    if(cachedSelectDateFilter) {
      filterValue = cachedSelectDateFilter.filterValue;
    }

    const defaultDateFrom: Date = this.selectDateFilterValue?.dateFrom || null;
    const defaultDateTo: Date = this.selectDateFilterValue?.dateTo || null;

    let filterFormControl: FilterFormControl = {
      control: new FormControl(null),
      additionalControl: new FormControl(defaultDateFrom),
      secondAdditionalControl: new FormControl(defaultDateTo),
    };

    if (defaultSelection && !filterValue) {
      filterFormControl.control.setValue(selectableItems.find((si) => si.id === defaultSelection.id));
      if (defaultSelection.isSingleDate) {
        this.showSelectDateFilterDateRange = false;
      }
    } else if (filterValue) {
      filterFormControl.control.setValue(selectableItems.find((si) => si.id === filterValue.dateType.id));
      filterFormControl.additionalControl.setValue(filterValue.dateFrom);
      filterFormControl.secondAdditionalControl.setValue(filterValue.dateTo);

      if (filterValue.dateType.isSingleDate) {
        this.showSelectDateFilterDateRange = false;
      }
    }

    return filterFormControl;
  }

  toggleItem(formItemData: FormItemData): void {
    const selectedItems: Array<any> = formItemData.control.value;
    const selectableItems: Array<any> = formItemData.config.config.selectData;
    const isAllCheckboxSelected: boolean = selectedItems.indexOf(this.selectAllValue) > -1;
    const isAllSelected = isAllCheckboxSelected ? selectedItems.length === selectableItems.length + 1
      : selectedItems.length === selectableItems.length;
    if (isAllSelected) {
      if (!isAllCheckboxSelected) {
        // only update selectedProvider when allCheckbox selected
        const newValues = selectedItems.concat([this.selectAllValue]);
        formItemData.control.setValue(newValues);
      }
      formItemData.isSelectAll = true;
    } else {
      if (isAllCheckboxSelected) {
        // remove allcheckbox selected
        const indexOfAllCheckbox: number = selectedItems.indexOf(this.selectAllValue);
        selectedItems.splice(indexOfAllCheckbox, 1);
        formItemData.control.setValue(selectedItems);
      }
      formItemData.isSelectAll = false;
    }

    this.setSelectedCaptionForCheckBoxGroupFilter(formItemData);
  }

  toggleAll(formItemData: FormItemData): void {
    const selectedItems: Array<any> = formItemData.control.value;
    const selectableItems: Array<any> = formItemData.config.config.selectData;
    const isAllSelected: boolean = selectedItems.indexOf(this.selectAllValue) > -1;
    if (isAllSelected) {
      formItemData.control.setValue(selectableItems.concat([this.selectAllValue]));
      formItemData.isSelectAll = true;
    } else {
      formItemData.control.setValue([]);
      formItemData.isSelectAll = false;
    }
    this.setSelectedCaptionForCheckBoxGroupFilter(formItemData);
  }

  setSelectedCaptionForCheckBoxGroupFilter(formItemData: FormItemData): void {
    const selectedProviders: Array<any> = formItemData.control?.value || [] ;
    const selectableItems: Array<any> = formItemData.config.config.selectData;
    const placeHolderForCheck = formItemData.config.config.placeholder;
    const realSelectedProvider: Array<any> = selectedProviders.filter(
        (item) => item !== this.selectAllValue
    );
    if(selectableItems.length > 1){
        formItemData.selectedCaption = ''.concat(
            realSelectedProvider ? realSelectedProvider.length.toString() : '0',
            ' of ',
            selectableItems ? selectableItems.length.toString(): '0',
            ' ' + placeHolderForCheck + ' selected',
        );
    }
    else{
        formItemData.selectedCaption = realSelectedProvider && realSelectedProvider.length !== 0 ? placeHolderForCheck  + ' selected' : placeHolderForCheck;
    }
    
  }

  retrieveDataForCheckBoxGroupFilter(control: FormControl, config: FilterItem): FilterOutput {
    let values: Array<any> = control.value || [];
    values = values.filter(item => item !== this.selectAllValue);
    if (values.length === 0) {
      return {
        config,
        filterValue: null,
      };
    }

    const filterText = values.map(item => item[config.config.textField]).join(", ");
    return {
      config,
      filterValue: {
        filterText,
        filterValue: values,
      }
    };
  }

  retrieveDataForSingleCheckFilter(control: FormControl, config: FilterItem): FilterOutput {
    const filterText = config.config.label;
    if(control.value === true){     
      return {
        config,
        filterValue: {
          filterText,
          filterValue: control.value.toString(),
        }
      }
    }
    return {
      config,
      filterValue : null
    };
  }

  toggleSingleCheckbox(event: MatCheckboxChange, formItemData: FormItemData): void {
    formItemData.control.setValue(event.checked);
  }
  

  // for custom filter
  getControlForCustomFilter(config: FilterItem): any {
    return {
      control: new FormControl(config?.filterValue?.filterValue),
      additionalControl: null,
    };
  }

  retrieveDataForCustomFilter(control: FormControl, config: FilterItem): FilterOutput {
    const customFilterConfig: CustomFilterConfig = config.config as CustomFilterConfig;
    return customFilterConfig.retrieveDataFun(control, config);
  }

  onSelectDateRestrictionChange(event: MatSelectChange): void {
    if (event && event.value) {
        const dateRestrict = event.value as SelectDateFilterSelectObject;
        const formDataItem = this.formDataList.find((fdi) => fdi.type === FilterType.SELECT_DATE_FILTER);
        if (dateRestrict.isSingleDate) {
          this.showSelectDateFilterDateRange = false;
          formDataItem.additionalControl?.setValue(new Date());
          formDataItem.secondAdditionalControl?.setValue(null);
        } else {
          this.showSelectDateFilterDateRange = true;
          formDataItem.additionalControl?.setValue(this.selectDateFilterValue.dateFrom);
          formDataItem.secondAdditionalControl?.setValue(this.selectDateFilterValue.dateTo);
        }
    }
  }
}
