<form [formGroup]="tableServerSideColumnFilterFormGroup">
	<div class="column-filter-wrapper">
		<mat-toolbar class="mat-primary m-0">
			<mat-toolbar-row class="flex flex-row justify-between items-center">
				<span class="title dialog-title">{{ data.column.displayName }}</span>
				<button mat-button (click)="matDialogRef.close()" aria-label="Close dialog" class="close-button">
					<mat-icon svgIcon="gpm_close"></mat-icon>
				</button>
			</mat-toolbar-row>
		</mat-toolbar>
		<div class="content m-0">
			<div class="field-group">
				<div class="field-wrapper">
					<!-- <mat-label>{{ data.column.config.label }}</mat-label> -->
					<mat-form-field appearance="outline" floatLabel="always">
						<mat-label>{{ data.column.config.placeholder }}</mat-label>
						<input matInput placeholder="{{ data.column.config.placeholder }}"
						[matAutocomplete]="auto"
						formControlName="autocompleteTextbox">
					</mat-form-field>
					<mat-autocomplete #auto="matAutocomplete"
						(optionSelected)="onAutoCompleteSelectionChanged($event)"
						[displayWith]="displayAutocompleteItem" >
						<mat-option *ngFor="let item of autocompleteItems" [value]="item">
							<span>{{ item.displayText }}</span>
							<!-- <small> | ID: {{item.id }}</small> -->
						</mat-option>
						<mat-option disabled *ngIf="autocompleteItems?.length === 0 && !isLoadingAutocomplete">
							<span>{{ data.column.config.noDataText}}</span>
						</mat-option>
						<mat-option disabled *ngIf="isLoadingAutocomplete">
							<mat-spinner diameter="35"></mat-spinner>
						</mat-option>
					</mat-autocomplete>
				</div>
			</div>
		</div>
		<div class="actions">
			<button mat-raised-button color="basic" class="apply-button" (click)="applyFilter()" aria-label="Apply filter">
				Apply
			</button>
		</div>
	</div>
</form>
