import { AuthService } from "app/core/auth/auth.service";
import { CacheService } from "app/core/cache/cache.service";
import { CacheableItems, ProviderDBModel, SiteDBModel, SystemUserDBModel } from "app/core/cache/cache.types";
import { IndexedDBService } from "app/core/cache/indexed-db.service";
import { ClientService } from "app/core/client/client.service";
import { ClientDetailsDrawerDataModel } from "app/core/client/models/client-details-drawer-data.model";
import { ClientHeaderDataModel } from "app/core/client/models/client-header-data.model";
import { ClientPersonalDetailsModel } from "app/core/client/models/client-personal-details.model";
import { ClientModel } from "app/core/client/models/client.model";
import { Scheme } from "app/core/config/app.config";
import { AppointmentStatus } from "app/core/enums/appointment-enums";
import { ClientByNameModel } from "app/core/models/clients-by-name.model";
import { NavigationService } from "app/core/navigation/navigation.service";
import { Navigation } from "app/core/navigation/navigation.types";
import { HealthlinkService } from "app/core/services/healthlink.service";
import { UtilService } from "app/core/services/util.service";
import { WebSocketService } from "app/core/web-socket/web-socket.service";
import { AppointmentData, ClientContactData, ClientEmails, ClientPhoneNumbers, ConditionData } from "app/layout/layout.types";
import { HlNotificationDetails } from "app/modules/healthlink/healthlink.types";
import { HubChannels, HubEvents } from "app/shared/common/hub-channels";
import { BehaviorSubject, forkJoin, Observable, of, Subject, zip } from "rxjs";
import { catchError, debounceTime, map, take, takeUntil, tap } from "rxjs/operators";
import { findIana, IanaName } from "windows-iana";

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import { Hub } from "@aws-amplify/core";
import { BreadcrumbsService } from "@components/breadcrumbs/breadcrumbs.service";
import { LoadingService } from "app/core/services/loading.service";
import { FuseConfigService } from "@fuse/services/config";
import { FuseMediaWatcherService } from "@fuse/services/media-watcher";
import { SplashScreenService } from "@components/splash-screen/splash-screen.service";
import { BnNgIdleService } from "bn-ng-idle";
import { EntityTypeEnum } from "app/core/enums/physio-enums";
import { EmailComposeService } from "@components/email-compose/email-compose-service";
import { EmailService } from "app/core/services/email.service";

interface DisplaySystemUser {
  organisationName: string;
  name: string;
  initials?: string;
  email: string | null;
  lockoutTime: number | null;
}

@Component({
  selector: "thin-layout",
  templateUrl: "./thin.component.html",
  styleUrls: ["./thin.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ThinLayoutComponent implements OnInit, OnDestroy {
  @HostBinding("class") classes = "thin-layout-component";
  isSystemUserInfoOpen: boolean = false;

  clientHeaderData: ClientHeaderDataModel;
  selectedClient: ClientByNameModel;

  numberOfConditions: number;

  today: number = Date.now();

  isScreenSmall: boolean;
  navigation: Navigation;
  gpmVersion: string;

  lightModeColor = "text-amber-400";
  dark_mode_scheme: boolean = false;
  darkModeColor = "";

  conditionsData: ConditionData[] = [];
  apptsData: AppointmentData[] = [];
  noShowAppts: AppointmentData[] = [];
  phoneData: ClientContactData[] = [];
  emailData: ClientContactData[] = [];
  debtorBalance: number | null;
  isLoading: boolean = false;

  isPaymentsOverlayOpen = false;
  noShows: number = 0;
  sysUser: DisplaySystemUser;

  ClientNameColor = "";
  //ClientDefaultNoColor background
  private clientDefaultNoColor = "rgb(255,255,255)";
  //ClientDefaultColor background
  private clientDefaultColorRed = "rgb(255,0,0)";

  liveData$ = this._webSocketService.hlMessages$.pipe(
    map((rows: any) => {
      console.log("WebSocket Data", rows);
      return rows["data"];
    }),
    catchError((err) => {
      throw err;
    }),
    tap({
      error: (err) => console.log("[Navigation Component] WS Error: ", err),
      complete: () => console.log("[Navigation Component] WS Connection Closed:"),
    })
  );

  @Output() onViewClientDetails = new EventEmitter<ClientDetailsDrawerDataModel>();

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _SplashScreenService: SplashScreenService,
    private _loadingService: LoadingService,
    private _fuseConfigService: FuseConfigService,
    private _navigationService: NavigationService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _indexedDBService: IndexedDBService,
    private _clientService: ClientService,
    private _cacheService: CacheService,
    private _utilService: UtilService,
    private _router: Router,
    private _breadCrumbService: BreadcrumbsService,
    private _webSocketService: WebSocketService,
    private _authService: AuthService,
    private _healthlinkService: HealthlinkService,
    private _emailComposeService: EmailComposeService,
    private _emailService: EmailService,
    private _bnIdle: BnNgIdleService
  ) {
    Hub.listen(HubChannels.UpdatesChannel, (_data) => {
      this._SplashScreenService.show();
    });

    Hub.listen(HubChannels.ClientUpdatesChannel, (data) => {
      if (data.payload.event === HubEvents.ClientDeleted) {
        this.selectedClient = null;
        this._cacheService.remove(CacheableItems.GPMSelectedClient);
        this._changeDetectorRef.markForCheck();
        return;
      }

      const client = data.payload.data.Client as ClientModel;
      const clientDetails = data.payload.data.ClientDetails as ClientPersonalDetailsModel;

      if (this.selectedClient && this.selectedClient.CLIENT_ID === clientDetails.CLIENT_ID) {
        this.selectedClient.FIRST_NAME = clientDetails.FIRST_NAME;
        this.selectedClient.LAST_NAME = clientDetails.LAST_NAME;

        const selectedClientObject: ClientByNameModel = {
          CLIENT_ID: client?.ID ?? clientDetails.CLIENT_ID,
          VENDOR_ID: client?.VENDOR_ID ?? clientDetails.VENDOR_ID,
          FIRST_NAME: clientDetails.FIRST_NAME,
          KNOWN_AS: clientDetails.KNOWN_AS,
          FIRST_NAMES: this.selectedClient.FIRST_NAMES,
          LAST_NAME: clientDetails.LAST_NAME,
          MAIDEN_NAME: clientDetails.MAIDEN_NAME,
          DOB: clientDetails.DOB,
        };

        this._cacheService.remove(CacheableItems.GPMSelectedClient);
        this._cacheService.set(CacheableItems.GPMSelectedClient, selectedClientObject);

        this._changeDetectorRef.markForCheck();
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._indexedDBService.getDBActiveSystemUserById(this._cacheService.get(CacheableItems.GPMSystemUserId)).subscribe((response) => {
      if (response) {
        const user: DisplaySystemUser = {
          organisationName: this._cacheService.get(CacheableItems.GPMUser).split("@")[1].replace("~", " "),
          name: response.displayName ?? response.name,
          email: response.email,
          lockoutTime: response.lockTimeoutMins,
        };
        const nameFragments = user.name.split(" ");
        user.initials =
          nameFragments.length > 1
            ? `${nameFragments[0][0]}${nameFragments[1][0]}`.toLocaleUpperCase()
            : `${nameFragments[0][0]}${nameFragments[0][1]}`.toLocaleUpperCase();
        this.sysUser = user;

        // get the inactivity time in seconds
        const userInactivityTime: number = user.lockoutTime ? user.lockoutTime * 60 : 0;
        if (userInactivityTime !== 0) {
          this._bnIdle.startWatching(userInactivityTime).subscribe((isTimedOut: boolean) => {
            if (isTimedOut) {
              this._router.navigate(["sign-out"]);
            }
          });
        }
      }
    });

    this._indexedDBService.getDBVendorById(this._cacheService.get(CacheableItems.GPMSessionVendorId)).subscribe((response) => {
      if (response) {
        this._cacheService.set(CacheableItems.SessionVendorTimeZone, response.timeZoneId);
      }
    });

    this._loadingService.visible$.subscribe((result) => {
      this.isLoading = result;
    });

    const selectedClient = this._cacheService.get(CacheableItems.GPMSelectedClient);
    if (selectedClient) {
      this.selectedClient = selectedClient;
      this.onClientSelect(selectedClient);
    }

    this.gpmVersion = this._cacheService.get(CacheableItems.GPMVersion);
    const vendorId = this._cacheService.get(CacheableItems.GPMSessionVendorId);
    // TODO: implement websocket
    // this._authService.getCurrentSession().subscribe((response) => {
    //   this._webSocketService.hlWebconnect(response.GenWSK, vendorId);
    // });

    // Subscribe to navigation data
    zip(this._healthlinkService.getHlNotificationDetails(), this._navigationService.navigation$)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        ([hlNotification, navigation]) => {
          this.navigation = navigation;
          // need to find healthlink nav and add the notification badge
          if (hlNotification.ShowNotification === true) {
            const healthlinkNav = this.navigation.compact.find((n) => n.id === "healthlink");
            healthlinkNav.badge = {
              title: "h",
              classes:
                "flex items-center justify-center flex-shrink-0 min-w-4 h-4 rounded-full text-white text-xs font-medium" +
                (hlNotification.IsProviderNotification === true ? " bg-gensolve-1000" : " bg-gensolve-600"),
            };
            this._navigationService.setNavigation(this.navigation);
          }
          this.addLogOutToNav();
          this._changeDetectorRef.markForCheck();
        },
        (err) => {
          // if anything goes wrong with the hlnotification call, load the navigation atleast for the user to carry on
          this._navigationService.navigation$.pipe(takeUntil(this._unsubscribeAll)).subscribe((navigation) => {
            this.navigation = navigation;
            this._navigationService.setNavigation(this.navigation);
            this.addLogOutToNav();
            this._changeDetectorRef.markForCheck();
          });
        }
      );

    this.liveData$.subscribe((response) => {
      let newEvent: HlNotificationDetails = JSON.parse(response);
      if (newEvent) {
        // need to find healthlink nav and add the notification badge
        const navigationIns = this._navigationService.navigationInst;
        if (navigationIns) {
          const healthlinkIndex = navigationIns.compact.findIndex((n) => n.id === "healthlink");
          const healthlinkNav = navigationIns.compact[healthlinkIndex];
          healthlinkNav.badge = {
            title: "h",
            classes:
              "flex items-center justify-center flex-shrink-0 min-w-4 h-4 rounded-full text-white text-xs font-medium fixed top-40" +
              (newEvent.IsProviderNotification === true ? " bg-gensolve-1000" : " bg-gensolve-600"),
          };
          // to trigger the fuse navigation component, we need to pass it as a new item
          const newHealthlinkNav = {
            ...healthlinkNav,
          };
          navigationIns.compact.splice(healthlinkIndex, 1, newHealthlinkNav);
          navigationIns.compact = [].concat(navigationIns.compact);
          this._navigationService.setNavigation(navigationIns);
          this._changeDetectorRef.detectChanges();
        }
      }
    });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$.pipe(takeUntil(this._unsubscribeAll)).subscribe(({ matchingAliases }) => {
      // Check if the screen is small
      this.isScreenSmall = !matchingAliases.includes("md");

      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
  }

  addLogOutToNav() {
    const logOutNav = this.navigation.compact.find((n) => n.id === "logout");
    if (!logOutNav) {
      this.navigation.compact.push({
        icon: "gpm_logout",
        id: "logout",
        link: "/sign-out",
        title: "Logout",
        tooltip: "Logout",
        type: "basic",
        classes: {
          icon: "font-medium w-4 h-5",
        },
      });
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();

    this._webSocketService.closeHlWebSocket();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  public onClientSelect(event: any): void {
    let apptsLoaded: boolean = false;
    let conditionsLoaded: boolean = false;

    this.numberOfConditions = null;
    this.selectedClient = event;

    this._cacheService.set(CacheableItems.GPMSelectedClient, this.selectedClient);

    // Empty arrays for new data
    this.conditionsData = [];
    this.apptsData = [];
    this.noShowAppts = [];
    this.phoneData = [];
    this.emailData = [];
    this.debtorBalance = null;
    this.noShows = 0;

    this._loadingService.show();

    this._clientService
      .getClientHeaderData(this.selectedClient.CLIENT_ID)
      .pipe(debounceTime(1000))
      .subscribe((response) => {
        this.clientHeaderData = response;
        this.debtorBalance = response.DebtorBalance;

        //Set client name color
        this.setClientNameColor(response);

        //Set Phones Data
        this.setClientPhonesData(response);

        //Set Emails Data
        this.setEmailsData(response);

        //Set Appts Data
        this.setApptData(response).subscribe({
          next: (data) => {
            if (data) {
              if (this.apptsData.length < 2) {
                this.apptsData.push(data);
              }

              if (
                data.Appointment.STATUS === AppointmentStatus.NoShowApptCharge ||
                data.Appointment.STATUS === AppointmentStatus.NoShowNoCharge ||
                data.Appointment.STATUS === AppointmentStatus.NoShowSetCharge
              ) {
                this.noShowAppts.push(data);
                this.noShows++;
              }
            }

            if (this.apptsData.length === response.Appointments.length || this.apptsData.length === 2) {
              apptsLoaded = true;
              if (conditionsLoaded) {
                this._loadingService.hide();
              }
            }
          },
          error: (err) => {
            console.log(err);
          },
        });

        //Set Conditions Data
        this.setConditionData(response).subscribe(
          (data) => {
            if (data) {
              if (this.conditionsData.length < 2) {
                this.conditionsData.push(data);
              }

              if (this.conditionsData.length === response.Conditions.length || this.conditionsData.length === 2) {
                conditionsLoaded = true;
                if (apptsLoaded) {
                  this._loadingService.hide();
                }
              }
            }
          },
          (err) => {
            console.log(err);
          }
        );

        if (apptsLoaded && conditionsLoaded) {
          this._loadingService.hide();
        }

        const currentUrl = this._router.url;
        if (currentUrl.includes("client-details")) {
          const urlClientId = Number(currentUrl.split("/")[2]);
          if (urlClientId !== this.selectedClient.CLIENT_ID) {
            this.viewClientDetails();
          }
        }
      });
  }

  private setClientNameColor(response: ClientHeaderDataModel) {
    // In case HIGHLIGHT_CLIENT_IND is equal to 1, and there's no HighlightInfo
    // It means we need to display 'default' color, as describes in GPM Windows, should be red
    if (response.Client.HIGHLIGHT_CLIENT_IND == 1 && response.HighlightInfo == null) {
      this.ClientNameColor = this.clientDefaultColorRed;
    } else if (response.HighlightInfo) {
      this.ClientNameColor = `rgb(${response.HighlightInfo?.COLOUR})`;
    } else {
      this.ClientNameColor = this.clientDefaultNoColor;
    }
  }

  private setClientPhonesData(response: ClientHeaderDataModel) {
    if (response.Client.HOME_MOBILE) {
      this.phoneData.push({
        value: response.Client.HOME_MOBILE,
        name: ClientPhoneNumbers.HOME_MOBILE,
      });
    }

    if (response.Client.HOME_PHONE) {
      this.phoneData.push({
        value: response.Client.HOME_PHONE,
        name: ClientPhoneNumbers.HOME_PHONE,
      });
    }

    if (response.Client.WORK_MOBILE) {
      this.phoneData.push({
        value: response.Client.WORK_MOBILE,
        name: ClientPhoneNumbers.WORK_MOBILE,
      });
    }

    if (response.Client.WORK_PHONE) {
      this.phoneData.push({
        value: response.Client.WORK_PHONE,
        name: ClientPhoneNumbers.WORK_PHONE,
      });
    }
  }

  private setEmailsData(response: ClientHeaderDataModel) {
    if (response.Client.HOME_EMAIL) {
      this.emailData.push({
        value: response.Client.HOME_EMAIL,
        name: ClientEmails.HOME_EMAIL,
      });
    }

    if (response.Client.WORK_EMAIL) {
      this.emailData.push({
        value: response.Client.WORK_EMAIL,
        name: ClientEmails.WORK_EMAIL,
      });
    }
  }

  public clearClient(): void {
    this.selectedClient = null;
    this.numberOfConditions = null;
    this._cacheService.remove(CacheableItems.GPMSelectedClient);
  }

  public viewClientDetails(): void {
    this._breadCrumbService.clearAppState().subscribe(() => {
      const clientId = this.selectedClient.CLIENT_ID;
      const url = `/client-details/${clientId}/client`;
      // navigate to dummy page first to force reload.
      this._router.navigateByUrl("/client-details-dummy", { skipLocationChange: true }).then(() => this._router.navigate([url]));
    });
  }

  private setApptData(data: ClientHeaderDataModel): Observable<AppointmentData> {
    const apptsData = new BehaviorSubject<AppointmentData>(null);

    const providers: ProviderDBModel[] = [];
    const sites: SiteDBModel[] = [];

    data.Appointments.sort((a, b) => new Date(b.START_DATETIME).getTime() - new Date(a.START_DATETIME).getTime());

    data.Appointments.forEach((appt) => {
      const provider = providers.find((p) => p.id === appt.PROVIDER_ID);
      const site = sites.find((s) => s.id === appt.SITE_ID);

      forkJoin({
        Provider: provider ? of(provider) : this._indexedDBService.getDBProviderById(appt.PROVIDER_ID),
        Site: site ? of(site) : this._indexedDBService.getDBSiteById(appt.SITE_ID),
      })
        .pipe(take(1))
        .subscribe((data) => {
          if (providers.find((p) => p.id !== (data.Provider as ProviderDBModel).id)) {
            providers.push(data.Provider);
          }

          if (sites.find((s) => s.id !== (data.Site as SiteDBModel).id)) {
            sites.push(data.Site);
          }

          const apptData: AppointmentData = {
            Provider: data.Provider,
            Site: data.Site,
            Appointment: appt,
            LocaleStartDateTime: new Date(appt.START_DATETIME),
          };
          apptsData.next(apptData);
        });
    });

    return apptsData.asObservable();
  }

  private setConditionData(data: ClientHeaderDataModel): Observable<ConditionData> {
    const conditionsData = new BehaviorSubject<ConditionData>(null);

    const providers: ProviderDBModel[] = [];
    const sites: SiteDBModel[] = [];

    data.Conditions.sort((a, b) => new Date(b.DATE_OF_INJURY).getTime() - new Date(a.DATE_OF_INJURY).getTime());

    data.Conditions.forEach((condition) => {
      const provider = providers.find((p) => p.id === condition.PROVIDER_ID);
      const site = sites.find((s) => s.id === data.Client.HOME_SITE_ID);

      forkJoin({
        Provider: provider ? of(provider) : this._indexedDBService.getDBProviderById(condition.PROVIDER_ID),
        Site: site ? of(site) : this._indexedDBService.getDBSiteById(data.Client.HOME_SITE_ID),
      })
        .pipe(take(1))
        .subscribe((data) => {
          if (providers.find((p) => p.id !== (data.Provider as ProviderDBModel).id)) {
            providers.push(data.Provider);
          }

          if (sites.find((s) => s.id !== (data.Site as SiteDBModel).id)) {
            sites.push(data.Site);
          }

          const ianaTz: IanaName[] = findIana((data.Site as SiteDBModel).timezoneId);

          const date = new Date(condition.DATE_OF_INJURY);

          const locale = ianaTz[0].includes("Australia") ? "en-AU" : "en-NZ";

          const localeDate = date.toLocaleString(locale, {
            month: "short",
            day: "2-digit",
            hour12: true,
            hour: "numeric",
            minute: "2-digit",
            timeZone: ianaTz[0],
            year: "2-digit",
          });

          const conditionData: ConditionData = {
            Provider: data.Provider,
            Condition: condition,
            LocalInjuryDateTime: new Date(localeDate),
          };
          conditionsData.next(conditionData);
        });
    });

    return conditionsData.asObservable();
  }

  onDarkModeToggle(event: any) {
    this.setScheme(event.checked ? "dark" : "light");
  }

  setScheme(scheme: Scheme): void {
    this._fuseConfigService.config = { scheme };
    this._utilService.setDarkModePreference(scheme);
    this.dark_mode_scheme = scheme == "dark";
    this.lightModeColor = this.dark_mode_scheme ? "" : "text-amber-400";
    this.darkModeColor = this.dark_mode_scheme ? "text-on-primary" : "";
  }

  selectCondition(event: any): void {
    this._router.navigate([`/condition-details/${event.Condition.ID}/info`]);
  }

  navigationOpenChanged(isOpen: boolean): void {}

  public composeEmail(email: string) {
    this._emailComposeService.openEmailComposer({
      recipientEmail: email,
      entityType: EntityTypeEnum.CLIENT,
      templateTypes: this._emailService.getEmailTemplateTypesByEntityType(EntityTypeEnum.CLIENT),
      siteId: this.clientHeaderData.Client.HOME_SITE_ID,
      clientId: this.selectedClient.CLIENT_ID,
      emailSuggestionEntityId: this.selectedClient.CLIENT_ID,
      emailSuggestionSubEntityId: 0,
    });
  }

  // Shortcuts

  @HostListener("document:keydown.control.1")
  goToNav1(): void {
    this._router.navigate(["/calendar"]);
  }

  @HostListener("document:keydown.control.2")
  goToNav2(): void {
    this._router.navigate(["/healthlink"]);
  }

  signOut(): void {
    this._router.navigate(["/sign-out"]);
  }

  goToClientAppointments(): void {
    this._router.navigate([`/client-details/${this.selectedClient.CLIENT_ID}/appointments`]);
  }

  goToClientConditions(): void {
    this._router.navigate([`/client-details/${this.selectedClient.CLIENT_ID}/conditions`]);
  }
}