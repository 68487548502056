import { AfterViewChecked, TemplateRef } from '@angular/core';
import { CellAlignment } from './mat-table-cell-content.service';
import { ColumnItem } from './mat-table-wrapper.service';

const SUMMARY_PREFIX = 'SUMMARY_';
const COLUMN_PREFIX = 'COLUMN_';

enum SummaryColumnType {
  SUMMARY_FOR_COLUMN = 'SUMMARY_FOR_COLUMN',
  SUMMARY_TITLE = 'SUMMARY_TITLE',
}

export interface SummaryCellValue {
  data: any;
}

interface SummaryColumn {
  summaryValue: SummaryCellValue;
  nameColumn: string;
  renderedItem?: ColumnRenderInfo;
}

export interface SummaryItemDefinition {
  title: string;  // static value to be displayed
  name: string; // name for summary
  titleTemplate?: TemplateRef<any>;  // dynamic value to be display
  summaryColumns?: Array<SummaryColumn>;
}

export interface SummaryCellItem {
  name: string;
  width: number;
  template: TemplateRef<any>;
  value: SummaryCellValue; // static value\
  type: SummaryColumnType;
  alignment: CellAlignment;
}

interface ColumnRenderInfo {
  name: string;
  width: number;
  alignment: CellAlignment;
}

// This class is to handling the summary information of mat-table-wrapper.
// It has summaryCellItemList represent for list of row. Each row has list of summary cell
// It tries to calculate the summary cell width as the same as in the table.
export abstract class MatTableSummaryComponent {
  summaryCellItemList: Array<Array<SummaryCellItem>>;
  summaryItemDefs: Array<SummaryItemDefinition>;

  renderedColumnInfo: Array<ColumnRenderInfo>;

  rowWidth: number;

  constructor() {
    this.summaryCellItemList = [];
    this.summaryItemDefs = [];
  }

  // the method to allow the outsider to update Summary def
  updateSummaryDef(width: number, displayColumnName: Array<string>, columnDefs: Array<ColumnItem>, summaryDefs: Array<SummaryItemDefinition>): void {
    // force update everything
    this._setupSummaryItems(width, displayColumnName, columnDefs, summaryDefs);
  }

  getColumnsRenderInfo(width: number, renderColumnDef: Array<ColumnItem>): Array<ColumnRenderInfo> {
    const minWidth: number = renderColumnDef
      .filter(item => item.width && item.width > 0)
      .reduce((accumulator, currentItem) => accumulator + currentItem.width, 0);

    const noOfNoWidthColumn: number = renderColumnDef.filter(item => !item.width).length;

    const baseWidth: number = (width - minWidth) / noOfNoWidthColumn;

    return renderColumnDef.map((item) => {
      const targetWidth: number = (item.width && item.width > 0) ? item.width : baseWidth;
      return  {
        name: item.fieldName,
        width: targetWidth,
        alignment: item.textStyle?.alignment,
      };
    });
  }

  // retrieve SummaryCellItem from SummarIemDefintion and renderedColumnInfo before rendering
  getSummaryCellFromSummaryDefinition(cellDef: SummaryItemDefinition, renderedColumnInfos: Array<ColumnRenderInfo>, width: number): Array<SummaryCellItem> {
    const columnSummaries: Array<SummaryColumn> = cellDef.summaryColumns || [];
    const foundRenderColumns: Array<SummaryColumn> = columnSummaries
      .map(item => ({
          summaryValue: item.summaryValue,
          nameColumn: item.nameColumn,
          renderedItem: renderedColumnInfos.find(col => col.name === item.nameColumn),
        }))
      .filter(item => !!item.renderedItem);

    // sort to make sure column keep the same order as renderColumnsInfo
    const sortedRenderColumns: Array<SummaryColumn> =
      foundRenderColumns.sort((first, second) => renderedColumnInfos.indexOf(first.renderedItem) > renderedColumnInfos.indexOf(second.renderedItem) ? 1 : -1);

    const indicesInRender: Array<number> = sortedRenderColumns.map(item => renderedColumnInfos.indexOf(item.renderedItem));

    // console.log("sortedRenderColumns ", sortedRenderColumns);
    // console.log("indicesInRender >>>> ", indicesInRender);

    // calculate newWidth for all elements
    const newWidths: Array<number> = indicesInRender.map((element, index, array) => {
      const toIndex: number = index + 1 < array.length ? array[index + 1] : renderedColumnInfos.length;
      const fromIndex: number = element;
      const list: Array<ColumnRenderInfo> = renderedColumnInfos.slice(fromIndex, toIndex);
      return list.reduce((accumulator, currentItem) => accumulator + currentItem.width, 0);
    });

    // create SummaryCellItems
    const result: Array<SummaryCellItem> = sortedRenderColumns.map((item, index) => ({
        name: item.nameColumn,
        width: newWidths[index],
        template: null,
        value: item.summaryValue,
        type: SummaryColumnType.SUMMARY_FOR_COLUMN,
        alignment: item.renderedItem.alignment,
      }));

    const widthForSummary: number = width - newWidths.reduce((accumulator, currentWidth) => accumulator + currentWidth, 0);
    const summaryItem: SummaryCellItem = {
      name: cellDef.name,
      width: widthForSummary,
      template: cellDef.titleTemplate,
      value: {
        data: cellDef.title,
      },
      type: SummaryColumnType.SUMMARY_TITLE,
      alignment: CellAlignment.LEFT, // title is alway align left
    };

    return [summaryItem].concat(result);
  }

  setupSummaryItems(width: number, displayColumnName: Array<string>, columnDefs: Array<ColumnItem>, summaryDefs: Array<SummaryItemDefinition>): void {
    if (Math.abs(width - this.rowWidth) < 1) {return;}

    this._setupSummaryItems(width, displayColumnName, columnDefs, summaryDefs);
  }

  initManuallySelectAndActionColumns(columnDefs: Array<ColumnItem>): Array<ColumnItem> {
    const actionDef: ColumnItem = {
      fieldName: 'Action',
      displayName: null,
      width: 48,
    };

    const selectDef: ColumnItem = {
      fieldName: 'Select',
      displayName: null,
      width: 72,
    };

    return [selectDef].concat(columnDefs).concat([actionDef]);
    // return [selectDef].concat(columnDefs);
  }

  _setupSummaryItems(width: number, displayColumnName: Array<string>, columnDefs: Array<ColumnItem>, summaryDefs: Array<SummaryItemDefinition>): void {
    // NOTE that Select and Action is not in the list of column definition, so we add them to the list and specify the width for them manually.
    const newColumnDefs: Array<ColumnItem> = this.initManuallySelectAndActionColumns(columnDefs);

    const renderedColumnDef: Array<ColumnItem> = displayColumnName
      .map(item => newColumnDefs.find(colDef => colDef.fieldName === item))
      .filter(item => !!item);

    // calculate renderedColumnInfo
    this.renderedColumnInfo = this.getColumnsRenderInfo(width, renderedColumnDef);

    // calculate SummaryCellItem
    this.summaryCellItemList = summaryDefs.map(item => this.getSummaryCellFromSummaryDefinition(item, this.renderedColumnInfo, width));

    this.rowWidth = width;
  }
}
