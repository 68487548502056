import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from "@angular/material/chips";
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatExpansionModule } from '@angular/material/expansion';
import { HealthlinkModule } from "app/modules/healthlink/healthlink.module";

import { EmailComposerContainerComponent } from "./email-compose-container/email-compose-container.component";
import { EmailComposeComponent } from "./email-compose.component";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { EmailComposeService } from "./email-compose-service";
import { EmailService } from "app/core/services/email.service";
import { MapDictionaryToArrayPipe } from "app/core/pipes/map-dictionary-to-array.pipe";
import { MatCheckboxModule } from "@angular/material/checkbox";

@NgModule({
    imports: [MatToolbarModule, MatChipsModule, MatIconModule, MatAutocompleteModule, MatSelectModule, MatDialogModule, MatButtonModule, CommonModule, MatFormFieldModule, MatInputModule, MatExpansionModule, ReactiveFormsModule, CKEditorModule, MatCheckboxModule],
    exports: [EmailComposeComponent, EmailComposerContainerComponent],
    declarations: [EmailComposeComponent,EmailComposerContainerComponent],
    providers: [EmailComposeService, EmailService, MapDictionaryToArrayPipe],
})
export class EmailModule {}
