import { Injectable } from "@angular/core";
import { ColumnItem, TableItemAction } from "@components/table/mat/mat-table-wrapper.service";
import { FilterColumnService, FilterItem } from "@components/table/table-column-filter/table-serverside-column-filter.service";
import { ServerSideTableService } from "@components/table/table-serverside.service";
import { ClientService } from "app/core/client/client.service";
import { VfClientMembershipApptsModel } from "app/core/models/appointment/vf-client-membership-appts.model";
import { DateTimeService } from "app/core/services/datetime.service";
import { Observable, of } from "rxjs";

const retrieveFilterValueForNameItem = (name: string, filterItemList: Array<FilterItem>): any | null => {
  const itemFilter: FilterItem = filterItemList.find((item) => item.fieldName === name);
  return itemFilter ? itemFilter.filterValue : null;
};

@Injectable()
export class AppointmentUsageTableService implements ServerSideTableService {
  public clientId: string;
  constructor(private _clientService: ClientService, private _dateTimeService: DateTimeService) {}

  getActionList(): TableItemAction[] {
    return [ ];
  }
  getColumnDefinitions(): ColumnItem[] {
    return [
      {
        fieldName: "START_DATETIME",
        displayName: "Appointment Date",
        config: {
          isDateTime: true,
        },
      },           
      { fieldName: "PROVIDER_NAME", displayName: "Appointment With" },
      { fieldName: "CHARGE_TYPE_NAME", displayName: "Service Charge Type" },
      { fieldName: "MEMBERSHIP_TYPE_NAME", displayName: "Membership", isSorting : true },
      { fieldName: "MEMBERSHIP_UNITS_USED", displayName: "Units Used" },
    ];
  }
  
  getData(): Observable<VfClientMembershipApptsModel[]> {    
    return this._clientService.getClientMembershipsApptUsage(this.clientId);
  }
}

@Injectable({ providedIn: "root" })
export class AppointmentUsageFilterService implements FilterColumnService {
  constructor() {}
  getAllowedFilterFields(): Array<FilterItem> {
    return [];
  }

  getSelectDataForFilterItem(item: FilterItem, params: any): Observable<any[]> {
    let results: Observable<any[]>;
    return results;
  }

  getDataButtonText(): string {
    return "";
  }
  getPageTitle(): string {
    return "Appointment Usage";
  };

  getFilterServiceUniqueName(): string {
    return "Appointment_Usage_Filter";
  }
}
