import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogInput } from '../table-serverside-column-filter.service';

@Component({
	selector: 'app-text-dialog-component',
	templateUrl: './text-dialog.component.html',
})

export class TextDialogComponent implements OnInit {
	filterTextValue = '';

	constructor(
		public matDialogRef: MatDialogRef<TextDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogInput
	) {
		this.filterTextValue = this.data.column && this.data.column.filterValue
			? this.data.column.filterValue.filterValue : '';
	}
	ngOnInit(): void {

	}

	applyFilter() {
		if (this.filterTextValue) {
			this.matDialogRef.close({
				item: this.data.column,
				filterValue: {
					filterValue: this.filterTextValue,
				}
			});
		}

	}
}
