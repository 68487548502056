import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@NgModule()
export class IconsModule {
    /**
     * Constructor
     */
    constructor(
        private _domSanitizer: DomSanitizer,
        private _matIconRegistry: MatIconRegistry
    ) {
        // Register icon sets
        this._matIconRegistry.addSvgIconSet(
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/material-twotone.svg'
            )
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'mat_outline',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/material-outline.svg'
            )
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'mat_solid',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/material-solid.svg'
            )
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'iconsmind',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/iconsmind.svg'
            )
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'feather',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/feather.svg'
            )
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'heroicons_outline',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/heroicons-outline.svg'
            )
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'heroicons_solid',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/heroicons-solid.svg'
            )
        );

        this._matIconRegistry.addSvgIcon(
            'error_icon',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/error_icon.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'gpm_previous_arrow',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/gpm_previous_arrow.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'gpm_next_arrow',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/gpm_next_arrow.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'gpm_view',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/gpm_view.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'gpm_setting',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/gpm_setting.svg'
            )
        );

        this._matIconRegistry.addSvgIcon(
            'gpm_calendar',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/gpm_calendar.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'gpm_sites',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/gpm_sites.svg'
            )
        );
        this._matIconRegistry.addSvgIcon(
            'gpm_providers',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/gpm_providers.svg'
            )
        );

        this.registerIcon('gpm_arrow_up');

        this.registerIcon('gpm_view');
        this.registerIcon('gpm_new');
        this.registerIcon('gpm_money_alert');
        this.registerIcon('gpm_invoice');

        // for header
        this.registerIcon('gpm_header_appointment');
        this.registerIcon('gpm_header_condition');
        this.registerIcon('gpm_header_currency');
        this.registerIcon('gpm_header_email');
        this.registerIcon('gpm_header_phone');
        this.registerIcon('gpm_header_info');
        this.registerIcon('gpm_header_edit_underline');
        this.registerIcon('gpm_header_plus');
        this.registerIcon('gpm_threedot');
        this.registerIcon('gpm_logout');

        // for client summary
        this.registerIcon('gpm_arrow_navigate_to');
        this.registerIcon('gpm_slide_sheet_detail_info');
        this.registerIcon('gpm_back_arrow');

        this.registerIcon('gpm_close');
        this.registerIcon('gpm_expand_card');

        //Healthlink New Message
        this.registerIcon('gpm_info');
        this.registerIcon('gpm_help');
        this.registerIcon('gpm_attach_file');

        // for table
        this.registerIcon('gpm_add_filter');
        this.registerIcon('gpm_healthlink');
        this.registerIcon('gpm_warning');
        this.registerIcon('gpm_matching');
        this.registerIcon('gpm_edit');

        this.registerIcon('gpm_search');
        this.registerIcon('gpm_close_no_border');

        this.registerIcon('gpm_refresh');

        // breadcrumb
        this.registerIcon('gpm_red_flag');
        this.registerIcon('gpm_square_nav');

        this.registerIcon('gpm_clients');
        this.registerIcon('gpm_copy');
        this.registerIcon('gpm_share_email');

        this.registerIcon('gpm_referrals');
        this.registerIcon('gpm_acc32');
        this.registerIcon('gpm_discharges');
        this.registerIcon('gpm_investigations');
        this.registerIcon('gpm_flip');
        this.registerIcon('gpm_administration');
        this.registerIcon('gpm_contracts');
        this.registerIcon('gpm_front_office');
        this.registerIcon('gpm_medical_notes');
        this.registerIcon('gpm_misc_billing');
        this.registerIcon('gpm_splinting');
        this.registerIcon('gpm_outcome_measures');
        this.registerIcon('gpm_condition_detail');
        this.registerIcon('gpm_info--circle');
    }

    registerIcon(iconName: string): void {
        const iconLocation: string = 'assets/icons/' + iconName + '.svg';
        this._matIconRegistry.addSvgIcon(
            iconName,
            this._domSanitizer.bypassSecurityTrustResourceUrl(iconLocation)
        );
    }
}
