import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TableItemAction } from "@components/table/mat/mat-table-wrapper.service";
import { ClientStatementsFilterColumnService, ClientStatementsTableService } from "./client-statements-table.service";


@Component({
  selector: "app-client-statements",
  templateUrl: "./client-statements.component.html",
  providers: [ClientStatementsTableService, ClientStatementsFilterColumnService],
})
export class ClientStatementsComponent implements OnInit {
  clientId: string;
  constructor(
    private _activatedRoute: ActivatedRoute,
    public tableService: ClientStatementsTableService,
    public filterColumnService: ClientStatementsFilterColumnService
  ) {
    const params = this._activatedRoute.snapshot.params;
    this.clientId = params['clientId'];

    this.tableService.clientId = this.clientId;
  }

  ngOnInit(): void {

  }

  onActionHandler(item: { rowItem: any; action: TableItemAction }): void {

  }
}
