import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FormItemData } from "@components/table/table-column-filter/dialogs/general-filter-dialog.service";
import { CustomFilterConfig } from "@components/table/table-column-filter/table-serverside-column-filter.service";
import { merge, Subscription } from "rxjs";
import { SubjectDetailsData } from "./client-events-subject-details-filter.types";

@Component({
  selector: "app-client-events-subject-details-filter",
  templateUrl: "client-events-subject-details-filter.component.html",
})
export class ClientEventsSubjectDetailsFilterComponent implements OnInit {
  @Input() formDataItem: FormItemData;

  subjectFC: FormControl;
  detailsFC: FormControl;

  config: CustomFilterConfig;
  mainFormControl: FormControl;

  subscription: Subscription;

  constructor() {
    this.subjectFC = new FormControl();
    this.detailsFC = new FormControl();
  }

  ngOnInit(): void {
    this.config = this.formDataItem.config.config as CustomFilterConfig;
    this.mainFormControl = this.formDataItem.control;

    const data: SubjectDetailsData = this.mainFormControl.value as SubjectDetailsData;
    this.subjectFC.setValue(data?.subject);
    this.detailsFC.setValue(data?.details);

    this.subscription = merge(this.subjectFC.valueChanges, this.detailsFC.valueChanges).subscribe(() => {
      this.updateAmountRange();
    });
  }

  updateAmountRange(): void {
    const outputData: SubjectDetailsData = {
      subject: this.subjectFC.value,
      details: this.detailsFC.value,
    };

    this.mainFormControl.setValue(outputData);
  }

  onSubjectInput(event: any): void {
    if (event && event.data) {
      this.detailsFC.disable();
    } else {
      this.detailsFC.enable();
    }
  }

  onDetailsnput(event: any): void {
    if (event && event.data) {
      this.subjectFC.disable();
    } else {
      this.subjectFC.enable();
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
