<div class="super-finder" [ngClass]="{'mobile': isMobile, 'noBackground': isNoBackground}" #rootEle>
  <mat-table #table [dataSource]="_dataSource" matSort #tableSort = "matSort" multiTemplateDataRows>
	<!--Column definition-->
	<ng-container *ngFor="let col of columns" [matColumnDef]="col.fieldName">
	  <mat-header-cell class="pr-2" *matHeaderCellDef [ngClass]="{'hasWidth': col.width && col.width > 0 && !isTabletPortrait && !isMobile, 'header-alignment-right': col.textStyle && col.textStyle.alignment === 'RIGHT' && !isMobile}" [style.width.%]="!isTabletPortrait  && !isMobile ? col.width : null">
		{{ col.displayName }}
		<div mat-sort-header class="sort-header" *ngIf="col.isSorting"></div>
	  </mat-header-cell>

	  <mat-cell class="pr-2" *matCellDef="let element" [ngClass]="{'hasWidth': col.width && col.width > 0 && !isTabletPortrait && !isMobile, 'cell-alignment-right': col.textStyle && col.textStyle.alignment === 'RIGHT' && !isMobile}" [style.width.%]="!isTabletPortrait  && !isMobile ? col.width : null">
		<app-mat-table-cell-content-wrapper [rowElementData]="element" [colmnDefinition]="col"
		  [requestEdit]="editItemList.indexOf(element) !== -1 && _tableStatus === 'EDIT' ? tableStatusEnum.EDIT : tableStatusEnum.VIEW"
		  [textStyle]="col.textStyle" [cellConfigProvider]="cellConfigProvider"
		  (dataChange)="dataContentChange($event)">

		</app-mat-table-cell-content-wrapper>
	  </mat-cell>
	</ng-container>

	<ng-container matColumnDef="expandedDetail">
	  <mat-cell *matCellDef="let element">
		<app-mat-table-row-sub-info-wrapper
		  (initiatedEvent)="initiatedEventForSub($event)" (destroyedEvent)="destroyedEventForSub($event)" (dataInitiationEvent)="dataInitiationEventForSub($event)"
		  [rowData]="element" [requestExpand]="element.isExpanded" [subEleConfig]="rowConfig.subRowElemengConfig">

		</app-mat-table-row-sub-info-wrapper>
	  </mat-cell>
	</ng-container>

	<!-- Column defition for setting -->
	<ng-container matColumnDef="Action">
	  <mat-header-cell *matHeaderCellDef class="tb-action-header">
		<button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="_settingColumns && _settingColumns.length > 0">
		  <mat-icon svgIcon="gpm_setting"></mat-icon>
		</button>
	  </mat-header-cell>
	  <mat-cell *matCellDef="let element" class="tb-action-cell">
		<div class="tb-action-cell-content" *ngIf="actionList.length === 1 || !isMobile">
		  <span (click)="selectActionHandler(element, action)" *ngFor="let action of actionList"
			class="tb-action-cell-content-title">{{action.label}}
		  </span>
		</div>
		<div *ngIf="actionList.length > 1 && isMobile" class="tb-action-cell-content-action-list-mobile"
		  [matMenuTriggerFor]="matMenuAction">
		  <span class="tb-action-text">Action</span>
		  <span class="tb-action-text tb-action-arrow"></span>
		  <mat-menu #matMenuAction overlapTrigger="true" closed="clickz">
			<div class="mat-menu-label">Action</div>
			<button *ngFor="let actionItem of actionList" mat-menu-item (click)="selectActionHandler(element, actionItem)">
			  {{ actionItem.label }}
			</button>
		  </mat-menu>
		</div>
	  </mat-cell>
	</ng-container>

	<!-- Column definition for multiple selection -->
	<ng-container matColumnDef="Select">
	  <mat-header-cell *matHeaderCellDef class="tb-select-header">
		<mat-checkbox [color]="'primary'" (change)="masterToggle($event)" [checked]="_selection.hasValue() && isAllSelected()"
		  [indeterminate]="_selection.hasValue() && !isAllSelected()"
		  [aria-label]="checkboxLabel()">
		</mat-checkbox>
	  </mat-header-cell>
	  <mat-cell *matCellDef="let element" class="tb-select-cell">
		<mat-checkbox [color]="'primary'" (click)="$event.stopPropagation()" (change)="childToggle($event, element)"
		  [checked]="_selection.isSelected(element)"
		  [aria-label]="checkboxLabel(element)">
		</mat-checkbox>
	  </mat-cell>
	</ng-container>

	<ng-container matColumnDef="Warning">
	  <mat-header-cell *matHeaderCellDef class="tb-warning-header">
		<mat-icon svgIcon="gpm_warning" matTooltip="Warning" class="tb-warning-icon"></mat-icon>
	  </mat-header-cell>
	  <mat-cell *matCellDef="let element" class="tb-warning-cell">
		<mat-icon svgIcon="gpm_warning" matTooltip="Unmatched data" *ngIf="rowConfig.isRowWarning(element)" class="tb-warning-icon" (click)="selectWarningHandler(element)"></mat-icon>
	  </mat-cell>
	</ng-container>
	<ng-container matColumnDef="DetailItem">
	  <mat-header-cell *matHeaderCellDef class="tb-detail-item-header">

	  </mat-header-cell>
	  <mat-cell *matCellDef="let element" class="tb-detail-item-cell">
		<mat-icon svgIcon="keyboard_arrow_up" *ngIf="!element.isExpanded"
		  class="tb-detail-item-icon" (click)="requestExpandForRow(element)"></mat-icon>
		<mat-icon svgIcon="keyboard_arrow_down" *ngIf="element.isExpanded"
		  class="tb-detail-item-icon" (click)="requestCollapseForRow(element)"></mat-icon>
	  </mat-cell>
	</ng-container>

	<mat-header-row *matHeaderRowDef="_displayedColumns"></mat-header-row>
	<mat-row *matRowDef="let row; columns: _displayedColumns" [ngClass]="{
		hovered: row.hovered,
		highlighted: _selection.isSelected(row),
		abnormalResult: rowConfig?.hasAbnormalResult(row)
		}"
		(mouseover)="rowMouseOver($event, row)" (mouseout)="rowMouseOut($event, row)">
	</mat-row>
	<ng-container [ngSwitch]="isDisplaySubElementForRow">
	  <ng-container *ngSwitchCase="true">
		<mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expansion-detail-row"></mat-row>
	  </ng-container>
	</ng-container>
	<!-- <div class="table-no-data-row" *matNoDataRow> No data matching </div> -->
  </mat-table>  
  <!-- Summary section -->
  <div class="summary-section">
	<div class="summary-row" *ngFor="let summaryRowItem of summaryCellItemList">
	  <div class="summary-cell" *ngFor="let summaryItem of summaryRowItem" [style.width.px]="summaryItem.width" [ngClass]="{'summary-cell-title': summaryItem.type === 'SUMMARY_TITLE', 'cell-alignment-right': summaryItem.alignment === 'RIGHT'}">
		<ng-container *ngIf="summaryItem.template">
		  <ng-container *ngTemplateOutlet="summaryItem.template">

		  </ng-container>
		</ng-container>
		<ng-container *ngIf="!summaryItem.template">
		  {{summaryItem.value.data}}
		</ng-container>
	  </div>
	</div>
  </div>
  <div class="tb-pagination" *ngIf="!isNoPaginator" [ngClass]="{'invisible': !checkToDisplayNavigator()}">
	<mat-paginator #paginator [pageSize]="_noElementPerPage" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
	</mat-paginator>
  </div>
  <!-- Menu for setting -->
  <mat-menu #menu="matMenu" class="tb-action-menu">
	<button *ngFor="let columnDis of _settingColumns" mat-menu-item>
	  <mat-checkbox (click)="$event.stopPropagation()"
		(change)="toggleSelectGroupOfColumns($event, columnDis)"
		[checked]="columnDis.isAdded">
		{{ $any(columnDis).groupID ? $any(columnDis).groupName : $any(columnDis).displayName}}
	  </mat-checkbox>
	</button>
	<!-- OPTION 1: Nested mat-menu for radio buttons
	<button mat-menu-item [matMenuTriggerFor]="billingFields">Billing Fields</button>
	<mat-menu #billingFields="matMenu">
	  <mat-radio-group>
		<button mat-menu-item>
		  <mat-radio-button value="">ACC</mat-radio-button>
		</button>
		<button mat-menu-item>
		  <mat-radio-button value="">Client</mat-radio-button>
		</button>
	  </mat-radio-group>
	</mat-menu>
	-->
	<!-- OPTION 2 
	<div class="tb-action-menu__group">
	  <label id="tb-action-menu__label-1" class="tb-action-menu__label">Billing Fields</label>
	  <mat-radio-group aria-labelledby="tb-action-menu__label-1">
		<mat-radio-button value="" class="tb-action-menu__radio">ACC</mat-radio-button>
		<mat-radio-button value="" class="tb-action-menu__radio">Client</mat-radio-button>
	  </mat-radio-group>
	</div>
	-->
  </mat-menu>
</div>