import { Injectable } from '@angular/core';
import { ColumnGroupConfig, ColumnItem, MASTER_GROUP_ID, RowItemConfig, TableActionType, TableItemAction } from '@components/table/mat/mat-table-wrapper.service';
import { FilterColumnService, FilterItem, FilterItemHelper } from '@components/table/table-column-filter/table-serverside-column-filter.service';
import { ServerSideTableService } from '@components/table/table-serverside.service';
import { ClientService } from 'app/core/client/client.service';
import { DateTimeService } from 'app/core/services/datetime.service';
import { Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { PhysioFullHistoryType } from "app/core/enums/physio-enums";
import { PhysioClientFullHistoryModel } from "app/core/models/physio/physio-client-full-history.model";

export enum HISTORY_COLS {
    ITEM_DATE = 'ITEM_DATE',
    PERSON_NAME = 'PERSON_NAME',
    TYPE_NAME = 'TYPE_NAME',
    DETAILS = 'DETAILS',
    AMOUNT = 'AMOUNT',
}

const CLIENT_HISTORIES_FIELDS = {
    HistoryType: "Show History Of Type",
    Date: "Date",
    ACC32s: "ACC32s",
    Appointments: "Appointments",
    Conditions: "Conditions",
    Discharges: "Discharges",
    SMS: "SMS",
    Events: "Events",
    Exams: "Exams",
    FormEntries: "FormEntries",
    HealthlinkMessages: "HealthlinkMessages",
    Investigations: "Investigations",
    Invoices: "Invoices",
    Letters: "Letters",
    Uploads: "Uploads",
    Medications: "Medications",
    ProductSales: "ProductSales",  
    Statements: "Statements",
    Payments: "Payments",    
    MedicalNotes: "MedicalNotes",
    ClassHistory: "ClassHistory",
    ClaimReviews: "ClaimReviews"
  };


@Injectable()
export class ClientFullHistoryServersideTableService implements ServerSideTableService {
    public destroySubscriptions: Subject<boolean> = new Subject<boolean>();

    public clientId: number;

    constructor(
        private _clientService: ClientService,
        private _dateTimeService: DateTimeService
    ) {}

    getActionList(): Array<TableItemAction> {
        return [];
    }

    getColumnDefinitions(): ColumnItem[] {
        return [
            {
                fieldName: HISTORY_COLS.ITEM_DATE,
                displayName: 'Date',
                config: {
                    isDate: true,
                },
                isSorting: true,
            },
            {
                fieldName: HISTORY_COLS.PERSON_NAME,
                displayName: 'Clinician / User',
            },
            {
                fieldName: HISTORY_COLS.TYPE_NAME,
                displayName: 'Type',
            },
            {
                fieldName: HISTORY_COLS.DETAILS,
                displayName: 'Details',
            },
            {
                fieldName: HISTORY_COLS.AMOUNT,
                displayName: 'Amount',                
            },
        ];
    }


    // for retrieving data from server or refreshing data from selectedFilter
    getData(selectedFilterList: FilterItem[]): Observable<any[]> {
        const betweenFilterValue: any = FilterItemHelper.retrieveFilterValueForNameItem(
                CLIENT_HISTORIES_FIELDS.Date,
                selectedFilterList
            );
        const dateFrom = betweenFilterValue && betweenFilterValue.filterFrom
            ? this._dateTimeService.FormatDate(betweenFilterValue.filterFrom, 'MM/DD/yyyy')
            : '';
        const dateTo = betweenFilterValue && betweenFilterValue.filterTo
            ? this._dateTimeService.FormatDate(betweenFilterValue.filterTo, 'MM/DD/yyyy')
            : '';

        const historyTypesFilter: any = FilterItemHelper.retrieveFilterValueForNameItem(CLIENT_HISTORIES_FIELDS.HistoryType, selectedFilterList);
        const historyTypesFilterOptions: Array<any> = historyTypesFilter ? historyTypesFilter.filterValue as Array<any> : [];

        const historyTypes : Array<number> = [];
        if(historyTypesFilterOptions.findIndex(t => t.type === CLIENT_HISTORIES_FIELDS.ACC32s) > -1){
            historyTypes.push(PhysioFullHistoryType.ACC32)
        }
        if(historyTypesFilterOptions.findIndex(t => t.type === CLIENT_HISTORIES_FIELDS.Appointments) > -1){
            historyTypes.push(PhysioFullHistoryType.Appointment)
        }
        if(historyTypesFilterOptions.findIndex(t => t.type === CLIENT_HISTORIES_FIELDS.Conditions) > -1){
            historyTypes.push(PhysioFullHistoryType.Condition)
        }
        if(historyTypesFilterOptions.findIndex(t => t.type === CLIENT_HISTORIES_FIELDS.Discharges) > -1){
            historyTypes.push(PhysioFullHistoryType.Discharge)
        }
        if(historyTypesFilterOptions.findIndex(t => t.type === CLIENT_HISTORIES_FIELDS.Events) > -1){
            historyTypes.push(PhysioFullHistoryType.Event)
        }
        if(historyTypesFilterOptions.findIndex(t => t.type === CLIENT_HISTORIES_FIELDS.FormEntries) > -1){
            historyTypes.push(PhysioFullHistoryType.FormEntry)
        }
        if(historyTypesFilterOptions.findIndex(t => t.type === CLIENT_HISTORIES_FIELDS.HealthlinkMessages) > -1){
            historyTypes.push(PhysioFullHistoryType.HLMessage)
        }
        if(historyTypesFilterOptions.findIndex(t => t.type === CLIENT_HISTORIES_FIELDS.Invoices) > -1){
            historyTypes.push(PhysioFullHistoryType.Invoice)
        }
        if(historyTypesFilterOptions.findIndex(t => t.type === CLIENT_HISTORIES_FIELDS.Investigations) > -1){
            historyTypes.push(PhysioFullHistoryType.Investigation)
        }
        if(historyTypesFilterOptions.findIndex(t => t.type === CLIENT_HISTORIES_FIELDS.Letters) > -1){
            historyTypes.push(PhysioFullHistoryType.Letter)
        }
        if(historyTypesFilterOptions.findIndex(t => t.type === CLIENT_HISTORIES_FIELDS.Medications) > -1){
            historyTypes.push(PhysioFullHistoryType.Medication)
        }
        if(historyTypesFilterOptions.findIndex(t => t.type === CLIENT_HISTORIES_FIELDS.Exams) > -1){
            historyTypes.push(PhysioFullHistoryType.Exam)
        }
        if(historyTypesFilterOptions.findIndex(t => t.type === CLIENT_HISTORIES_FIELDS.ProductSales) > -1){
            historyTypes.push(PhysioFullHistoryType.ClientAppointmentProduct)
        }
        if(historyTypesFilterOptions.findIndex(t => t.type === CLIENT_HISTORIES_FIELDS.Payments) > -1){
            historyTypes.push(PhysioFullHistoryType.Payment)
        }
        if(historyTypesFilterOptions.findIndex(t => t.type === CLIENT_HISTORIES_FIELDS.Statements) > -1){
            historyTypes.push(PhysioFullHistoryType.Statement)
        }
        if(historyTypesFilterOptions.findIndex(t => t.type === CLIENT_HISTORIES_FIELDS.Uploads) > -1){
            historyTypes.push(PhysioFullHistoryType.Upload)
        }
        if(historyTypesFilterOptions.findIndex(t => t.type === CLIENT_HISTORIES_FIELDS.MedicalNotes) > -1){
            historyTypes.push(PhysioFullHistoryType.MedicalNote)
        }
        if(historyTypesFilterOptions.findIndex(t => t.type === CLIENT_HISTORIES_FIELDS.ClassHistory) > -1){
            historyTypes.push(PhysioFullHistoryType.ClassHistory)
        }
        if(historyTypesFilterOptions.findIndex(t => t.type === CLIENT_HISTORIES_FIELDS.ClaimReviews) > -1){
            historyTypes.push(PhysioFullHistoryType.ConditionReview)
        }
        if(historyTypesFilterOptions.findIndex(t => t.type === CLIENT_HISTORIES_FIELDS.SMS) > -1){
            historyTypes.push(PhysioFullHistoryType.SMS)
        }


        
        return this._clientService
            .getClientFullHistory(this.clientId, dateFrom, dateTo, historyTypes)
            .pipe(
                takeUntil(this.destroySubscriptions),
                map((history: Array<PhysioClientFullHistoryModel>) => {
                    return history; 
                })
            );
    }

}

@Injectable()
export class ClientFullHistoryFilterColumnService implements FilterColumnService {
    public destroySubscriptions: Subject<boolean> = new Subject<boolean>();

    constructor() { }

    protected historyTypes: any[] = [
        {
            value: false,
            type: CLIENT_HISTORIES_FIELDS.ACC32s,
            label: CLIENT_HISTORIES_FIELDS.ACC32s
          },
          {
              value: false,
              type: CLIENT_HISTORIES_FIELDS.Appointments,
              label: CLIENT_HISTORIES_FIELDS.Appointments
          },
          {
              value: false,
              type: CLIENT_HISTORIES_FIELDS.Conditions,
              label: CLIENT_HISTORIES_FIELDS.Conditions
          },
          {
              value: false,
              type: CLIENT_HISTORIES_FIELDS.Discharges,
              label: CLIENT_HISTORIES_FIELDS.Discharges
          },
          {
              value: false,
              type: CLIENT_HISTORIES_FIELDS.SMS,
              label: CLIENT_HISTORIES_FIELDS.SMS
          },
          {
              value: false,
              type: CLIENT_HISTORIES_FIELDS.Events,
              label: CLIENT_HISTORIES_FIELDS.Events
          },
          {
              value: false,
              type: CLIENT_HISTORIES_FIELDS.Exams,
              label: "Exams/Notes"
          },
          {
              value: false,
              type: CLIENT_HISTORIES_FIELDS.FormEntries,
              label: "Form Entries"
          },
          {
              value: false,
              type: CLIENT_HISTORIES_FIELDS.HealthlinkMessages,
              label: "Healthlink Messages"
          },
          {
              value: false,
              type: CLIENT_HISTORIES_FIELDS.Investigations,
              label: "Investigations"
          },
          {
              value: false,
              type: CLIENT_HISTORIES_FIELDS.Invoices,
              label: "Invoices"
          },
          {
              value: false,
              type: CLIENT_HISTORIES_FIELDS.Letters,
              label: "Letters"
          },
          {
            value: false,
            type: CLIENT_HISTORIES_FIELDS.Uploads,
            label: "Uploads"
          },
          {
              value: false,
              type: CLIENT_HISTORIES_FIELDS.Medications,
              label: "Medications"
          },
          {
              value: false,
              type: CLIENT_HISTORIES_FIELDS.ProductSales,
              label: "Product Sales"
          },
          {
              value: false,
              type: CLIENT_HISTORIES_FIELDS.Payments,
              label: "Payments/Write Offs"
          },
          {
              value: false,
              type: CLIENT_HISTORIES_FIELDS.Statements,
              label: "Statements"
          },
          {
              value: false,
              type: CLIENT_HISTORIES_FIELDS.MedicalNotes,
              label: "Clinical Notes"
          },
          {
              value: false,
              type: CLIENT_HISTORIES_FIELDS.ClassHistory,
              label: "Class History"
          },
          {
              value: false,
              type: CLIENT_HISTORIES_FIELDS.ClaimReviews,
              label: "Claim Reviews"
          }
      ];
        

    getAllowedFilterFields(): Array<FilterItem> {
        return [
            {
                fieldName: CLIENT_HISTORIES_FIELDS.Date,
                displayName: 'Between',
                config: {
                    label: "Date",
                    isDateRange: true,
                },
            },
            {
                fieldName: CLIENT_HISTORIES_FIELDS.HistoryType,
                displayName: 'Show History of Type(s)',                
                config: {
                    label: "Show History Of Type",
                    placeholder: "History types",
                    isCheckBoxGroup: true,
                    value : true,
                    textField: "label",
	                valueField: "type",
                },
            }            
        ];
    }

    getSelectDataForFilterItem(item: FilterItem): Observable<Array<any>> {
        let results: Observable<any> = of([]);
        switch (item.fieldName) {
        case CLIENT_HISTORIES_FIELDS.HistoryType:
            results = this.getObservableDataForHistoryTypes();
            break;
        }

        return results;
    }

  getObservableDataForHistoryTypes(): Observable<Array<any>> {
    return of(this.historyTypes);
    }
    

    getDataButtonText(): string {
        return "Get Client History";
    }

    getPageTitle(): string {
        return "Client History";
      };

    getFilterServiceUniqueName(): string {
        return "Client_Full_History_Filter";
    }
}
