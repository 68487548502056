import { Route } from "@angular/router";
import { AuthGuard } from "app/core/auth/guards/auth.guard";
import { NoAuthGuard } from "app/core/auth/guards/noAuth.guard";
import { LayoutComponent } from "app/layout/layout.component";
import { InitialDataResolver } from "app/app.resolvers";

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [
  // Redirect empty path to '/dashboard'
  { path: "", pathMatch: "full", redirectTo: "calendar" },

  // Redirect signed in user to the '/calendar'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: "signed-in-redirect", pathMatch: "full", redirectTo: "calendar" },

  // Auth routes for guests
  {
    path: "",
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "sign-in",
        loadChildren: () => import("app/modules/auth/sign-in/sign-in.module").then((m) => m.AuthSignInModule),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: "",
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "sign-out",
        loadChildren: () => import("app/modules/auth/sign-out/sign-out.module").then((m) => m.AuthSignOutModule),
      },
    ],
  },

  // Admin routes
  {
    path: "",
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: "calendar",
        loadChildren: () => import("app/modules/calendar/calendar.module").then((m) => m.CalendarModule),
      },
      {
        path: "appointment",
        loadChildren: () => import("app/modules/appointment/appointment.module").then((m) => m.AppointmentModule),
      },
      {
        path: "client-details",
        loadChildren: () => import("app/modules/client/client.module").then((m) => m.ClientModule),
      },
      {
        path: "client-details-dummy",
        loadChildren: () => import("app/modules/client/client.module").then((m) => m.ClientModule),
      },
      {
        path: "healthlink",
        loadChildren: () => import("app/modules/healthlink/healthlink.module").then((m) => m.HealthlinkModule),
      },
      {
        path: "condition-details",
        loadChildren: () => import("app/modules/condition/condition.module").then((m) => m.ConditionModule),
      },
      {
        path: "clients",
        loadChildren: () => import("app/modules/client-list/client-list.module").then((m) => m.ClientListModule),
      },
      {
        path: "admin-types",
        loadChildren: () => import("app/modules/admin-types/admin-types.module").then((m) => m.AdminTypesModule),
      },
      {
        path: "contracts",
        loadChildren: () => import("app/modules/contracts/contracts-menu.module").then((m) => m.ContractsMenuModule),
      },
    ],
  },
];
