import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatRadioModule } from "@angular/material/radio";
import { AutocompleteFieldModule } from "@components/autocomplete-field/autocomplete-field.module";
import { SharedModule } from "@shared/shared.module";

import { ReferredByDialogComponent } from "./referred-by-dialog.component";

@NgModule({
  imports: [SharedModule, MatRadioModule, MatFormFieldModule, AutocompleteFieldModule, MatButtonModule, MatDialogModule],
  exports: [ReferredByDialogComponent],
  declarations: [ReferredByDialogComponent],
  providers: [],
})
export class ReferredByDialogModule {}
