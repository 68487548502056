<!-- Shortcuts -->
<input class="hidden" (keydown.control.b)="goToNav1()" />
<input class="hidden" (keydown.control.b)="goToNav2()" />

<mat-drawer-container class="app-container">
    
    <!-- Sidebar -->
    <mat-drawer #drawer class="left-sidebar" [mode]="isScreenSmall ? 'over' : 'side'" [opened]="isScreenSmall ? 'false' : 'true'">
        <div class="left-sidebar-wrapper">
            <div class="left-sidebar-content">
                <!-- Gensolve Header Logo -->
                <div class="left-sidebar-header">
                    <img class="left-sidebar-header__logo" src="assets/images/logo/gen-logo.png" alt="Gensolve GPM" width="56" height="56" />
                </div>
                <!-- Main Navigation -->
                <nav class="left-sidebar-nav">
                    <ul>
                        <li class="left-sidebar-nav__item">
                            <a class="left-sidebar-nav__link" href="/calendar" matTooltip="Calendar (Ctrl + 1)">
                                <mat-icon class="left-sidebar-nav__link-icon" [svgIcon]="'heroicons_outline:calendar'"></mat-icon>
                                <span class="sr-only">Calendar</span>
                            </a>
                        </li>
                        <li class="left-sidebar-nav__item">
                            <a class="left-sidebar-nav__link" href="/clients/search" matTooltip="Clients">
                                <mat-icon class="left-sidebar-nav__link-icon" [svgIcon]="'gpm_clients'"></mat-icon>
                                <span class="sr-only">Clients</span>
                            </a>
                        </li>
                        <li class="left-sidebar-nav__item">
                            <a class="left-sidebar-nav__link" href="/healthlink" matTooltip="Healthlink (Ctrl + 2)">
                                <mat-icon class="left-sidebar-nav__link-icon" [svgIcon]="'gpm_healthlink'"></mat-icon>
                                <span class="sr-only">Healthlink</span>
                                <span class="left-sidebar-nav__link-badge">
                                    <span>h</span>
                                </span>
                            </a>
                        </li>
                        <!-- Has --active class -->
                        <li class="left-sidebar-nav__item">
                            <a class="left-sidebar-nav__link left-sidebar-nav__link--active" href="/admin-types" matTooltip="Admin Types (Ctrl + 3)">
                                <mat-icon class="left-sidebar-nav__link-icon" [svgIcon]="'heroicons_outline:identification'"></mat-icon>
                                <span class="sr-only">Administration</span>
                            </a>
                        </li>
                        <li class="left-sidebar-nav__item">
                            <a class="left-sidebar-nav__link" href="/contracts" matTooltip="Contracts (Ctrl + 4)">
                                <mat-icon class="left-sidebar-nav__link-icon" [svgIcon]="'mat_outline:source'"></mat-icon>
                                <span class="sr-only">Contracts</span>
                            </a>
                        </li>
                        <li class="left-sidebar-nav__item">
                            <a class="left-sidebar-nav__link" href="/sign-out" matTooltip="Logout">
                                <mat-icon class="left-sidebar-nav__link-icon" [svgIcon]="'gpm_logout'"></mat-icon>
                                <span class="sr-only">Logout</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="left-sidebar-footer">
                <!-- Gensolve Footer Logo -->
                <img src="\assets\images\logo\gen-logo-with-letters.png" class="left-sidebar-footer__logo" alt="Gensolve" width="64" height="17">
                <!-- GPM Version -->
                <span class="left-sidebar-footer__version">v.{{ gpmVersion }}</span>
            </div>
        </div>
    </mat-drawer>

    <!-- Main Content -->
    <mat-drawer-content class="flex flex-col flex-auto w-full min-w-0 h-full">

        <!-- Header -->
        <div
            class="relative flex flex-0 items-center w-full h-12 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden header-container z-20 justify-between">
            <!-- Navigation / Search / Client -->
            <div class="flex items-center h-full">
                <!-- Toggle Navigation -->
                <button class="mx-2" mat-icon-button (click)="drawer.toggle()"
                    matTooltip="Toogle left menu" [matTooltipDisabled]="isScreenSmall" data-cy='ToogleLeftMenu'>
                    <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
                </button>

                <!-- Search Bar -->
                <search [appearance]="'bar'" (onClientSelect)="onClientSelect($event)"></search>
            </div>

            <!-- Client -->

            <!-- Enter hex value in the style attribute -->
            <div class="user-info-container flex justify-between items-center grow mx-2.5 px-2.5 w-full h-full border-x-[1px] border-slate-200" *ngIf="selectedClient" [ngStyle]="{'box-shadow': 'inset 0 0 0 3px ' + ClientNameColor}">
                <div class="flex items-center">
                    <!-- Name & DOB -->
                    <div class="flex md:flex-row flex-col items-center info">
                        <!-- Name -->
                        <span class="name" data-cy='client-name'>{{
                            selectedClient.FIRST_NAME +
                            " " +
                            selectedClient.LAST_NAME
                            }}</span>
                        <ng-container *ngIf="!isScreenSmall">
                            <!-- DOB -->
                            <span class="text-md md:text-lg text-left md:text-center w-full md:w-auto md:ml-2">{{
                                selectedClient?.DOB?.toString() | formatDate }}</span>
                        </ng-container>

                    </div>
                    <!-- Edit Client -->
                    <button mat-icon-button class="mx-1" matTooltip="Edit" [matTooltipDisabled]="isScreenSmall"
                        (click)="viewClientDetails()" data-cy='client-edit' [disabled]="isLoading">
                        <mat-icon svgIcon="gpm_header_edit_underline"></mat-icon>
                    </button>

                    <!-- Hide until functionality is implemented -->    
                    <!-- Events -->
                    <button mat-icon-button class="max-sm:hidden mx-1" *ngIf="false" matTooltip="Events"
                        [matTooltipDisabled]="isScreenSmall" [disabled]="isLoading">
                        <mat-icon svgIcon="gpm_header_info"></mat-icon>
                    </button>
                </div>
                
                <!-- Enter the alert description in the matTooltip attribute -->
                <button type="button" matTooltip="Description of what the alert is for" class="ml-1" *ngIf="ClientNameColor != ''">
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="20" fill="none" aria-hidden="true" focusable="false" role="img">
                        <path [ngStyle]="{'fill': ClientNameColor}" fill-rule="evenodd" d="m14.53 2.033 8.05 12.834a2.548 2.548 0 0 1 .02 2.775 2.89 2.89 0 0 1-1.112 1.015 3.214 3.214 0 0 1-1.517.362H3.85a3.211 3.211 0 0 1-1.5-.38 2.881 2.881 0 0 1-1.092-1.017 2.619 2.619 0 0 1-.306-.714 2.536 2.536 0 0 1 .338-2.041l8-12.834a2.88 2.88 0 0 1 1.1-1.031c.461-.248.986-.379 1.52-.379s1.058.13 1.519.379c.46.248.84.604 1.1 1.031Zm-2.62 4.153c-.264 0-.519.096-.706.268a.88.88 0 0 0-.293.649v3.666a.88.88 0 0 0 .293.648c.187.172.442.269.707.269.265 0 .52-.097.707-.269a.88.88 0 0 0 .293-.648V7.103a.88.88 0 0 0-.293-.649.995.995 0 0 0-.433-.233 1.08 1.08 0 0 0-.274-.035Zm0 7.333c-.197 0-.39.054-.555.155a.938.938 0 0 0-.368.411.846.846 0 0 0-.057.53.897.897 0 0 0 .274.47c.14.127.318.215.511.25.195.035.396.017.579-.052a.987.987 0 0 0 .448-.338.863.863 0 0 0 .169-.51.88.88 0 0 0-.293-.647 1.048 1.048 0 0 0-.707-.269Z" clip-rule="evenodd"/>
                    </svg>
                </button>

            </div>

            <!-- Actions -->
            <div class="flex items-center h-full" *ngIf="selectedClient">
                <!-- Mobile Menu -->
                <button mat-icon-button class="md:hidden" [matMenuTriggerFor]="actionMobile" [disabled]="isLoading"
                    data-cy='mobile-client-menu'>
                    <mat-icon svgIcon="gpm_threedot"></mat-icon>
                </button>

                <!-- Aditional Info -->
                <ng-container *ngIf="!isScreenSmall">
                    <div class="flex items-center">
                        <!-- Phone Data -->
                        <button mat-icon-button [matMenuTriggerFor]="phoneMenu" class="mx-1"
                            matTooltip="Client phone numbers" [matTooltipDisabled]="isScreenSmall" [disabled]="isLoading"
                            data-cy='client-phone'>
                            <mat-icon svgIcon="gpm_header_phone"></mat-icon>
                        </button>

                        <!-- Email Data -->
                        <button mat-icon-button [matMenuTriggerFor]="emailMenu" class="mx-1" matTooltip="Client e-mails"
                            [matTooltipDisabled]="isScreenSmall" [disabled]="isLoading" data-cy='client-email'>
                            <mat-icon svgIcon="gpm_header_email"></mat-icon>
                        </button>

                        <!-- Payments Data -->
                        <button mat-icon-button [matMenuTriggerFor]="paymentData" class="mx-1" matTooltip="Client payments"
                            [matTooltipDisabled]="isScreenSmall" [disabled]="isLoading" data-cy='client-balance'>
                            <mat-icon svgIcon="gpm_header_currency"></mat-icon>
                        </button>

                        <!-- Conditions Data -->
                        <button mat-icon-button [matMenuTriggerFor]="conditionMenu" class="mx-1"
                            matTooltip="Client conditions" [matTooltipDisabled]="isScreenSmall" [disabled]="isLoading"
                            data-cy='client-conditions'>
                            <mat-icon svgIcon="gpm_header_condition"></mat-icon>
                        </button>

                        <!-- Appointments Data -->
                        <button mat-icon-button [matMenuTriggerFor]="apptsMenu" class="mx-1"
                            matTooltip="Client appointments" [matTooltipDisabled]="isScreenSmall" [disabled]="isLoading"
                            data-cy='client-appts'>
                            <mat-icon svgIcon="gpm_header_appointment"></mat-icon>
                        </button>

                        <!-- No Shows -->
                        <button *ngIf="noShows && noShows > 0" mat-icon-button [matMenuTriggerFor]="noShowsMenu"
                            class="max-sm:hidden mx-1" matTooltip="No Shows" [matTooltipDisabled]="isScreenSmall"
                            [disabled]="isLoading" data-cy='no-shows'>
                            <mat-icon [matBadge]="noShows" [matBadgeHidden]="isLoading" [matBadgeSize]="'small'"
                                svgIcon="mat_outline:event_busy"></mat-icon>
                        </button>
                    </div>
                </ng-container>
            </div>

            <!-- System User Info Button -->
            <ng-container *ngIf="sysUser">
                <div class="flex bg-slate-800/[.05] h-full">
                    <button (click)="isSystemUserInfoOpen = !isSystemUserInfoOpen" type="button" cdkOverlayOrigin
                        #systemUserInfoTriggker="cdkOverlayOrigin">
                        <div class="user-initials user-initials--small">
                            <span>{{ sysUser.initials }}</span>
                        </div>
                    </button>
                </div>
                <!-- System User Info -->
                <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="systemUserInfoTriggker"
                    [cdkConnectedOverlayOpen]="isSystemUserInfoOpen">
                    <div class="flex flex-col shadow rounded-md bg-white m-1">
                        <!-- Header with close button -->
                        <div class="bg-slate-700 rounded-t-md">
                            <div class="flex items-center justify-between p-2">
                                <span class="text-white">Logged in as</span>
                                <button class="rounded-full bg-white flex"
                                    (click)="isSystemUserInfoOpen = !isSystemUserInfoOpen">
                                    <mat-icon class="text-slate-700" svgIcon="mat_outline:close"></mat-icon>
                                </button>
                            </div>
                        </div>

                        <div class="flex flex-col items-center my-8 mx-6">
                            <div class="user-initials user-initials--large">
                                <span>{{ sysUser.initials }}</span>
                            </div>
                            <span class="mt-2 font-semibold">{{ sysUser.organisationName }}</span>
                            <span>{{ sysUser.name }}</span>
                            <ng-container *ngIf="sysUser.email">
                                <span>{{ sysUser.email }}</span>
                            </ng-container>
                        </div>

                        <div class="flex my-4 gap-1 items-center justify-center">
                            <button mat-button [color]="'primary'" *ngIf="false">
                                <div class="flex items-center">
                                    <mat-icon svgIcon="mat_outline:settings"></mat-icon>
                                    <span>Settings</span>
                                </div>
                            </button>
                            <button mat-stroked-button [color]="'primary'" (click)="signOut()">
                                <div class="flex items-center gap-2">
                                    <mat-icon svgIcon="mat_outline:logout"></mat-icon>
                                    <span>Log Out</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </div>

        <!-- Content -->
        <div class="flex flex-col flex-auto -mt-16 pt-16 overflow-y-scroll z-10 content-container">
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
    Otherwise, layout changes won't be registered and the view won't be updated! -->
            <router-outlet *ngIf="true"></router-outlet>
        </div>
    </mat-drawer-content>

    <!-- Conditions -->
    <mat-menu #conditionMenu="matMenu" overlapTrigger="false" class="condition-menu">
        <div>
            <ng-container *ngIf="
                    conditionsData && conditionsData.length > 0;
                    else noConditions
                ">
                <!-- Hide until functionality is implemented -->
                <div class="mat-menu-label" *ngIf="false">
                    <span>Add New Condition</span>
                </div>
                <button *ngFor="let condition of conditionsData" mat-menu-item class="flex justify-between"
                    (click)="selectCondition(condition)">
                    <div class="flex flex-col flex-auto mr-5">
                        <!-- Provider -->
                        <ng-container *ngIf="condition.Provider">
                            <div class="flex items-center line-clamp-1">
                                <span class="font-semibold">Provider: </span>
                                <span [innerHTML]="
                                        condition.Provider.firstname +
                                        ' ' +
                                        condition.Provider.lastname
                                    "></span>
                            </div>
                        </ng-container>
                        <!-- Description -->
                        <ng-container *ngIf="condition.Condition.INJURY_DESC">
                            <div class="line-clamp-1">
                                <span class="font-semibold">Description: </span>
                                <span [innerHTML]="condition.Condition.INJURY_DESC"></span>
                            </div>
                        </ng-container>
                        <!-- Date of Injury -->
                        <div class="mt-2 text-sm leading-none text-secondary">
                            DOI:
                            {{
                            condition.LocalInjuryDateTime.toString()
                            | formatDate
                            }}
                        </div>
                    </div>

                    <mat-icon svgIcon="gpm_header_edit_underline" class="text-gensolve-400"></mat-icon>
                </button>

                <button class="text-gensolve-500 text-center text-xl ml-2 my-2" (click)="goToClientConditions()">See
                    more...</button>
            </ng-container>

            <ng-template #noConditions>
                <div class="p-4">
                    <span class="text-xl"> This client has no conditions yet </span>
                </div>
            </ng-template>
        </div>
    </mat-menu>

    <!-- Appointments -->
    <mat-menu #apptsMenu="matMenu" overlapTrigger="false" class="condition-menu">
        <div>
            <ng-container *ngIf="apptsData && apptsData.length > 0; else noAppts">
                <div class="pt-4 px-4">
                    <span class="font-medium">Appointments</span>
                </div>
                <div *ngFor="let appt of apptsData" mat-menu-item class="border-t-0">
                    <mat-divider></mat-divider>
                    <div class="flex">
                        <!-- Labels -->
                        <div class="flex flex-col mr-5">
                            <ng-container *ngIf="appt.Provider">
                                <span class="font-medium">Provider: </span>
                            </ng-container>
                            <ng-container *ngIf="appt.Site">
                                <span class="font-medium">Site: </span>
                            </ng-container>
                            <span class="font-medium">Date: </span>
                        </div>

                        <!-- Data -->
                        <div class="flex flex-col">
                            <!-- Provider -->
                            <ng-container *ngIf="appt.Provider">
                                <span [innerHTML]="appt.Provider.firstname + ' ' + appt.Provider.lastname">
                                </span>
                            </ng-container>
                            <!-- Site -->
                            <ng-container *ngIf="appt.Site">
                                <span [innerHTML]="appt.Site.name"></span>
                            </ng-container>
                            <!-- Appt Start Time -->
                            <span>{{ appt.LocaleStartDateTime.toString() | formatDateTimeWithDay }}</span>
                        </div>
                    </div>
                </div>

                <button mat-raised-button color="primary" class="text-center ml-2 my-2" (click)="goToClientAppointments()">
                    View All Appointments
                </button>

            </ng-container>

            <ng-template #noAppts>
                <div class="p-4">
                    <span class="text-xl">
                        This client has no appointments yet
                    </span>
                </div>
            </ng-template>
        </div>
    </mat-menu>

    <!-- Phone Menu -->
    <mat-menu #phoneMenu="matMenu" overlapTrigger="false">
        <div>
            <ng-container *ngIf="phoneData && phoneData.length > 0; else noPhones">
                <button *ngFor="let phoneItem of phoneData" mat-menu-item class="flex justify-between">
                    <a [href]="'tel:' + phoneItem.value">
                        <div class="flex items-center line-clamp-1">
                            <span class="font-semibold">{{ phoneItem.name }}:
                            </span>
                            <span>{{ phoneItem.value }}</span>
                        </div>
                    </a>
                </button>
            </ng-container>

            <ng-template #noPhones>
                <div class="p-4">
                    <span class="text-xl"> This client has no phone numbers </span>
                </div>
            </ng-template>
        </div>
    </mat-menu>

    <!-- Email Menu -->
    <mat-menu #emailMenu="matMenu" class="email-menu" overlapTrigger="false">
        <div>
            <ng-container *ngIf="emailData && emailData.length > 0; else noEmails">
                <button *ngFor="let emailItem of emailData" mat-menu-item class="flex justify-between">
                    <div class="flex items-center line-clamp-1" (click)="composeEmail(emailItem.value)">
                        <span class="font-semibold">{{ emailItem.name }}:
                        </span>
                        <span>{{ emailItem.value }}</span>
                    </div>
                </button>
            </ng-container>

            <ng-template #noEmails>
                <div class="p-4">
                    <span class="text-xl"> This client has no e-mails </span>
                </div>
            </ng-template>
        </div>
    </mat-menu>

    <!-- Payment Data -->
    <mat-menu #paymentData="matMenu" overlapTrigger="false">
        <ng-container *ngIf="debtorBalance > 0; else noDebtorBalance">
            <div class="p-4">
                <span class="text-xl">
                    {{ debtorBalance | currency: "$" }}
                </span>
            </div>
        </ng-container>

        <ng-template #noDebtorBalance>
            <div class="p-4">
                <span class="text-xl"> No outstanding balance </span>
            </div>
        </ng-template>
    </mat-menu>

    <!-- Appointments -->
    <mat-menu #noShowsMenu="matMenu" overlapTrigger="false" class="condition-menu">
        <div>
            <ng-container *ngIf="noShowAppts && noShowAppts.length > 0">
                <button *ngFor="let appt of noShowAppts" mat-menu-item class="flex justify-between">
                    <div class="flex flex-col flex-auto mr-5">
                        <!-- Provider -->
                        <ng-container *ngIf="appt.Provider">
                            <div class="flex items-center line-clamp-1">
                                <span class="font-semibold">Provider: </span>
                                <span [innerHTML]="
                                        appt.Provider.firstname +
                                        ' ' +
                                        appt.Provider.lastname
                                    ">
                                </span>
                            </div>
                        </ng-container>
                        <!-- Site -->
                        <ng-container *ngIf="appt.Site">
                            <div class="flex items-center line-clamp-2">
                                <span class="font-semibold">Site: </span>
                                <span [innerHTML]="appt.Site.name"></span>
                            </div>
                        </ng-container>
                        <!-- Appt Start Time -->
                        <div class="flex items-center line-clamp-2">
                            <span class="font-semibold">Date: </span>
                            {{ appt.LocaleStartDateTime.toString() | formatDateTimeWithDay }}
                        </div>
                    </div>
                    <!-- Hide until functionality is implemented -->
                    <mat-icon svgIcon="gpm_header_edit_underline" *ngIf="false"></mat-icon>
                </button>
            </ng-container>
        </div>
    </mat-menu>

    <!-- Mobile Menu -->
    <mat-menu #actionMobile="matMenu" overlapTrigger="false" class="mobile-menu-action">
        <div>
            <button mat-menu-item class="flex justify-between" [matMenuTriggerFor]="phoneMenu"
                data-cy='client-phone-mobile'>
                Phone
            </button>
            <button mat-menu-item class="flex justify-between" [matMenuTriggerFor]="emailMenu"
                data-cy='client-email-mobile'>
                Email
            </button>
            <button mat-menu-item class="flex justify-between" [matMenuTriggerFor]="paymentData"
                data-cy='client-payment-mobile'>
                Client payments
            </button>
            <button mat-menu-item class="flex justify-between" [matMenuTriggerFor]="conditionMenu"
                data-cy='client-condition-mobile'>
                Conditions
            </button>
            <button mat-menu-item class="flex justify-between" [matMenuTriggerFor]="apptsMenu"
                data-cy='client-appts-mobile'>
                Appointments
            </button>
            <button *ngIf="noShows && noShows > 0" mat-menu-item class="flex justify-between"
                [matMenuTriggerFor]="noShowsMenu">
                <div class="flex items-center" data-cy='no-shows-mobile'>
                    <span>No Shows</span>
                    <div class="ml-1">
                        (<span class="text-gensolve-500 font-semibold">
                            {{ noShows }}
                        </span>)
                    </div>
                </div>
            </button>
        </div>
    </mat-menu>

</mat-drawer-container>