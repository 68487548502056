/* eslint-disable arrow-body-style */
/* eslint-disable arrow-parens */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/quotes */
import { ClientService } from "app/core/client/client.service";

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { EditCardStatus } from "@components/card/card.types";

import { ClientDetailInitialData } from "../../../client.types";
import { debounceTime } from "rxjs/operators";
import { ClientDetailsPatchModel } from "app/core/client/models/client-details-patch.model";
import { compare, Operation } from "fast-json-patch";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { DialogType } from "@components/dialog/dialog.types";
import { DialogComponent } from "@components/dialog/dialog.component";
import { DialogOutput } from "@components/table/table-column-filter/table-serverside-column-filter.service";
import { LoadingService } from "app/core/services/loading.service";
import { ClientGroupingClientPatchModel } from "app/core/client/models/client-grouping-client-patch.model";
import { ClientGroupingClientModel } from "app/core/client/models/client-grouping-client.model";
import { forkJoin, Observable } from "rxjs";
import { ClientGroupingModel } from "app/core/client/models/client-grouping.model";

interface ClientGroupRow {
  id: number;
  form: FormGroup;
}

interface SimpleClientGroupingClient {
  clientGroupingId: number;
  details: string;
}

@Component({
  selector: "app-client-groups",
  templateUrl: "client-groups.component.html",
  styleUrls: ["./client-groups.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientGroupsComponent implements OnInit {
  @HostBinding("class") classes = "app-client-groups flex flex-col";
  @Output() cardBeingEdited = new EventEmitter<boolean>();
  @Input() canEdit: boolean;

  clientGroupings: ClientGroupingModel[];

  status: EditCardStatus;
  disableSave: boolean = true;

  clientGroupsFormRows: ClientGroupRow[] = [];

  patchClientGroupingsClient: ClientGroupingClientPatchModel[];

  isLoading: boolean;

  clientGroupingClients: ClientGroupingClientModel[];
  clientDetailInitialData: ClientDetailInitialData;

  untouchedClientGroupingClientFormData: any;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _loadingService: LoadingService,
    private _activatedRoute: ActivatedRoute,
    private _clientService: ClientService,
    private _formBuilder: FormBuilder,
    private _dialog: MatDialog
  ) {
    this.status = EditCardStatus.NORMAL;
  }

  ngOnInit(): void {
    this._loadingService.visible$.subscribe((response) => {
      this.isLoading = response;
    });

    this._activatedRoute.data.subscribe((data: any) => {
      this.clientDetailInitialData = data.initialData as ClientDetailInitialData;
      this.clientGroupingClients = [...this.clientDetailInitialData.ClientDetails.ClientGroups];
      this.clientGroupings = this.clientDetailInitialData.ClientGroupings.sort((x, y) => x.NAME.localeCompare(y.NAME));

      const defaultClientGrouping: ClientGroupingModel = {
        ID: null,
        NAME: null,
        DESCRIPTION: null,
        DELETED_IND: null,
        ORGANISATION_ID: null,
        UPDATED_DATE: null,
      };
      this.clientGroupings.splice(0, 0, defaultClientGrouping);

      this.innitRowsForms();
    });
  }

  getClientGroupingName(clientGroupingId: number): string {
    return this.clientGroupings.find((cg) => cg.ID === clientGroupingId).NAME;
  }

  checkForChanges(): void {
    const initialData: SimpleClientGroupingClient[] = this.clientGroupingClients.map((cgc) => {
      return { clientGroupingId: cgc.CLIENT_GROUPING_ID, details: cgc.DETAILS };
    });

    const currentData: SimpleClientGroupingClient[] = this.clientGroupsFormRows
      .map((cgfr) => cgfr.form)
      .map((f) => {
        return { clientGroupingId: +f.get("clientGrouping")?.value?.ID, details: f.get("clientGroupingClientDetails")?.value };
      })
      .filter((r) => !isNaN(r.clientGroupingId));

    if (JSON.stringify(initialData) === JSON.stringify(currentData)) {
      this.disableSave = true;
    } else {
      this.disableSave = false;
    }
  }

  innitRowsForms(): void {
    if (this.clientGroupingClients && this.clientGroupingClients.length > 0) {
      this.clientGroupingClients.forEach((cgc) => {
        const rowForm: ClientGroupRow = {
          id: cgc.ID,
          form: this._formBuilder.group({
            clientGrouping: new FormControl(this.clientGroupings.find((cg) => cg.ID === cgc.CLIENT_GROUPING_ID)),
            clientGroupingClientDetails: new FormControl(cgc.DETAILS),
          }),
        };

        this.clientGroupsFormRows.push(rowForm);
      });
    }

    this.insertDefaultRow();
  }

  insertDefaultRow(): void {
    const defaultRow: ClientGroupRow = {
      id:
        this.clientGroupsFormRows && this.clientGroupsFormRows.length > 0
          ? this.clientGroupsFormRows[this.clientGroupsFormRows.length - 1].id + 1
          : 0,
      form: this._formBuilder.group({
        clientGrouping: new FormControl(),
        clientGroupingClientDetails: new FormControl(),
      }),
    };

    this.clientGroupsFormRows.push(defaultRow);
  }

  onClientGroupingSelect(rowId: number): void {
    if (this.clientGroupsFormRows[this.clientGroupsFormRows.length - 1].id === rowId) {
      this.insertDefaultRow();
    }

    this.checkForChanges();
  }

  removeFormRow(rowId: number): void {
    if (this.clientGroupsFormRows.length === 1) {
      this.clientGroupsFormRows[0].form.reset();
      this.checkForChanges();
      return;
    }

    if (this.clientGroupsFormRows[this.clientGroupsFormRows.length - 1].id !== rowId) {
      this.clientGroupsFormRows.splice(
        this.clientGroupsFormRows.findIndex((cgfr) => cgfr.id === rowId),
        1
      );

      this._changeDetectorRef.markForCheck();
      this.checkForChanges();
    }

    this.checkForChanges();
  }

  onSave(): void {
    this.clientGroupsFormRows.forEach((cgfr) => cgfr.form.disable());

    const obs: Observable<ClientGroupingClientModel>[] = [];

    const currentData: Array<ClientGroupingClientModel> = this.clientGroupsFormRows
      .map((cgfr) => {
        const row = this.clientGroupingClients.find((cgc) => cgc.ID === cgfr.id);

        const clientGroupingId = cgfr.form.get("clientGrouping")?.value?.ID;
        const details = cgfr.form.get("clientGroupingClientDetails").value;
        if (row) {
          const dataItem: ClientGroupingClientModel = {
            ID: row.ID,
            CLIENT_ID: row.CLIENT_ID,
            CLIENT_GROUPING_ID: clientGroupingId,
            DELETED_IND: row.DELETED_IND,
            VENDOR_ID: row.VENDOR_ID,
            DETAILS: details,
            CREATED_DATE: row.CREATED_DATE,
            UPDATED_DATE: row.UPDATED_DATE,
          };
          return dataItem;
        } else if (clientGroupingId) {
          const newDataItem: ClientGroupingClientModel = {
            ID: null,
            CLIENT_ID: this.clientDetailInitialData.ClientDetails.Client.ID,
            CLIENT_GROUPING_ID: clientGroupingId,
            DELETED_IND: 0,
            VENDOR_ID: null,
            DETAILS: details,
            CREATED_DATE: null,
            UPDATED_DATE: null,
          };
          return newDataItem;
        } else {
          return null;
        }
      })
      .filter((cd) => cd !== null);

    //Update ops
    this.clientGroupingClients.forEach((clientGroupingClient) => {
      let comparingRow = currentData.find((cd) => cd.ID === clientGroupingClient.ID);

      if (!comparingRow) {
        comparingRow = { ...clientGroupingClient };
        comparingRow.DELETED_IND = 1;
      }
      const operation = compare(clientGroupingClient, comparingRow, true);

      if (operation && operation.length > 0) {
        obs.push(this._clientService.patchClientGroupingClient(clientGroupingClient.ID, operation));
      }
    });

    //Create ops
    currentData
      .filter((cd) => cd.ID === null)
      .forEach((fcd) => {
        obs.push(
          this._clientService.createClientGroupingClient({
            ClientGroupingId: fcd.CLIENT_GROUPING_ID,
            ClientId: fcd.CLIENT_ID,
            Details: fcd.DETAILS,
          })
        );
      });

    if (obs.length > 0) {
      forkJoin(obs).subscribe({
        next: (response: ClientGroupingClientModel[]) => {
          if (response && response.length > 0) {
            response.forEach((obj) => {
              const objIndex = this.clientGroupingClients.findIndex((cgc) => cgc.ID === obj.ID);
              //Deleted
              if (obj.DELETED_IND === 1) {
                this.clientGroupingClients.splice(objIndex, 1);
              }

              //Updated
              if (objIndex >= 0 && obj.DELETED_IND === 0) {
                this.clientGroupingClients[objIndex] = obj;
              }

              //Created
              if (objIndex === -1 && obj.ID) {
                this.clientGroupingClients.push(obj);
              }
            });

            this.clientGroupsFormRows = [];
            this.innitRowsForms();

            this.status = EditCardStatus.NORMAL;
            this.cardBeingEdited.emit(false);
            this.disableSave = true;
            this.clientGroupsFormRows.forEach((cgfr) => cgfr.form.enable());
          }
        },
        error: (err) => {
          console.error(err);
        },
      });
    }
  }

  onCancel(): void {
    if (!this.disableSave) {
      const config = new MatDialogConfig();
      config.data = {
        title: "Changes unsaved",
        content: "You have changes unsaved. Are you sure you want to leave?",
        type: DialogType.YES_NO,
      };
      const dialogRef = this._dialog.open(DialogComponent, config);
      dialogRef.afterClosed().subscribe((result: DialogOutput) => {
        if (!result) {
          return;
        }
        this.close();
      });
    } else {
      this.close();
    }
  }

  onEdit(): void {
    if (!this.canEdit) {
      return;
    }

    this.status = EditCardStatus.EDIT;
    this.cardBeingEdited.emit(true);
  }

  close(): void {
    this.clientGroupsFormRows = [];
    this.innitRowsForms();

    this.disableSave = true;

    this.status = EditCardStatus.NORMAL;
    this.cardBeingEdited.emit(false);
  }
}
