import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
    selector: 'app-letters-uploads-component',
    templateUrl: './letters-and-uploads.component.html',
    styleUrls: [],
    encapsulation: ViewEncapsulation.None,    
  })

  export class LettersUploadsComponent implements OnInit {

    @Input() ownerId: number;

    constructor()
        {

        }
      
    ngOnInit(): void {
    }

    onActionHandler($event){

    }
  }
