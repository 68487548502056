import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FormItemData } from "@components/table/table-column-filter/dialogs/general-filter-dialog.service";
import { forkJoin, merge, Subscription } from "rxjs";
import { ClientInvoiceType } from "./client-invoice.types";
import { AmountRangeData, AmountRangeFilterConfig } from "./invoice-amount-range.service";

@Component({
  selector: "app-client-invoices-amount-range-filter",
  templateUrl: "./client-invoice-amount-range-filter.component.html",
  styleUrls: [
    "./client-invoice-amount-range-filter.component.scss"
  ]
})
export class ClientInvoicesAmountRangeFilterComponent implements OnInit, OnDestroy {
  @Input() formDataItem: FormItemData;

  amountTypeControl: FormControl = new FormControl();
  maximumNoControl: FormControl = new FormControl();
  percentageNoControl: FormControl = new FormControl();

  config: AmountRangeFilterConfig;
  mainFormControl: FormControl;

  subscription: Subscription;


  constructor() {

  }

  ngOnInit(): void {
    this.config = this.formDataItem.config.config as AmountRangeFilterConfig;
    this.mainFormControl = this.formDataItem.control;

    const data: AmountRangeData = this.mainFormControl.value as AmountRangeData;
    const selectedInvoiceType = this.config.invoiceTypes.find(i => i.id === data?.invoiceType.id);
    
    this.amountTypeControl.setValue(selectedInvoiceType);
    this.maximumNoControl.setValue(data?.lowerNo);
    this.percentageNoControl.setValue(data?.withinPercentage);

    // Set default data
    if (!data) {
      const defaultInvoiceType = this.config.invoiceTypes.find((i) => i.id === ClientInvoiceType.INVOICE_TOTAL);
      this.amountTypeControl.setValue(defaultInvoiceType);
    }        
    
    this.subscription = merge(
      this.amountTypeControl.valueChanges,
      this.maximumNoControl.valueChanges,
      this.percentageNoControl.valueChanges
    ).subscribe(() => {
      this.updateAmountRange();
    });
  }

  updateAmountRange(): void {
    const outputData: AmountRangeData = {
      invoiceType: this.amountTypeControl.value,
      lowerNo: this.maximumNoControl.value,
      withinPercentage: this.percentageNoControl.value,
    };

    this.mainFormControl.setValue(outputData);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
