import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-card-info-comp',
  templateUrl: './card-info.component.html',
  styleUrls: ['./card-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardInfoComponent implements OnInit {
    @HostBinding('class') classes = 'app-card-summary-info';
    // configuration for appearance
    @Input() fixedHeight: number;
    @Input() navigationVisible: boolean;
    @Input() title: string;
    @Input() expandTooltip: string;
    @Output() expandEvent: EventEmitter<void> = new EventEmitter();

    constructor() {
        this.navigationVisible = true;
    }

    onExpandHandler(): void {
        this.expandEvent.emit();
    }

    ngOnInit(): void {

    }
}
