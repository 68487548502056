<form [formGroup]="tableServerSideColumnFilterFormGroup">
	<div class="column-filter-wrapper">
		<mat-toolbar class="mat-primary m-0">
			<mat-toolbar-row class="flex flex-row justify-between items-center">
				<span class="title dialog-title">{{ data.column.displayName }}</span>
				<button mat-button (click)="matDialogRef.close()" aria-label="Close dialog" class="close-button">
					<mat-icon svgIcon="gpm_close"></mat-icon>
				</button>
			</mat-toolbar-row>
		</mat-toolbar>
		<div class="content m-0">
			<div class="field-group">
				<mat-form-field appearance="outline">
					<mat-label>Enter a date range</mat-label>
					<mat-date-range-input [rangePicker]="picker">
						<input matStartDate placeholder="Start date" formControlName="filterValueFrom">
						<input matEndDate placeholder="End date" formControlName="filterValueTo">
					</mat-date-range-input>
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
			</div>
		</div>
		<div class="actions">
			<button mat-raised-button color="basic" class="apply-button" (click)="applyFilter()" aria-label="Apply filter">
				Apply
			</button>
		</div>
	</div>
</form>
