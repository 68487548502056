<app-edit-card-comp class="flex-1 info-section" [status]="status" (editEvent)="onEdit()" (saveEvent)="onSave()"
    (cancelEvent)="onCancel()" [disableSave]="disableSave" [disableActions]="isLoading">
    <div class="flex md:flex-row flex-col" (click)="onEdit()">
        <mat-card-subtitle>Client Groups</mat-card-subtitle>

        <!-- Edit View -->
        <div class="grid xl:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-4 flex-1" *ngIf="status === 'EDIT'">
            <ng-container *ngFor="let row of clientGroupsFormRows">
                <div class="flex gap-1" [formGroup]="row.form">
                    <mat-form-field class="w-full" appearance="outline">
                        <mat-select (selectionChange)="onClientGroupingSelect(row.id)" formControlName="clientGrouping">
                            <ng-container>
                                <mat-option *ngFor="let clientGrouping of clientGroupings" [value]="clientGrouping">{{
                                    clientGrouping.NAME }}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-full" appearance="outline">
                        <input (input)="checkForChanges()" matInput formControlName="clientGroupingClientDetails">
                    </mat-form-field>
                    <button (click)="removeFormRow(row.id)">
                        <mat-icon color="primary" svgIcon="heroicons_outline:x"></mat-icon>
                    </button>
                </div>
            </ng-container>
        </div>

        <!-- Display View -->
        <div class="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 flex-1" *ngIf="status !== 'EDIT'">
            <div class="w-full element">
                <ng-container *ngFor="let clientGroupingClient of clientGroupingClients" else noClientGroupingClients>
                    <div class="info">- {{ getClientGroupingName(clientGroupingClient.CLIENT_GROUPING_ID) }}</div>
                </ng-container>

                <ng-template #noClientGroupingClients>
                    <div class="info">{{ "-" }}</div>
                </ng-template>
            </div>
        </div>
    </div>
</app-edit-card-comp>