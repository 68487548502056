import { SystemUserDBModel } from "app/core/cache/cache.types";
import { ClientService } from "app/core/client/client.service";
import { EventTypeEnum, GetEventTypeDescription } from "app/core/enums/physio-enums";
import { DateTimeService } from "app/core/services/datetime.service";
import { EventTypeModel } from "app/core/models/physio/event-type.model";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { Injectable, TemplateRef } from "@angular/core";
import { FormControl } from "@angular/forms";
import { BrowserStorageCache } from "@aws-amplify/cache";
import { ColumnGroupConfig, ColumnItem, MASTER_GROUP_ID, TableItemAction } from "@components/table/mat/mat-table-wrapper.service";
import { FilterOutput } from "@components/table/table-column-filter/dialogs/general-filter-dialog.service";
import {
  CustomFilterConfig,
  FilterColumnService,
  FilterItem,
  SelectDateFilterSelectObject,
  SelectDateFilterValue,
} from "@components/table/table-column-filter/table-serverside-column-filter.service";
import { ServerSideTableService } from "@components/table/table-serverside.service";

import { ClientEventsRequestModel } from "../client.types";
import { SubjectDetailsData } from "./client-events-filters/subject-details-filter/client-events-subject-details-filter.types";
import { ActionToItem, ClientEventRestricToEnum } from "./client-events.types";
import { ClientEventModel } from "./models/client-event.model";

const CLIENT_EVENTS_FILTERS = {
  RESTRICT_TO_EVENTS: "RESTRICT_TO_EVENTS",
  OF_TYPE: "OF_TYPE",
  SUBJECT_DETAILS: "SUBJECT_DETAILS",
  ACTION_TO: "ACTION_TO",
};

const CLIENT_EVENTS_COLUMNS = {
  CREATED_DATE: "CreatedDate",
  CREATED_BY: "CreatedBy",
  COMPLETED_BY: "CompletedBy",
  EVENT_TYPE: "EventType",
  SUBJECT: "Subject",
  ACTION_TO: "ActionTo",
  DATE_DUE: "DateDue",
  COMPLETED: "Completed",
};

const RESTRICT_TO_TYPES: SelectDateFilterSelectObject[] = [
  { name: "Due As At", id: ClientEventRestricToEnum.DUE_AS_AT, isSingleDate: true },
  { name: "Completed From", id: ClientEventRestricToEnum.COMPLETED_FROM },
  { name: "Created From", id: ClientEventRestricToEnum.CREATED_FROM },
  { name: "Due From", id: ClientEventRestricToEnum.DUE_FROM },
];

const retrieveFilterValueForNameItem = (name: string, filterItemList: Array<FilterItem>): any | null => {
  const itemFilter: FilterItem = filterItemList.find((item) => item.fieldName === name);
  return itemFilter ? itemFilter.filterValue : null;
};

@Injectable({ providedIn: "root" })
export class ClientEventsServerSideTableService implements ServerSideTableService {
  public clientId: string;

  private _systemUsers: SystemUserDBModel[];
  public get systemUsers(): SystemUserDBModel[] {
    return this._systemUsers;
  }
  public set systemUsers(value: SystemUserDBModel[]) {
    this._systemUsers = value;
  }

  private _eventTypes: EventTypeModel[] = [];
  public get eventTypes(): EventTypeModel[] {
    return this._eventTypes;
  }
  public set eventTypes(value: EventTypeModel[]) {
    this._eventTypes = value;
  }

  private _actionToItems: ActionToItem[];
  public get actionToItems(): ActionToItem[] {
    return this._actionToItems;
  }
  public set actionToItems(value: ActionToItem[]) {
    this._actionToItems = value;
  }

  constructor(private _dateTimeService: DateTimeService, private _clientService: ClientService) {}

  getActionList(): TableItemAction[] {
    return [];
  }

  getColumnDefinitions(): ColumnItem[] {
    return [
      {
        fieldName: "CreatedDate",
        displayName: "Created",
        config: {
          isDate: true,
        }
      },
      { fieldName: "CreatedBy", displayName: "Created By" },
      { fieldName: "CompletedBy", displayName: "Completed By" },
      { fieldName: "EventType", displayName: "Event Type" },
      { fieldName: "Subject", displayName: "Subject" },
      { fieldName: "ActionTo", displayName: "Action To" },
      { fieldName: "DateDue", displayName: "Date Due", config: { isDate: true } },
      { fieldName: "Completed", displayName: "Completed", config: { isDate: true }, isSorting: true },
    ];
  }

  getDisplayColumnConfig(): ColumnGroupConfig {
    return {
      defaultColumns: [
        CLIENT_EVENTS_COLUMNS.CREATED_DATE,
        CLIENT_EVENTS_COLUMNS.EVENT_TYPE,
        CLIENT_EVENTS_COLUMNS.SUBJECT,
        CLIENT_EVENTS_COLUMNS.ACTION_TO,
        CLIENT_EVENTS_COLUMNS.DATE_DUE,
        CLIENT_EVENTS_COLUMNS.COMPLETED,
      ],
      groupColumnDefinition: [
        {
          groupID: MASTER_GROUP_ID,
          groupName: "",
          columnNames: [CLIENT_EVENTS_COLUMNS.CREATED_BY, CLIENT_EVENTS_COLUMNS.COMPLETED_BY],
        },
      ],
      displayGroup: [MASTER_GROUP_ID],
    };
  }

  getData(selectedFilterItem: FilterItem[]): Observable<ClientEventModel[]> {
    const selectDateFilterValue: SelectDateFilterValue = retrieveFilterValueForNameItem(
      CLIENT_EVENTS_FILTERS.RESTRICT_TO_EVENTS,
      selectedFilterItem
    );

    const dateFrom: string =
      selectDateFilterValue && selectDateFilterValue.dateFrom
        ? this._dateTimeService.FormatDate(selectDateFilterValue.dateFrom.toString(), "MM/DD/yyyy")
        : "";

    const dateTo: Date | string =
      selectDateFilterValue && selectDateFilterValue.dateTo
        ? this._dateTimeService.FormatDate(selectDateFilterValue.dateTo.toString(), "MM/DD/yyyy")
        : "";

    const eventTypeFilterValue: any = retrieveFilterValueForNameItem(CLIENT_EVENTS_FILTERS.OF_TYPE, selectedFilterItem);

    const eventTypeFilterData: EventTypeModel = eventTypeFilterValue
      ? this.eventTypes.find((et) => et.Id === eventTypeFilterValue.filterValue)
      : null;

    const subjectDetailsFilterValue: any = retrieveFilterValueForNameItem(CLIENT_EVENTS_FILTERS.SUBJECT_DETAILS, selectedFilterItem);

    const subjectDetailsFilterData: SubjectDetailsData = subjectDetailsFilterValue
      ? (subjectDetailsFilterValue.filterValue as SubjectDetailsData)
      : null;

    const actionToFilterValue: any = retrieveFilterValueForNameItem(CLIENT_EVENTS_FILTERS.ACTION_TO, selectedFilterItem);

    const actionFilterData: ActionToItem = actionToFilterValue
      ? this.actionToItems.find((ati) => ati.id === actionToFilterValue.filterValue)
      : null;

    const requestObject: ClientEventsRequestModel = {
      clientId: +this.clientId.trim(),
      subject: subjectDetailsFilterData?.subject || "",
      details: subjectDetailsFilterData?.details || "",
      restrictActionUser: actionFilterData && actionFilterData.id ? true : false,
      actionUserId: actionFilterData?.id || 0,
      isActionUserTeam: actionFilterData?.isTeam || false,
      restrictEventType: eventTypeFilterData && eventTypeFilterData.Id ? true : false,
      isCustomEventType: eventTypeFilterData?.IsCustom || false,
      eventType: eventTypeFilterData?.Id || 0,
      dateType: selectDateFilterValue?.dateType.id || 0,
      dateFrom: dateFrom,
      dateTo: dateTo,
    };

    return this._clientService.getClientEvents(requestObject).pipe(
      map((response) =>
        response.map((e) => {
          let clientEventModel: ClientEventModel = {
            CreatedDate: new Date(e.DATE_CREATED),
            EventType: this.getEventTypeInformation(e.EVENT_TYPE, e.CUSTOM_EVENT_TYPE_ID),
            Subject: e.SUBJECT,
            ActionTo: this.systemUsers.find((su) => su.id === e.ACTION_USER_ID)?.displayName || "-",
            DateDue: e.DATE_DUE ? new Date(e.DATE_DUE) : null,
            Completed: e.DATE_COMPLETED ? new Date(e.DATE_COMPLETED) : null,
            CompletedBy: e.COMPLETED_BY || "-",
            CreatedBy: e.CREATED_BY,
          };
          return clientEventModel;
        })
      )
    );
  }

  getEventTypeInformation(eventTypeId: number, customEventTypeId: number){
    if(eventTypeId != EventTypeEnum.Custom){
      return GetEventTypeDescription(eventTypeId);
    }
    else{
      let customName = this.eventTypes.find((x) => x.Id == customEventTypeId && x.IsCustom == true).Name;
      return customName;
    }
  }
}

@Injectable({ providedIn: "root" })
export class ClientEventsFilterColumnService implements FilterColumnService {
  private _eventTypes: EventTypeModel[] = [];
  public get eventTypes(): EventTypeModel[] {
    return this._eventTypes;
  }
  public set eventTypes(value: EventTypeModel[]) {
    this._eventTypes = value;
  }

  private _actionToItems: ActionToItem[];
  public get actionToItems(): ActionToItem[] {
    return this._actionToItems;
  }
  public set actionToItems(value: ActionToItem[]) {
    this._actionToItems = value;
  }

  private _restrictToTpl: TemplateRef<any>;
  public get restrictToTpl(): TemplateRef<any> {
    return this._restrictToTpl;
  }
  public set restrictToTpl(value: TemplateRef<any>) {
    this._restrictToTpl = value;
  }

  private _subjectDetailsTpl: TemplateRef<any>;
  public get subjectDetailsTpl(): TemplateRef<any> {
    return this._subjectDetailsTpl;
  }
  public set subjectDetailsTpl(value: TemplateRef<any>) {
    this._subjectDetailsTpl = value;
  }

  constructor() {}

  getAllowedFilterFields(): Array<FilterItem> {
    const sevenDaysBefore = new Date().setDate(new Date().getDate() - 7);
    const selectDateFilterInitialValue: SelectDateFilterValue = {
      dateFrom: new Date(sevenDaysBefore),
      dateTo: new Date(),
      dateType: RESTRICT_TO_TYPES.find((rt) => rt.id === ClientEventRestricToEnum.COMPLETED_FROM),
    };

    const configForSubjectDetailsFilter: CustomFilterConfig = {
      isCustom: true,
      templateRef: this.subjectDetailsTpl,
      retrieveDataFun: this.retrieveOutputDataForSubjecDetails,
    };

    const configFilters: Array<FilterItem> = [
      {
        fieldName: CLIENT_EVENTS_FILTERS.RESTRICT_TO_EVENTS,
        displayName: "Restrict To Events",
        config: {
          isSelectDate: true,
          selectData: RESTRICT_TO_TYPES,
          initialValue: selectDateFilterInitialValue,
          placeholder: "",
          label: "Restrict To Events",
          textField: "name",
          valueField: "id",
        },
      },
      {
        fieldName: CLIENT_EVENTS_FILTERS.OF_TYPE,
        displayName: "Of Type",
        config: {
          isSelect: true,
          selectData: this.eventTypes,
          placeholder: "",
          label: "Of Type",
          textField: "Name",
          valueField: "Id",
        },
      },
      {
        fieldName: CLIENT_EVENTS_FILTERS.SUBJECT_DETAILS,
        displayName: "Where",
        config: configForSubjectDetailsFilter,
      },
      {
        fieldName: CLIENT_EVENTS_FILTERS.ACTION_TO,
        displayName: "Action To",
        config: {
          isSelect: true,
          selectData: this.actionToItems,
          placeholder: "",
          label: "Action To",
          textField: "name",
          valueField: "id",
        },
      },
    ];

    // get stored custom filters from cache if any
    const storedCustomFilters = BrowserStorageCache.getItem("Custom-" + this.getFilterServiceUniqueName()) as FilterItem[];
    if (storedCustomFilters) {
      configFilters
        .filter((f) => f.config?.isCustom)
        .forEach((item) => {
          const storedFilter = storedCustomFilters.find((f) => f.fieldName === item.fieldName);
          if (storedFilter) {
            const filterText = storedFilter.filterValue.filterText;
            const data = storedFilter.filterValue.filterValue;
            item.filterValue = { filterText: filterText, filterValue: data };
          }
        });
    }
    return configFilters;
  }

  getSelectDataForFilterItem(item: FilterItem, params: any): Observable<any[]> {
    let results: Observable<any[]>;
    switch (item.fieldName) {
      case CLIENT_EVENTS_FILTERS.OF_TYPE:
        results = of(this.eventTypes);
        break;
      case CLIENT_EVENTS_FILTERS.ACTION_TO:
        results = of(this.actionToItems);
        break;
    }

    return results;
  }

  getDataButtonText(): string {
    return "";
  }
  getPageTitle(): string {
    return "Events";
  };

  getFilterServiceUniqueName(): string {
    return "Client_Events_Filter";
  }

  private retrieveOutputDataForSubjecDetails(formControl: FormControl, config: FilterItem): FilterOutput {
    const data = formControl.value as SubjectDetailsData;
    let filterValue = null;

    if (data?.subject?.trim() || data?.details?.trim()) {
      let filterText = "";
      if (data.subject) {
        filterText = `Subject is like ${data.subject}`;
      } else if (data.details) {
        filterText = `Details are like ${data.details}`;
      }

      filterValue = {
        filterText,
        filterValue: data,
      };
    }

    return {
      config,
      filterValue,
    };
  }
}
