import { Observable } from "rxjs";

import { Injectable } from "@angular/core";

import { APIService } from "../api/api.service";
import { SystemUserByNameModel } from "../models/organisation/system-user-by-name.model";
import { SystemUserModel } from "../models/organisation/system-user.model";

@Injectable({ providedIn: "root" })
export class SystemUserService {
  constructor(private _apiService: APIService) {}

  public getSystemUsersByName(searchTerm: string, isSupport: boolean): Observable<SystemUserByNameModel[]> {
    return this._apiService.get(`/system-users?command=search&SearchTerm=${searchTerm}&isSupport=${isSupport}`);
  }

  public getSystemUserById(id: number): Observable<SystemUserModel>{
    return this._apiService.get(`/systemUser?id=${id}`);
  }
}
