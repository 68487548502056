import { EditCardStatus } from "app/components/card/card.types";
import { EmploymentIntensityDBModel } from "app/core/cache/cache.types";
import { ClientService } from "app/core/client/client.service";
import { ClientDetailsPatchModel } from "app/core/client/models/client-details-patch.model";
import { ClientPersonalDetailsModel } from "app/core/client/models/client-personal-details.model";
import { EmployerByNameModel } from "app/core/models/appointment/employer-by-name.model";
import { OccupationModel } from "app/core/models/physio/occupation.model";
import { EmployerService } from "app/core/services/employer.service";
import { OccupationService } from "app/core/services/occupation.service";
import { compare } from "fast-json-patch";
import { forkJoin, Observable, of, Subject } from "rxjs";
import { debounceTime, map, takeUntil } from "rxjs/operators";

import { AfterViewInit, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { AutocompleteDataObject } from "@components/autocomplete-field/autocomplete-field.types";
import { DialogComponent } from "@components/dialog/dialog.component";
import { DialogType } from "@components/dialog/dialog.types";
import { DialogOutput } from "@components/table/table-column-filter/table-serverside-column-filter.service";
import { LoadingService } from "app/core/services/loading.service";

import { ClientDetailInitialData } from "../../../client.types";
import { UtilService } from "app/core/services/util.service";
import { EmploymentTypeModel } from "app/core/models/physio/employment-type.model";

@Component({
  selector: "app-client-employment-detail",
  templateUrl: "./client-employment-details.component.html",
  styleUrls: ["./client-employment-details.component.scss"],
})
export class ClientEmploymentDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() cardBeingEdited = new EventEmitter<boolean>();
  @Input() canEdit: boolean;

  @HostBinding("class") classes = "app-client-employment-detail flex flex-col";
  status: EditCardStatus;

  occupationFC: FormControl;
  employmentTypeFC: FormControl;
  employerFC: FormControl;
  employmentIntensityFC: FormControl;

  clientDetailsForm: FormGroup;

  selectedEmploymentType: EmploymentTypeModel;

  occupations: AutocompleteDataObject[] = [];
  employers: AutocompleteDataObject[] = [];

  disableEdit: boolean = true;
  isLoading: boolean;

  clientDetailInitialData: ClientDetailInitialData;

  fetchEmployers = this._fetchEmployersByName.bind(this);
  fetchOccupations = this._fetchOccupationsByName.bind(this);

  employmentTypes: EmploymentTypeModel[];
  employmentIntensities: EmploymentIntensityDBModel[];
  clientPersonalDetails: ClientPersonalDetailsModel;

  patchClientDetails: ClientDetailsPatchModel;
  untouchedClientDetailsFormData: any;

  isGPMAU: boolean;
  isGPMNZ: boolean;

  public destroySubscriptions: Subject<boolean> = new Subject<boolean>();

  constructor(
    private _occupationService: OccupationService,
    private _loadingService: LoadingService,
    private _employerService: EmployerService,
    private _activatedRoute: ActivatedRoute,
    private _clientService: ClientService,
    private _utilService: UtilService,
    private _formBuilder: FormBuilder,
    private _dialog: MatDialog
  ) {
    this.status = EditCardStatus.NORMAL;

    this.innitFormControls();
  }

  innitFormControls(): void {
    this.occupationFC = new FormControl();
    this.employmentTypeFC = new FormControl();
    this.employerFC = new FormControl();
    this.employmentIntensityFC = new FormControl();

    this.clientDetailsForm = this._formBuilder.group({
      occupation: this.occupationFC,
      employer: this.employerFC,
      employmentIntensity: this.employmentIntensityFC,
      employmentType: this.employmentTypeFC,
    });
  }

  ngOnInit(): void {
    this._loadingService.visible$.subscribe((response) => {
      this.isLoading = response;
    });

    this.isGPMAU = this._utilService.isGPMAU();
    this.isGPMNZ = this._utilService.isGPMNZ();

    this._activatedRoute.data.subscribe((data: any) => {
      this.clientDetailInitialData = data.initialData as ClientDetailInitialData;
      this.employmentTypes = this.clientDetailInitialData.EmploymentTypesObject.EmploymentTypes.filter((et) => et.DELETED_IND !== 1);
      const filteredEmploymentIntensities = this.clientDetailInitialData.EmploymentIntensities.filter((et) => !et.deleted);
      const defaultValue: EmploymentIntensityDBModel = {
        id: null,
        name: null,
        accCode: null,
        deleted: false,
      };
      filteredEmploymentIntensities.unshift(defaultValue);
      this.employmentIntensities = filteredEmploymentIntensities.sort((a, b) =>
        a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : b.name?.toLowerCase() > a.name?.toLowerCase() ? -1 : 0
      );
      this.clientPersonalDetails = this.clientDetailInitialData.ClientDetails.PersonalDetails;

      this.setInitialData(this.clientPersonalDetails);
      this.setPatchClientDetails(this.clientPersonalDetails);
    });
  }

  ngAfterViewInit(): void {
    this.untouchedClientDetailsFormData = this.clientDetailsForm.value;

    this.clientDetailsForm.valueChanges.pipe(debounceTime(200)).subscribe((data) => {
      if (JSON.stringify(this.untouchedClientDetailsFormData) === JSON.stringify(data)) {
        this.clientDetailsForm.markAsPristine();
      }
    });
  }

  setInitialData(clientPersonalDetails: ClientPersonalDetailsModel): void {
    if (clientPersonalDetails.OCCUPATION_ID || clientPersonalDetails.OCCUPATION_NAME) {
      if (this.isGPMAU) {
        this.occupationFC.setValue(clientPersonalDetails.OCCUPATION_NAME);
      } else if (this.isGPMNZ) {
        const occupation: AutocompleteDataObject = {
          name: this.clientDetailInitialData.ClientOccupation.NAME || null,
          id: clientPersonalDetails.OCCUPATION_ID,
        };
        this.occupationFC.setValue(occupation);
      }
    }

    //Employment Type
    if (clientPersonalDetails.EMPLOYMENT_TYPE_ID) {
      const conditionEmploymentType = this.employmentTypes.find((et) => et.ID === clientPersonalDetails.EMPLOYMENT_TYPE_ID);
      this.employmentTypeFC.setValue(conditionEmploymentType);
      this.selectedEmploymentType = conditionEmploymentType;
    } else {
      const conditionEmploymentType = this.employmentTypes.find(
        (et) => et.ID === this.clientDetailInitialData.EmploymentTypesObject.SelectedEmploymentType
      );
      this.employmentTypeFC.setValue(conditionEmploymentType);
      this.selectedEmploymentType = conditionEmploymentType;
    }

    //Employer
    if (clientPersonalDetails.EMPLOYER_ID && this.clientDetailInitialData.ClientDetails.ClientEmployer) {
      const clientEmployer: AutocompleteDataObject = {
        id: this.clientDetailInitialData.ClientDetails.ClientEmployer.ID,
        name: this.clientDetailInitialData.ClientDetails.ClientEmployer.NAME,
      };
      this.employerFC.setValue(clientEmployer);
    }

    // Employment Intensity
    if (clientPersonalDetails.EMPLOYMENT_INTENSITY_ID) {
      const clientEmploymentIntensity = this.employmentIntensities.find((ei) => ei.id === clientPersonalDetails.EMPLOYMENT_INTENSITY_ID);
      this.employmentIntensityFC.setValue(clientEmploymentIntensity);
    }
  }

  setPatchClientDetails(personalDetails: ClientPersonalDetailsModel): void {
    const patchClientDetails: ClientDetailsPatchModel = {
      OCCUPATION_ID: personalDetails.OCCUPATION_ID,
      OCCUPATION_NAME: personalDetails.OCCUPATION_NAME,
      EMPLOYER_ID: personalDetails.EMPLOYER_ID,
      EMPLOYMENT_INTENSITY_ID: personalDetails.EMPLOYMENT_INTENSITY_ID,
      EMPLOYMENT_TYPE_ID: personalDetails.EMPLOYMENT_TYPE_ID,
    };

    this.patchClientDetails = patchClientDetails;
  }

  onSave(): void {
    if (!this.clientDetailsForm.touched) {
      return;
    }

    let patchClientDetailsObservable: Observable<ClientPersonalDetailsModel>;
    let getClientOcuppationObservable: Observable<OccupationModel>;

    const occupation = this.occupationFC.value || null;

    const clientDetailsData: ClientDetailsPatchModel = {
      OCCUPATION_ID: occupation?.id || null,
      OCCUPATION_NAME: occupation && this.isGPMAU ? occupation : occupation?.id ? occupation.id : null,
      EMPLOYER_ID: (this.employerFC.value as AutocompleteDataObject)?.id || null,
      EMPLOYMENT_INTENSITY_ID: (this.employmentIntensityFC.value as AutocompleteDataObject)?.id || null,
      EMPLOYMENT_TYPE_ID: this.employmentTypeFC.value?.ID || null,
    };

    if (clientDetailsData.EMPLOYMENT_TYPE_ID && clientDetailsData.EMPLOYMENT_TYPE_ID === -1) {
      clientDetailsData.EMPLOYMENT_TYPE_ID = null;
    }

    const clientDetailsPatchOperations = compare(this.patchClientDetails, clientDetailsData, true);
    if (clientDetailsPatchOperations.length > 0) {
      patchClientDetailsObservable = this._clientService.patchClientDetails(
        this.clientPersonalDetails.CLIENT_ID,
        clientDetailsPatchOperations
      );
    }

    if (patchClientDetailsObservable) {
      this.clientDetailsForm.disable();

      if (occupation && occupation?.id !== this.clientPersonalDetails.OCCUPATION_ID && this.isGPMNZ) {
        getClientOcuppationObservable = this._occupationService.getOccupationById(occupation.id);
      }

      forkJoin({
        PatchClientDetailsResponse: patchClientDetailsObservable,
        ClientOccupation: getClientOcuppationObservable || of(null),
      }).subscribe({
        next: (response) => {
          if (response) {
            const clientDetails = response.PatchClientDetailsResponse;
            if (this._clientService.selectedClient) {
              this._clientService.selectedClient.PersonalDetails = clientDetails;
            }

            if (response.ClientOccupation) {
              this.clientDetailInitialData.ClientOccupation = response.ClientOccupation;
            }

            this.untouchedClientDetailsFormData = this.clientDetailsForm.value;
            this.setInitialData(clientDetails);
            this.setPatchClientDetails(clientDetails);

            this.status = EditCardStatus.NORMAL;
            this.cardBeingEdited.emit(false);
            this.clientDetailsForm.enable();
          }
        },
        error: (err) => {
          console.log(err);
          this.clientDetailsForm.enable();

          this.onCancel();
        },
      });
    }
  }

  onCancel(): void {
    if (JSON.stringify(this.untouchedClientDetailsFormData) !== JSON.stringify(this.clientDetailsForm.value)) {
      const config = new MatDialogConfig();
      config.data = {
        title: "Changes unsaved",
        content: "You have changes unsaved. Are you sure you want to leave?",
        type: DialogType.YES_NO,
      };
      const dialogRef = this._dialog.open(DialogComponent, config);

      dialogRef.afterClosed().subscribe((result: DialogOutput) => {
        if (!result) {
          return;
        }

        this.close();
      });
    } else {
      this.close();
    }
  }

  onEdit(): void {
    if (!this.canEdit) {
      return;
    }

    this.status = EditCardStatus.EDIT;
    this.cardBeingEdited.emit(true);
  }

  close(): void {
    this.clientDetailsForm.reset();
    this.innitFormControls();
    this.setInitialData(this.clientPersonalDetails);

    this.status = EditCardStatus.NORMAL;
    this.cardBeingEdited.emit(false);
  }

  _fetchEmployersByName(searchTerm: string = ""): Observable<AutocompleteDataObject[]> {
    return this._employerService.getEmployersByName(searchTerm).pipe(
      debounceTime(500),
      takeUntil(this.destroySubscriptions),
      map((employersFound: EmployerByNameModel[]) => {
        return employersFound.map((employer: EmployerByNameModel) => {
          const employerFound: AutocompleteDataObject = {
            id: employer.ID,
            name: employer.NAME,
          };
          return employerFound;
        });
      })
    );
  }

  _fetchOccupationsByName(searchTerm: string = ""): Observable<AutocompleteDataObject[]> {
    return this._occupationService.getOccupationsByName(searchTerm).pipe(
      debounceTime(500),
      takeUntil(this.destroySubscriptions),
      map((occupationsFound: OccupationModel[]) => {
        return occupationsFound.map((employer: OccupationModel) => {
          const occupationFound: AutocompleteDataObject = {
            id: employer.ID,
            name: employer.NAME,
          };
          return occupationFound;
        });
      })
    );
  }

  displayEmployerAndWorkIntensity(): boolean {
    // Not in paid employment in New Zealand
    if (
      this.selectedEmploymentType &&
      (this.selectedEmploymentType.ID === 716 || this.selectedEmploymentType.NAME.toLowerCase().includes("not in paid employment"))
    ) {
      return false;
    }

    if (this.selectedEmploymentType && this.selectedEmploymentType.SELF_EMPLOYED_IND === 0) {
      return true;
    }
  }

  ngOnDestroy(): void {
    this.destroySubscriptions.complete();
  }
}
