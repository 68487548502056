import { EditCardStatus } from "app/components/card/card.types";
import { EthnicGroupDBModel, SiteDBModel, TitleDBModel } from "app/core/cache/cache.types";
import { ClientService } from "app/core/client/client.service";
import { ClientDetailsPatchModel } from "app/core/client/models/client-details-patch.model";
import { ClientPatchModel } from "app/core/client/models/client-patch.model";
import { ClientPersonalDetailsModel } from "app/core/client/models/client-personal-details.model";
import { compare } from "fast-json-patch";
import { forkJoin, Observable, of } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";

import { ClientDetailInitialData, ClientGender, Gender } from "../../../client.types";
import { PatchClientResponseModel } from "app/core/client/models/patch-client-response.model";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";

import { DialogType } from "@components/dialog/dialog.types";
import { DialogOutput } from "@components/table/table-column-filter/table-serverside-column-filter.service";
import { DialogComponent } from "@components/dialog/dialog.component";
import { LoadingService } from "app/core/services/loading.service";
import { DateTimeService } from "app/core/services/datetime.service";

@Component({
  selector: "app-client-personal-info",
  templateUrl: "./client-personal-info.component.html",
  styleUrls: ["./client-personal-info.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientPersonalInfoComponent implements OnInit, AfterViewInit {
  @HostBinding("class") classes = "app-client-personal-info flex flex-col";
  status: EditCardStatus;

  titleFC: FormControl;
  firstNameFC: FormControl;
  middleNameFC: FormControl;
  lastNameFC: FormControl;
  showMiddleNameFC: FormControl;

  knownAsFC: FormControl;
  maidenNameFC: FormControl;
  genderFC: FormControl;
  dobFC: FormControl;
  dodFC: FormControl;
  homeSiteFC: FormControl;
  ethnicGroupFC: FormControl;
  otherEthnicGroupFC: FormControl;

  clientForm: FormGroup;
  clientDetailsForm: FormGroup;

  patchClient: ClientPatchModel;
  patchClientDetails: ClientDetailsPatchModel;

  clientDetailInitialData: ClientDetailInitialData;
  sites: SiteDBModel[];
  titles: TitleDBModel[];
  ethnicGroups: EthnicGroupDBModel[];

  isMobile: boolean;
  isLoading: boolean;

  clientGenders: ClientGender[] = [
    { identifier: Gender.Male, name: "Male" },
    { identifier: Gender.Female, name: "Female" },
  ];

  untouchedClientFormData: any;
  untouchedClientDetailsFormData: any;

  ClientAge: number = 0;

  @Output() cardBeingEdited = new EventEmitter<boolean>();
  @Input() canEdit: boolean;

  constructor(
    private _loadingService: LoadingService,
    private _breakpointObserver: BreakpointObserver,
    private _changeDetectorRef: ChangeDetectorRef,
    private _dateTimeService: DateTimeService,
    private _activatedRoute: ActivatedRoute,
    private _clientService: ClientService,
    private _formBuilder: FormBuilder,
    private _dialog: MatDialog
  ) {
    this.status = EditCardStatus.NORMAL;

    this.innitFormControls();

    this.isMobile = this._breakpointObserver.isMatched(Breakpoints.XSmall);
  }

  private innitFormControls() {
    this.titleFC = new FormControl("");
    this.firstNameFC = new FormControl("");
    this.middleNameFC = new FormControl("");
    this.lastNameFC = new FormControl("");

    this.knownAsFC = new FormControl("");
    this.maidenNameFC = new FormControl("");
    this.genderFC = new FormControl("");
    this.dobFC = new FormControl("");
    this.dodFC = new FormControl("");

    this.homeSiteFC = new FormControl("");
    this.ethnicGroupFC = new FormControl("");
    this.otherEthnicGroupFC = new FormControl("");
    this.showMiddleNameFC = new FormControl("");

    this.clientForm = this._formBuilder.group({
      site: this.homeSiteFC,
    });

    this.clientDetailsForm = this._formBuilder.group({
      title: this.titleFC,
      firstName: this.firstNameFC,
      middleName: this.middleNameFC,
      lastName: this.lastNameFC,
      knownAs: this.knownAsFC,
      maidenName: this.maidenNameFC,
      gender: this.genderFC,
      dob: this.dobFC,
      dod: this.dodFC,
      ethnicGroup: this.ethnicGroupFC,
      otherEthnicGroup: this.otherEthnicGroupFC,
      showMiddleName: this.showMiddleNameFC,
    });
  }

  ngOnInit(): void {
    this._loadingService.visible$.subscribe((response) => {
      this.isLoading = response;
    });

    this._activatedRoute.data.subscribe((data: any) => {
      this.clientDetailInitialData = data.initialData as ClientDetailInitialData;
      this.sites = this.clientDetailInitialData.Sites.filter(
        (s) => s.organisationId === this.clientDetailInitialData.ClientDetails.Client.ORGANISATION_ID && s.active && !s.deleted
      );

      this.titles = this.clientDetailInitialData.Titles.filter((t) => !t.deleted).sort((x, y) => x.name.localeCompare(y.name));
      this.ethnicGroups = this.clientDetailInitialData.EthnicGroups.filter((eg) => !eg.deleted).sort((x, y) =>
        x.name.localeCompare(y.name)
      );

      this.setInitialClientData();
      this.setPatchClient();
      this.setPatchClientDetails();
    });
  }

  ngAfterViewInit(): void {
    this.untouchedClientFormData = this.clientForm.value;
    this.untouchedClientDetailsFormData = this.clientDetailsForm.value;

    this.clientForm.valueChanges.pipe(debounceTime(200)).subscribe((data) => {
      if (JSON.stringify(this.untouchedClientFormData) === JSON.stringify(data)) {
        this.clientForm.markAsPristine();
      }
    });

    this.clientDetailsForm.valueChanges.pipe(debounceTime(200)).subscribe((data) => {
      if (JSON.stringify(this.untouchedClientDetailsFormData) === JSON.stringify(data)) {
        this.clientDetailsForm.markAsPristine();
      }
    });
  }

  public onChangeDoB(): void {
    this.SetClientAge(this.dobFC.value);
  }

  private setInitialClientData() {
    let personalDetails = this.clientDetailInitialData.ClientDetails.PersonalDetails;
    // Set Title
    if (personalDetails.TITLE_ID) {
      this.titleFC.setValue(
        this.clientDetailInitialData.Titles.find((t) => t.id === this.clientDetailInitialData.ClientDetails.PersonalDetails.TITLE_ID)
      );
    }

    // Set Gender
    if (personalDetails.SEX) {
      let sex = this.clientDetailInitialData.ClientDetails.PersonalDetails.SEX;
      if (sex) {
        this.genderFC.setValue(this.clientGenders.find((cg) => cg.identifier === sex));
      }
    }

    // Set First Name
    if (personalDetails.FIRST_NAME) {
      this.firstNameFC.setValue(personalDetails.FIRST_NAME);
    }

    // Set Last Name
    if (personalDetails.LAST_NAME) {
      this.lastNameFC.setValue(personalDetails.LAST_NAME);
    }

    // Set Middle Name
    if (personalDetails.MIDDLE_NAME) {
      this.middleNameFC.setValue(personalDetails.MIDDLE_NAME);
    }

    // Set checkbox middle name
    if (personalDetails.SHOW_MIDDLE_NAME_IN_LISTS_IND){
      this.showMiddleNameFC.setValue(personalDetails.SHOW_MIDDLE_NAME_IN_LISTS_IND);
    }

    // Set Maiden Name
    if (personalDetails.MAIDEN_NAME) {
      this.maidenNameFC.setValue(personalDetails.MAIDEN_NAME);
    }

    // Set Known As
    if (personalDetails.KNOWN_AS) {
      this.knownAsFC.setValue(personalDetails.KNOWN_AS);
    }

    // Set DOB
    if (personalDetails.DOB) {
      const localeDate = this._dateTimeService.convertToLocale(personalDetails.DOB);
      this.dobFC.setValue(localeDate);
      this.SetClientAge(localeDate);
    }

    // Set DOD
    if (personalDetails.DATE_OF_DEATH) {
      const localeDate = this._dateTimeService.convertToLocale(personalDetails.DATE_OF_DEATH);
      this.dodFC.setValue(localeDate);
    }

    // Set Home Site
    if (this.clientDetailInitialData.ClientDetails.Client.HOME_SITE_ID) {
      this.homeSiteFC.setValue(
        this.clientDetailInitialData.Sites.find((s) => s.id === this.clientDetailInitialData.ClientDetails.Client.HOME_SITE_ID)
      );
    }

    // Set Ethnic Group
    if (personalDetails.ETHNIC_GROUP_ID) {
      this.ethnicGroupFC.setValue(this.clientDetailInitialData.EthnicGroups.find((eg) => eg.id === personalDetails.ETHNIC_GROUP_ID));
    }

    // Set Other Ethnic Group Details
    if (personalDetails.OTHER_ETHNIC_GROUP_DETAILS) {
      this.otherEthnicGroupFC.setValue(personalDetails.OTHER_ETHNIC_GROUP_DETAILS);
    }

    this._changeDetectorRef.markForCheck();
  }

  private setPatchClient(): void {
    let patchClient: ClientPatchModel = {
      HOME_SITE_ID: this.clientDetailInitialData.ClientDetails.Client.HOME_SITE_ID,
    };

    this.patchClient = patchClient;
  }

  private setPatchClientDetails(): void {
    let patchClientDetails: ClientDetailsPatchModel = {
      SEX: this.clientDetailInitialData.ClientDetails.PersonalDetails.SEX,
      TITLE_ID: this.clientDetailInitialData.ClientDetails.PersonalDetails.TITLE_ID,
      FIRST_NAME: this.clientDetailInitialData.ClientDetails.PersonalDetails.FIRST_NAME,
      LAST_NAME: this.clientDetailInitialData.ClientDetails.PersonalDetails.LAST_NAME,
      MIDDLE_NAME: this.clientDetailInitialData.ClientDetails.PersonalDetails.MIDDLE_NAME,
      DOB: this.clientDetailInitialData.ClientDetails.PersonalDetails.DOB?.toString(),
      KNOWN_AS: this.clientDetailInitialData.ClientDetails.PersonalDetails.KNOWN_AS,
      DATE_OF_DEATH: this.clientDetailInitialData.ClientDetails.PersonalDetails.DATE_OF_DEATH?.toString() || null,
      MAIDEN_NAME: this.clientDetailInitialData.ClientDetails.PersonalDetails.MAIDEN_NAME,
      ETHNIC_GROUP_ID: this.clientDetailInitialData.ClientDetails.PersonalDetails.ETHNIC_GROUP_ID,
      OTHER_ETHNIC_GROUP_DETAILS: this.clientDetailInitialData.ClientDetails.PersonalDetails.OTHER_ETHNIC_GROUP_DETAILS,
      SHOW_MIDDLE_NAME_IN_LISTS_IND: this.clientDetailInitialData.ClientDetails.PersonalDetails.SHOW_MIDDLE_NAME_IN_LISTS_IND
    };

    this.patchClientDetails = patchClientDetails;
  }

  private SetClientAge(localeDate: Date): void {
    let timeDiff = Math.abs(Date.now() - localeDate.getTime());
    this.ClientAge = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
  }

  onSave(): void {
    let patchClientObservable: Observable<PatchClientResponseModel>;
    let patchClientDetailsObservable: Observable<ClientPersonalDetailsModel>;

    if (this.clientForm.touched) {
      let clientData: ClientPatchModel = {
        HOME_SITE_ID: (this.homeSiteFC.value as SiteDBModel).id,
      };
      const clientPatchOperations = compare(this.patchClient, clientData, true);

      patchClientObservable = this._clientService.patchClient(this.clientDetailInitialData.ClientDetails.Client.ID, clientPatchOperations);
    }

    if (this.clientDetailsForm.touched) {
      let clientDetailsData: ClientDetailsPatchModel = {
        SEX: (this.genderFC.value as ClientGender)?.identifier || null,
        TITLE_ID: (this.titleFC.value as TitleDBModel)?.id || null,
        FIRST_NAME: this.firstNameFC.value || null,
        LAST_NAME: this.lastNameFC.value || null,
        MIDDLE_NAME: this.middleNameFC.value || null,
        DOB: this._dateTimeService.convertToISOUTC(this.dobFC.value as Date) || null,
        KNOWN_AS: this.knownAsFC.value || null,
        DATE_OF_DEATH: this._dateTimeService.convertToISOUTC(this.dodFC.value as Date) || null,
        MAIDEN_NAME: this.maidenNameFC.value || null,
        ETHNIC_GROUP_ID: this.ethnicGroupFC.value ? (this.ethnicGroupFC.value as EthnicGroupDBModel).id : null,
        OTHER_ETHNIC_GROUP_DETAILS: this.otherEthnicGroupFC.value || null,
        SHOW_MIDDLE_NAME_IN_LISTS_IND: this.showMiddleNameFC.value ? 1 : 0
      };

      const clientDetailsPatchOperations = compare(this.patchClientDetails, clientDetailsData, true);
      
      patchClientDetailsObservable = this._clientService.patchClientDetails(
        this.clientDetailInitialData.ClientDetails.Client.ID,
        clientDetailsPatchOperations
      );
    }

    if (patchClientObservable || patchClientDetailsObservable) {
      this.clientForm.disable();
      this.clientDetailsForm.disable();

      forkJoin({
        PatchClientResponse: patchClientObservable || of(null),
        ClientDetails: patchClientDetailsObservable || of(null),
      }).subscribe(
        (response) => {
          if (response) {
            if (response.PatchClientResponse) {
              const patchClientResponse = response.PatchClientResponse as PatchClientResponseModel;
              if (this._clientService.selectedClient) {
                this._clientService.selectedClient.Client = patchClientResponse.Client;
              }
              this.clientDetailInitialData.ClientDetails.Client = patchClientResponse.Client;
              this.untouchedClientFormData = this.clientForm.value;
              this.setPatchClient();
            }

            if (response.ClientDetails) {
              if (this._clientService.selectedClient) {
                this._clientService.selectedClient.PersonalDetails = response.ClientDetails;
              }
              this.clientDetailInitialData.ClientDetails.PersonalDetails = response.ClientDetails;
              this.untouchedClientDetailsFormData = this.clientDetailsForm.value;
              this.setPatchClientDetails();
            }

            this.status = EditCardStatus.NORMAL;
            this.cardBeingEdited.emit(false);
            this.clientForm.enable();
            this.clientDetailsForm.enable();
          }
        },
        (err) => {
          console.log(err);
          this.clientForm.enable();
          this.clientDetailsForm.enable();
        }
      );
    }
  }

  onCancel(): void {
    if (
      JSON.stringify(this.untouchedClientFormData) !== JSON.stringify(this.clientForm.value) ||
      JSON.stringify(this.untouchedClientDetailsFormData) !== JSON.stringify(this.clientDetailsForm.value)
    ) {
      const config = new MatDialogConfig();
      config.data = {
        title: "Changes unsaved",
        content: "You have changes unsaved. Are you sure you want to leave?",
        type: DialogType.YES_NO,
      };
      const dialogRef = this._dialog.open(DialogComponent, config);

      dialogRef.afterClosed().subscribe((result: DialogOutput) => {
        if (!result) {
          return;
        }

        this.close();
      });
    } else {
      this.close();
    }
  }

  onEdit(): void {
    if (!this.canEdit) {
      return;
    }

    this.status = EditCardStatus.EDIT;
    this.cardBeingEdited.emit(true);
  }

  close(): void {
    this.clientForm.reset();
    this.clientDetailsForm.reset();
    this.innitFormControls();
    this.setInitialClientData();

    this.status = EditCardStatus.NORMAL;
    this.cardBeingEdited.emit(false);
  }
}
