import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from '@shared/shared.module';

import { AutocompleteFieldComponent } from './autocomplete-field.component';

@NgModule({
    imports: [
        SharedModule,
        MatInputModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatIconModule,
    ],
    exports: [AutocompleteFieldComponent],
    declarations: [AutocompleteFieldComponent],
    providers: [],
})
export class AutocompleteFieldModule {}
