export enum RelationshipType {
  Other = 0,
  Parent = 1,
  Child = 2,
  AuntUncle = 3,
  NieceNephew = 4,
  Sibling = 5,
  Partner = 6,
  Spouse = 7,
  Friend = 8,
  Grandparent = 9,
  Grandchild = 10,
  Daughter = 11,
  Son = 12,
}