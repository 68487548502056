import { Injectable } from "@angular/core";
import { ColumnItem, TableItemAction } from "@components/table/mat/mat-table-wrapper.service";
import { FilterItem } from "@components/table/table-column-filter/table-serverside-column-filter.service";
import { ServerSideTableService } from "@components/table/table-serverside.service";
import { Observable, of } from "rxjs";

@Injectable()
export class ClientSubInvoicesServersideTableService implements ServerSideTableService {
  getActionList(): TableItemAction[] {
    return [];
  }
  getColumnDefinitions(): ColumnItem[] {
    return [
      {
        fieldName: "data1",
        displayName: "Data 1",
      },
      {
        fieldName: "data2",
        displayName: "Data 2",
      },
    ];
  }
  getData(selectedFilterItem: FilterItem[]): Observable<any[]> {
    return of([{
      data1: "data0_1",
      data2: "data0_2"
    }, {
      data1: "data1_1",
      data2: "data1_2"
    }, {
      data1: "data2_1",
      data2: "data2_2"
    }, {
      data1: "data3_1",
      data2: "data3_2"
    }]);
  }
}
