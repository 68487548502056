<div class="w-full h-full">
    
    <app-title-header pageTitle={{pageTitle}}></app-title-header>
    
    <div class="workspace">
        
        <div class="flex flex-col sm:flex-row items-start gap-5">

            <app-client-details-side-menu class="w-full sm:w-auto" [option]="clientDetailsSideMenuActions.Account" [clientId]="clientId">
            </app-client-details-side-menu>

            <div class="w-full">
                <div class="workspace-body">
                    <mat-card class="body-content flex flex-col sm:flex-1 p-0">
                        <mat-tab-group class="rounded-tl rounded-tr overflow-hidden" animationDuration="0ms">
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <div class="flex flex-row items-center">
                                        <span>Payment</span>
                                    </div>
                                </ng-template>
                                <app-client-payments></app-client-payments>
                            </mat-tab>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <div class="flex flex-row items-center">
                                        <span>Invoices</span>
                                    </div>
                                </ng-template>
                                <app-client-invoices></app-client-invoices>
                            </mat-tab>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <div class="flex flex-row items-center">
                                        <span>Write-Offs</span>
                                    </div>
                                </ng-template>
                                <app-client-writeoffs></app-client-writeoffs>
                            </mat-tab>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <div class="flex flex-row items-center">
                                        <span>Statements</span>
                                    </div>
                                </ng-template>
                                <app-client-statements></app-client-statements>
                            </mat-tab>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <div class="flex flex-row items-center">
                                        <span>Charges</span>
                                    </div>
                                </ng-template>
                                <app-client-charges></app-client-charges>
                            </mat-tab>
                        </mat-tab-group>
                    </mat-card>
                </div>
            </div>

        </div>

    </div>

</div>
