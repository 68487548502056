/* tslint:disable:max-line-length */
import { FuseNavigationItem } from "@fuse/components/navigation";

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: "administration",
    title: "Administration",
    type: "group",
    icon: "heroicons_outline:home",
    children: [
      {
        id: "org",
        title: "Organisation",
        subtitle: "Manage your Organisation",
        type: "group",
        classes: {
          title: "text-pink-600",
        },
        children: [
          {
            id: "administration.vendors",
            title: "Vendors",
            type: "basic",
            icon: "mat_outline:corporate_fare",
            link: "/administration/vendors",
          },
          {
            id: "administration.sites",
            title: "Sites",
            type: "basic",
            icon: "mat_outline:local_hospital",
            link: "/administration/sites",
          },
          {
            id: "administration.clinicians",
            title: "Clinicians",
            type: "basic",
            icon: "mat_outline:badge",
            link: "/administratioin/clinicians",
          },
          {
            id: "administration.userAccessRoles",
            title: "User Access Roles",
            type: "basic",
            icon: "mat_outline:account_box",
            link: "/administration/user-access-roles",
          },
          {
            id: "administration.systemUsers",
            title: "System Users",
            type: "basic",
            icon: "mat_outline:groups",
            link: "/administration/system-users",
          },
        ],
      },
      {
        id: "admin",
        title: "Admin",
        subtitle: "Admin-level actions",
        type: "group",
        classes: {
          title: "text-pink-600",
        },
        children: [
          {
            id: "administration.apiConfig",
            title: "API Configuration",
            type: "basic",
            icon: "mat_outline:api",
            link: "/administration/api-config",
          },
          {
            id: "administration.appReleases",
            title: "App Releases",
            type: "basic",
            icon: "mat_outline:new_releases",
            link: "/administration/app-releases",
          },
        ],
      },
    ],
  },
  {
    id: "banking",
    title: "Banking and Daily Takings",
    type: "group",
    icon: "mat_outline:account_balance",
    children: [
      {
        id: "takings",
        title: "Daily Takings",
        subtitle: "Manage your Takings",
        type: "group",
        classes: {
          title: "text-pink-600",
        },
        children: [
          {
            id: "banking.takingsHistory",
            title: "Takings History",
            type: "basic",
            icon: "mat_outline:account_balance_wallet",
            link: "/banking/takings-history",
          },
          {
            id: "banking.newDailyTaking",
            title: "New Daily Taking",
            type: "basic",
            icon: "mat_outline:ballot",
            link: "/banking/new-daily-taking",
          },
        ],
      },
      {
        id: "bankings",
        title: "Bankings",
        subtitle: "Manage your Bankings",
        type: "group",
        classes: {
          title: "text-pink-600",
        },
        children: [
          {
            id: "banking.bankingHistory",
            title: "Banking History",
            type: "basic",
            icon: "mat_outline:assignment",
            link: "/banking/history",
          },
          {
            id: "administration.newBanking",
            title: "New Baking",
            type: "basic",
            icon: "mat_outline:calculate",
            link: "/banking/new",
          },
        ],
      },
    ],
  },
  {
    id: "contracts",
    title: "Contracts",
    type: "group",
    icon: "mat_outline:source",
    children: [
      {
        id: "abp&frpContracts",
        title: "ABP & FRP Contracts",
        subtitle: "Manage your Contracts",
        type: "group",
        classes: {
          title: "text-pink-600",
        },
        children: [
          {
            id: "contracts.programmeTypes",
            title: "Programme Types",
            type: "basic",
            icon: "mat_outline:auto_stories",
            link: "/contracts/programme-types",
          },
          {
            id: "contracts.abpContracts",
            title: "ABP Contracts",
            type: "basic",
            icon: "heroicons_outline:book-open",
            link: "/contracts/abp-contracts",
          },
          {
            id: "contracts.abpContractsReport",
            title: "ABP Contracts Report",
            type: "basic",
            icon: "heroicons_outline:document-report",
            link: "/contracts/abp-contracts-report",
          },
        ],
      },
      {
        id: "customContracts",
        title: "Custom Contracts",
        subtitle: "Create Custom Contracts",
        type: "group",
        classes: {
          title: "text-pink-600",
        },
        children: [
          {
            id: "contracts.contractTypes",
            title: "Contract Types",
            type: "basic",
            icon: "heroicons_outline:document-text",
            link: "/contracts/contract-types",
          },
          {
            id: "contracts.allContracts",
            title: "All Contracts",
            type: "basic",
            icon: "heroicons_outline:document-duplicate",
            link: "/contracts/all",
          },
        ],
      },
    ],
  },
  {
    id: "frontOffice",
    title: "Front Office",
    type: "group",
    icon: "mat_outline:apps",
    children: [
      {
        id: "toDo",
        title: "To Do",
        subtitle: "Pending events",
        type: "group",
        classes: {
          title: "text-pink-600",
        },
        children: [
          {
            id: "frontOffice.events",
            title: "Events",
            type: "basic",
            icon: "mat_outline:event",
            link: "/front-office/events",
          },
          {
            id: "frontOffice.claimReviews",
            title: "Claim Reviews",
            type: "basic",
            icon: "mat_outline:rate_review",
            link: "/front-office/claim-reviews",
          },
        ],
      },
      {
        id: "companies",
        title: "Companies",
        subtitle: "Manage your Companies",
        type: "group",
        classes: {
          title: "text-pink-600",
        },
        children: [
          {
            id: "frontOffice.allCompanies",
            title: "All Companies",
            type: "basic",
            icon: "mat_outline:apartment",
            link: "/front-office/all-companies",
          },
          {
            id: "frontOffice.clinics",
            title: "Clinics",
            type: "basic",
            icon: "mat_outline:add_to_queue",
            link: "/front-office/referrers",
          },
          {
            id: "frontOffice.referrers",
            title: "Referrers",
            type: "basic",
            icon: "mat_outline:supervised_user_circle",
            link: "/front-office/referrers",
          },
        ],
      },
      {
        id: "customForms",
        title: "Custom Forms",
        subtitle: "Create custom forms",
        type: "group",
        classes: {
          title: "text-pink-600",
        },
        children: [
          {
            id: "frontOffice.formEntries",
            title: "Form Entries",
            type: "basic",
            icon: "mat_outline:table_view",
            link: "/front-office/form-entries",
          },
          {
            id: "frontOffice.groupEntries",
            title: "Group Entries",
            type: "basic",
            icon: "mat_outline:collections_bookmark",
            link: "/front-office/group-entries",
          },
        ],
      },
    ],
  },
  {
    id: "products",
    title: "Products",
    type: "group",
    icon: "mat_outline:summarize",
    children: [
      {
        id: "admin",
        title: "Admin",
        subtitle: "Manage your Products",
        type: "group",
        classes: {
          title: "text-pink-600",
        },
        children: [
          {
            id: "products.programmeTypes",
            title: "Manufacturers",
            type: "basic",
            icon: "mat_outline:maps_home_work",
            link: "/products/manufacturers",
          },
          {
            id: "products.products",
            title: "Products",
            type: "basic",
            icon: "mat_outline:medication",
            link: "/products/abp-contracts",
          },
        ],
      },
      {
        id: "productReports",
        title: "Reports",
        subtitle: "Products Reports",
        type: "group",
        classes: {
          title: "text-pink-600",
        },
        children: [
          {
            id: "productReports.productsInStock",
            title: "Products in Stock",
            type: "basic",
            icon: "mat_outline:list_alt",
            link: "/product-reports/products-in-stock",
          },
          {
            id: "productReports.productsToOrder",
            title: "Products to Order",
            type: "basic",
            icon: "mat_outline:library_books",
            link: "/product-reports/products-to-order",
          },
        ],
      },
    ],
  },
  {
    id: "reports",
    title: "Reports",
    subtitle: "Get a list of all the reports you can run",
    type: "group",
    icon: "mat_outline:assignment",
    children: [
      {
        id: "reports.reports",
        title: "Reports",
        type: "basic",
        icon: "mat_outline:assignment_turned_in",
        link: "/reports/all",
      },
      {
        id: "reports.templateMerge",
        title: "Template Merge",
        type: "basic",
        icon: "mat_outline:content_copy",
        link: "/reports/template-merge",
      },
    ],
  },
];
export const compactNavigation: FuseNavigationItem[] = [
  {
    id: "administration",
    title: "administration",
    tooltip: "Administration",
    type: "aside",
    icon: "heroicons_outline:home",
    hidden: () => true,
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "calendar",
    title: "Calendar",
    tooltip: "Calendar (Ctrl + 1)",
    type: "basic",
    icon: "heroicons_outline:calendar",
    link: "/calendar",
    classes: {
      icon: "font-medium w-[1.125rem] h-[1.125rem]",
    },
  },
  {
    id: "banking",
    title: "Banking",
    tooltip: "Banking",
    type: "aside",
    icon: "mat_outline:account_balance",
    hidden: () => true,
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "frontOffice",
    title: "Front Office",
    tooltip: "Front Office",
    type: "aside",
    icon: "mat_outline:apps",
    hidden: () => true,
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "products",
    title: "Products",
    tooltip: "Products",
    type: "aside",
    icon: "mat_outline:inventory",
    hidden: () => true,
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "reports",
    title: "Reports",
    tooltip: "Reports",
    type: "aside",
    icon: "mat_outline:assignment",
    hidden: () => true,
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "clients",
    title: "Clients",
    tooltip: "Clients",
    type: "basic",
    icon: "gpm_clients",
    link: "/clients/search",
    classes: {
      icon: "font-medium w-[1.125rem] h-4",
    },
  },
  {
    id: "healthlink",
    title: "Healthlink",
    tooltip: "Healthlink (Ctrl + 2)",
    type: "basic",
    icon: "gpm_healthlink",
    link: "/healthlink",
    classes: {
      icon: "font-medium w-[1.125rem] h-4",
    },
  },
  {
    id: "admin-types",
    title: "Administration",
    tooltip: "Admin Types (Ctrl + 3)",
    type: "basic",
    icon: "heroicons_outline:identification",
    link: "/admin-types",
    classes: {
      icon: "font-medium w-[1.125rem] h-4",
    },
  },
  {
    id: "contracts",
    title: "Contracts",
    tooltip: "Contracts (Ctrl + 4)",
    type: "basic",
    icon: "mat_outline:source",
    link: "/contracts",
    classes: {
      icon: "font-medium w-[1.125rem] h-4",
    }
  },
];
