import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIService } from '../api/api.service';
import { LetterTemplateModel } from "../models/organisation/letter-template.model";

@Injectable({ providedIn: 'root' })
export class LetterService {
    constructor(private _apiService: APIService) {}

    public getLetterTemplates(templateType?: number): Observable<LetterTemplateModel[]> {
        return this._apiService.get(
            `/letters?command=getLetterTemplates&templateType=${templateType}`
        );
    }
}

