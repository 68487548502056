import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APIService } from "../api/api.service";
import { AddressModel } from "../models/appointment/address.model";

export interface CreatePostalAddressObject {
  ClientId: number;
  PostCode: string;
  PoBoxNo: string;
  StreetNo: string;
  Suburb: string;
  City: string;
  State: string;
  Country: string;
}

@Injectable({ providedIn: "root" })
export class AddressService {
  constructor(private _apiService: APIService) {}

  createPostalAddress(createObj: CreatePostalAddressObject): Observable<AddressModel> {
    return this._apiService.post(`/address?command=createPostalAddress`, createObj);
  }
}
