export const environment = {
    production: true,
    hmr: false,
    localAPI: 'http://localhost:3000',
    apiUrl: 'http://localhost:3000',
    wsEndPoint:'wss://uc3zl4ybr1.execute-api.ap-southeast-2.amazonaws.com/test',
    reconnectInterval: 2000,
    checkForUpdates: true,
    useLocalDevEnv: false,
    locktime: 5
};
