import { Injectable } from '@angular/core';

import { CacheableItems } from './cache.types';

@Injectable({ providedIn: 'root' })
export class CacheService {

    constructor() {}

    //Wrapper methods
    public set(
        key: CacheableItems,
        value: any,
    ): void {
        localStorage.setItem(key, value);
    }

    public get(key: CacheableItems): any {
        return localStorage.getItem(key);
    }

    public clear(): void {
        localStorage.clear();
    }

    public remove(key: CacheableItems): void {
        localStorage.removeItem(key);
    }
}
