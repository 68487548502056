import { Component, HostBinding, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppStateDBModel } from "app/core/cache/cache.types";
import { UtilService } from "app/core/services/util.service";
import { ClientDetailsSideMenuActions } from "../client.types";

@Component({
    selector: 'app-client-documents',
    templateUrl: './client-documents.component.html'
})
export class ClientDocumentsComponent implements OnInit {
    @HostBinding('class') classes = 'app-client-documents w-full block h-full';
    
    clientId: number;
    pageTitle: string;

    ClientDetailsSideMenuActions = ClientDetailsSideMenuActions;

    constructor(private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _utilService: UtilService){
        const params = this._activatedRoute.snapshot.params;
        this.clientId = params['clientId'];
    }
    
    ngOnInit(){
        this._activatedRoute.data.subscribe((data: any) => {
            const clientPersonalDetails = data.initialData;
            // Page Title
            this.pageTitle = this._utilService.getFullNameDob(clientPersonalDetails.FIRST_NAME, clientPersonalDetails.LAST_NAME, clientPersonalDetails.DOB);
          });
    }

    navigateToPage(url: string): void {
        this._router.navigate([url]);
    }

}