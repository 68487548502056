import { CardModule } from "app/components/card/card.module";
import { SpinnerService } from "app/core/services/spinner.service";

import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { RouterModule } from "@angular/router";
import { BreadcrumbsModule } from "@components/breadcrumbs/breadcrumbs.module";
import { LettersUploadsModule } from "@components/letters-and-uploads/letters-uploads.module";
import { TableModule } from "@components/table/table.module";
import { SharedModule } from "@shared/shared.module";

import { ClientAccountsComponent } from "./client-accounts/client-accounts.component";
import { ClientDetailsSideMenuComponent } from "./client-details/client-details-side-menu/client-details-side-menu.component";
import { ClientDetailsComponent } from "./client-details/client-details.component";
import { ClientDocumentsComponent } from "./client-documents/client-documents.component";
import { ClientEmploymentDetailsComponent } from "./client-details/client-details-info/client-employment-details/client-employment-details.component";
import { ClientHealthlinkComponent } from "./client-healthlink/client-healthlink.component";
import { ClientHomeContactDetailsComponent } from "./client-details/client-details-info/client-home-contact-details/client-home-contact-details.component";
import { ClientInvoicesComponent } from "./client-invoices/client-invoices.component";
import { ClientPaymentsComponent } from "./client-payment/client-payments.component";
import { ClientWriteOffsComponent } from "./client-writeoffs/client-writeoffs.component";
import { ClientPersonalInfoComponent } from "./client-details/client-details-info/client-personal-info/client-personal-info.component";
import { ClientSummaryComponent } from "./client-summary/client-summary.component";
import { clientRoutes } from "./client.routing";
import { DuplicateClientsDialogComponent } from "./new-client-dialog/duplicate-clients/duplicate-clients-dialog.component";
import { AddNewClientDialogComponent } from "./new-client-dialog/add-new-client-dialog.component";
import { AddNewClientDialogService } from "./new-client-dialog/add-new-client-dialog.service";
import { ClientOtherInformationComponent } from "./client-details/client-details-info/client-other-information/client-other-information.component";
import { AutocompleteFieldModule } from "@components/autocomplete-field/autocomplete-field.module";
import { MatRadioModule } from "@angular/material/radio";
import { ClientStatementsComponent } from "./client-statements/client-statements.component";
import { ClientInvoicesAmountRangeFilterComponent } from "./client-invoices/client-invoice-amount-range-filter.component";
import { ClientAppointmentsComponent } from "./client-appointments/client-appointments.component";
import { AddressModule } from "@components/address/address.module";
import { ClientNotesComponent } from "./client-details/client-details-info/client-notes/client-notes.component";
import { ClientEventsComponent } from "./client-events/client-events.component";
import { ClientEventsSubjectDetailsFilterComponent } from "./client-events/client-events-filters/subject-details-filter/client-events-subject-details-filter.component";
import { ClientAthleteDetailsComponent } from "./client-details/client-details-info/client-athlete-details/client-athlete-details.component";
import { ClientChargesComponent } from "./client-charges/client-charges.component";
import { ClientConditionsComponent } from "./client-conditions/client-conditions.component";
import { DialogModule } from "@components/dialog/dialog.module";
import { ClientFullHistoryComponent } from "./client-full-history/client-full-history.component";
import { ClientMembershipsComponent } from "./client-memberships/client-memberships.component";
import { MembershipsComponent } from "./client-memberships/memberships/memberships.component";
import { MembershipAppointmentUsageComponent } from "./client-memberships/appointment-usage/appointment-usage.component";
import { MatMenuModule } from "@angular/material/menu";
import { ReferredByDialogModule } from "@components/referred-by-dialog/referred-by-dialog.module";
import { ClientVaxStatusDialogComponent } from "./client-details/client-details-info/client-vax-status-dialog/client-vax-status-dialog.component";
import { ClientRelationshipsComponent } from "./client-relationships/client-relationships.component";
import { ClientGroupsComponent } from "./client-details/client-details-info/client-groups/client-groups.component";

@NgModule({
  imports: [
    RouterModule.forChild(clientRoutes),
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatSelectModule,
    MatDividerModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatButtonModule,
    MatDialogModule,
    MatTabsModule,
    MatButtonModule,
    MatRadioModule,

    TableModule,

    BreadcrumbsModule,

    SharedModule,

    CardModule,
    AutocompleteFieldModule,
    AddressModule,
    DialogModule,
    ReferredByDialogModule,
    LettersUploadsModule,
  ],
  exports: [ClientSummaryComponent],
  declarations: [
    ClientDetailsComponent,
    ClientEmploymentDetailsComponent,
    ClientPersonalInfoComponent,
    ClientHomeContactDetailsComponent,
    ClientSummaryComponent,
    ClientHealthlinkComponent,
    ClientDocumentsComponent,
    ClientDetailsSideMenuComponent,
    ClientNotesComponent,
    ClientAthleteDetailsComponent,
    DuplicateClientsDialogComponent,
    AddNewClientDialogComponent,
    ClientAccountsComponent,
    ClientPaymentsComponent,
    ClientInvoicesComponent,
    ClientOtherInformationComponent,
    ClientStatementsComponent,
    ClientAppointmentsComponent,
    ClientWriteOffsComponent,
    ClientChargesComponent,
    ClientEventsComponent,
    ClientEventsSubjectDetailsFilterComponent,
    ClientConditionsComponent,
    ClientFullHistoryComponent,
    ClientMembershipsComponent,
    MembershipsComponent,
    MembershipAppointmentUsageComponent,
    ClientInvoicesAmountRangeFilterComponent,
    ClientVaxStatusDialogComponent,
    ClientRelationshipsComponent,
    ClientGroupsComponent
  ],
  providers: [SpinnerService, AddNewClientDialogService],
})
export class ClientModule {}
