import { SiteDBModel } from "app/core/cache/cache.types";
import { IndexedDBService } from "app/core/cache/indexed-db.service";
import { ClientService } from "app/core/client/client.service";
import { ClientByNameModel } from "app/core/models/clients-by-name.model";
import { zip } from "rxjs";

import { Component, HostBinding, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "@components/dialog/dialog.component";
import { DialogType } from "@components/dialog/dialog.types";

import { NewClientRequestModel } from "../client.types";

import { DuplicateClientsDialogComponent } from "./duplicate-clients/duplicate-clients-dialog.component";
import { ClientCreateDialogActions } from "app/core/enums/client.create-enums";

@Component({
  selector: "app-add-new-client-dialog",
  templateUrl: "./add-new-client-dialog.component.html",
})
export class AddNewClientDialogComponent implements OnInit {
  @HostBinding("class") classes = "add-new-client-dialog";

  createClientForm: FormGroup;
  firstNameFC: FormControl;
  surNameFC: FormControl;
  dobFC: FormControl;
  homeSiteFC: FormControl;

  sites: SiteDBModel[] = [];

  isFormSubmitted: boolean = false;

  public CreatingClient: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddNewClientDialogComponent>,
    private _clientService: ClientService,
    private _indexedDbService: IndexedDBService,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.createClientForm = new FormGroup({
      firstNameFC: new FormControl(null, Validators.required),
      surNameFC: new FormControl(null, Validators.required),
      dobFC: new FormControl(null),
      homeSiteFC: new FormControl(null, Validators.required),
    });

    zip(this._indexedDbService.getAllSites(), this._indexedDbService.getAllSystemUserSites()).subscribe(([sites, systemUserSites]) => {
      systemUserSites.forEach((systemUserSite) => {
        const site = sites.find((s) => s.id === systemUserSite.siteId);
        if (site) {
          this.sites.push(site);
        }
      });
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  duplicateClient(listOfClients: ClientByNameModel[], newClientModel: NewClientRequestModel): void {
    const dialogRef = this._dialog.open(DuplicateClientsDialogComponent, {
      panelClass: "duplicate-clients-dialog-panel",
      backdropClass: "duplicate-clients-dialog-backdrop",
      width: "540px",
      maxWidth: "90vw",
      data: {
        duplicates: listOfClients,
        client: newClientModel,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (response) => {
        switch (response) {
          case ClientCreateDialogActions.CreateClient:
            this.createClient(newClientModel);
            break;
          case ClientCreateDialogActions.CloseDialog:
            this.close();
            break;
          default:
            break;
        }
      },
      error: (err) => {
        this.showErrorDialog(err);
      },
    });
  }

  validateBeforeCreate(): void {
    this.isFormSubmitted = true;
    if (this.createClientForm.invalid) {
      this.createClientForm.markAllAsTouched();
      return;
    }

    const newClientModel: NewClientRequestModel = {
      FirstName: this.createClientForm.get("firstNameFC").value,
      LastName: this.createClientForm.get("surNameFC").value,
      DateOfBirth: this.createClientForm.get("dobFC").value,
      HomeSiteId: this.createClientForm.get("homeSiteFC").value.id,
    };

    this._clientService.getClientsByName(`${newClientModel.FirstName} ${newClientModel.LastName}`).subscribe({
      next: (response) => {
        if (response.length > 0) {
          this.duplicateClient(response, newClientModel);
        } else {
          this.createClient(newClientModel);
        }
      },
      error: (err) => {
        this.showErrorDialog(err);
      },
    });
  }

  createClient(newClientModel: NewClientRequestModel): void {
    this.CreatingClient = true;
    this._clientService.createClient(newClientModel).subscribe({
      next: (client) => {
        if (client) {
          // set the newly created client on the header
          const clientByName: ClientByNameModel = {
            CLIENT_ID: client.ID,
            VENDOR_ID: client.VENDOR_ID,
            FIRST_NAME: newClientModel.FirstName,
            KNOWN_AS: "",
            FIRST_NAMES: "",
            LAST_NAME: newClientModel.LastName,
            MAIDEN_NAME: "",
            DOB: newClientModel.DateOfBirth ? newClientModel.DateOfBirth : null,
          };
          this.CreatingClient = false;
          this._clientService.setSelectedHeaderClient(clientByName);
        }
        this.close();
      },
      error: (err) => {
        this.showErrorDialog(err);
      },
    });
  }

  showErrorDialog(error: any): void {
    this._dialog.open(DialogComponent, {
      data: {
        title: "Error!!",
        content: error,
        type: DialogType.OK,
      },
    });
  }
}
