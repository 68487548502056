import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { IndexedDBService } from './core/cache/indexed-db.service';
import { SplashScreenService } from '@components/splash-screen/splash-screen.service';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class InitialDataResolver implements Resolve<any> {
    /**
     * Constructor
     */
    constructor(
        private _SplashScreen: SplashScreenService,
        private _navigationService: NavigationService,
        private _indexedDBService: IndexedDBService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Use this resolver to resolve initial mock-api for the application
     *
     * @param route
     * @param state
     */
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> {
        this._SplashScreen.show();

        // Fork join multiple API endpoint calls to wait all of them to finish
        return forkJoin([
            this._navigationService.get(),
            //TODO: move the caching end point to the new api and implement it, uncomment the below line
            //this._indexedDBService.getInitialCache(),
        ]).pipe(
            tap((response) => {
                if (response) {
                    this._SplashScreen.hide();
                }
            })
        );
    }
}
