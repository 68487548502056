<app-edit-card-comp class="flex-1 info-section" [status]="status" (editEvent)="onEdit()" (saveEvent)="onSave()"
    (cancelEvent)="onCancel()" [disableActions]="isLoading">
    <div class="flex md:flex-row flex-col" (click)="onEdit()">
        <mat-card-subtitle>Home Address/Contact Details</mat-card-subtitle>

        <!-- Edit View -->
        <div class="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 flex-1" *ngIf="status === 'EDIT'">
            <!-- Physical -->
            <div class="flex flex-col">
                <span class="mb-2">Physical Address</span>
                <app-address [addressModel]="homeAddress" [disable]="disableAddressForm"
                    (formValue)="setAddressFormValue($event)" [dhbs]="dhbs" [clientDHBId]="clientDHBId">
                </app-address>
            </div>

            <!-- Postal -->
            <div class="flex flex-col">
                <div class="flex flex-row justify-between gap-2">
                    <span class="mb-2">Postal Address</span>
                    <mat-checkbox [formControl]="postalAddressSameAsPhysicalFC" color="primary"
                        (change)="onPostalAddressCheck($event)">Same as Physical?</mat-checkbox>
                </div>
                <app-address [addressModel]="postalAddress" [disable]="disablePostalAddressForm"
                    (formValue)="setPostalAddressFormValue($event)" [showSearchAddressInput]="!hideSearchAddressBox">
                </app-address>
            </div>

            <!-- Home Contact Details Display View -->
            <div class="w-full flex flex-col gap-y-4">
                <mat-form-field class="w-full md:mt-7" *ngIf="status === 'EDIT'" appearance="outline">
                    <mat-label>Phone</mat-label>
                    <input matInput [formControl]="phoneFC" />
                </mat-form-field>
                <mat-form-field class="w-full" *ngIf="status === 'EDIT'" appearance="outline">
                    <mat-label>Fax</mat-label>
                    <input matInput [formControl]="faxFC" />
                </mat-form-field>
                <mat-form-field class="w-full" *ngIf="status === 'EDIT'" appearance="outline">
                    <mat-label>Mobile</mat-label>
                    <input matInput [formControl]="mobileFC" />
                </mat-form-field>
                <mat-form-field class="w-full" *ngIf="status === 'EDIT'" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput [formControl]="emailFC" />
                </mat-form-field>
            </div>
        </div>

        <!-- Display View -->
        <div class="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 flex-1" *ngIf="status !== 'EDIT'">
            <!-- Home Address Display View -->
            <div class="w-full element">
                <div class="title">Home Address</div>
                <ng-container
                    *ngIf="homeAddress.STREET_NO || homeAddress.CITY || homeAddress.POST_CODE || homeAddress.COUNTRY else noAddress">
                    <div class="info">
                        {{ homeAddress.STREET_NO || ''}} <br />
                        {{ (homeAddress.CITY || '') + " " +
                        (homeAddress.POST_CODE || '')}}<br />
                        {{ homeAddress.COUNTRY || ''}}
                    </div>
                </ng-container>

                <ng-template #noAddress>
                    No Address has been set for this client
                </ng-template>
            </div>

            <!-- Postal Address Display View -->
            <div class="w-full element">
                <div class="title">Postal Address</div>
                <ng-container
                    *ngIf="postalAddress.STREET_NO || postalAddress.CITY || postalAddress.POST_CODE || postalAddress.COUNTRY else noAddress">
                    <div class="info">
                        {{ postalAddress.STREET_NO || ''}} <br />
                        {{ (postalAddress.CITY || '') + " " +
                        (postalAddress.POST_CODE || '')}}<br />
                        {{ postalAddress.COUNTRY || ''}}
                    </div>
                </ng-container>

                <ng-template #noAddress>
                    No Postal Address has been set for this client
                </ng-template>
            </div>

            <!-- Home Contact Details Display View -->
            <div class="w-full">
                <div class="element">
                    <div class="title">Home Contact Details</div>
                    <div class="info">
                        <span *ngIf="phoneFC.value">P: {{ phoneFC.value }} <br /></span>
                        <span *ngIf="mobileFC.value">M: {{ mobileFC.value }} <br /></span>
                        <span *ngIf="faxFC.value">F: {{ faxFC.value }} <br /></span>
                        <span *ngIf="emailFC.value">E: {{ emailFC.value }} <br /></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-edit-card-comp>