import { animate, state, style, transition, trigger } from "@angular/animations";
import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { Subscription } from "rxjs";
import { SubRowElementConfig } from "./mat-table-wrapper.service";

@Component({
  selector: 'app-mat-table-row-sub-info-wrapper',
  templateUrl: './mat-table-row-sub-info.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class MatTableRowSubInfoWrapperComponent implements OnInit, OnChanges, OnDestroy {
  @HostBinding("class") classes = "app-mat-table-row-sub-info-wrapper w-full";

  @Input() rowData: any;
  @Input() requestExpand: boolean;
  @Input() subEleConfig: SubRowElementConfig;
  cachedSubRowInfo: any; // store data at runtime for sub information

  // events below to allow mat-table-wrapper to communicate with RowSubWrapper component
  @Output() initiatedEvent: EventEmitter<any> = new EventEmitter();
  @Output() destroyedEvent: EventEmitter<any> = new EventEmitter();
  @Output() dataInitiationEvent: EventEmitter<any> = new EventEmitter();

  dataSubscription: Subscription;

  isLoading: boolean;

  constructor(protected changeDetector: ChangeDetectorRef,) {
    this.isLoading = false;
  }

  ngOnInit(): void {
    this.initiatedEvent.next(this.rowData);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const requestExpandNotFirstChange: boolean = changes.requestExpand && !changes.requestExpand.firstChange;
    if (requestExpandNotFirstChange) {
      const isNeedExpand = !!changes.requestExpand.currentValue;
      if (isNeedExpand && !this.cachedSubRowInfo) {
        if (this.subEleConfig.retrieveDataForSubElement) {
          this.isLoading = true;
          this.dataSubscription = this.subEleConfig.retrieveDataForSubElement(this.rowData).subscribe((data: any) => {
            this.isLoading = false;
            this.cachedSubRowInfo = data;

            this.dataInitiationEvent.next({
              row: this.rowData,
              data,
            });

            this.changeDetector.markForCheck();
          });
        }
      }
    }
  }

  ngOnDestroy(): void {
    // notify the table-wrapper say that it is destroyed
    this.dataSubscription?.unsubscribe();
    this.destroyedEvent.next(this.rowData);
  }

}
