import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminTypeDBModel } from "app/core/cache/cache.types";
import { IndexedDBService } from "app/core/cache/indexed-db.service";
import { AdminTypeModel } from "app/core/models/organisation/admin-type.model";
import { AdminTypeService } from "app/core/services/admin-type.service";
import { firstValueFrom } from "rxjs";
import { DialogType } from "../dialog.types";
import { AdminTypeDialogData, DialogDataModel } from "../models/dialog-data.model";
import { AdminTypeRequestModel } from "./admin-type-dialog.types";

@Component({
    selector: 'app-admin-type-dialog',
    templateUrl: './admin-type-dialog.component.html',
    styleUrls: ['./admin-type-dialog.component.scss']
})
export class AdminTypeDialogComponent implements OnInit {
    title: string;
    adminTypeId: number | null;
    adminTypeTypeId: number;
    adminTypeNameFC: FormControl;
    descriptionFC: FormControl;
    adminTypeForm: FormGroup;
    constructor(
        private _dialogRef: MatDialogRef<AdminTypeDialogComponent>,
        private _formBuilder: FormBuilder,
        private _indexedDbService: IndexedDBService,
        private _adminTypeService: AdminTypeService,
        @Inject(MAT_DIALOG_DATA) data: AdminTypeDialogData
    ) {
        this.adminTypeNameFC = new FormControl();
        this.descriptionFC = new FormControl();
        this.adminTypeForm = this._formBuilder.group({
            adminTypeName: this.adminTypeNameFC,
            description: this.descriptionFC,
        });
        this.title = data.title;
        this.adminTypeId = data.adminTypeId;
        this.adminTypeTypeId = data.adminTypeTypeId;
    }

    ngOnInit() {
        if(this.adminTypeId){
            this._indexedDbService.getDBAdminTypeById(this.adminTypeId).subscribe((response: AdminTypeDBModel) => {
                if(response){
                    this.adminTypeNameFC.setValue(response.name);
                    this.descriptionFC.setValue(response.description);
                }
            });
        }        
        
    }
    cancel(): void {
        this._dialogRef.close();
    }
    save(): void {
        const requestModel : AdminTypeRequestModel = {
            Id: this.adminTypeId,
            Name: this.adminTypeNameFC.value,
            Description: this.descriptionFC.value,
            AdminTypeTypeId: this.adminTypeTypeId
        }
        if(requestModel.Id){
            this._adminTypeService.editAdminType(requestModel).subscribe((response: AdminTypeModel) => {
                // update the indexed db just in case. But we will remove admin type db from indexed db soon
                const adminType = this.updateCacheDb(response);
                this._dialogRef.close(response);                                
            })            
        }
        else{
            this._adminTypeService.createAdminType(requestModel).subscribe((response: AdminTypeModel) => {
                // update the indexed db just in case. But we will remove admin type db from indexed db soon
                const adminType = this.updateCacheDb(response);
                this._dialogRef.close(response);
            });
        }        
        
    }

    private updateCacheDb(adminType: AdminTypeModel) : AdminTypeDBModel{
        const adminTypeData: AdminTypeDBModel = {
            id: adminType.ID,
            name: adminType.NAME,
            description: adminType.DESCRIPTION,
            deleted: adminType.DELETED_IND === 1 ? true : false,
            active: adminType.ACTIVE_IND === 1 ? true: false,
            adminTypeType: adminType.ADMIN_TYPE_TYPE
          }
          this._indexedDbService.updateDBAdminType(adminTypeData);
          return adminTypeData;
    }
}
