import { Injectable } from '@angular/core';
import { ColumnGroupConfig, ColumnItem, MASTER_GROUP_ID, RowItemConfig, TableActionType, TableItemAction } from '@components/table/mat/mat-table-wrapper.service';
import { FilterColumnService, FilterItem, FilterItemHelper } from '@components/table/table-column-filter/table-serverside-column-filter.service';
import { ServerSideTableService } from '@components/table/table-serverside.service';
import { ClientService } from 'app/core/client/client.service';
import { PhysioAppointmentModel } from "app/core/models/physio/physio-appointment.model";
import { DateTimeService } from 'app/core/services/datetime.service';
import { Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { GetAppointmentStatusDescription } from 'app/core/enums/appointment-enums';
import { UtilService } from "app/core/services/util.service";

export enum APPOINTMENT_COLS {
    ALL = 'ALL',
    START_DATETIME = 'START_DATETIME',
    PROVIDER_NAME = 'PROVIDER_NAME',
    DURATIONS = 'DURATIONS',
    STATUS_NAME = 'STATUS_NAME',
    SITE_NAME = 'SITE_NAME',
    EXAM_CREATED = 'EXAM_CREATED',
    CLAIM_NO = 'CLAIM_NO',
    BILLING_CODE = 'BILLING_CODE',
    SCHEDULE_NO = 'SCHEDULE_NO',
    ISL_STATUS = 'ISL_STATUS',
    BILL_TO_TYPE = 'BILL_TO_TYPE',
    CHARGE = 'CHARGE',
    INVOICED = 'INVOICED',
    PAID_WRITTEN_OFF = 'PAID_WRITTEN_OFF'
}

const APPOINTMENT_FILTERS = {
    BETWEEN: 'BETWEEN',
    CANCELLED: 'CANCELLED',
};

const ACC_BILLING_FIELDS = "ACC_BILLING_FIELDS";
const CLIENT_BILLING_FIELDS = "CLIENT_BILLING_FIELDS";


@Injectable()
export class ClientAppointmentsServersideTableService implements ServerSideTableService {
    public destroySubscriptions: Subject<boolean> = new Subject<boolean>();

    public clientId: number;

    constructor(
        private _clientService: ClientService,
        private _dateTimeService: DateTimeService,
        private _utilService: UtilService
    ) {}

    getActionList(): Array<TableItemAction> {
        return [];
    }

    getColumnDefinitions(): ColumnItem[] {
        return [
            {
                fieldName: APPOINTMENT_COLS.START_DATETIME,
                displayName: 'Date',
                config: {
                    isDateTime: true,
                },
                isSorting: true,
            },
            {
                fieldName: APPOINTMENT_COLS.PROVIDER_NAME,
                displayName: 'Provider',
            },
            {
                fieldName: APPOINTMENT_COLS.SITE_NAME,
                displayName: 'Site',
            },
            {
                fieldName: APPOINTMENT_COLS.DURATIONS,
                displayName: 'Mins',
                width: 5 
            },
            {
                fieldName: APPOINTMENT_COLS.STATUS_NAME,
                displayName: 'Status', 
                width:10             
            },
            { fieldName: APPOINTMENT_COLS.EXAM_CREATED, displayName: "Exam", width:5, isSorting: true },
            { fieldName: APPOINTMENT_COLS.CLAIM_NO, displayName: "Claim No", isSorting: true },
            { fieldName: APPOINTMENT_COLS.BILLING_CODE, displayName: "Code", isSorting: true },
            { fieldName: APPOINTMENT_COLS.SCHEDULE_NO, displayName: "Schedule", width:10, isSorting: true },
            { fieldName: APPOINTMENT_COLS.ISL_STATUS, displayName: "Bill Status", isSorting: true },
            { fieldName: APPOINTMENT_COLS.BILL_TO_TYPE, displayName: "Counted To", isSorting: true },
            {
                fieldName: APPOINTMENT_COLS.CHARGE,
                displayName: "Charge",
                isSorting: true,
                config: { isCurrency: true }
            },
            {
                fieldName: APPOINTMENT_COLS.INVOICED,
                displayName: "Invoiced",
                isSorting: true,
                config: { isCurrency: true }
            },
            {
                fieldName: APPOINTMENT_COLS.PAID_WRITTEN_OFF,
                displayName: "Paid / WO",
                isSorting: true,
                config: { isCurrency: true }
            }
        ];
    }

    getDisplayColumnConfig(): ColumnGroupConfig {

        // we just need client billing fields for AU
    const auDisplayColumnGroup = [CLIENT_BILLING_FIELDS];
    const nzDisplayColumnGroup = [ACC_BILLING_FIELDS, CLIENT_BILLING_FIELDS];

        return {
            defaultColumns: [
                APPOINTMENT_COLS.START_DATETIME,
                APPOINTMENT_COLS.PROVIDER_NAME,
                APPOINTMENT_COLS.SITE_NAME,
                APPOINTMENT_COLS.DURATIONS,
                APPOINTMENT_COLS.STATUS_NAME,
            ],
            groupColumnDefinition: [
                {
                    groupID: ACC_BILLING_FIELDS,
                    groupName: "Show ACC Billings Fields",
                    columnNames: [
                        APPOINTMENT_COLS.CLAIM_NO,
                        APPOINTMENT_COLS.EXAM_CREATED,
                        APPOINTMENT_COLS.BILLING_CODE,
                        APPOINTMENT_COLS.SCHEDULE_NO,
                        APPOINTMENT_COLS.ISL_STATUS,
                        APPOINTMENT_COLS.BILL_TO_TYPE
                    ]
                  },
                  {
                    groupID: CLIENT_BILLING_FIELDS,
                    groupName: "Show Client Billings Fields",
                    columnNames: [
                        APPOINTMENT_COLS.CHARGE, 
                        APPOINTMENT_COLS.INVOICED,
                        APPOINTMENT_COLS.PAID_WRITTEN_OFF
                    ]
                  }
            ],
            displayGroup: this._utilService.isGPMNZ() ? nzDisplayColumnGroup : auDisplayColumnGroup
        };
    }


    // for retrieving data from server or refreshing data from selectedFilter
    getData(selectedFilterList: FilterItem[]): Observable<any[]> {
        const betweenFilterValue: any = FilterItemHelper.retrieveFilterValueForNameItem(
                APPOINTMENT_FILTERS.BETWEEN,
                selectedFilterList
            );
        const dateFrom = betweenFilterValue && betweenFilterValue.filterFrom
            ? this._dateTimeService.FormatDate(betweenFilterValue.filterFrom, 'MM/DD/yyyy')
            : '';
        const dateTo = betweenFilterValue && betweenFilterValue.filterTo
            ? this._dateTimeService.FormatDate(betweenFilterValue.filterTo, 'MM/DD/yyyy')
            : '';

        const showCancelledApptFilter: any = FilterItemHelper.retrieveFilterValueForNameItem(
                APPOINTMENT_FILTERS.CANCELLED,
                selectedFilterList
            );
        
        const showCancelled = showCancelledApptFilter ? showCancelledApptFilter.filterValue : false;
        
        return this._clientService
            .getClientAppointments(this.clientId, dateFrom, dateTo, showCancelled)
            .pipe(
                takeUntil(this.destroySubscriptions),
                map((appointments: Array<PhysioAppointmentModel>) => {
                    this.formatData(appointments);
                    return appointments.sort((a,b) => b.START_DATETIME.localeCompare(a.START_DATETIME));
                })
            );
    }

    protected formatData(data: Array<PhysioAppointmentModel>): void {
        data.forEach((item) => {
            item.STATUS_NAME = GetAppointmentStatusDescription(item.STATUS);
        });
    }
}

@Injectable()
export class ClientAppointmentsFilterColumnService implements FilterColumnService {
    public destroySubscriptions: Subject<boolean> = new Subject<boolean>();

    constructor() { }

    getAllowedFilterFields(): Array<FilterItem> {
        return [
            {
                fieldName: APPOINTMENT_FILTERS.BETWEEN,
                displayName: 'Between',
                config: {
                    label: "Appointment Date",
                    isDateRange: true,
                },
            },
            {
                fieldName: APPOINTMENT_FILTERS.CANCELLED,
                displayName: 'Show Cancelled Appointments',
                config: {
                    label: "Show Cancelled Appointments",
                    isSingleCheck: true,
                    value : false
                },
            }            
        ];
    }

    getSelectDataForFilterItem(item: FilterItem): Observable<Array<any>> {
        return of([]);
    }

    getDataButtonText(): string {
        return "Get Client Appointments";
    }

    getPageTitle(): string {
        return "Appointments";
      };

    getFilterServiceUniqueName(): string {
        return "Client_Appointment_Filter";
    }
}
