<div class="column-filter-wrapper">
	<mat-toolbar class="mat-primary m-0">
		<mat-toolbar-row class="flex flex-row justify-between items-center">
			<span class="title dialog-title">{{ data.column.displayName }}</span>
			<button mat-button (click)="matDialogRef.close()" aria-label="Close dialog" class="close-button">
				<mat-icon svgIcon="gpm_close"></mat-icon>
			</button>
		</mat-toolbar-row>
	</mat-toolbar>
	<div class="content m-0">
		<div class="field-group">
			<div class="field-wrapper">
				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label>Contains</mat-label>
					<input matInput [(ngModel)]="filterTextValue">
				</mat-form-field>
			</div>
		</div>
	</div>
	<div class="actions">
		<button mat-raised-button color="basic" class="apply-button" (click)="applyFilter()" aria-label="Apply filter">
			Apply
		</button>
	</div>
</div>
