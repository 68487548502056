import { Observable } from "rxjs";

import { Injectable } from "@angular/core";

import { APIService } from "../api/api.service";
import { OccupationModel } from "../models/physio/occupation.model";

@Injectable({ providedIn: "root" })
export class OccupationService {
  constructor(private _apiService: APIService) {}

  public getOccupationsByName(searchTerm: string): Observable<OccupationModel[]> {
    return this._apiService.get(`/occupations?command=search&SearchTerm=${searchTerm}`);
  }

  public getOccupationById(occupationId: number): Observable<OccupationModel> {
    return this._apiService.get(`/occupations?command=occupationById&id=${occupationId}`);
  }
}
