import { EntityTypeEnum } from "app/core/enums/physio-enums";
import { EntityType } from "app/core/global/interfaces";

export const ReferredByTypes: EntityType[] = [
  { code: EntityTypeEnum.NONE, description: "None" },
  { code: EntityTypeEnum.CLIENT_SELF, description: "Client Self Referred" },
  { code: EntityTypeEnum.CLIENT, description: "Another Client" },
  { code: EntityTypeEnum.COMPANY, description: "Company" },
  { code: EntityTypeEnum.DEBTOR, description: "Debtor / Insurer" },
  { code: EntityTypeEnum.EMPLOYER, description: "Employer" },
  { code: EntityTypeEnum.SYSTEM_USER, description: "Internal / System User" },
  { code: EntityTypeEnum.REFERRER, description: "Referrer / GP" },
];
