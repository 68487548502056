import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APIService } from "../api/api.service";
import { CompanyModel } from "../models/organisation/company.model";

@Injectable({ providedIn: 'root' })
export class ClinicService {
    constructor(private _apiService: APIService){}

    getClinics(): Observable<Array<CompanyModel>>{
        return this._apiService.get(`/clinics?command=getClinics`) as Observable<Array<CompanyModel>>;
    }

}
