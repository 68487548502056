import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { DHBDBModel } from "app/core/cache/cache.types";
import { AddressModel } from "app/core/models/appointment/address.model";
import { PatchAddressModel } from "app/core/models/appointment/patch-address.model";
import { AddressSuggestItem } from "app/core/models/organisation/address-suggest.model";

@Component({
  selector: "app-address",
  templateUrl: "address.component.html",
})
export class AddressComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() addressModel: AddressModel;
  @Input() disable: boolean;
  @Input() dhbs?: DHBDBModel[];
  @Input() clientDHBId?: number;
  @Input() showSearchAddressInput: boolean = true;

  @Output() formValue = new EventEmitter<any>();

  streetFC: FormControl;
  suburbFC: FormControl;
  cityFC: FormControl;
  stateFC: FormControl;
  postCodeFC: FormControl;
  countryFC: FormControl;
  dhbFC: FormControl;

  addressForm: FormGroup;

  untouchedAddressFormData: any;

  isMobile: boolean;

  constructor(private _formBuilder: FormBuilder, private _breakpointObserver: BreakpointObserver) {
    this.streetFC = new FormControl();
    this.suburbFC = new FormControl();
    this.cityFC = new FormControl();
    this.stateFC = new FormControl();
    this.postCodeFC = new FormControl();
    this.countryFC = new FormControl();
    this.dhbFC = new FormControl();

    this.addressForm = this._formBuilder.group({
      address: this.streetFC,
      suburb: this.suburbFC,
      city: this.cityFC,
      state: this.stateFC,
      postCode: this.postCodeFC,
      country: this.countryFC,
      dhb: this.dhbFC,
    });

    this.isMobile = this._breakpointObserver.isMatched(Breakpoints.XSmall);
  }

  ngOnInit(): void {
    if (this.addressModel) {
      if (this.dhbs?.length > 0) {
        this.dhbs = [...this.dhbs].filter((d) => d.active && !d.deleted);
        const defaultDHB: DHBDBModel = {
          id: null,
          deleted: false,
          organisationId: null,
          name: null,
          code: null,
          description: null,
          active: true,
          dhbRegionId: 0,
        };
        this.dhbs.splice(0, 0, defaultDHB);
      }
      this.setInitialData();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disable.currentValue) {
      this.addressForm.disable();
    } else {
      this.addressForm.enable();
    }

    if (changes.addressModel.currentValue || changes.clientDHBId.currentValue) {
      this.setInitialData();
    }
  }

  ngAfterViewInit(): void {
    this.untouchedAddressFormData = this.addressForm.value;

    this.addressForm.valueChanges.subscribe((data) => {
      if (JSON.stringify(this.untouchedAddressFormData) === JSON.stringify(data)) {
        this.addressForm.markAsPristine();
        return;
      }

      this.formValue.emit(this.getPatchAddressFormValue(data));
    });
  }

  setInitialData(): void {
    if (this.addressModel) {
      this.streetFC.setValue(this.addressModel.STREET_NO);
      this.suburbFC.setValue(this.addressModel.SUBURB);
      this.cityFC.setValue(this.addressModel.CITY);
      this.stateFC.setValue(this.addressModel.STATE);
      this.postCodeFC.setValue(this.addressModel.POST_CODE);
      this.countryFC.setValue(this.addressModel.COUNTRY);
    }

    if (this.clientDHBId && this.dhbs?.length > 0) {
      this.dhbFC.setValue(this.dhbs.find((d) => d.id === this.clientDHBId));
    }

    this.formValue.emit(this.getPatchAddressFormValue(this.addressForm.value));
  }

  getPatchAddressFormValue(formValue: any): PatchAddressModel | { address: PatchAddressModel; dhb: DHBDBModel } {
    const address: PatchAddressModel = {
      POST_CODE: formValue.postCode,
      STREET_NO: formValue.address,
      SUBURB: formValue.suburb,
      CITY: formValue.city,
      STATE: formValue.state,
      COUNTRY: formValue.country,
    };

    if (formValue.dhb) {
      return { address, dhb: formValue.dhb };
    }

    return address;
  }

  selectedChanged(item: AddressSuggestItem): void {
    if (item !== null) {
      this.addressModel.STREET_NO = item.Street;
      this.addressModel.SUBURB = item.Suburb;
      this.addressModel.CITY = item.CityOrTown;
      this.addressModel.STATE = item.State;
      this.addressModel.POST_CODE = item.Postcode;
      this.addressModel.COUNTRY = item.Country;

      this.setInitialData();
    }
  }
}
