import { AdminTypeType } from "app/modules/admin-types/admin-types.types";

export enum AdminTypesEnum {
  DocumentAction = 1,
  UploadFileType = 4,
  ContactPhoneType = 5,
  ContactEmailType = 6,
  ContactAddressType = 7,
  CompanyType = 8,
  BankAccountFormatType = 9,
  CompanyContactPosition = 10,
  ReferralSource = 11,
  ProductGroup = 12,
  ContactFaxType = 13,
  DebtorBusinessType = 14,
  CustomTaxRateType = 15,
  EftposNz = 16,
  FixedAssetCategory = 17,
  DhbRegion = 18,
  VaxStatus = 19,

  //Appt
  WhyChooseUs = 1003,
  CancellationReason = 1004,
  ClientInactiveReason = 1004,
  ClientContactType = 1005,

  //Physio
  PatientGoal = 2001,
  GoalActivity = 2002,
  DischargeOutcome = 2003,
  TrainerProviderType = 2004,
  InternalSportType = 2005,
  CardLevel = 2006,
  DoseType = 2007,
  MCSPatternType = 2008,
  DiabetesRiskType = 2009,
  InjuryType = 2011,
  MainProviderReason = 2012,
  TreatmentType = 2013,
}

export enum AdminTypesDescriptionEnum {
  DocumentAction = "Document Action",
  UploadFileType = "Upload File Type",
  ContactPhoneType = "Contact Phone Type",
  ContactEmailType = "Contact Email Type",
  ContactAddressType = "Contact Address Type",
  CompanyType = "Company Type",
  BankAccountFormatType = "Bank Account Format Type",
  CompanyContactPosition = "Company Contact Position",
  ReferralSource = "Referral Source",
  ProductGroup = "Product Group",
  ContactFaxType = "Contact Fax Type",
  DebtorBusinessType = "Debtor Business Type",
  CustomTaxRateType = "Custom Tax Rate",
  EftposNz = "Eftpos Nz",
  FixedAssetCategory = "Asset Category",
  DhbRegion = "DHB Region",
  VaxStatus = "Vaccionation Status",

  //Appt
  WhyChooseUs = "Why Choose Us",
  CancellationReason = "Cancellation Reason",
  ClientInactiveReason = "Client Inactive Reason",
  ClientContactType = "Client Contact Type",

  //Physio
  PatientGoal = "Patient Goal",
  GoalActivity = "Goal Activity/Plan",
  DischargeOutcome = "Discharge Outcome",
  TrainerProviderType = "Other Provider Type",
  InternalSportType = "Sport Type",
  CardLevel = "Card Level",
  DoseType = "Dose Type",
  MCSPatternType = "MCS Pattern",
  DiabetesRiskType = "Diabetes Risk Type",
  InjuryType = "Injury Type",
  MainProviderReason = "Main Provider Reason",
  TreatmentType = "Treatment Type",
}

export function GetAdminTypeTypeDescription(adminType: AdminTypesEnum): string {
  let desc: string;

  switch (adminType) {
    case AdminTypesEnum.DocumentAction:
      desc = AdminTypesDescriptionEnum.DocumentAction;
      break;
    case AdminTypesEnum.UploadFileType:
      desc = AdminTypesDescriptionEnum.UploadFileType;
      break;
    case AdminTypesEnum.ContactPhoneType:
      desc = AdminTypesDescriptionEnum.ContactPhoneType;
      break;
    case AdminTypesEnum.ContactEmailType:
      desc = AdminTypesDescriptionEnum.ContactEmailType;
      break;
    case AdminTypesEnum.ContactAddressType:
      desc = AdminTypesDescriptionEnum.ContactAddressType;
      break;
    case AdminTypesEnum.CompanyType:
      desc = AdminTypesDescriptionEnum.CompanyType;
      break;
    case AdminTypesEnum.BankAccountFormatType:
      desc = AdminTypesDescriptionEnum.BankAccountFormatType;
      break;
    case AdminTypesEnum.CompanyContactPosition:
      desc = AdminTypesDescriptionEnum.CompanyContactPosition;
      break;
    case AdminTypesEnum.ReferralSource:
      desc = AdminTypesDescriptionEnum.ReferralSource;
      break;
    case AdminTypesEnum.ProductGroup:
      desc = AdminTypesDescriptionEnum.ProductGroup;
      break;
    case AdminTypesEnum.ContactFaxType:
      desc = AdminTypesDescriptionEnum.ContactFaxType;
      break;
    case AdminTypesEnum.DebtorBusinessType:
      desc = AdminTypesDescriptionEnum.DebtorBusinessType;
      break;
    case AdminTypesEnum.CustomTaxRateType:
      desc = AdminTypesDescriptionEnum.CustomTaxRateType;
      break;
    case AdminTypesEnum.EftposNz:
      desc = AdminTypesDescriptionEnum.EftposNz;
      break;
    case AdminTypesEnum.FixedAssetCategory:
      desc = AdminTypesDescriptionEnum.FixedAssetCategory;
      break;
    case AdminTypesEnum.DhbRegion:
      desc = AdminTypesDescriptionEnum.DhbRegion;
      break;
    case AdminTypesEnum.VaxStatus:
      desc = AdminTypesDescriptionEnum.VaxStatus;
      break;
    case AdminTypesEnum.CardLevel:
      desc = AdminTypesDescriptionEnum.CardLevel;
      break;
    case AdminTypesEnum.WhyChooseUs:
      desc = AdminTypesDescriptionEnum.WhyChooseUs;
      break;
    case AdminTypesEnum.CancellationReason:
      desc = AdminTypesDescriptionEnum.CancellationReason;
      break;
    case AdminTypesEnum.ClientInactiveReason:
      desc = AdminTypesDescriptionEnum.ClientInactiveReason;
      break;
    case AdminTypesEnum.ClientContactType:
      desc = AdminTypesDescriptionEnum.ClientContactType;
      break;
    case AdminTypesEnum.PatientGoal:
      desc = AdminTypesDescriptionEnum.PatientGoal;
      break;
    case AdminTypesEnum.GoalActivity:
      desc = AdminTypesDescriptionEnum.GoalActivity;
      break;
    case AdminTypesEnum.DischargeOutcome:
      desc = AdminTypesDescriptionEnum.DischargeOutcome;
      break;
    case AdminTypesEnum.TrainerProviderType:
      desc = AdminTypesDescriptionEnum.TrainerProviderType;
      break;
    case AdminTypesEnum.InternalSportType:
      desc = AdminTypesDescriptionEnum.InternalSportType;
      break;
    case AdminTypesEnum.InternalSportType:
      desc = AdminTypesDescriptionEnum.InternalSportType;
      break;
    case AdminTypesEnum.DoseType:
      desc = AdminTypesDescriptionEnum.DoseType;
      break;
    case AdminTypesEnum.MCSPatternType:
      desc = AdminTypesDescriptionEnum.MCSPatternType;
      break;
    case AdminTypesEnum.DiabetesRiskType:
      desc = AdminTypesDescriptionEnum.DiabetesRiskType;
      break;
    case AdminTypesEnum.InjuryType:
      desc = AdminTypesDescriptionEnum.InjuryType;
      break;
    case AdminTypesEnum.MainProviderReason:
      desc = AdminTypesDescriptionEnum.MainProviderReason;
      break;
    case AdminTypesEnum.TreatmentType:
      desc = AdminTypesDescriptionEnum.TreatmentType;
      break;
    default:
      desc = null;
      break;
  }

  return desc;
}

export function GetAllAdminTypeTypes(): AdminTypeType[] {
  const adminTypes = [... new Set(Object.values(AdminTypesEnum).filter((v) => !isNaN(Number(v))))];

  let adminTypeTypes: AdminTypeType[] = [];

  adminTypes.forEach((adminType) => {
    const adminTypeType: AdminTypeType = {
      id: +adminType,
      description: GetAdminTypeTypeDescription(+adminType)
    }
    adminTypeTypes.push(adminTypeType);
  });

  adminTypeTypes.sort((a, b) => {
    const textA = a.description.toUpperCase();
    const textB = b.description.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });

  return adminTypeTypes;
}

export enum EntityTypeEnum {
  NONE = 0,
  COMPANY = 1,
  COMPANY_CONTACT = 2,
  CONTACT = 3,
  EMPLOYEE = 4,
  VENDOR = 5,
  SITE = 6,
  DEBTOR = 7,
  CREDITOR = 8,
  VENDOR_CONTACT = 9,
  SYSTEM_USER = 10,
  DEBTOR_CONTACT = 11,
  INVOICE = 12,
  DOCUMENT = 13,
  BUSINESS_PAYMENT = 14,
  CASH_SALE = 15,
  DEBTOR_STATEMENT = 16,
  CREDITOR_PAYMENT = 17,
  UPLOAD_FILE = 19,
  DEBTOR_STATEMENT_BATCH = 20,
  DYNAMIC_FORM_ENTRY = 21,
  DYNAMIC_GROUP_ENTRY = 22,
  EXPENSE = 23,
  DEBTOR_SITE = 24,
  DHB = 25,

  //Appt
  CLIENT = 1000,
  PROVIDER = 1001,
  EMPLOYER = 1002,
  CLIENT_APPOINTMENT = 1003,
  REFERRER = 1004,
  CLASS_APPOINTMENT = 1005,
  CLIENT_SELF = 1006,
  APPOINTMENT = 1007,

  //Physio
  P_CONTRACT_APPOINTMENT = 2000,
  EXAM = 2001,
  ABP_CONTRACT = 2002,
  CUSTOM_CONTRACT = 2003,
  CLIENT_CONDITION = 2004,
  HISO_FORM = 2005,
  INSURER_SCHEDULE = 2006,
  ASSESSMENT = 2007,
  CONTRACT_CONTACT = 2008,
}

export enum EntityTypeDetailEnum {
  NONE = "",
  COMPANY = "Company",
  DEBTOR = "Debtor",
  SYSTEM_USER = "System User",
  CLIENT = "Client",
  EMPLOYER = "Employer",
  REFERRER = "Referrer",
  CLIENT_SELF = "Client Self Referred",
}

export enum EventTypeEnum {
  Custom = 0,
  PhoneIn = 1,
  PhoneOut = 2,
  EmailIn = 3,
  EmailOut = 4,
  FaxIn = 5,
  FaxOut = 6,
  ActionDocument = 7,
  SMSIn = 8,
  SMSOut = 9,
  FilledInForm = 10,
  ClientNotes = 101,
}

export enum EventTypeDescriptionEnum {
  Custom = "Custom",
  PhoneIn = "Phone In",
  PhoneOut = "Phone Out",
  EmailIn = "Email In",
  EmailOut = "Email Out",
  FaxIn = "Fax In",
  FaxOut = "Fax Out",
  ActionDocument = "Action Document",
  SMSIn = "SMS In",
  SMSOut = "SMS Out",
  FilledInForm = "Filled In Form",
  ClientNotes = "Client Notes",
}

export enum PhysioFullHistoryType {
  ACC32 = 1,
  Appointment = 2,
  Condition = 3,
  Discharge = 4,
  Event = 5,
  Invoice = 6,
  Letter = 7,
  Upload = 8,
  HLMessage = 9,
  Exam = 10,
  Payment = 11,
  Statement = 12,
  Medication = 13,
  Investigation = 14,
  FormEntry = 15,
  MedicalNote = 16,
  ClassHistory = 17,
  CashSale = 18,
  SMS = 19,
  ContractAppointmentProduct = 20,
  ClientAppointmentProduct = 21,
  BusinessInvoice = 22,
  MedicareOnline = 23,
  ConditionReview = 24
}

export function GetEventTypeDescription(eventType: number): string {
  let desc: string;

  switch (eventType) {
    case EventTypeEnum.Custom:
      desc = EventTypeDescriptionEnum.Custom;
      break;
    case EventTypeEnum.PhoneIn:
      desc = EventTypeDescriptionEnum.PhoneIn;
      break;
    case EventTypeEnum.PhoneOut:
      desc = EventTypeDescriptionEnum.PhoneOut;
      break;
    case EventTypeEnum.EmailIn:
      desc = EventTypeDescriptionEnum.EmailIn;
      break;
    case EventTypeEnum.EmailOut:
      desc = EventTypeDescriptionEnum.EmailOut;
      break;
    case EventTypeEnum.FaxIn:
      desc = EventTypeDescriptionEnum.FaxIn;
      break;
    case EventTypeEnum.FaxOut:
      desc = EventTypeDescriptionEnum.FaxOut;
      break;
    case EventTypeEnum.ActionDocument:
      desc = EventTypeDescriptionEnum.ActionDocument;
      break;
    case EventTypeEnum.SMSIn:
      desc = EventTypeDescriptionEnum.SMSIn;
      break;
    case EventTypeEnum.SMSOut:
      desc = EventTypeDescriptionEnum.SMSOut;
      break;
    case EventTypeEnum.FilledInForm:
      desc = EventTypeDescriptionEnum.FilledInForm;
      break;
    case EventTypeEnum.ClientNotes:
      desc = EventTypeDescriptionEnum.ClientNotes;
      break;
    default:
      desc = null;
      break;
  }

  return desc;
}

export enum AssessmentOwnerType {
  Condition = 1,
  AbpContract = 2,
  Contract = 3,
  Exam = 4
}

export enum PhysioProviderType {
  Physiotherapist = 1,
  Osteopath = 2,
  Podiatrist = 3,
  Acupuncturist = 4,
  Chiropractor = 5,
  PersonalTrainer = 6,
  Any = 7,
  Handtherapist = 8,
  GP = 9,
  Specialist = 10,
  ExercisePhysiologist = 11,
  MassageTherapist = 12,
  Psychologist = 13,
  OccupationalTherapist = 14,
  PhysiotherapistEpn = 15,
  DiabetesEducator = 16,
  Dietitian = 17,
  Counselor = 18,
  RemedialMassage = 19,
  Pharmacist = 20,
  SpeechPathology = 21,
  SpeechTherapist = 22,
  PhysioSpecialist = 23
}

export enum CountedToEpnType
{
  NonEpn = 0,
  Epn = 1,
  PhySpecialist = 2,
  AlliedHealth = 3,
  AlliedHealthPhySpecialist = 4,
  MbiAlliedPhysio = 5
}

export enum PhysioApptCountedToType
{
    Condition = 0,
    Acc32 = 1,
    PostSurgery = 2
}

export class PhysioProviderTypeEnum {
  public static ProviderTypeEnumDescriptions = [
    { value: PhysioProviderType.Physiotherapist, label: "Physiotherapist" },
    { value: PhysioProviderType.Osteopath, label: "Osteopath" },
    { value: PhysioProviderType.Podiatrist, label: "Podiatrist" },
    { value: PhysioProviderType.Acupuncturist, label: "Acupuncturist" },
    { value: PhysioProviderType.Chiropractor, label: "Chiropractor" },
    { value: PhysioProviderType.PersonalTrainer, label: "Personal Trainer" },
    { value: PhysioProviderType.Handtherapist, label: "Hand Therapist" },
    { value: PhysioProviderType.GP, label: "General Practitioner" },
    { value: PhysioProviderType.Specialist, label: "Specialist" },
    {
      value: PhysioProviderType.ExercisePhysiologist,
      label: "Exercise Physiologist"
    },
    { value: PhysioProviderType.MassageTherapist, label: "Massage Therapist" },
    { value: PhysioProviderType.Psychologist, label: "Psychologist" },
    {
      value: PhysioProviderType.OccupationalTherapist,
      label: "Occupational Therapist"
    },
    {
      value: PhysioProviderType.PhysiotherapistEpn,
      label: "Physiotherapist - EPN"
    },
    { value: PhysioProviderType.DiabetesEducator, label: "Diabetes Educator" },
    { value: PhysioProviderType.Dietitian, label: "Dietitian" },
    { value: PhysioProviderType.Counselor, label: "Counselor" },
    { value: PhysioProviderType.RemedialMassage, label: "Remedial Massage" },
    { value: PhysioProviderType.Pharmacist, label: "Pharmacist" },
    { value: PhysioProviderType.SpeechPathology, label: "Speech Pathology" },
    { value: PhysioProviderType.SpeechTherapist, label: "Speech Therapist" },
    { value: PhysioProviderType.PhysioSpecialist, label: "Physio Specialist" },
    { value: PhysioProviderType.Any, label: "Any Clinician" }
  ];

  static getProviderTypeDescription(providerType: PhysioProviderType) {
    return this.ProviderTypeEnumDescriptions.find(x => x.value === providerType).label;
  }
}

export enum AccContractType
{
    Regulation = 1,
    Epn = 2,
    HandTherapy = 3,
    Healthwise = 4,
    ClinicalServices = 5,
    CustomContract = 6,
    Ipm = 7, // Interventional Pain Management
    PhySpecialist = 8,
    AlliedHealthServices = 9,
    AlliedHealthPhySpecialist = 10,
    MBIPhysio = 11
}

export enum CSCEligibleStatus	{
  NotEligible = 0,
  Eligible = 1,
  EligibleSelfReferred = 2
}

export enum ReadCodeAvailableType
{
    AllConditions = 1,
    PrivateConditions = 2,
    ACCAndInsuredConditions = 3,
    InsuredConditions = 4
}

export enum PhysioApptAccStatus
{
    NotInSchedule = 0,
    InSchedule = 1
}