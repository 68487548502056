import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { UploadsFilterColumnService, UploadsTableService } from "./uploads-table.service";

@Component({
    selector: 'app-uploads-component',
    templateUrl: './uploads.component.html',
    styleUrls: [],
    encapsulation: ViewEncapsulation.None,
    providers: [
        UploadsFilterColumnService,
        UploadsTableService,
    ],
  })

  export class UploadsComponent implements OnInit {

    @Input() ownerId: number;

    constructor(public tableService: UploadsTableService, 
        public tableFilterService: UploadsFilterColumnService)
        {

        }
      
    ngOnInit(): void {
        this.tableService.OwnerId = this.ownerId;
    }

    onActionHandler($event){

    }
  }
