export enum GPMProductsEnum {
  GPM_NZ_TEST,
  GPM_AU_TEST,
  GPM_UK_TEST,
  GPM_NZ_LIVE,
  GPM_AU_LIVE,
  GPM_UK_LIVE,
}

export enum GPMProductPhysioCountry{
  NZ = "NZ",
  AU = "AU",  
  UK = "UK"  
}

