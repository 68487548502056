import { Component, EventEmitter, HostBinding, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BreadcrumbsService } from "@components/breadcrumbs/breadcrumbs.service";
import { EditCardStatus } from "app/components/card/card.types";
import { AppStateDBModel } from "app/core/cache/cache.types";
import { UtilService } from "app/core/services/util.service";
import { ClientDetailInitialData, ClientDetailsSideMenuActions } from "../client.types";

@Component({
  selector: "app-client-detail-info",
  templateUrl: "./client-details.component.html"
})
export class ClientDetailsComponent implements OnInit {
  @HostBinding("class") classes = "app-client-detail-info flex flex-col w-full h-full";
  @Output() closeEvent = new EventEmitter<void>();

  ClientDetailsSideMenuActions = ClientDetailsSideMenuActions;

  previousState: AppStateDBModel[] = [];

  statusForPersonalInfo: EditCardStatus;
  statusForHomeContact: EditCardStatus;

  clientId: number;
  pageTitle: string;

  isGPMNZ: boolean;

  canEditCard: boolean = true;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _breadCrumbService: BreadcrumbsService,
    private _utilService: UtilService,
  ) {
    this.statusForPersonalInfo = EditCardStatus.NORMAL;

    const params = this._activatedRoute.snapshot.params;
    this.clientId = params["clientId"];
  }

  ngOnInit(): void {
    this._breadCrumbService.addChild("Client Detail", this._router.url, null).subscribe((response) => {
      this.previousState = response;
    });
    this._activatedRoute.data.subscribe((data: any) => {
      const clientDetailInitialData = data.initialData as ClientDetailInitialData;
       const clientPersonalDetails = clientDetailInitialData.ClientDetails.PersonalDetails;
      this.pageTitle = this._utilService.getFullNameDob(clientPersonalDetails.FIRST_NAME, clientPersonalDetails.LAST_NAME, clientPersonalDetails.DOB);
    });

    this.isGPMNZ = this._utilService.isGPMNZ();
  }

  close(): void {
    this.closeEvent.emit();
    this._breadCrumbService.goBack();
  }

  navigateToPage(url: string): void {
    this._router.navigate([url]);
  }

  checkCanEditCard(event: boolean): void {
    this.canEditCard = !event;
  }
}
