import { APIService } from "app/core/api/api.service";
import { ClientEventsRequestModel, ClientRelationships, NewClientRequestModel } from "app/modules/client/client.types";
import { Operation } from "fast-json-patch";
import { BehaviorSubject, Observable, of } from "rxjs";
import { tap } from "rxjs/operators";

import { Injectable } from "@angular/core";
import { LettersRequestModel, UploadsRequestModel } from "@components/letters-and-uploads/letters-uploads.types";

import { AddressModel } from "../models/appointment/address.model";
import { BusinessPaymentModel } from "../models/appointment/business-payment.model";
import { ClientConditionModel } from "../models/appointment/client-condition.model";
import { EventModel } from "../models/appointment/event.model";
import { ExistingInvoiceModel } from "../models/appointment/existing-invoice.model";
import { ClientByNameModel } from "../models/clients-by-name.model";
import { VfDebtorChargesModel } from "../models/organisation/vf-debtor-charges.model";
import { VfDocumentsModel } from "../models/organisation/vf-documents.model";
import { VfUploadsModel } from "../models/organisation/vf-uploads.model";
import { VfWriteoffModel } from "../models/organisation/vf-writeoffs.model";
import { PhysioAppointmentModel } from "../models/physio/physio-appointment.model";
import { PhysioClientFullHistoryModel } from "../models/physio/physio-client-full-history.model";
import { ClientDetailsDataModel } from "./models/client-details-data.model";
import { ClientHeaderDataModel } from "./models/client-header-data.model";
import { ClientPersonalDetailsModel } from "./models/client-personal-details.model";
import { ClientModel } from "./models/client.model";
import { ContactDetailsModel } from "./models/contact-details.model";
import { PatchClientResponseModel } from "./models/patch-client-response.model";
import { VFFindClientModel } from "./models/vf-find-client.model";
import { VfClientMembershipsModel } from "../models/appointment/vf-client-memberships.model";
import { VfClientMembershipApptsModel } from "../models/appointment/vf-client-membership-appts.model";
import { ResultsModel } from "../models/results.model";
import { AdvancedSearchRequestModel } from "app/modules/client-list/client-list.types";
import { HighlighModel } from "../models/highlight-model";
import { ClientGroupingModel } from "./models/client-grouping.model";
import { ClientGroupingClientModel } from "./models/client-grouping-client.model";

@Injectable({ providedIn: "root" })
export class ClientService {
  private _selectedClient: BehaviorSubject<ClientDetailsDataModel | null> = new BehaviorSubject(null);

  private _selectedHeaderClient: BehaviorSubject<ClientByNameModel>;

  get selectedClient(): ClientDetailsDataModel {
    return this._selectedClient.value;
  }

  constructor(private _apiService: APIService) {
    this._selectedHeaderClient = new BehaviorSubject<ClientByNameModel>(null);
  }

  // get selected client
  getSelectedHeaderClient(): Observable<ClientByNameModel> {
    return this._selectedHeaderClient.asObservable();
  }

  // set
  setSelectedHeaderClient(client: ClientByNameModel) {
    this._selectedHeaderClient.next(client);
  }

  public getClientHeaderData(clientId: number): Observable<ClientHeaderDataModel> {
    return this._apiService.get(`/clients?command=header&id=${clientId}`);
  }

  public getClientById(clientId: number): Observable<ClientByNameModel> {
    return this._apiService.get(`/clients?command=getClientById&id=${clientId}`);
  }

  public getClientDetailsByConditionId(conditionId: number): Observable<ClientPersonalDetailsModel> {
    return this._apiService.get(`/clients?command=clientDetailsByConditionId&id=${conditionId}`);
  }

  public getClientDocuments(model: LettersRequestModel): Observable<VfDocumentsModel[]> {
    return this._apiService.get(
      `/clients?command=clientDocuments&id=${model.ownerId}&DateFrom=${model.dateFrom}&DateTo=${model.dateTo}&ActionedToId=${model.actionedToId}&LetterTemplateId=${model.templateId}`
    );
  }

  public getClientUploads(model: UploadsRequestModel): Observable<VfUploadsModel[]> {
    return this._apiService.get(
      `/clients?command=clientUploads&id=${model.ownerId}&DocDateFrom=${model.docDateFrom}&DocDateTo=${model.docDateTo}&UploadedDateFrom=${model.uploadedDateFrom}&UploadedDateTo=${model.uploadedDateTo}&FileTypeId=${model.fileTypeId}`
    );
  }

  public getClientRelarionships(clientId: number): Observable<ClientRelationships[]> {
    return this._apiService.get(`/clients?command=relationships&id=${clientId}`);
  }

  getClientsByName(searchTerm: string): Observable<ClientByNameModel[]> {
    return this._apiService.get(`/clients?command=search&SearchTerm=${searchTerm}`);
  }

  public getClientDetails(clientId: number, top: number, dateFrom?: string, dateTo?: string): Observable<ClientDetailsDataModel> {
    if (this.selectedClient && this.selectedClient.Client.ID === clientId) {
      return of(this.selectedClient);
    }

    let query: string = `/clients?command=details&id=${clientId}&Top=${top}`;
    if (dateFrom) {
      query += `&DateFrom=${dateFrom}`;
    }

    if (dateTo) {
      query += `&DateTo=${dateTo}`;
    }

    return this._apiService.get<ClientDetailsDataModel>(query).pipe(
      tap((clientDetails) => {
        this._selectedClient.next(clientDetails);
      })
    );
  }

  public getClientPayments(
    clientId: string,
    top: number,
    dateFrom: string,
    dateTo: string,
    siteId: string
  ): Observable<Array<BusinessPaymentModel>> {
    return this._apiService.get(
      `/clients?command=payments&id=${clientId}&Top=${top}&DateFrom=${dateFrom}&DateTo=${dateTo}&SiteId=${siteId}`
    );
  }

  public getClientWriteOffs(clientId: string, dateFrom: string, dateTo: string): Observable<VfWriteoffModel[]> {
    return this._apiService.get(`/clients?command=writeoffs&id=${clientId}&DateFrom=${dateFrom}&DateTo=${dateTo}`);
  }

  public getClientInvoices(
    clientId: string,
    top: number,
    dateFrom: string,
    dateTo: string,
    siteId: string,
    invoiceNo: string,
    billingRef: string,
    paymentRef: string,
    isUnPaid: boolean,
    itemDetails: string,
    amountType: number,
    middleAmount: string,
    percentage: string
  ): Observable<Array<ExistingInvoiceModel>> {
    const parameter = `id=${clientId}&Top=${top}&DateFrom=${dateFrom}&DateTo=${dateTo}&SiteId=${siteId}&InvoiceNo=${invoiceNo}&BillingRef=${billingRef}&PaymentRef=${paymentRef}&IsUnPaid=${isUnPaid}&ItemDetails=${itemDetails}&AmountType=${amountType}&InvoiceAmount=${middleAmount}&Percentage=${percentage}`;
    return this._apiService.get(`/clients?command=invoices&${parameter}`);
  }

  public getClientStatements(clientId: string, dateFrom: string, dateTo: string): Observable<Array<ExistingInvoiceModel>> {
    return this._apiService.get(`/clients?command=statements&id=${clientId}&DateFrom=${dateFrom}&DateTo=${dateTo}`);
  }

  public getClientCharges(clientId: string, dateFrom: string, dateTo: string, showThirdParty: boolean): Observable<VfDebtorChargesModel[]> {
    return this._apiService.get(
      `/clients?command=charges&id=${clientId}&DateFrom=${dateFrom}&DateTo=${dateTo}&ShowThirdParty=${showThirdParty}`
    );
  }

  public getClientConditions(clientId: number): Observable<ClientConditionModel[]> {
    return this._apiService.get(`/clients?command=clientConditions&id=${clientId}`);
  }

  public getClientAppointments(
    clientId: number,
    dateFrom: string,
    dateTo: string,
    showCancelled: boolean,
    conditionId?: number
  ): Observable<PhysioAppointmentModel[]> {
    let query = `/clients?command=appointments&id=${clientId}&ShowCancelled=${showCancelled ?? false}`;

    if (conditionId) {
      query += `&ConditionId=${conditionId}`;
    }
    if (dateFrom) {
      query += `&DateFrom=${dateFrom}`;
    }
    if (dateTo) {
      query += `&DateTo=${dateTo}`;
    }
    return this._apiService.get(query);
  }

  public getClientFullHistory(
    clientId: number,
    dateFrom: string,
    dateTo: string,
    historyTypes: number[]
  ): Observable<PhysioClientFullHistoryModel[]> {
    let query: string = `/clients?command=fullHistory&id=${clientId}`;
    if (dateFrom) {
      query += `&DateFrom=${dateFrom}`;
    }
    if (dateTo) {
      query += `&DateTo=${dateTo}`;
    }
    if (historyTypes && historyTypes.length > 0) {
      query += `&HistoryTypes=${historyTypes}`;
    }
    return this._apiService.get(query);
  }

  public getClientMemberships(clientId: string): Observable<Array<VfClientMembershipsModel>> {
    return this._apiService.get(`/clients?command=memberships&id=${clientId}`);
  }

  public getClientMembershipsApptUsage(clientId: string): Observable<Array<VfClientMembershipApptsModel>> {
    return this._apiService.get(`/clients?command=membershipApptUsage&id=${clientId}`);
  }

  public patchClient(clientId: number, operations: any[]): Observable<PatchClientResponseModel> {
    return this._apiService.patch<PatchClientResponseModel>(`/clients?command=client&id=${clientId}`, operations).pipe(
      tap((response) => {
        if (response && this.selectedClient.Client?.ID === response.Client.ID) {
          this.selectedClient.Client = response.Client;
        }
      })
    );
  }

  public patchClientDetails(clientId: number, operations: Operation[]): Observable<ClientPersonalDetailsModel> {
    return this._apiService.patch<ClientPersonalDetailsModel>(`/clients?command=clientdetails&id=${clientId}`, operations).pipe(
      tap((clientPersonalDetails) => {
        if (clientPersonalDetails && this.selectedClient.PersonalDetails?.CLIENT_ID === clientPersonalDetails.CLIENT_ID) {
          this.selectedClient.PersonalDetails = clientPersonalDetails;
        }
      })
    );
  }

  public patchClientHomeContactDetails(clientId: number, operations: Operation[]): Observable<ContactDetailsModel> {
    return this._apiService.patch(`/clients?command=homeContactDetails&id=${clientId}`, operations);
  }

  public patchClientHomeAddress(clientId: number, operations: Operation[]): Observable<AddressModel> {
    return this._apiService.patch(`/clients?command=homeAddress&id=${clientId}`, operations);
  }

  public patchClientPostalAddress(clientId: number, operations: Operation[]): Observable<AddressModel> {
    return this._apiService.patch(`/clients?command=postalAddress&id=${clientId}`, operations);
  }

  public createClient(createClientModel: NewClientRequestModel): Observable<ClientModel> {
    return this._apiService.post(`/clients?command=createClient`, createClientModel);
  }

  public getClientEvents(getClientEventsRequest: ClientEventsRequestModel): Observable<EventModel[]> {
    const data = getClientEventsRequest;

    return this._apiService.get(
      `/events?command=getByClientId&id=${data.clientId}&Subject=${data.subject}&Details=${data.details}&RestrictActionUser=${data.restrictActionUser}&ActionUserId=${data.actionUserId}&IsActionUserTeam=${data.isActionUserTeam}&RestrictEventType=${data.restrictEventType}&IsCustomEventType=${data.isCustomEventType}&EventType=${data.eventType}&DateType=${data.dateType}&DateFrom=${data.dateFrom}&DateTo=${data.dateTo}`
    );
  }

  public getAllClients(rowsPerPage: number, pageNumber: number): Observable<VFFindClientModel[]> {
    return this._apiService.get(`/clients?command=allClients&RowsPerPage=${rowsPerPage}&PageNumber=${pageNumber}`);
  }
  public getClientsByAdvancedSearch(model: AdvancedSearchRequestModel): Observable<VFFindClientModel[]> {
    let searchQuery = `&FirstName=${model.FirstName}&LastName=${model.LastName}&NHINumber=${model.NhiNumber}&Phone=${model.Phone}&Mobile=${model.Mobile}&Email=${model.Email}&InternalRef=${model.InternalRef}&AccClaimNumber=${model.ClaimNo}`;
    if (model.DobFrom && model.DobTo) {
      searchQuery += `&DateOfBirthFrom=${model.DobFrom}&DateOfBirthTo=${model.DobTo}`;
    }
    return this._apiService.get(`/clients?command=advancedSearch${searchQuery}`);
  }

  public deleteClient(clientId: number): Observable<ResultsModel> {
    return this._apiService.delete(`/clients?command=delete&id=${clientId}`);
  }

  public mergeClients(clientIdToKeep: number, clientIdToMerge: number): Observable<ResultsModel> {
    return this._apiService.put(`/clients?command=mergeClients`, { ClientTo: clientIdToMerge, ClientFrom: clientIdToKeep });
  }

  public GetHighlightClientSelectedValues(): Observable<HighlighModel[]> {
    return this._apiService.get(`/clients?command=highlightClientSelectedValues`);
  }

  public getClientGroups(): Observable<ClientGroupingModel[]> {
    return this._apiService.get(`/clients?command=clientGroupings`);
  }

  public patchClientGroupingClient(clientGroupingClientId: number, ops: Operation[]): Observable<ClientGroupingClientModel> {
    return this._apiService.patch(`/clients?command=clientGroupingClient&id=${clientGroupingClientId}`, ops);
  }

  public createClientGroupingClient(createClientGroupingClientObject: {
    ClientGroupingId: number;
    ClientId: number;
    Details: string;
  }): Observable<ClientGroupingClientModel> {
    return this._apiService.post(`/clients?command=createClientGroupingClient`, createClientGroupingClientObject);
  }
}
