import {
  AdminTypeDBModel,
  DHBDBModel,
  EmploymentIntensityDBModel,
  EthnicGroupDBModel,
  OrganisationPhysioDetailsDBModel,
  ReferrerDBModel,
  SiteDBModel,
  SystemUserDBModel,
  TeamDBModel,
  TitleDBModel,
  VendorApptDetailsDBModel,
  VendorDBModel,
  VendorPhysioDetailsDBModel,
} from "app/core/cache/cache.types";
import { ClientDetailsDataModel } from "app/core/client/models/client-details-data.model";
import { ClientGroupingModel } from "app/core/client/models/client-grouping.model";
import { ClientByNameModel } from "app/core/models/clients-by-name.model";
import { CompanyModel } from "app/core/models/organisation/company.model";
import { EventTypeModel } from "app/core/models/physio/event-type.model";
import { OccupationModel } from "app/core/models/physio/occupation.model";
import { GetEmploymentTypesResponseModel } from "app/core/services/employment-types.service";

export enum ClientSummaryViewStatus {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CLIENT_SUMMARY = "CLIENT_SUMMARY",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CLIENT_DETAIL = "CLIENT_DETAIL",
}

export interface NewClientRequestModel {
  FirstName: string;
  LastName: string;
  DateOfBirth: Date;
  HomeSiteId: number;

  Phone?: string;
  Email?: string;
  NHINumber?: string;
  Street?: string;
  City?: string;
  State?: string;
  PostCode?: string;
  Country?: string;
}

export enum ClientDetailsSideMenuActions {
  ClientSummary = "Client Summary",
  ClientDetail = "Client Detail",
  Appointments = "Appointments",
  Events = "Events",
  Conditions = "Conditions",
  Memberships = "Memberships",
  LettersUploads = "Letters/Uploads",
  Account = "Account",
  Healthlink = "Healthlink",
  FullHistory = "Full History",
  Relationships = "Relationships",
}

export interface ClientDetailInitialData {
  Titles: TitleDBModel[];
  Sites: SiteDBModel[];
  EthnicGroups: EthnicGroupDBModel[];
  ClientDetails: ClientDetailsDataModel;
  Referrers: ReferrerDBModel[];
  Clinics: CompanyModel[];
  DiabetesRiskTypes: AdminTypeDBModel[];
  ReferralSources: AdminTypeDBModel[];
  WhyChooseUsItems: AdminTypeDBModel[];
  VendorApptDetails: VendorApptDetailsDBModel[];
  Vendors: VendorDBModel[];
  EmploymentIntensities: EmploymentIntensityDBModel[];
  VendorPhysioDetails: VendorPhysioDetailsDBModel;
  InternalSports: AdminTypeDBModel[];
  CardLevels: AdminTypeDBModel[];
  OrgPhysioDetails: OrganisationPhysioDetailsDBModel;
  VaxStatuses: AdminTypeDBModel[];
  SystemUser: SystemUserDBModel;
  ClientGroupings: ClientGroupingModel[];
  EmploymentTypesObject: GetEmploymentTypesResponseModel;
  ClientOccupation: OccupationModel;
  DHBs: DHBDBModel[];
}

export interface ClientGender {
  identifier: Gender;
  name: string;
}

export enum Gender {
  Male = "M",
  Female = "F",
}

export interface ClientEventsRequestModel {
  clientId: number;
  subject: string;
  details: string;
  restrictActionUser: boolean | null;
  actionUserId: number | null;
  isActionUserTeam: boolean | null;
  restrictEventType: boolean | null;
  isCustomEventType: boolean | null;
  eventType: number | null;
  dateType: number | null;
  dateFrom: Date | string;
  dateTo: Date | string;
}

export interface ClientEventsInitialData {
  EventTypes: EventTypeModel[];
  ActiveSystemUsers: SystemUserDBModel[];
  ActiveTeams: TeamDBModel[];
  ClientDetails:ClientByNameModel;
}

export interface ClientRelationships {
  ID: number | null;
  CLIENT_ID: number | null;
  CLIENT_NAME: string;
  RELATED_CLIENT_ID: number | null;
  RELATION_NAME: string;
  RELATED_HOME_PHONE: string;
  RELATED_WORK_PHONE: string;
  RELATED_MOBILE_PHONE: string;
  RELATED_ADDRESS: string;
  RELATIONSHIP_TYPE: number | null;
  RELATIONSHIP_TYPE_NAME: string;
  RELATIONSHIP_CUSTOM: string;
  REVERSED: number | null;
  EMERGENCY_CONTACT_IND: number | null;
  RELATED_EMAIL: string;
  ORGANISATION_ID: number | null;
}
