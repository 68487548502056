import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppStateDBModel } from 'app/core/cache/cache.types';
import { IndexedDBService } from 'app/core/cache/indexed-db.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BreadcrumbsService {
    constructor(
        private _indexedDBService: IndexedDBService,
        private _router: Router
    ) {}

    private _addParent(
        label: string,
        url: string,
        state: any
    ): Observable<number> {
        return this._indexedDBService.clearAppState(url).pipe(
            switchMap(() =>
                this._indexedDBService.addToAppState(label, url, state, true)
            ),
            map((response) => response ?? null)
        );
    }

    public addChild(
        label: string,
        url: string,
        state: any
    ): Observable<AppStateDBModel[]> {
        return this._indexedDBService.getAppStateByUrl(url).pipe(
            switchMap((response) => {
                if (response?.length > 0) {
                    return this._indexedDBService.deleteAppStateFrom(
                        response[0].id
                    );
                } else {
                    return this._indexedDBService.addToAppState(
                        label,
                        url,
                        state,
                        false
                    );
                }
            }),
            switchMap(() => this.getAppState()),
            map((response) => response ?? null)
        );
    }

    public getAppState(): Observable<AppStateDBModel[]> {
        return this._indexedDBService.getAppState().pipe(
            map((response) => {
                let state: AppStateDBModel[] = [];
                if (response[0]) {
                    state.push(response[0]);
                } else {
                    return state;
                }

                response.forEach((row) => {
                    if (row.isParent && row.id >= state[0].id) {
                        state[0] = row;
                    } else {
                        state.push(row);
                    }
                });

                return state;
            })
        );
    }

    public getAppStateByUrl(url: string): Observable<AppStateDBModel> {
        return this._indexedDBService.getAppStateByUrl(url).pipe(
            map((response) => {
                return response && response.length > 0 ? response[0] : null;
            })
        );
    }

    public clearAppState(): Observable<void> {
        return this._indexedDBService.clearAppState();
    }

    public deleteChildById(id: number): Observable<number> {
        return this._indexedDBService.deleteAppStateById(id);
    }

    public goBack(): void {
        this.getAppState().subscribe((response) => {
            if (response) {
                let url =
                    response.length >= 2
                        ? response[response.length - 2]?.url
                        : response[0]?.url;

                if (url) {
                    this._router.navigate([url]);
                }
            }
        });
    }

    public updateById(id: number, state: any): Observable<number> {
        return this._indexedDBService.updateAppStateById(id, state);
    }

    public addParent(
        label: string,
        url: string,
        state: any
    ): Observable<AppStateDBModel> {
        return this.getAppStateByUrl(url).pipe(
            switchMap((response) =>
                this._addParent(label, url, response?.state || state)
            ),
            switchMap((response) =>
                response
                    ? this._indexedDBService.getAppStateById(response)
                    : of(null)
            ),
            map((response) => response ?? null)
        );
    }
}
