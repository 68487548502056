import { CacheService } from "app/core/cache/cache.service";
import { CacheableItems } from "app/core/cache/cache.types";
import { IndexedDBService } from "app/core/cache/indexed-db.service";
import { ClientService } from "app/core/client/client.service";
import { ClientDetailsDrawerDataModel } from "app/core/client/models/client-details-drawer-data.model";
import { AdminTypesEnum } from "app/core/enums/physio-enums";
import { ClinicService } from "app/core/services/clinic.service";
import { EmploymentTypesService } from "app/core/services/employment-types.service";
import { EventsService } from "app/core/services/events.service";
import { forkJoin, Observable, of } from "rxjs";
import { map, mergeMap, tap } from "rxjs/operators";

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";

import { ClientDetailInitialData, ClientEventsInitialData } from "./client.types";
import { LoadingService } from "app/core/services/loading.service";
import { OccupationService } from "app/core/services/occupation.service";
import { ClientByNameModel } from "app/core/models/clients-by-name.model";

@Injectable({ providedIn: "root" })
export class ClientSummaryResolver implements Resolve<ClientDetailsDrawerDataModel> {
  constructor(
    private _indexedDBService: IndexedDBService,
    private _loadingService: LoadingService,
    private _clientService: ClientService
  ) {}
  resolve(route: ActivatedRouteSnapshot): Observable<ClientDetailsDrawerDataModel> {
    const clientId: number = parseInt(route.paramMap.get("clientId"), 10);

    this._loadingService.show();

    return this._clientService.getClientDetails(clientId, 1, null, null).pipe(
      mergeMap((clientDetailRes: any) => {
        const titleId = clientDetailRes?.PersonalDetails?.TITLE_ID;
        const siteId = clientDetailRes?.Client?.HOME_SITE_ID;
        return forkJoin({
          Site: siteId ? this._indexedDBService.getDBSiteById(siteId) : of(null),
          Title: titleId ? this._indexedDBService.getDBTitleById(titleId) : of(null),
        }).pipe(
          map((response: any) => {
            const clientDetails: ClientDetailsDrawerDataModel = {
              ClientDetails: clientDetailRes,
              HomeSite: response.Site,
              Title: response.Title,
            };

            return clientDetails;
          }),
          tap(() => {
            this._loadingService.hide();
          })
        );
      })
    );
  }
}

@Injectable({ providedIn: "root" })
export class ClientDetailResolver implements Resolve<ClientDetailInitialData> {
  constructor(
    private _employmentTypesService: EmploymentTypesService,
    private _occupationService: OccupationService,
    private _loadingService: LoadingService,
    private _indexedDBService: IndexedDBService,
    private _clinicService: ClinicService,
    private _clientService: ClientService,
    private _cacheService: CacheService
  ) {}
  resolve(route: ActivatedRouteSnapshot): Observable<ClientDetailInitialData> | Promise<ClientDetailInitialData> | ClientDetailInitialData {
    const clientId = +route.paramMap.get("clientId");
    this._loadingService.show();

    return this._clientService
      .getClientDetails(clientId, 1, null, null)
      .pipe(
        mergeMap((client) =>
          forkJoin({
            Titles: this._indexedDBService.getAllTitles(),
            Sites: this._indexedDBService.getAllSites(),
            Referrers: this._indexedDBService.getAllReferrers(),
            Clinics: this._clinicService.getClinics(),
            EthnicGroups: this._indexedDBService.getAllEthnicGroups(),
            ClientDetails: of(client),
            DiabetesRiskTypes: this._indexedDBService.getAllAdminTypesByType(AdminTypesEnum.DiabetesRiskType),
            ReferralSources: this._indexedDBService.getAllAdminTypesByType(AdminTypesEnum.ReferralSource),
            WhyChooseUsItems: this._indexedDBService.getAllAdminTypesByType(AdminTypesEnum.WhyChooseUs),
            VendorApptDetails: this._indexedDBService.getAllVendorApptDetails(),
            Vendors: this._indexedDBService.getAllVendors(),
            EmploymentIntensities: this._indexedDBService.getAllEmploymentIntensities(),
            VendorPhysioDetails: this._indexedDBService.getVendorPhysioDetails(),
            InternalSports: this._indexedDBService.getAllAdminTypesByType(AdminTypesEnum.InternalSportType),
            CardLevels: this._indexedDBService.getAllAdminTypesByType(AdminTypesEnum.CardLevel),
            OrgPhysioDetails: this._indexedDBService.getOrgPhysioDetails(),
            VaxStatuses: this._indexedDBService.getAllAdminTypesByType(AdminTypesEnum.VaxStatus),
            SystemUser: this._indexedDBService.getDBActiveSystemUserById(this._cacheService.get(CacheableItems.GPMSystemUserId)),
            ClientGroupings: this._clientService.getClientGroups(),
            EmploymentTypesObject: this._employmentTypesService.getEmployersByName(clientId),
            ClientOccupation: client.PersonalDetails.OCCUPATION_ID
              ? this._occupationService.getOccupationById(client.PersonalDetails.OCCUPATION_ID)
              : of(null),
            DHBs: this._indexedDBService.getAllDHBs(),
          })
        )
      )
      .pipe(
        tap(() => {
          this._loadingService.hide();
        })
      );
  }
}

@Injectable({ providedIn: "root" })
export class ClientResolver implements Resolve<ClientByNameModel> {
  constructor(private _clientService: ClientService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<ClientByNameModel> | Promise<ClientByNameModel> | ClientByNameModel {
    const clientId: number = parseInt(route.paramMap.get("clientId"), 10);
    return this._clientService.getClientById(clientId);
  }
}

@Injectable({ providedIn: "root" })
export class ClientEventsResolver implements Resolve<ClientEventsInitialData> {
  constructor(private _eventsService: EventsService, private _clientService: ClientService, private _indexedDBService: IndexedDBService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<ClientEventsInitialData> | Promise<ClientEventsInitialData> | ClientEventsInitialData {
    const clientId: number = parseInt(route.paramMap.get("clientId"), 10);
    return forkJoin({
      EventTypes: this._eventsService.getAllEventTypes(),
      ActiveSystemUsers: this._indexedDBService.getAllActiveSystemUsers(),
      ActiveTeams: this._indexedDBService.getAllActiveTeams(),
      ClientDetails: this._clientService.getClientById(clientId),
    });
  }
}
