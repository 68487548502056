import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UtilService } from 'app/core/services/util.service';
import { CellAlignment, CellConfigProvider, CellDataConfig, CellStatus, CellTextStyle, MatableContentDataChange, TextTheme } from './mat-table-cell-content.service';
import { ColumnItem, TableStatus } from './mat-table-wrapper.service';

@Component({
  selector: 'app-mat-table-cell-content-wrapper',
  templateUrl: './mat-table-cell-content.component.html',
})

export class MatTableContentCellWrapperComponent implements OnInit, OnChanges {
  @HostBinding('class') classes = 'mat-table-cell-content-wrapper';
  @HostBinding('class.one-line') oneline = true;
  @HostBinding('class.theme-ruby') themeRuby = false;
  @HostBinding('class.theme-grey') themeGrey = false;
  @HostBinding('class.underline') underline = false;

  @Input() rowElementData: any;
  @Input() colmnDefinition: ColumnItem;

  @Input() textStyle: CellTextStyle;
  @Input() cellConfigProvider: CellConfigProvider;

  @Input() requestEdit: TableStatus;

  @Output() dataChange: EventEmitter<MatableContentDataChange> = new EventEmitter();

  _textStyle: CellTextStyle;

  _dataConfig: CellDataConfig;
  _cellStatus: CellStatus;

  _data: any;

  currencyValue: string;


  constructor(private _utilService: UtilService) {
    this.currencyValue = this._utilService.getCurrencyFromLocale();
  }

  initiateDefaultTextStyle(textStyle: CellTextStyle, cellConfigProvider: CellConfigProvider): CellTextStyle {
    const defaultStyle: CellTextStyle = {
      theme: TextTheme.GREY,
      isUnderline: false,
      alignment: CellAlignment.LEFT,
      displayTooltip: false
    };

    // cell config provider is more important than celltextStyle
    const processedTextStyle = cellConfigProvider ? cellConfigProvider.getStyleConfigForCell(this.rowElementData, this.colmnDefinition) : textStyle;

    if (!processedTextStyle) {return defaultStyle;}

    if (processedTextStyle.theme)  {defaultStyle.theme = processedTextStyle.theme;}
    if (processedTextStyle.isUnderline) {defaultStyle.isUnderline = processedTextStyle.isUnderline;}
    if (processedTextStyle.alignment) {defaultStyle.alignment = processedTextStyle.alignment;}
    if (processedTextStyle.displayTooltip) {defaultStyle.displayTooltip = processedTextStyle.displayTooltip;}

    return defaultStyle;
  }

  ngOnInit(): void {
    this._textStyle = this.initiateDefaultTextStyle(this.textStyle, this.cellConfigProvider);

    this._dataConfig = !this.colmnDefinition.config ? CellDataConfig.TEXT
      : (this.colmnDefinition.config.isDate || this.colmnDefinition.config.isDateRange) ? CellDataConfig.DATE
      : this.colmnDefinition.config.isDateTime ? CellDataConfig.DATE_TIME
      : this.colmnDefinition.config.isTime ? CellDataConfig.TIME
      : this.colmnDefinition.config.isDay ? CellDataConfig.DAY
      : this.colmnDefinition.config.isYesNo ? CellDataConfig.YES_NO
      : this.colmnDefinition.config.isCurrency ? CellDataConfig.CURRENCY : CellDataConfig.TEXT;

    // set theme and underline
    this.themeGrey = this._textStyle.theme === TextTheme.GREY;
    this.themeRuby = this._textStyle.theme === TextTheme.RUBY;
    this.underline = this._textStyle.isUnderline;

    this.calculateData();
  }

  requestEditStatus(tableStatus: TableStatus): CellStatus {
    return tableStatus === TableStatus.EDIT && this.colmnDefinition.canEdit ? CellStatus.EDIT : CellStatus.VIEW;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const requestEditItemChange: boolean = changes.requestEdit && !changes.requestEdit.firstChange;
    if (requestEditItemChange) {
      // calculate
      this.calculateData();
    }
  }

  calculateData(): void {
    this._cellStatus = this.requestEditStatus(this.requestEdit);
    this._data = this.rowElementData[this.colmnDefinition.fieldName];
  }

  changeData(event: Event): void {
    const target: any = event.target;
    const newValue: any = target.value;
    this.dataChange.emit({
      columnItemDef: this.colmnDefinition,
      newRowData: newValue,
      rowData: this.rowElementData,
    });
  }
}
