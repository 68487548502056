<div class="w-full h-full">
    
    <app-title-header pageTitle={{pageTitle}}></app-title-header>
    
    <div class="workspace">

        <div class="flex flex-col sm:flex-row items-start gap-5">

            <app-client-details-side-menu class="w-full sm:w-auto" [option]="ClientDetailsSideMenuActions.Conditions" [clientId]="clientId">
            </app-client-details-side-menu>
            
            <div class="w-full">
                <div class="workspace-body">
                    <mat-card class="flex flex-col sm:flex-1 p-0">
                        <app-serverside-table
                            [isNoBorder]="true"
                            [serversideTableService]="tableService"
                            [serversideFilterService]="filterColumnService"
                            (actionEvent)="onActionEvent($event)">
                        </app-serverside-table>
                    </mat-card>
                </div>
            </div>

        </div>

    </div>

</div>