import { Routes } from "@angular/router";
import { ClientAccountsComponent } from "./client-accounts/client-accounts.component";
import { ClientDetailsComponent } from "./client-details/client-details.component";
import { ClientDocumentsComponent } from "./client-documents/client-documents.component";
import { ClientHealthlinkComponent } from "./client-healthlink/client-healthlink.component";
import { ClientAppointmentsComponent } from "./client-appointments/client-appointments.component";
import { ClientSummaryComponent } from "./client-summary/client-summary.component";
import { ClientDetailResolver, ClientEventsResolver, ClientResolver, ClientSummaryResolver } from "./client.resolver";
import { ClientEventsComponent } from "./client-events/client-events.component";
import { ClientConditionsComponent } from "./client-conditions/client-conditions.component";
import { ClientFullHistoryComponent } from "./client-full-history/client-full-history.component";
import { ClientMembershipsComponent } from "./client-memberships/client-memberships.component";
import { ClientRelationshipsComponent } from "./client-relationships/client-relationships.component";

export const clientRoutes: Routes = [
  {
    path: ":clientId/healthlink",
    component: ClientHealthlinkComponent,
    resolve: {
      initialData: ClientResolver,
    },
  },
  {
    path: ":clientId/documents",
    component: ClientDocumentsComponent,
    resolve: {
      initialData: ClientResolver,
    },
  },
  {
    path: ":clientId/client",
    component: ClientSummaryComponent,
    resolve: {
      initialData: ClientSummaryResolver,
    },
  },
  {
    path: ":clientId/summary",
    component: ClientSummaryComponent,
    resolve: {
      initialData: ClientSummaryResolver,
    },
  },
  {
    path: ":clientId/detail",
    component: ClientDetailsComponent,
    resolve: {
      initialData: ClientDetailResolver,
    },
  },
  {
    path: ":clientId/accounts",
    component: ClientAccountsComponent,
    resolve: {
      initialData: ClientResolver,
    },
  },
  {
    path: ":clientId/events",
    component: ClientEventsComponent,
    resolve: {
      initialData: ClientEventsResolver,
    },
  },
  {
    path: ":clientId/appointments",
    component: ClientAppointmentsComponent,
    resolve: {
      initialData: ClientResolver,
    },
  },
  {
    path: ":clientId/conditions",
    component: ClientConditionsComponent,
    resolve: {
      initialData: ClientResolver,
    },
  },
  {
    path: ":clientId/full-history",
    component: ClientFullHistoryComponent,
    resolve: {
      initialData: ClientResolver,
    },
  },
  {
    path: ":clientId/memberships",
    component: ClientMembershipsComponent,
    resolve: {
      initialData: ClientResolver,
    },
  },
  {
    path: ":clientId/relationships",
    component: ClientRelationshipsComponent,
    resolve: {
      initialData: ClientResolver,
    },
  },
];
