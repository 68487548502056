import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateTimeService } from 'app/core/services/datetime.service';
import { DialogInput } from '../table-serverside-column-filter.service';

@Component({
	selector: 'app-date-range-dialog-component',
	templateUrl: './date-range-dialog.component.html',
})

export class DateRangeDialogComponent implements OnInit {
	tableServerSideColumnFilterFormGroup: any;

	constructor(
		private datettimeService: DateTimeService,
		public matDialogRef: MatDialogRef<DateRangeDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogInput
	) {

	}
	ngOnInit(): void {
		const filterFrom = this.data.column && this.data.column.filterValue
			? this.data.column.filterValue.filterFrom : null;
		const filterTo = this.data.column && this.data.column.filterValue
			? this.data.column.filterValue.filterTo : null;

		this.tableServerSideColumnFilterFormGroup = new FormGroup({
			filterValueFrom: new FormControl(filterFrom),
			filterValueTo: new FormControl(filterTo)
		});
	}

	applyFilter() {
		const filterFromVal = this.tableServerSideColumnFilterFormGroup.controls.filterValueFrom.value;
		const filterToVal = this.tableServerSideColumnFilterFormGroup.controls.filterValueTo.value;

		const filterFrom = filterFromVal ? this.datettimeService.toStartOfDay(filterFromVal) : null;
		const filterTo = filterToVal ? this.datettimeService.toEndOfDay(filterToVal) : null;

		this.matDialogRef.close({
			item: this.data.column,
			filterValue: {
				filterFrom,
				filterTo,
			}
		});
	}
}
