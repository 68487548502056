<div class="table-column-container" [ngClass]="{'noborder': isNoBorder}">
	<app-table-serverside-column-filter *ngIf="this.hideFilter===false"
		(applyEvent)="applyFilter($event)" (selectedFilterEvent)="selectedFilterHandler($event)" [tableServersideFilterService]="serversideFilterService" [customFilterDialogService]="customFilterDialogService">
	</app-table-serverside-column-filter>
	<app-mat-table-wrapper (actionEvent)="actionEvent.emit($event)" (selectEvent)="selectRow($event)"
		[isNoPaginator]="isNoPaginator" [isMultipleSelection]="isMultipleSelection"
		[displayColumnConfig]="displayColumnConfig"
		[columns]="columns" [actionList]="actionList" [data]="data"
    [initialSelectedItem]="initialSelectedData"
    [editable]="editable" [editItemList]="editItemList" (dataChanged)="dataChanged.emit($event)"
    [cellConfigProvider]="cellConfigProvider" [rowConfig]="rowConfig"
    [summaryDefs]="summaryDefs">
	</app-mat-table-wrapper>
    <div *ngIf="tableStatus !=='NORMAL'" [ngClass]="{'table-status': true, 'has-paginator': !isNoPaginator}">
        {{ tableStatus === 'FETCHING' ? 'Fetching data' : (tableStatus === 'NO_DATA' ? 'No matching data' : '')}}
    </div>
</div>
