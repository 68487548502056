import { Injectable } from "@angular/core";
import { APIService } from "../api/api.service";

@Injectable({ providedIn: 'root' })
export class AddressSearchService {
    constructor(private _apiService: APIService){}

    suggestAddress(physioCountry: string, searchTerm: string) {        
        return this._apiService.get(`/address?command=getAddressSuggestion&PhysioCountry=${physioCountry}&SearchTerm=${searchTerm}`);
      }
    
      getAddressDetails(physioCountry: string, addressId: string) {
        return this._apiService.get(`/address?command=getAddressDetails&PhysioCountry=${physioCountry}&AddressId=${addressId}`);
      }
}
