import {
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { LoadingService } from "app/core/services/loading.service";
import { AppStateDBModel } from 'app/core/cache/cache.types';

@Component({
    selector: 'app-title-header',
    templateUrl: 'title-header.component.html'
})
export class TitleHeaderComponent implements OnInit {
    @HostBinding('class') classes = 'breadcrumbs';
    @Input() pageTitle: string;

    @Output() navigateTo = new EventEmitter<string>();

    isLoading: boolean;

    constructor(private _loadingService: LoadingService) {}

    ngOnInit(): void {
        this._loadingService.visible$.subscribe((response) => {
            this.isLoading = response;
        });
    }
}
