import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { CacheableItems } from "../cache/cache.types";
import { UtilService } from "../services/util.service";
import { UpdateService } from "../update/update.service";
import { Operation } from "fast-json-patch";
import { LoadingService } from "../services/loading.service";

@Injectable({
  providedIn: "root",
})
export class APIService {
  private requestOptions: { headers: HttpHeaders; observe: "body" };
  private headerDict: {
    "id-token": string;
    "session-vendor-id": string;
    "gpm-version": string;
  };

  constructor(
    private _httpClient: HttpClient,
    private _loadingService: LoadingService,
    private _utilService: UtilService,
    private _updateService: UpdateService
  ) {}

  public get<T>(url: string, additionalHeaders?: { key: string; value: string }[]): Observable<T> {
    const apiUrl = this.getAPIUrl();
    this.prepareRequest(additionalHeaders);

    return this._httpClient.get(`${apiUrl}${url}`, this.requestOptions).pipe(
      tap((response: HttpResponse<T>) => {
        //Check version
        response.headers.keys();

        const version = response.headers.get("gpm-version");

        if (response.status === 205 && !this._updateService.updating) {
          this._updateService.updateApp(version);
        } else if (!this._utilService.getGPMVersion() && version) {
          this._utilService.setGPMVersion(version);
        }
      }),
      catchError((err) => {
        console.log(err);
        this._loadingService.hide();
        throw new Error(err);
      }),
      map((response) => response.body)
    );
  }

  public post<T, S>(url: string, body: S, additionalHeaders?: { key: string; value: string }[]): Observable<T> {
    const apiUrl = this.getAPIUrl();
    this.prepareRequest(additionalHeaders);

    return this._httpClient.post(`${apiUrl}${url}`, body, this.requestOptions).pipe(
      tap((response: HttpResponse<T>) => {
        //Check version
        response.headers.keys();

        const version = response.headers.get("gpm-version");

        if (response.status === 205 && !this._updateService.updating) {
          this._updateService.updateApp(version);
        } else if (!this._utilService.getGPMVersion() && version) {
          this._utilService.setGPMVersion(version);
        }
      }),
      catchError((err) => {
        console.log(err);
        this._loadingService.hide();
        throw new Error(err);
      }),
      map((response) => response.body)
    );
  }

  public put<T, S>(url: string, body: S, additionalHeaders?: { key: string; value: string }[]): Observable<T> {
    const apiUrl = this.getAPIUrl();
    this.prepareRequest(additionalHeaders);

    return this._httpClient.put(`${apiUrl}${url}`, body, this.requestOptions).pipe(
      tap((response: HttpResponse<T>) => {
        //Check version
        response.headers.keys();

        const version = response.headers.get("gpm-version");

        if (response.status === 205 && !this._updateService.updating) {
          this._updateService.updateApp(version);
        } else if (!this._utilService.getGPMVersion() && version) {
          this._utilService.setGPMVersion(version);
        }
      }),
      catchError((err) => {
        console.log(err);
        this._loadingService.hide();
        throw new Error(err);
      }),
      map((response) => response.body)
    );
  }

  public patch<T>(url: string, operations: Operation[], additionalHeaders?: { key: string; value: string }[]): Observable<T> {
    const apiUrl = this.getAPIUrl();
    this.prepareRequest(additionalHeaders);

    return this._httpClient.patch(`${apiUrl}${url}`, operations, this.requestOptions).pipe(
      tap((response: HttpResponse<T>) => {
        //Check version
        response.headers.keys();

        const version = response.headers.get("gpm-version");

        if (response.status === 205 && !this._updateService.updating) {
          this._updateService.updateApp(version);
        } else if (!this._utilService.getGPMVersion() && version) {
          this._utilService.setGPMVersion(version);
        }
      }),
      catchError((err) => {
        console.log(err);
        this._loadingService.hide();
        throw new Error(err);
      }),
      map((response) => response.body)
    );
  }

  public delete<T>(url: string, additionalHeaders?: { key: string; value: string }[]): Observable<T> {
    const apiUrl = this.getAPIUrl();
    this.prepareRequest(additionalHeaders);

    return this._httpClient.delete(`${apiUrl}${url}`, this.requestOptions).pipe(
      tap((response: HttpResponse<T>) => {
        //Check version
        response.headers.keys();

        const version = response.headers.get("gpm-version");

        if (response.status === 205 && !this._updateService.updating) {
          this._updateService.updateApp(version);
        } else if (!this._utilService.getGPMVersion() && version) {
          this._utilService.setGPMVersion(version);
        }
      }),
      catchError((err) => {
        console.log(err);
        this._loadingService.hide();
        throw new Error(err);
      }),
      map((response) => response.body)
    );
  }

  private prepareRequest(additionalHeaders?: { key: string; value: string }[]): void {
    this.setRequestHeaders();
    this.requestOptions = {
      headers: new HttpHeaders(this.headerDict),
      observe: "response" as "body",
    };

    if (additionalHeaders?.length > 0) {
      additionalHeaders.forEach((header) => {
        this.requestOptions.headers.append(header.key, header.value);
      });
    }
  }

  private setRequestHeaders(): void {
    const gpmVersion = this._utilService.getGPMVersion();
    const idToken = localStorage.getItem(CacheableItems.AccessToken);
    const sessionVendorId = localStorage.getItem(CacheableItems.GPMSessionVendorId);

    this.headerDict = {
      "id-token": idToken ? idToken : "",
      "session-vendor-id": sessionVendorId ? sessionVendorId.toString() : "",
      "gpm-version": gpmVersion ? gpmVersion.toString() : "",
    };
  }

  private getAPIUrl(): string {
    if (!environment.production && environment.useLocalDevEnv) {
      return environment.localAPI;
    } else {
      return environment.apiUrl;
    }
  }
}
