export enum ClassAppointmentStatus {
  Confirmed = 0,
  Started = 1,
  Completed = 2,
  Cancelled = 3
}

export enum PhysioConditionType {
  ACC = 1,
  Insured = 2,
  Private = 3
}

export enum AppointmentType {
  CLIENT = 1,
  PROVIDER = 2,
  CLASS_APPOINTMENT = 3,
  QUICK_APPOINTMENT = 4,
  startFrom = 100,
  P_CONTRACT = startFrom + 0
}

export enum AppointmentControlType {
  PROVIDER = 1,
  CLASS_APPOINTMENT = 2,
  QUICK_APPOINTMENT = 3,
  startFrom = 100,
  PHYSIO = startFrom + 0,
  PERSONAL_TRAINER = startFrom + 1,
  ABP_ASSESSMENT = startFrom + 2,
  ABP_REHAB = startFrom + 3,
  P_CONTRACT = startFrom + 4
}

export enum AppointmentStatus {
    CancelledApptCharge = -6,
    CancelledSetCharge = -5,
    NoShowSetCharge = -4,
    NoShowApptCharge = -3,
    NoShowNoCharge = -2,
    CancelledNoCharge = -1,
    Booked = 0,
    Confirmed = 1,
    Arrived = 2,
    WithProvider = 3,
    Completed = 4,
    ArrivedPaid = 5,
    ArrivedComplimentary = 6,
    ViewMedicalNotes = 99
}

export function GetAppointmentStatusDescription(status: number): string {
    switch (status) {
        case +AppointmentStatus.Arrived:
            return 'Arrived';
        case +AppointmentStatus.Booked:
            return 'Booked';
        case +AppointmentStatus.CancelledNoCharge:
            return 'Cancelled (No Charge)';
        case +AppointmentStatus.CancelledApptCharge:
            return 'Cancelled (Appt Charge)';
        case +AppointmentStatus.CancelledSetCharge:
            return 'Cancelled (Set Charge)';
        case +AppointmentStatus.NoShowApptCharge:
            return 'No Show (Appt Charge)';
        case +AppointmentStatus.NoShowSetCharge:
            return 'No Show (Set Charge)';
        case +AppointmentStatus.NoShowNoCharge:
            return 'No Show (No Charge)';
        case +AppointmentStatus.Completed:
            return 'Completed';
        case +AppointmentStatus.Confirmed:
            return 'Confirmed';
        case +AppointmentStatus.WithProvider:
            return 'With Provider';
        case +AppointmentStatus.ArrivedPaid:
            return 'Arrived Paid';
        case +AppointmentStatus.ArrivedComplimentary:
            return 'Arrived Complimentary';
        default:
            return 'Unknown';
    }
    
}

export enum ChargePaidByType{
    Client = 1,
    Memebership = 2,
    Debtor = 3
}
