import { Injectable } from "@angular/core";

import { AddressModel } from "../models/appointment/address.model";
import { CacheableItems } from "app/core/cache/cache.types";

import { CacheService } from "../cache/cache.service";
import { IanaName } from "windows-iana";
import { GPMProductPhysioCountry, GPMProductsEnum } from "../enums/gpm-products.enum";
import { DateTimeService } from "./datetime.service";

@Injectable({ providedIn: "root" })
export class UtilService {
  constructor(private _cacheService: CacheService, private _dateTimeService: DateTimeService) {}

  public getLocale(): string {
    return this._cacheService.get(CacheableItems.GenLocale);
  }

  public getCurrencyFromLocale(): string {
    const locale: string = this.getLocale();
    let currency: string = "NZD";
    switch (locale) {
      case "en-AU":
        currency = "AUD";
        break;
      case "en-GB":
        currency = "GBP";
        break;
    }

    return currency;
  }

  public getGPMVersion() {
    return this._cacheService.get(CacheableItems.GPMVersion);
  }

  public setGPMVersion(gpmVersion: string): void {
    this._cacheService.set(CacheableItems.GPMVersion, gpmVersion);
  }

  public getDarkModePreference(): string {
    return this._cacheService.get(CacheableItems.GPMDarkMode);
  }

  public setDarkModePreference(scheme: string): void {
    this._cacheService.set(CacheableItems.GPMDarkMode, scheme);
  }

  public insertStringAt(originalString: string, index: number, stringToBeInserted: string): string {
    return originalString.substring(0, index) + stringToBeInserted + " " + originalString.substring(index + 1);
  }

  public firstCharToUpper(originalString: string): string {
    originalString = originalString.toLocaleLowerCase();
    return originalString.charAt(0).toLocaleUpperCase() + originalString.slice(1);
  }

  public changeTimezone(date: Date, ianaTz: IanaName[]): Date {
    const invdate = new Date(
      date.toLocaleString(`en-${ianaTz[2]}`, {
        timeZone: ianaTz[0],
      })
    );

    const diff = date.getTime() - invdate.getTime();

    return new Date(date.getTime() - diff);
  }

  public getFullName(firstName: string, lastName: string): string {
    let name = "";
    name += firstName ? firstName + " " : "";
    name += lastName ? lastName : "";
    return name;
  }

  public getFullNameDob(firstName: string, lastName: string, dob: Date) {
    const fullName = this.getFullName(firstName, lastName);
    const dateOfBirth = dob ? this._dateTimeService.FormatDate(dob.toString()) : "";
    return fullName + " " + dateOfBirth;
  }

  public getFullAddress(addressObject: AddressModel): string {
    return (
      (addressObject?.STREET_NO ? addressObject.STREET_NO + " " : "") +
      (addressObject?.SUBURB ? addressObject.SUBURB + " " : "") +
      (addressObject?.CITY ? addressObject.CITY + " " : "") +
      (addressObject?.STATE ? addressObject.STATE + " " : "") +
      (addressObject?.POST_CODE ? addressObject.POST_CODE + " " : "") +
      (addressObject?.COUNTRY ? addressObject.COUNTRY + " " : "")
    );
  }

  public getEnumValues(enumerator: any): number[] {
    const values: number[] = [];
    for (const enumMember in enumerator) {
      if (typeof enumerator[enumMember] === "number") {
        values.push(+enumerator[enumMember]);
      }

      if (typeof enumerator[enumMember] === "string") {
        values.push(enumerator[enumMember]);
      }
    }

    return values;
  }

  public isGPMNZ(): boolean {
    const gpmProduct: number = +this._cacheService.get(CacheableItems.GPMProduct);

    return gpmProduct === GPMProductsEnum.GPM_NZ_TEST || gpmProduct === GPMProductsEnum.GPM_NZ_LIVE;
  }

  public isGPMAU(): boolean {
    const gpmProduct: number = +this._cacheService.get(CacheableItems.GPMProduct);

    return gpmProduct === GPMProductsEnum.GPM_AU_TEST || gpmProduct === GPMProductsEnum.GPM_AU_LIVE;
  }

  public isGPMUK(): boolean {
    const gpmProduct: number = +this._cacheService.get(CacheableItems.GPMProduct);

    return gpmProduct === GPMProductsEnum.GPM_UK_TEST || gpmProduct === GPMProductsEnum.GPM_UK_LIVE;
  }

  public getAppPhysioCountry(): GPMProductPhysioCountry {
    if (this.isGPMNZ()) {
      return GPMProductPhysioCountry.NZ;
    } else if (this.isGPMAU()) {
      return GPMProductPhysioCountry.AU;
    } else if (this.isGPMUK()) {
      return GPMProductPhysioCountry.UK;
    }
  }

  isMobile(): boolean {
    if (navigator && navigator.userAgent && navigator.userAgent != null) {
      const strUserAgent = navigator.userAgent.toLowerCase();
      const arrMatches = strUserAgent.match(
        /(android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|iphone|ipod|ipad|macintosh|macin)/
      );
      if (arrMatches != null) {
        return true;
      }
    } // End if (navigator && navigator.userAgent)

    return false;
  }
}
