import { Observable } from "rxjs";

import { Injectable } from "@angular/core";

import { APIService } from "../api/api.service";
import { CompanyModel } from "../models/organisation/company.model";
import { CompanyByNameModel } from "../models/organisation/company-by-name.model";

@Injectable({ providedIn: "root" })
export class CompanyService {
  constructor(private _apiService: APIService) {}

  public getAllCompanies(): Observable<CompanyModel[]> {
    return this._apiService.get(`/companies?command=getAll`);
  }

  public getCompaniesByName(searchTerm: string): Observable<CompanyByNameModel[]> {
    return this._apiService.get(`/companies?command=search&SearchTerm=${searchTerm}`);
  }
}
