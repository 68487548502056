import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { fuseAnimations } from '@fuse/animations/public-api';

import { ClientByNameModel } from 'app/core/models/clients-by-name.model';

import { APIService } from 'app/core/api/api.service';
import { AddNewClientDialogService, PositionX, PositionY } from 'app/modules/client/new-client-dialog/add-new-client-dialog.service';
import { ClientService } from 'app/core/client/client.service';

import {  MatDialogRef, MatDialog, MatDialogActions, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdvancedSearchDialogComponent } from './advanced-search-dialog/advanced-search-dialog.component';

@Component({
    selector: 'search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    encapsulation: ViewEncapsulation.None,
    exportAs: 'fuseSearch',
    animations: fuseAnimations,
})
export class SearchComponent implements OnChanges, OnInit, OnDestroy {
    @Input() appearance: 'basic' | 'bar' = 'basic';
    @Input() debounce: number = 300;
    @Input() minLength: number = 4;
    @Output() search: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClientSelect: EventEmitter<any> = new EventEmitter<any>();

    opened: boolean = false;
    isScreenSmall: boolean;
    resultSets: ClientByNameModel[];
    searchControl: FormControl = new FormControl();
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _apiService: APIService,
        private _addNewClientDialogService: AddNewClientDialogService,
        private dialog: MatDialog,
        private _clientService: ClientService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Host binding for component classes
     */
    @HostBinding('class') get classList(): any {
        return {
            'search-appearance-bar': this.appearance === 'bar',
            'search-appearance-basic': this.appearance === 'basic',
            'search-opened': this.opened,
        };
    }

    /**
     * Setter for bar search input
     *
     * @param value
     */
    @ViewChild('barSearchInput')
    set barSearchInput(value: ElementRef) {
        // If the value exists, it means that the search input
        // is now in the DOM and we can focus on the input..
        if (value) {
            // Give Angular time to complete the change detection cycle
            setTimeout(() => {
                // Focus to the input element
                value.nativeElement.focus();
            });
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On changes
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        // Appearance
        if ('appearance' in changes) {
            // To prevent any issues, close the
            // search after changing the appearance
            this.close();
        }
    }

    /**
     * On init
     */
    ngOnInit(): void {
        // get client if it is selected from anywhere else in the app
        this._clientService.getSelectedHeaderClient().subscribe(client => {
            if (client) {
                this.searchControl.setValue(client);
                this._onClientSelect(client);
            }
        });  

        // Subscribe to the search field value changes
        this.searchControl.valueChanges
            .pipe(
                debounceTime(this.debounce),
                takeUntil(this._unsubscribeAll),
                map((value) => {
                    // Set the resultSets to null if there is no value or
                    // the length of the value is smaller than the minLength
                    // so the autocomplete panel can be closed
                    if (!value || value.length < this.minLength) {
                        this.resultSets = null;
                    }

                    // Continue
                    return value;
                }),
                // Filter out undefined/null/false statements and also
                // filter out the values that are smaller than minLength
                filter(value => value && value.length >= this.minLength)
            )
            .subscribe((value) => {
                this._apiService
                    .get(`/clients?command=search&SearchTerm=${value}`)
                    .subscribe((clients: ClientByNameModel[]) => {
                        // Store the result sets
                        this.resultSets = clients;

                        // Execute the event
                        this.search.next(clients);
                    });
            });

            // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(({ matchingAliases }) => {
            // Check if the screen is small
            this.isScreenSmall = !matchingAliases.includes('md');

            // Mark for check
            this._changeDetectorRef.markForCheck();
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On keydown of the search input
     *
     * @param event
     */
    onKeydown(event: KeyboardEvent): void {
        // Listen for escape to close the search
        // if the appearance is 'bar'
        if (this.appearance === 'bar') {
            // Escape
            if (event.code === 'Escape') {
                // Close the search
                this.close();
            }
        }
    }

    /**
     * Open the search
     * Used in 'bar'
     */
    @HostListener('document:keydown.control.shift.f')
    open(): void {
        // Return if it's already opened
        if (this.opened) {
            return;
        }

        // Open the search
        this.opened = true;
    }

    /**
     * Close the search
     * * Used in 'bar'
     */
    close(): void {
        // Return if it's already closed
        if (!this.opened) {
            return;
        }

        // Clear the search input
        this.searchControl.setValue('');

        // Close the search
        this.opened = false;
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    displayWith(obj?: any): string | undefined {
        return obj ? `${obj.FIRST_NAME} ${obj.LAST_NAME}` : undefined;
    }

    _onClientSelect(event: any): void {
        this.onClientSelect.next(event);
        this.close();        
    }

    createNewClient(event: Event){
        const elem: HTMLElement = event.currentTarget as HTMLElement;
        this._addNewClientDialogService.openDialogWithElement(elem, PositionX.LEFT, PositionY.BOTTOM);
        return;
    }
    
    openAdvancedSearchDialog() {
        const dialogRef = this.dialog.open(AdvancedSearchDialogComponent,{
            panelClass: 'advanced-search-dialog-panel',
            backdropClass: 'advanced-search-dialog-backdrop',
        });
    }

}
