
import * as rawSitesJsonData from './mock_sites.json';
import * as rawEventJsonData from './mock_events.json';
import * as rawProviderJsonData from './mock_providers.json';

// import * as rawSitesJsonData from './sites.json';
const currentYear = new Date().getFullYear();
const parseAdjust = (eventDate: string): Date => {
    const date = new Date(eventDate);
    return date;
};

const eventJsonData: any = rawEventJsonData as any;
let eventData: Array<any> = eventJsonData.default;

eventData = eventData.map((item) => {
    item.start = parseAdjust(item.start);
    item.end = parseAdjust(item.end);
    return item;
});

const siteJsonData: any = rawSitesJsonData as any;
const siteData: Array<any> = siteJsonData.default;

const providerJsonData: any = rawProviderJsonData as any;
const providerData: Array<any> = providerJsonData.default;


export {
    eventData,
    siteData,
    providerData,
};
