<div class="mat-dialog-header">
    <h2 mat-dialog-title>Choose a Referrer</h2>
</div>

<mat-dialog-content class="mat-typography">
    <span class="font-medium">Select the Referrer Type and then choose the Referrer:</span>

    <div class="flex flex-col">
        <ng-container *ngFor="let item of options">
            <mat-radio-button [disabled]="isLoading" [color]="'primary'" class="my-2" [value]="item.code"
                (change)="referrerTypeSelected($event)" [checked]="data.referredByType?.EntityType === item.code">
                {{item.description}}
            </mat-radio-button>
        </ng-container>
    </div>

    <ng-container *ngIf="showReferredByField">
        <div class="flex mt-4">
            <span class="mr-2">Referred By:</span>
            <mat-form-field class="w-full">
                <app-autocomplete-field class="w-full" [formControl]="referredByFC" [items]="items"
                    (isLoading)="isLoading = $event" [fetchDataFrom]="fetchItems"
                    (onOptionSelected)="referredByFC.setValue($event.option?.value || $event)">
                </app-autocomplete-field>
                <mat-hint>Start typing on the autocomplete field</mat-hint>
            </mat-form-field>
        </div>
    </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" (click)="save()" [disabled]="isLoading">
        Ok
    </button>
    <button mat-stroked-button mat-dialog-close color="primary" (click)="close()" [disabled]="isLoading">
        Cancel
    </button>
</mat-dialog-actions>