import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { SpinnerService } from 'app/core/services/spinner.service';
import { Observable } from 'rxjs';
import { DialogInput, FilterItem } from '../table-serverside-column-filter.service';

@Component({
	selector: 'app-select-dialog-component',
	templateUrl: './select-dialog.component.html',
})

export class SelectDialogComponent implements OnInit {
	filterText = '';
	tableServerSideColumnFilterFormGroup: any;

	constructor(
		private spinnerService: SpinnerService,
		public matDialogRef: MatDialogRef<SelectDialogComponent>,
		private changeDetector: ChangeDetectorRef,
		@Inject(MAT_DIALOG_DATA) public data: DialogInput
	) {
	}
	ngOnInit(): void {
		const filterValue: string = this.data.column && this.data.column.filterValue
			? this.data.column.filterValue.filterValue : null;
		this.tableServerSideColumnFilterFormGroup = new FormGroup({
			filterValue: new FormControl(filterValue),
		});

		if (this.data.serviceCallback) {
			const item: FilterItem = this.data.column as FilterItem;
			// only load data again when we don't have cached data
			if (item.config && !item.config.selectData) {
				const dataObservable: Observable<Array<any>> = this.data.serviceCallback(item, {});
				this.spinnerService.show();
				dataObservable.subscribe((result: Array<any>) => {
					item.config.selectData = result;
					this.spinnerService.hide();
					this.changeDetector.detectChanges();
				});
			}

		}
	}

	public selectedItemChanged(event: MatSelectChange): void {
		this.filterText = event.source.triggerValue;
		this.tableServerSideColumnFilterFormGroup.controls['filterValue'].setValue(
			event.value
		);
	}

	applyFilter() {
		const filterValue: string = this.tableServerSideColumnFilterFormGroup.controls['filterValue'].value;
		if (filterValue) {
			this.matDialogRef.close({
				item: this.data.column,
				filterValue: {
					filterText: this.filterText,
					filterValue,
				}
			});
		}

	}
}
