import { ClientService } from "app/core/client/client.service";
import { ClientDetailsPatchModel } from "app/core/client/models/client-details-patch.model";
import { ClientPatchModel } from "app/core/client/models/client-patch.model";
import { ClientPersonalDetailsModel } from "app/core/client/models/client-personal-details.model";
import { ClientModel } from "app/core/client/models/client.model";
import { PatchClientResponseModel } from "app/core/client/models/patch-client-response.model";
import { DebtorPatchModel } from "app/core/models/appointment/debtor-patch.model";
import { DebtorModel } from "app/core/models/appointment/debtor.model";
import { DebtorService } from "app/core/services/debtor.service";
import { compare } from "fast-json-patch";
import { forkJoin, Observable, of } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { AfterViewInit, Component, EventEmitter, HostBinding, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { EditCardStatus } from "@components/card/card.types";
import { DialogComponent } from "@components/dialog/dialog.component";
import { DialogType } from "@components/dialog/dialog.types";
import { DialogOutput } from "@components/table/table-column-filter/table-serverside-column-filter.service";
import { LoadingService } from "app/core/services/loading.service";

import { ClientDetailInitialData } from "../../../client.types";
import { HighlighModel } from "app/core/models/highlight-model";
import { ClientByNameModel } from "app/core/models/clients-by-name.model";
import { ContactDetailsModel } from "app/core/client/models/contact-details.model";

@Component({
  selector: "app-client-notes",
  templateUrl: "client-notes.component.html",
  styleUrls: ["./client-notes.component.scss"],
})
export class ClientNotesComponent implements OnInit, AfterViewInit {
  @HostBinding("class") classes = "app-client-notes flex flex-col";
  status: EditCardStatus;

  notesFC: FormControl;
  remindClientApptsFC: FormControl;
  excludeFromStatementBatchesFC: FormControl;
  includeInMailoutsFC: FormControl;
  highlightClientCheckFC: FormControl;
  highlightClientValueFC: FormControl;

  clientForm: FormGroup;
  clientDetailsForm: FormGroup;
  debtorForm: FormGroup;

  isLoading: boolean;

  clientDetailInitialData: ClientDetailInitialData;
  clientPersonalDetails: ClientPersonalDetailsModel;
  client: ClientModel;
  debtor: DebtorModel;

  patchClient: ClientPatchModel;
  patchClientDetails: ClientDetailsPatchModel;
  patchDebtor: DebtorPatchModel;
  untouchedClientFormData: any;
  untouchedClientDetailsFormData: any;
  untouchedDebtorFormData: any;
  clientDetails: ClientByNameModel;

  HighLightsLov: HighlightLovModel[] = [];

  @Output() cardBeingEdited = new EventEmitter<boolean>();
  @Input() canEdit: boolean;

  constructor(
    private _loadingService: LoadingService,
    private _activatedRoute: ActivatedRoute,
    private _clientService: ClientService,
    private _debtorService: DebtorService,
    private _formBuilder: FormBuilder,
    private _dialog: MatDialog
  ) {
    this.status = EditCardStatus.NORMAL;

    this.innitFormControls();
  }

  private innitFormControls() {
    this.notesFC = new FormControl();
    this.remindClientApptsFC = new FormControl();
    this.excludeFromStatementBatchesFC = new FormControl();
    this.includeInMailoutsFC = new FormControl();
    this.highlightClientCheckFC = new FormControl();
    this.highlightClientValueFC = new FormControl();

    this.clientForm = this._formBuilder.group({
      notes: this.notesFC,
      remindClientAppts: this.remindClientApptsFC,
      highlightClientCheck: this.highlightClientCheckFC,
      highlightClientValue: this.highlightClientValueFC,
    });

    this.clientDetailsForm = this._formBuilder.group({
      includeInMailouts: this.includeInMailoutsFC,
    });

    this.debtorForm = this._formBuilder.group({
      excludeFromStatementBatches: this.excludeFromStatementBatchesFC,
    });
  }

  ngOnInit() {
    this._loadingService.visible$.subscribe((response) => {
      this.isLoading = response;
    });

    this._activatedRoute.data.subscribe((data: any) => {
      this.clientDetailInitialData = data.initialData as ClientDetailInitialData;
      this.clientPersonalDetails = this.clientDetailInitialData.ClientDetails.PersonalDetails;
      this.client = this.clientDetailInitialData.ClientDetails.Client;
      this.debtor = this.clientDetailInitialData.ClientDetails.Debtor;

      this.setInitialData();
      this.setPatchClient(this.client);
      this.setPatchClientDetails(this.clientPersonalDetails);
      this.setPatchDebtor(this.debtor);
      this.populateLovHighLight();
      this.setClientDetailsModel(this.clientPersonalDetails, this.clientDetailInitialData.ClientDetails.HomeContactDetails);
    });
  }

  ngAfterViewInit(): void {
    this.untouchedClientFormData = this.clientForm.value;
    this.untouchedClientDetailsFormData = this.clientDetailsForm.value;
    this.untouchedDebtorFormData = this.debtorForm.value;

    this.clientForm.valueChanges.pipe(debounceTime(200)).subscribe((data) => {
      if (JSON.stringify(this.untouchedClientFormData) === JSON.stringify(data)) {
        this.clientForm.markAsPristine();
      }
    });

    this.clientDetailsForm.valueChanges.pipe(debounceTime(200)).subscribe((data) => {
      if (JSON.stringify(this.untouchedClientDetailsFormData) === JSON.stringify(data)) {
        this.clientDetailsForm.markAsPristine();
      }
    });

    this.debtorForm.valueChanges.pipe(debounceTime(200)).subscribe((data) => {
      if (JSON.stringify(this.untouchedDebtorFormData) === JSON.stringify(data)) {
        this.debtorForm.markAsPristine();
      }
    });
  }

  private setInitialData() {
    if (this.client.NOTES) {
      this.notesFC.setValue(this.client.NOTES);
    }

    if (this.client.SEND_REMINDERS_IND) {
      this.remindClientApptsFC.setValue(this.client.SEND_REMINDERS_IND === 1);
    }

    if (this.client.HIGHLIGHT_CLIENT_IND) {
      this.highlightClientCheckFC.setValue(this.client.HIGHLIGHT_CLIENT_IND === 1);
      this.highlightClientValueFC.setValue(this.HighLightsLov.find((hl) => hl.Id === this.client.HIGHLIGHT_ID));
    } else {
      this.highlightClientValueFC.setValue(this.HighLightsLov.find((hl) => hl.Id === null));
    }

    if (this.clientPersonalDetails.INCLUDE_IN_MAILOUTS) {
      this.includeInMailoutsFC.setValue(this.clientPersonalDetails.INCLUDE_IN_MAILOUTS === 1);
    }

    if (this.clientDetailInitialData.ClientDetails.Debtor.EXCLUDE_FROM_STMT_BATCH_IND) {
      this.excludeFromStatementBatchesFC.setValue(this.clientDetailInitialData.ClientDetails.Debtor.EXCLUDE_FROM_STMT_BATCH_IND === 1);
    }
  }

  private setDefaultLovHightLight(): void {
    let value: HighlightLovModel = {
      Id: null,
      Description: "Default - Red",
    };
    this.HighLightsLov.push(value);
    this.highlightClientValueFC.setValue(this.HighLightsLov.find((hl) => hl.Id === null));
  }

  private populateLovHighLight(): void {
    this._clientService.GetHighlightClientSelectedValues().subscribe((data: HighlighModel[]) => {
      this.setDefaultLovHightLight();
      data.forEach((el) => {
        let value: HighlightLovModel = {
          Id: el.ID,
          Description: el.DESCRIPTION,
        };
        this.HighLightsLov.push(value);
      });

      if (this.client.HIGHLIGHT_CLIENT_IND) {
        this.highlightClientCheckFC.setValue(this.client.HIGHLIGHT_CLIENT_IND === 1);
        this.highlightClientValueFC.setValue(this.HighLightsLov.find((hl) => hl.Id === this.client.HIGHLIGHT_ID));
      }
    });
  }

  private setPatchClient(client: ClientModel): void {
    let patchClient: ClientPatchModel = {
      NOTES: client.NOTES,
      SEND_REMINDERS_IND: client.SEND_REMINDERS_IND,
    };

    this.patchClient = patchClient;
  }

  private setPatchClientDetails(clientPersonalDetails: ClientPersonalDetailsModel): void {
    let patchClientDetails: ClientDetailsPatchModel = {
      INCLUDE_IN_MAILOUTS: clientPersonalDetails.INCLUDE_IN_MAILOUTS,
    };

    this.patchClientDetails = patchClientDetails;
  }

  private setClientDetailsModel(personalDetails: ClientPersonalDetailsModel, contactModel: ContactDetailsModel): void {
    const client: ClientByNameModel = {
      CLIENT_ID: personalDetails.ID,
      VENDOR_ID: personalDetails.VENDOR_ID,
      FIRST_NAME: personalDetails.FIRST_NAME,
      KNOWN_AS: personalDetails.KNOWN_AS,
      FIRST_NAMES: "",
      LAST_NAME: personalDetails.LAST_NAME,
      MAIDEN_NAME: personalDetails.MAIDEN_NAME,
      DOB: personalDetails.DOB,
      HOME_MOBILE: contactModel.MOBILE,
      HOME_PHONE: contactModel.PHONE,
    };
    this.clientDetails = client;
  }

  private setPatchDebtor(debtor: DebtorModel): void {
    let patchDebtor: DebtorPatchModel = {
      EXCLUDE_FROM_STMT_BATCH_IND: debtor.EXCLUDE_FROM_STMT_BATCH_IND,
    };

    this.patchDebtor = patchDebtor;
  }

  onSave(): void {
    let patchClientObservable: Observable<PatchClientResponseModel>;
    let patchClientDetailsObservable: Observable<ClientPersonalDetailsModel>;
    let patchDebtorObservable: Observable<DebtorModel>;

    if (this.clientForm.touched) {
      let clientData: ClientPatchModel = {
        NOTES: this.notesFC.value || null,
        SEND_REMINDERS_IND: this.remindClientApptsFC.value ? 1 : 0,
        HIGHLIGHT_CLIENT_IND: this.highlightClientCheckFC.value ? 1 : 0,
        HIGHLIGHT_ID: this.highlightClientCheckFC.value ? this.highlightClientValueFC.value.Id : null,
      };

      const clientPatchOperations = compare(this.patchClient, clientData, true);
      patchClientObservable =
        clientPatchOperations?.length > 0
          ? this._clientService.patchClient(this.clientDetailInitialData.ClientDetails.Client.ID, clientPatchOperations)
          : null;
    }

    if (this.clientDetailsForm.touched) {
      let clientDetailsData: ClientDetailsPatchModel = {
        INCLUDE_IN_MAILOUTS: this.includeInMailoutsFC.value ? 1 : 0,
      };

      const clientDetailsPatchOperations = compare(this.patchClientDetails, clientDetailsData, true);

      patchClientDetailsObservable =
        clientDetailsPatchOperations?.length > 0
          ? this._clientService.patchClientDetails(this.clientDetailInitialData.ClientDetails.Client.ID, clientDetailsPatchOperations)
          : null;
    }

    if (this.debtorForm.touched) {
      let debtorData: DebtorPatchModel = {
        EXCLUDE_FROM_STMT_BATCH_IND: this.excludeFromStatementBatchesFC.value ? 1 : 0,
      };

      const debtorPatchOperations = compare(this.patchDebtor, debtorData, true);

      patchDebtorObservable =
        debtorPatchOperations?.length > 0
          ? this._debtorService.patchDebtor(this.clientDetailInitialData.ClientDetails.Client.DEBTOR_ID, debtorPatchOperations)
          : null;
    }

    if (patchClientObservable || patchClientDetailsObservable || patchDebtorObservable) {
      this.clientForm.disable();
      this.clientDetailsForm.disable();
      this.debtorForm.disable();

      forkJoin({
        PatchClientResponse: patchClientObservable || of(null),
        ClientDetails: patchClientDetailsObservable || of(null),
        Debtor: patchDebtorObservable || of(null),
      }).subscribe(
        (response) => {
          if (response) {
            if (response.PatchClientResponse) {
              const patchClientResponse = response.PatchClientResponse as PatchClientResponseModel;
              if (this._clientService.selectedClient) {
                this._clientService.selectedClient.Client = patchClientResponse.Client;
                this._clientService.selectedClient.ReferredByEntity = patchClientResponse.ReferredByEntity;
              }

              this.untouchedClientFormData = this.clientForm.value;
              this.setPatchClient(patchClientResponse.Client);

              this._clientService.setSelectedHeaderClient(this.clientDetails);
            }

            if (response.ClientDetails) {
              if (this._clientService.selectedClient) {
                this._clientService.selectedClient.PersonalDetails = response.ClientDetails;
              }

              this.untouchedClientDetailsFormData = this.clientDetailsForm.value;
              this.setPatchClientDetails(response.ClientDetails);
            }

            if (response.Debtor) {
              if (this._clientService.selectedClient) {
                this._clientService.selectedClient.Debtor = response.Debtor;
              }

              this.untouchedDebtorFormData = this.debtorForm.value;
              this.setPatchDebtor(response.Debtor);
            }

            this.status = EditCardStatus.NORMAL;
            this.cardBeingEdited.emit(false);
            this.clientForm.enable();
            this.clientDetailsForm.enable();
            this.debtorForm.enable();
          }
        },
        (err) => {
          console.log(err);
          this.clientForm.enable();
          this.clientDetailsForm.enable();
          this.debtorForm.enable();
        }
      );
    }
  }

  onCancel(): void {
    if (
      JSON.stringify(this.untouchedClientFormData) !== JSON.stringify(this.clientForm.value) ||
      JSON.stringify(this.untouchedClientDetailsFormData) !== JSON.stringify(this.clientDetailsForm.value) ||
      JSON.stringify(this.untouchedDebtorFormData) !== JSON.stringify(this.debtorForm.value)
    ) {
      const config = new MatDialogConfig();
      config.data = {
        title: "Changes unsaved",
        content: "You have changes unsaved. Are you sure you want to leave?",
        type: DialogType.YES_NO,
      };
      const dialogRef = this._dialog.open(DialogComponent, config);

      dialogRef.afterClosed().subscribe((result: DialogOutput) => {
        if (!result) {
          return;
        }

        this.close();
      });
    } else {
      this.close();
    }
  }

  onEdit(): void {
    if (!this.canEdit) {
      return;
    }

    this.status = EditCardStatus.EDIT;
    this.cardBeingEdited.emit(true);
  }

  close(): void {
    this.clientForm.reset();
    this.clientDetailsForm.reset();
    this.debtorForm.reset();
    this.innitFormControls();
    this.setInitialData();

    this.status = EditCardStatus.NORMAL;
    this.cardBeingEdited.emit(false);
  }
}

export interface HighlightLovModel {
  Id: number;
  Description: string;
}
