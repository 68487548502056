import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { APIService } from '../api/api.service';
import { AdminTypeRequestModel } from "@components/dialog/admin-type/admin-type-dialog.types";
import { AdminTypeModel } from "../models/organisation/admin-type.model";

@Injectable({ providedIn: "root" })
export class AdminTypeService {
  constructor(private _apiService: APIService) { }

  public getAdminTypesByType(adminTypeType: number, excludeInactive: boolean = true): Observable<AdminTypeModel[]> {
    return this._apiService.get(`/admin-type?command=getAdminTypesByType&AdminTypeType=${adminTypeType}&ExcludeInactive=${excludeInactive}`);
  }

  public editAdminType(model: AdminTypeRequestModel): Observable<AdminTypeModel> {
    return this._apiService.post(`/admin-type?command=editAdminType&id=${model.Id}`, model);
  }

  public createAdminType(model: AdminTypeRequestModel): Observable<AdminTypeModel> {
    return this._apiService.post(`/admin-type?command=createAdminType`, model);
  }
}
