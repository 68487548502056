<form class="w-full flex flex-col gap-y-4" [formGroup]="addressForm">
    <div class="field-wrapper" [ngClass]="{'mt-11': !showSearchAddressInput && !isMobile}">
        <app-address-search *ngIf="showSearchAddressInput" (selectedChanged)="selectedChanged($event)"></app-address-search>
    </div>
    <mat-form-field class="w-full" appearance="outline">
        <mat-label>Street</mat-label>
        <input matInput [formControl]="streetFC" />
    </mat-form-field>
    <mat-form-field class="w-full" appearance="outline">
        <mat-label>Suburb</mat-label>
        <input matInput [formControl]="suburbFC" />
    </mat-form-field>
    <mat-form-field class="w-full" appearance="outline">
        <mat-label>City</mat-label>
        <input matInput [formControl]="cityFC" />
    </mat-form-field>
    <mat-form-field class="w-full" appearance="outline">
        <mat-label>State</mat-label>
        <input matInput [formControl]="stateFC" />
    </mat-form-field>
    <mat-form-field class="w-full" appearance="outline">
        <mat-label>PostCode</mat-label>
        <input matInput [formControl]="postCodeFC" />
    </mat-form-field>
    <mat-form-field class="w-full" appearance="outline">
        <mat-label>Country</mat-label>
        <input matInput [formControl]="countryFC" />
    </mat-form-field>

    <ng-container *ngIf="dhbs && dhbs.length > 0">
        <mat-form-field class="w-full" appearance="outline">
            <mat-label>DHB</mat-label>
            <mat-select [formControl]="dhbFC">
                <mat-option *ngFor="let dhb of dhbs" [value]="dhb">{{dhb.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>
</form>