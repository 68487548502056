<app-edit-card-comp
  class="flex-1 info-section"
  [status]="status"
  (editEvent)="onEdit()"
  (saveEvent)="onSave()"
  (cancelEvent)="onCancel()"
  [disableSave]="clientForm.pristine && clientDetailsForm.pristine && debtorForm.pristine"
  [disableActions]="isLoading">
  <div class="flex md:flex-row flex-col" (click)="onEdit()">
    <mat-card-subtitle>More Details</mat-card-subtitle>

    <!-- Edit View -->
    <div class="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 flex-1" *ngIf="status === 'EDIT'">
      <!-- Notes -->
      <mat-form-field class="w-full" appearance="outline">
        <mat-label>Notes</mat-label>
        <textarea matInput [formControl]="notesFC"></textarea>
      </mat-form-field>

      <!-- Remind Client of Appts -->
      <mat-checkbox [color]="'primary'" [formControl]="remindClientApptsFC">Remind Client of Appointments? </mat-checkbox>

      <!-- Exclude from statement batches -->
      <mat-checkbox [color]="'primary'" [formControl]="excludeFromStatementBatchesFC">Exclude from Statement Batches?</mat-checkbox>

      <!-- Include in mailouts -->
      <mat-checkbox [color]="'primary'" [formControl]="includeInMailoutsFC">Include in Mailouts?</mat-checkbox>

      <!-- Highlight Client when Selected -->
      <mat-checkbox [color]="'primary'" [formControl]="highlightClientCheckFC">Highlight Client when Selected?</mat-checkbox>

      <mat-form-field class="w-full" appearance="outline" [hidden]="!highlightClientCheckFC.value">
        <mat-select [formControl]="highlightClientValueFC">
          <mat-option *ngFor="let hl of HighLightsLov" [value]="hl">{{ hl.Description }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Display View -->
    <div class="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 flex-1" *ngIf="status !== 'EDIT'">
      <div class="w-full element">
        <div class="title">Notes</div>
        <div class="info">{{ notesFC.value || "-" }}</div>
      </div>
      <div class="w-full element">
        <div class="title">Remind Client of Appointments?</div>
        <div class="info">{{ remindClientApptsFC.value ? "Yes" : "No" }}</div>
      </div>
      <div class="w-full element">
        <div class="title">Exclude from Statement Batches?</div>
        <div class="info">{{ excludeFromStatementBatchesFC.value ? "Yes" : "No" }}</div>
      </div>
      <div class="w-full element">
        <div class="title">Include in Mailouts?</div>
        <div class="info">{{ includeInMailoutsFC.value ? "Yes" : "No" }}</div>
      </div>
      <div class="w-full element">
        <div class="title">Highlight Client when Selected?</div>
        <div class="info">{{ highlightClientCheckFC.value ? "Yes" : "No" }}</div>
        <div class="info" [hidden]="!highlightClientCheckFC.value">
          {{ highlightClientValueFC.value ? highlightClientValueFC.value.Description : "" }}
        </div>
      </div>
    </div>
  </div>
</app-edit-card-comp>
