export interface HealthLinkStatus {
  key: string;
  value: string;
  count: string;
}

export const DefaultStatusCount = '...';

export const HEALTHLINK_STATUS_ALL = {
  key: '10000',
  value: 'All',
  count: DefaultStatusCount,
};
