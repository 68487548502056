<input class="hidden" (keydown.control.b)="open()" />
<!-- Bar search -->
<ng-container *ngIf="appearance === 'bar'">
    <div class="flex items-center">
        <button
            mat-icon-button
            *ngIf="!opened"
            (click)="createNewClient($event)"
            matTooltip="Add New Client"
            [matTooltipDisabled]="isScreenSmall"
            data-cy='CreateNewClient'
            >
            <mat-icon [svgIcon]="'gpm_new'"></mat-icon>
        </button>
        <button
            mat-icon-button
            *ngIf="!opened"
            (click)="open()"
            matTooltip="Search Client (Ctrl+Shift+f)"
            [matTooltipDisabled]="isScreenSmall"
            data-cy='SearchButton'
            >
            <mat-icon [svgIcon]="'heroicons_outline:search'"></mat-icon>
        </button>
    </div>
    
    
    <div
        class="absolute inset-0 flex items-center flex-shrink-0 z-99 bg-card"
        *ngIf="opened"
        @slideInTop
        @slideOutTop
    >
        <mat-icon
            class="ml-2"
            [svgIcon]="'heroicons_outline:search'"
        ></mat-icon>
        <input
            class="w-full h-full px-4 sm:px-8"
            [formControl]="searchControl"
            [matAutocomplete]="matAutocomplete"
            [placeholder]="'Search for a client'"
            (keydown)="onKeydown($event)"
            #barSearchInput
            data-cy='SearchInput'
        />
        <mat-autocomplete
            class="max-h-128 sm:px-2 border-t rounded-b shadow-md"
            [disableRipple]="true"
            [displayWith]="displayWith"
            (optionSelected)="_onClientSelect($event.option.value)"
            #matAutocomplete="matAutocomplete"
        >
            <mat-option
                class="
                    py-0
                    px-6
                    text-md
                    pointer-events-none
                    text-secondary
                    bg-transparent
                "
                *ngIf="resultSets && !resultSets.length"
            >
                No results found!
            </mat-option>
            <mat-option *ngFor="let client of resultSets" [value]="client">
                {{ client.FIRST_NAME }} {{ client.LAST_NAME }}
            </mat-option>
        </mat-autocomplete>
        <div class="search-buttons absolute flex items-center">
            <button class="btn-link text-base" (click)="openAdvancedSearchDialog()">Advanced <span class="sr-only sm:not-sr-only">search</span></button>
            <button
                class="
                    flex-shrink-0
                    w-10
                    h-10
                    ml-2
                    sm:ml-4
                "
                mat-icon-button
                (click)="close()"
            >
                <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
            </button>
        </div>
    </div>
</ng-container>

<!-- Contact result template -->
<ng-template #contactResult let-result>
    <div class="flex items-center">
        <div
            class="
                flex flex-shrink-0
                items-center
                justify-center
                w-8
                h-8
                rounded-full
                overflow-hidden
                bg-primary-100
                dark:bg-primary-800
            "
        >
            <img *ngIf="result.avatar" [src]="result.avatar" />
            <mat-icon
                class="m-0 icon-size-5 text-primary dark:text-primary-400"
                *ngIf="!result.avatar"
                [svgIcon]="'heroicons_outline:user-circle'"
            ></mat-icon>
        </div>
        <div class="ml-3 truncate">
            <span [innerHTML]="result.name"></span>
        </div>
    </div>
</ng-template>

<!-- Page result template -->
<ng-template #pageResult let-result>
    <div class="flex flex-col">
        <div class="truncate leading-normal" [innerHTML]="result.title"></div>
        <div class="truncate leading-normal text-sm text-secondary">
            {{ result.link }}
        </div>
    </div>
</ng-template>

<!-- Task result template -->
<ng-template #taskResult let-result>
    <div class="flex items-center">
        <ng-container *ngIf="result.completed">
            <mat-icon
                class="mr-0 text-primary dark:text-primary-400"
                [svgIcon]="'heroicons_outline:check-circle'"
            ></mat-icon>
        </ng-container>
        <ng-container *ngIf="!result.completed">
            <mat-icon
                class="mr-0 text-hint"
                [svgIcon]="'heroicons_outline:check-circle'"
            ></mat-icon>
        </ng-container>
        <div
            class="ml-3 truncate leading-normal"
            [ngClass]="{ 'line-through text-hint': result.completed }"
            [innerHTML]="result.title"
        ></div>
    </div>
</ng-template>
