import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { SharedModule } from "@shared/shared.module";
import { MatDividerModule } from "@angular/material/divider";
import { BreadcrumbsComponent } from "./breadcrumbs.component";

@NgModule({
  imports: [SharedModule, MatIconModule, MatDividerModule],
  exports: [BreadcrumbsComponent],
  declarations: [BreadcrumbsComponent],
  providers: [],
})
export class BreadcrumbsModule {}
