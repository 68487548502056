<app-edit-card-comp class="flex-1 info-section" [status]="status" (editEvent)="onEdit()"
    (saveEvent)="onSave()" (cancelEvent)="onCancel()" [disableSave]="clientDetailsForm.pristine"
    [disableActions]="isLoading">
    <div class="flex md:flex-row flex-col" (click)="onEdit()">
        <mat-card-subtitle>Carded Athlete Details</mat-card-subtitle>

        <!-- Edit View -->
        <div class="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 flex-1" *ngIf="status === 'EDIT'">
            <!-- Carded Athlete Number -->
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>Carded Athlete Number</mat-label>
                <input matInput [formControl]="cardedAthleteNumberFC" />
            </mat-form-field>

            <!-- Internal Sport -->
            <ng-container *ngIf="showInternalSport">
                <mat-form-field class="w-full" appearance="outline">
                    <mat-label>Internal Sport</mat-label>
                    <mat-select [formControl]="internalSportFC">
                        <mat-option *ngFor="let sport of internalSports" [value]="sport">{{ sport.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>

            <!-- Card Level -->
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>Card Level</mat-label>
                <mat-select [formControl]="cardLevelFC">
                    <mat-option *ngFor="let cardLevel of cardLevels" [value]="cardLevel">{{ cardLevel.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Allow HPSNZ Export -->
            <ng-container *ngIf="showAllowHPSNZExport">
                <mat-checkbox [color]="'primary'" [formControl]="allowHPSNZExportFC">Allow HPSNZ Export?
                </mat-checkbox>
            </ng-container>
        </div>

        <!-- Display View -->
        <div class="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 flex-1" *ngIf="status !== 'EDIT'">
            <div class="w-full element">
                <div class="title">Carded Athlete Number</div>
                <div class="info">{{ cardedAthleteNumberFC.value || "-" }}</div>
            </div>
            <ng-container *ngIf="showInternalSport">
                <div class="w-full element">
                    <div class="title">Internal Sport</div>
                    <div class="info">{{ internalSportFC.value?.name || "-" }}</div>
                </div>
            </ng-container>
            <div class="w-full element">
                <div class="title">Card Level</div>
                <div class="info">{{ cardLevelFC.value?.name || "-" }}</div>
            </div>
            <ng-container *ngIf="showAllowHPSNZExport">
                <div class="w-full element">
                    <div class="title">Allow HPSNZ Export?</div>
                    <div class="info">{{ allowHPSNZExportFC.value ? 'Yes' : 'No' }}</div>
                </div>
            </ng-container>
        </div>
    </div>
</app-edit-card-comp>