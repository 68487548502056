import { TemplateRef } from "@angular/core";
import { Observable } from "rxjs";
import { CellAlignment, CellTextStyle } from './mat-table-cell-content.service';

// reserved key for master group which all the belonged columns will be at the root level
export const MASTER_GROUP_ID = 'master_group_id';
export const COLUMN_ALL = 'ALL_COLUMN'; // special fields to indicate all object is passed to table-cell

export enum TableActionType {
  VIEW,
  EDIT,
  DOWNLOAD,
  DELETE,
  WARNING,
}

export interface TableItemAction {
  actionType: TableActionType;
  label: string;
}

export interface ColumnItem {
  fieldName: string;  // filedName to access data for displaying
  displayName: string; // display name in the column headerisSorting
  pipe?: string;  // use later to replaced for config
  config?: any; // config for data displaying
  isSorting?: boolean;  // this columns can be sorted;
  canEdit?: boolean;  // indicate the columns can be edit
  width?: number;     // indicate width of columns
  order?: number;
  textStyle?: CellTextStyle;
}

export interface ColumnGroup {
  groupID: string;
  groupName: string;
  columnNames: Array<string>;
}

export interface ColumnItemDisplay extends ColumnItem {
  isAdded: boolean;
}

export interface ColumnGroupDisplay extends ColumnGroup {
  columns: Array<ColumnItemDisplay>;
  isAdded: boolean;
}

export interface ColumnGroupConfig {
  defaultColumns: Array<string>;  // default columns to display
  groupColumnDefinition: Array<ColumnGroup>;  // group definition
  displayGroup: Array<string>;    // group wanna to display
}

export interface SelectedAdditionalColumns{
  tableUrl: string;
  selectedColumns: string[]; 
}

const flatFun = function(data: any[]): any[] {
    return data.reduce((acc: any[], val: any) => acc.concat(val), []);
};

export const ColumnGroupHelper = {
  getColumnSettingList: (columnGroupConfig: ColumnGroupConfig, columnDefinition: Array<ColumnItem>): Array<ColumnItemDisplay | ColumnGroupDisplay> => {
    if (!columnGroupConfig || !columnGroupConfig.displayGroup || !columnGroupConfig.groupColumnDefinition) {return [];}
    // get all master column
    const hasMaterGroupNeedToDisplay: boolean = !!columnGroupConfig.displayGroup.find(item => item === MASTER_GROUP_ID);
    const masterGroup: ColumnGroup = hasMaterGroupNeedToDisplay ? columnGroupConfig.groupColumnDefinition.find(item => item.groupID === MASTER_GROUP_ID) : null;    
    const masterColumns: Array<ColumnItemDisplay> = !masterGroup ? []
      : masterGroup.columnNames.map(item => columnDefinition
        .find(column => column.fieldName === item))
        .filter(columnItem => !!columnItem)
        .map(item => ({
            ...item,
            isAdded: false
          }));

    // all column for all group
    const groupColumnList: Array<ColumnGroupDisplay> = columnGroupConfig.displayGroup
      .map(groupName => columnGroupConfig.groupColumnDefinition.find(group => group.groupID === groupName))
      .filter(groupItem => !!groupItem && groupItem.groupID !== MASTER_GROUP_ID)
      .map((groupItem) => {
        const columns: Array<ColumnItemDisplay> = groupItem.columnNames
          .map(name => columnDefinition
          .find(columnDef => columnDef.fieldName === name))
          .filter(item => !!item)
          .map(item => ({
              ...item,
              isAdded: false
            }));

        return {
          ...groupItem,
          columns,
          isAdded: false
        };
      })
      .filter(item => item.columns.length > 0);

    return masterColumns.concat(groupColumnList as Array<any>);
  },

  isColumnGroup: (data: any): boolean => data instanceof Object && 'groupID' in data,

  findColumnGroupNameSelected: (list: Array<ColumnItemDisplay | ColumnGroupDisplay>): Array<string> => {
    const allColumns: Array<Array<ColumnItemDisplay>> = list.filter(item => !!item.isAdded).map((item: ColumnItemDisplay | ColumnGroupDisplay) => ColumnGroupHelper.isColumnGroup(item) ? (item as ColumnGroupDisplay).columns : [item as ColumnItemDisplay]);
    return flatFun(allColumns).map(item => (item as ColumnItemDisplay).fieldName);
  }
};

// Table status
export enum TableStatus {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

// Data Change Evenet
export interface TableDataChangeEvent {
  newData: Array<any>;
}

// Row config
export interface RowItemConfig {
  displayWarning?: boolean;
  isRowWarning?: (rowData: any) => boolean;
  hasAbnormalResult?: (rowData: any) => boolean;

  subRowElemengConfig?: SubRowElementConfig;
}

export interface SubRowElementConfig {
  needSubElement: boolean;
  subElementTemp?: TemplateRef<any>;
  retrieveDataForSubElement?: (rowData: any) => Observable<any>;
}

export interface RowSubData {
  row: any;
  data: any;
  dataInitiated: boolean;
}
