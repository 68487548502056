import { Injectable } from "@angular/core";
import { CellAlignment, TextTheme } from "@components/table/mat/mat-table-cell-content.service";
import { ColumnGroupConfig, ColumnItem, MASTER_GROUP_ID, TableActionType, TableItemAction } from "@components/table/mat/mat-table-wrapper.service";
import { FilterColumnService, FilterItem } from "@components/table/table-column-filter/table-serverside-column-filter.service";
import { ServerSideTableService } from "@components/table/table-serverside.service";
import { ClientService } from "app/core/client/client.service";
import { ExistingInvoiceModel } from "app/core/models/appointment/existing-invoice.model";
import { DateTimeService } from "app/core/services/datetime.service";
import { Observable, of } from "rxjs";

const CLIENT_INVOICES_FIELDS = {
  DATE: "date_to",
};

const retrieveFilterValueForNameItem = (name: string, filterItemList: Array<FilterItem>): any | null => {
  const itemFilter: FilterItem = filterItemList.find((item) => item.fieldName === name);
  return itemFilter ? itemFilter.filterValue : null;
};

@Injectable()
export class ClientStatementsTableService implements ServerSideTableService {
  public clientId: string;
  constructor(private _clientService: ClientService, private _dateTimeService: DateTimeService) {}

  getActionList(): TableItemAction[] {
    return [ ];
  }
  getColumnDefinitions(): ColumnItem[] {
    return [
      { fieldName: "EMAIL", displayName: "Email" },
      { fieldName: "SITE_NAME", displayName: "Site" },
      { fieldName: "RESTRICT_SITE", displayName: "Site Only?" },
      {
        fieldName: "DATE_COMPLETED",
        displayName: "Emailed Date",
        config: {
          isDate: true,
        },
      },
      {
        fieldName: "DATE_TO",
        displayName: "Date To",
        config: {
          isDate: true,
        },
      },           
      { fieldName: "NOTES", displayName: "Notes" },
      { 
        fieldName: "FEE_TOTAL_AMOUNT", 
        displayName: "Fee",
        config: {
          isCurrency: true,
        },        
      },
      { 
        fieldName: "CLOSING_BALANCE", 
        displayName: "Closing Balance",
        config: {
          isCurrency: true,
        } 
      },
      { 
        fieldName: "OVERDUE_30", 
        displayName: "30 Days",
        config: {
          isCurrency: true,
        }  
      },
      { 
        fieldName: "OVERDUE_60", 
        displayName: "60 Days",
        config: {
          isCurrency: true,
        } 
       },
      { 
        fieldName: "OVERDUE_90", 
        displayName: "90+ Days",
        config: {
          isCurrency: true,
        }  
      },
    ];
  }

  getDisplayColumnConfig(): ColumnGroupConfig {
    return {
        defaultColumns: [
            "SITE_NAME",
            "RESTRICT_SITE",
            "DATE_COMPLETED",
            "DATE_TO",
            "NOTES",
            "FEE_TOTAL_AMOUNT",
            "CLOSING_BALANCE"
        ],
        groupColumnDefinition: [
            {
                groupID: MASTER_GROUP_ID,
                groupName: '',
                columnNames: [
                  "EMAIL",
                  "OVERDUE_30",
                  "OVERDUE_60",
                  "OVERDUE_90"
                ],
            },
        ],
        displayGroup: [MASTER_GROUP_ID],
    };
}
  
  getData(selectedFilterItem: FilterItem[]): Observable<ExistingInvoiceModel[]> {
    const dateRangeFilterValue: any = retrieveFilterValueForNameItem(CLIENT_INVOICES_FIELDS.DATE, selectedFilterItem);

    const dateFrom: string = dateRangeFilterValue && dateRangeFilterValue.filterFrom ? this._dateTimeService.FormatDate(dateRangeFilterValue.filterFrom, 'MM/DD/yyyy') : "";

    const dateTo: Date | string = dateRangeFilterValue && dateRangeFilterValue.filterTo ? this._dateTimeService.FormatDate(dateRangeFilterValue.filterTo, 'MM/DD/yyyy') : "";

    return this._clientService.getClientStatements(this.clientId, dateFrom, dateTo);
  }
}

@Injectable()
export class ClientStatementsFilterColumnService implements FilterColumnService {
  getAllowedFilterFields(): Array<FilterItem> {
    return [
      {
        fieldName: CLIENT_INVOICES_FIELDS.DATE,
        displayName: "Statement Date",
        config: {
          isDateRange: true,
          label:"Statement Date"
        },
      },
    ];
  }

  getSelectDataForFilterItem(item: FilterItem): Observable<Array<any>> {
    return of([]);
  }

  getPageTitle(): string {
    return "Statements";
  };

  getDataButtonText(): string {
    return "Get Client Statements";
  }
  getFilterServiceUniqueName(): string {
    return "Client_Statements_Filter";
  }
}
