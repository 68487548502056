<app-edit-card-comp
  class="flex-1 personal-info info-section"
  [status]="status"
  (editEvent)="onEdit()"
  (saveEvent)="onSave()"
  (cancelEvent)="onCancel()"
  [disableSave]="clientForm.pristine && clientDetailsForm.pristine"
  [disableActions]="isLoading">
  <div class="flex md:flex-row flex-col" (click)="onEdit()">
    <mat-card-subtitle>Personal Information</mat-card-subtitle>
    <!-- Edit -->
    <div class="grid sm:grid-cols-2 grid-cols-1 gap-4 flex-1 w-full" *ngIf="status === 'EDIT'">
      <div class="w-full flex flex-col gap-4">
        <!-- Title -->
        <mat-form-field class="w-full" appearance="outline">
          <mat-label>Please Select - Title</mat-label>
          <mat-select [formControl]="titleFC">
            <mat-option [value]="-1"></mat-option>
            <mat-option *ngFor="let title of titles" [value]="title">{{ title.name }} </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- First Name -->
        <mat-form-field class="w-full" appearance="outline">
          <mat-label>First Name</mat-label>
          <input matInput [formControl]="firstNameFC" />
        </mat-form-field>

        <!-- Middle Name -->
        <mat-form-field class="w-full" appearance="outline">
          <mat-label>Middle Name</mat-label>
          <div class="flex">
            <input matInput [formControl]="middleNameFC" />
            <mat-checkbox [color]="'primary'" [formControl]="showMiddleNameFC"></mat-checkbox>
          </div>
        </mat-form-field>

        <!-- Last Name -->
        <mat-form-field class="w-full" appearance="outline">
          <mat-label>Last Name</mat-label>
          <input matInput [formControl]="lastNameFC" />
        </mat-form-field>

        <!-- Home Site -->
        <mat-form-field class="w-full" appearance="outline">
          <mat-label>Home Site</mat-label>
          <mat-select [formControl]="homeSiteFC">
            <mat-option *ngFor="let site of sites" [value]="site">{{ site.name }} </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Ethnic Group -->
        <mat-form-field class="w-full" appearance="outline">
          <mat-label>Please Select - Ethnic Group</mat-label>
          <mat-select [formControl]="ethnicGroupFC">
            <mat-option *ngFor="let ethnicGroup of ethnicGroups" [value]="ethnicGroup">{{ ethnicGroup.name }} </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Mobiler Other Ethnic Group -->
        <ng-container *ngIf="isMobile">
          <mat-form-field *ngIf="ethnicGroupFC.value?.name === 'Other'" class="w-full" appearance="outline">
            <mat-label>Other Details</mat-label>
            <input matInput [formControl]="otherEthnicGroupFC" />
          </mat-form-field>
        </ng-container>
      </div>
      <div class="w-full flex flex-col gap-4">
        <!-- Gender -->
        <mat-form-field class="w-full" appearance="outline">
          <mat-label>Please Select - Gender</mat-label>
          <mat-select [formControl]="genderFC">
            <mat-option [value]="-1"></mat-option>
            <mat-option *ngFor="let gender of clientGenders" [value]="gender">{{ gender.name }}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Known As -->
        <mat-form-field class="w-full" appearance="outline">
          <mat-label>Known As</mat-label>
          <input matInput [formControl]="knownAsFC" />
        </mat-form-field>

        <!-- Maiden Name -->
        <mat-form-field class="w-full" appearance="outline">
          <mat-label>Maiden Name</mat-label>
          <input matInput [formControl]="maidenNameFC" />
        </mat-form-field>

        <!-- DOB -->
        <mat-form-field appearance="outline" floatLabel="auto" class="w-full">
          <mat-label>Date Of Birth - {{ClientAge}} years</mat-label>
          <input matInput [matDatepicker]="dateOfBirthPicker" [formControl]="dobFC" (dateChange)="onChangeDoB()"/>
          <mat-datepicker-toggle matSuffix [for]="dateOfBirthPicker"></mat-datepicker-toggle>
          <mat-datepicker #dateOfBirthPicker></mat-datepicker>
        </mat-form-field>

        <!-- DOD -->
        <mat-form-field appearance="outline" floatLabel="auto" class="w-full">
          <mat-label>Date Of Death</mat-label>
          <input matInput [matDatepicker]="dateOfDeathPicker" [formControl]="dodFC" />
          <mat-datepicker-toggle matSuffix [for]="dateOfDeathPicker"></mat-datepicker-toggle>
          <mat-datepicker #dateOfDeathPicker></mat-datepicker>
        </mat-form-field>

        <!-- Other Ethnic Group -->
        <ng-container *ngIf="!isMobile">
          <mat-form-field *ngIf="ethnicGroupFC.value?.name === 'Other'" class="w-full" appearance="outline">
            <mat-label>Other Details</mat-label>
            <input matInput [formControl]="otherEthnicGroupFC" />
          </mat-form-field>
        </ng-container>
      </div>
    </div>

    <!-- View -->
    <div class="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 flex-1" *ngIf="status !== 'EDIT'">
      <div class="w-full element">
        <div class="title">Name</div>
        <div class="info">
          {{
            (titleFC.value?.name || "") +
              " " +
              firstNameFC.value +
              " " +
              (middleNameFC.value || "") +
              "
                    " +
              lastNameFC.value
          }}
        </div>
      </div>
      <div class="w-full element">
        <div class="title">Gender</div>
        <div class="info">{{ genderFC.value?.name || "-" }}</div>
      </div>
      <div class="w-full element">
        <div class="title">Known As</div>
        <div class="info">{{ knownAsFC.value || "-" }}</div>
      </div>
      <div class="w-full element">
        <div class="title">Maiden Name</div>
        <div class="info">{{ maidenNameFC.value || "-" }}</div>
      </div>
      <div class="w-full element">
        <div class="title">Home Site</div>
        <div class="info">{{ homeSiteFC.value?.name || "-" }}</div>
      </div>
      <div class="w-full element">
        <div class="title">Date of Birth</div>
        <div class="info">{{ dobFC.value ? (dobFC.value | formatDate) + " - " + ClientAge + " years": "-" }}</div>
      </div>
      <div class="w-full element">
        <div class="title">Date of Death</div>
        <div class="info">{{ dodFC.value ? (dodFC.value | formatDate) : "-" }}</div>
      </div>
      <div class="w-full element">
        <div class="title">Ethnic Group</div>
        <div class="info">
          {{
            ethnicGroupFC.value
              ? ethnicGroupFC.value.name !== "Other"
                ? ethnicGroupFC.value.name
                : ethnicGroupFC.value.name + " - " + otherEthnicGroupFC.value
              : "-"
          }}
        </div>
      </div>
    </div>
  </div>
</app-edit-card-comp>
