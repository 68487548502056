export enum EmailAttachmentType {
    Letter = 1,
    Upload = 2,
    Report = 3,
    LocalFile = 4,
    Signature = 5,
    S3File = 6,
    // Physio
    MedicalNotes = 2001,
    CustomFormEntry = 2002
  }

  export enum SystemFileType {
    UploadFile,
    Template,
    Document
  }

  export enum EmailSuggestContextType{
    None,
    ClientDetails,
    ContractDetails,
    ConditionDetails,
    Clinician,
    Creditor,
    Company,
    CompanyContact,
    Debtor,
    DebtorContact,
    Employer,
    Employee,
    Referrer,
    Site,
    SystemUser,
    Vendor
}

export enum EmailTemplateType {
  // Organisation
  InvoiceRecovery = 1,
  BusinessPayment = 2,
  CashSale = 3,
  DebtorStatement = 4,
  CreditorPayment = 5,
  Expense = 6,
  CustomForm = 7,

  // Appointment
  Client = 1001,

  AppointmentSlip = 1002,
  AppointmentReminder = 1003,

  ClientAppointment = 1004,
  TelehealthAppointmentReminder = 1005,

  // Physio
  Contract = 2001,
  Condition = 2002
}

export class EmailEnums {
  static AttachmentTypeDescription = [
    { value: EmailAttachmentType.Letter, label: "Letter" },
    { value: EmailAttachmentType.Upload, label: "Upload" },
    { value: EmailAttachmentType.Report, label: "Medical Notes" },
    { value: EmailAttachmentType.LocalFile, label: "Local File" },
    { value: EmailAttachmentType.Signature, label: "Signature" },
    { value: EmailAttachmentType.S3File, label: "S3 File" },
    { value: EmailAttachmentType.MedicalNotes, label: "Medical Notes" },
    { value: EmailAttachmentType.CustomFormEntry, label: "Custom Form Entry" }
  ];

  static EmailTemplateTypeDescription = [
    { value: EmailTemplateType.InvoiceRecovery, label: "Invoice Recovery" },
    { value: EmailTemplateType.BusinessPayment, label: "Business Payment" },
    { value: EmailTemplateType.CashSale, label: "Cash Sale" },
    { value: EmailTemplateType.DebtorStatement, label: "Debtor Statement" },
    { value: EmailTemplateType.CreditorPayment, label: "Creditor Payment" },
    { value: EmailTemplateType.Expense, label: "Expense" },
    { value: EmailTemplateType.CustomForm, label: "Custom Form" },
    { value: EmailTemplateType.Client, label: "Client" },
    { value: EmailTemplateType.AppointmentSlip, label: "Appointment Slip" },
    {
      value: EmailTemplateType.AppointmentReminder,
      label: "Appointment Reminder"
    },
    { value: EmailTemplateType.ClientAppointment, label: "Client Appointment" },
    {
      value: EmailTemplateType.TelehealthAppointmentReminder,
      label: "Telehealth Appointment Reminder"
    },
    { value: EmailTemplateType.Contract, label: "Custom Contract" },
    { value: EmailTemplateType.Condition, label: "Condition" }
  ];

  static getAttachmentTypeDescription(type: EmailAttachmentType) {
    return this.AttachmentTypeDescription.find(x => x.value === type).label;
  }

  static getEmailTemplateTypeDescription(type: EmailTemplateType) {
    return this.EmailTemplateTypeDescription.find(x => x.value === type).label;
  }
}

  