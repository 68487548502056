import { EditCardStatus } from "app/components/card/card.types";
import { DHBDBModel } from "app/core/cache/cache.types";
import { ClientService } from "app/core/client/client.service";
import { ClientDetailsPatchModel } from "app/core/client/models/client-details-patch.model";
import { ContactDetailsModel } from "app/core/client/models/contact-details.model";
import { AddressModel } from "app/core/models/appointment/address.model";
import { PatchAddressModel } from "app/core/models/appointment/patch-address.model";
import { PatchContactDetailsModel } from "app/core/models/appointment/patch-contact-details.model";
import { AddressService, CreatePostalAddressObject } from "app/core/services/address.service";
import { UtilService } from "app/core/services/util.service";
import { compare } from "fast-json-patch";
import { forkJoin, Observable, of } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { AfterViewInit, Component, EventEmitter, HostBinding, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { DialogComponent } from "@components/dialog/dialog.component";
import { DialogType } from "@components/dialog/dialog.types";
import { DialogOutput } from "@components/table/table-column-filter/table-serverside-column-filter.service";
import { LoadingService } from "app/core/services/loading.service";

import { ClientDetailInitialData } from "../../../client.types";
import { ClientPersonalDetailsModel } from "app/core/client/models/client-personal-details.model";

@Component({
  selector: "app-client-home-contact-details",
  templateUrl: "./client-home-contact-details.component.html",
  styleUrls: ["./client-home-contact-details.component.scss"],
})
export class ClientHomeContactDetailsComponent implements OnInit, AfterViewInit {
  @HostBinding("class") classes = "flex flex-col";
  @Output() cardBeingEdited = new EventEmitter<boolean>();
  @Input() canEdit: boolean;

  status: EditCardStatus;

  phoneFC: FormControl;
  faxFC: FormControl;
  mobileFC: FormControl;
  emailFC: FormControl;
  postalAddressSameAsPhysicalFC: FormControl;

  homeContactDetailsForm: FormGroup;

  isLoading: boolean;
  clientDetailInitialData: ClientDetailInitialData;
  homeContactDetails: ContactDetailsModel;
  homeAddress: AddressModel;

  postalAddress: AddressModel;
  disablePostalAddressForm: boolean;
  postalAddressFormValue: PatchAddressModel;

  patchContactDetails: PatchContactDetailsModel;
  patchHomeAddress: PatchAddressModel;
  homeAddressFormValue: PatchAddressModel;
  patchPostalAddress: PatchAddressModel;
  patchClientDetails: ClientDetailsPatchModel;

  untouchedHomeContactDetailsFormData: any;

  disableAddressForm: boolean;

  isGPMNZ: boolean;
  dhbs: DHBDBModel[];
  clientDHBId: number;
  selectedDHB: DHBDBModel;
  hideSearchAddressBox: boolean;

  constructor(
    private _loadingService: LoadingService,
    private _addressService: AddressService,
    private _activatedRoute: ActivatedRoute,
    private _clientService: ClientService,
    private _formBuilder: FormBuilder,
    private _utilService: UtilService,
    private _dialog: MatDialog
  ) {
    this.status = EditCardStatus.NORMAL;

    this.innitFormControls();
  }

  innitFormControls(): void {
    this.phoneFC = new FormControl();
    this.faxFC = new FormControl();
    this.mobileFC = new FormControl();
    this.emailFC = new FormControl();
    this.postalAddressSameAsPhysicalFC = new FormControl();

    this.homeContactDetailsForm = this._formBuilder.group({
      phone: this.phoneFC,
      fax: this.faxFC,
      mobile: this.mobileFC,
      email: this.emailFC,
      postalAddressSameAsPhysical: this.postalAddressSameAsPhysicalFC,
    });
  }

  ngOnInit(): void {
    this._loadingService.visible$.subscribe((response) => {
      this.isLoading = response;
    });

    this.isGPMNZ = this._utilService.isGPMNZ();

    this._activatedRoute.data.subscribe((data: any) => {
      this.clientDetailInitialData = data.initialData as ClientDetailInitialData;
      this.homeContactDetails = this.clientDetailInitialData.ClientDetails.HomeContactDetails;
      this.homeAddress = this.clientDetailInitialData.ClientDetails.HomeAddress;
      this.dhbs =
        this.isGPMNZ && this.clientDetailInitialData.VendorPhysioDetails[0].allowEvoIntegration ? this.clientDetailInitialData.DHBs : null;
      this.clientDHBId = this.clientDetailInitialData.ClientDetails.PersonalDetails.DHB_ID;

      this.setInitialData();

      this.setPatchContactDetails(this.homeContactDetails);
      this.setPatchAddress(this.homeAddress, false);
      this.setPatchClientDetails();
    });
  }

  ngAfterViewInit(): void {
    this.untouchedHomeContactDetailsFormData = this.homeContactDetailsForm.value;

    this.homeContactDetailsForm.valueChanges.pipe(debounceTime(200)).subscribe((data) => {
      if (JSON.stringify(this.untouchedHomeContactDetailsFormData) === JSON.stringify(data)) {
        this.homeContactDetailsForm.markAsPristine();
      }
    });
  }

  setInitialData(): void {
    if (this.homeContactDetails.PHONE) {
      this.phoneFC.setValue(this.homeContactDetails.PHONE);
    }

    if (this.homeContactDetails.FAX) {
      this.faxFC.setValue(this.homeContactDetails.FAX);
    }

    if (this.homeContactDetails.MOBILE) {
      this.mobileFC.setValue(this.homeContactDetails.MOBILE);
    }

    if (this.homeContactDetails.EMAIL) {
      this.emailFC.setValue(this.homeContactDetails.EMAIL);
    }

    this.postalAddressSameAsPhysicalFC.setValue(this.homeContactDetails.UNIQUE_POSTAL_ADDRESS_IND === 0);

    this.hideSearchAddressBox = this.homeContactDetails.UNIQUE_POSTAL_ADDRESS_IND === 0;

    if (this.homeContactDetails.UNIQUE_POSTAL_ADDRESS_IND === 1 && this.homeContactDetails.POSTAL_ADDRESS_ID > 0) {
      this.postalAddress = this.clientDetailInitialData.ClientDetails.PostalAddress;
    } else {
      this.postalAddress = this.homeAddress;
      this.disablePostalAddressForm = true;
    }

    this.setPatchAddress(this.postalAddress, true);
  }

  setPatchContactDetails(contactDetails: ContactDetailsModel): void {
    const patchContactDetails: PatchContactDetailsModel = {
      PHONE: contactDetails.PHONE,
      FAX: contactDetails.FAX,
      EMAIL: contactDetails.EMAIL,
      MOBILE: contactDetails.MOBILE,
      UNIQUE_POSTAL_ADDRESS_IND: contactDetails.UNIQUE_POSTAL_ADDRESS_IND,
    };

    this.patchContactDetails = patchContactDetails;
  }

  setPatchAddress(address: PatchAddressModel, isPostal: boolean): void {
    const patchAddress: PatchAddressModel = {
      POST_CODE: address.POST_CODE,
      STREET_NO: address.STREET_NO,
      SUBURB: address.SUBURB,
      CITY: address.CITY,
      STATE: address.STATE,
      COUNTRY: address.COUNTRY,
    };

    if (isPostal) {
      this.patchPostalAddress = patchAddress;
      return;
    }

    this.patchHomeAddress = patchAddress;
  }

  setPatchClientDetails(): void {
    const patchClientDetails: ClientDetailsPatchModel = {
      DHB_ID: this.clientDetailInitialData.ClientDetails.PersonalDetails.DHB_ID,
    };

    this.patchClientDetails = patchClientDetails;
  }

  public setAddressFormValue(event: PatchAddressModel | any): void {
    if (event) {
      this.homeAddressFormValue = event.address ?? event;
    }

    if (event.dhb) {
      this.selectedDHB = event.dhb;
    }
  }

  public setPostalAddressFormValue(event: PatchAddressModel): void {
    if (event) {
      this.postalAddressFormValue = event;
    }
  }

  onSave(): void {
    let patchClientContactDetailsObservable: Observable<PatchContactDetailsModel>;
    let patchClientAddressObservable: Observable<PatchAddressModel>;
    let patchClientPostalAddressObservable: Observable<PatchAddressModel>;
    let createClientPostalAddressObservable: Observable<AddressModel>;
    let patchClientDetailsObservable: Observable<ClientPersonalDetailsModel>;

    if (this.homeContactDetailsForm.touched) {
      const contactDetails: PatchContactDetailsModel = {
        PHONE: this.phoneFC.value || null,
        FAX: this.faxFC.value || null,
        EMAIL: this.emailFC.value || null,
        MOBILE: this.mobileFC.value || null,
        UNIQUE_POSTAL_ADDRESS_IND: this.postalAddressSameAsPhysicalFC.value ? 0 : 1,
      };

      const contactDetailsPatchOperations = compare(this.patchContactDetails, contactDetails, true);

      patchClientContactDetailsObservable =
        contactDetailsPatchOperations?.length > 0
          ? this._clientService.patchClientHomeContactDetails(
              this.clientDetailInitialData.ClientDetails.Client.ID,
              contactDetailsPatchOperations
            )
          : null;
    }

    if (this.homeAddressFormValue) {
      const address: PatchAddressModel = {
        POST_CODE: this.homeAddressFormValue.POST_CODE || null,
        STREET_NO: this.homeAddressFormValue.STREET_NO || null,
        SUBURB: this.homeAddressFormValue.SUBURB || null,
        CITY: this.homeAddressFormValue.CITY || null,
        STATE: this.homeAddressFormValue.STATE || null,
        COUNTRY: this.homeAddressFormValue.COUNTRY || null,
      };

      const clientAddressPatchOperations = compare(this.patchHomeAddress, address, true);

      patchClientAddressObservable =
        clientAddressPatchOperations?.length > 0
          ? this._clientService.patchClientHomeAddress(this.clientDetailInitialData.ClientDetails.Client.ID, clientAddressPatchOperations)
          : null;

      // DHB
      if (this.selectedDHB) {
        const patchClientDetails: ClientDetailsPatchModel = {
          DHB_ID: this.selectedDHB.id,
        };

        const clientDetailsPatchOperations = compare(this.patchClientDetails, patchClientDetails, true);

        patchClientDetailsObservable =
          clientDetailsPatchOperations?.length > 0
            ? this._clientService.patchClientDetails(this.clientDetailInitialData.ClientDetails.Client.ID, clientDetailsPatchOperations)
            : null;
      }
    }

    if (this.postalAddressFormValue && this.homeContactDetails.POSTAL_ADDRESS_ID !== null && !this.postalAddressSameAsPhysicalFC.value) {
      const postalAddress: PatchAddressModel = {
        POST_CODE: this.postalAddressFormValue.POST_CODE || null,
        STREET_NO: this.postalAddressFormValue.STREET_NO || null,
        SUBURB: this.postalAddressFormValue.SUBURB || null,
        CITY: this.postalAddressFormValue.CITY || null,
        STATE: this.postalAddressFormValue.STATE || null,
        COUNTRY: this.postalAddressFormValue.COUNTRY || null,
      };

      const clientPostalAddressPatchOperations = compare(this.patchPostalAddress, postalAddress, true);

      patchClientPostalAddressObservable =
        clientPostalAddressPatchOperations?.length > 0
          ? this._clientService.patchClientPostalAddress(
              this.clientDetailInitialData.ClientDetails.Client.ID,
              clientPostalAddressPatchOperations
            )
          : null;
    }

    if (this.postalAddressFormValue && this.homeContactDetails.POSTAL_ADDRESS_ID === null && !this.postalAddressSameAsPhysicalFC.value) {
      const newPostalAddress: CreatePostalAddressObject = {
        ClientId: this.clientDetailInitialData.ClientDetails.Client.ID,
        PostCode: "",
        PoBoxNo: this.postalAddressFormValue.STREET_NO || "",
        StreetNo: this.postalAddressFormValue.STREET_NO || "",
        Suburb: this.postalAddressFormValue.SUBURB || "",
        City: this.postalAddressFormValue.CITY || "",
        State: this.postalAddressFormValue.STATE || "",
        Country: this.postalAddressFormValue.CITY || "",
      };

      createClientPostalAddressObservable = this._addressService.createPostalAddress(newPostalAddress);
    }

    if (
      patchClientContactDetailsObservable ||
      patchClientAddressObservable ||
      patchClientPostalAddressObservable ||
      createClientPostalAddressObservable ||
      patchClientDetailsObservable
    ) {
      this.homeContactDetailsForm.disable();
      this.disablePostalAddressForm = true;
      this.disableAddressForm = true;

      forkJoin({
        PatchContactDetailsResponse: patchClientContactDetailsObservable || of(null),
        PatchAddressResponse: patchClientAddressObservable || of(null),
        PatchPostalAddressResponse: patchClientPostalAddressObservable || of(null),
        CreateClientPostalAddressResponse: createClientPostalAddressObservable || of(null),
        PatchClientDetails: patchClientDetailsObservable || of(null),
      }).subscribe({
        next: (response) => {
          if (response) {
            if (response.PatchContactDetailsResponse) {
              const patchContactDetailsResponse = response.PatchContactDetailsResponse as ContactDetailsModel;
              if (this._clientService.selectedClient) {
                this._clientService.selectedClient.HomeContactDetails = patchContactDetailsResponse;
              }
              this.untouchedHomeContactDetailsFormData = this.homeContactDetailsForm.value;
              this.setPatchContactDetails(patchContactDetailsResponse);

              this.disablePostalAddressForm = patchContactDetailsResponse.UNIQUE_POSTAL_ADDRESS_IND === 0;
            }

            if (response.PatchAddressResponse) {
              const homeAddress: AddressModel = {
                ID: this._clientService.selectedClient.HomeAddress.ID,
                POST_CODE: response.PatchAddressResponse.POST_CODE,
                PO_BOX_NO: response.PatchAddressResponse.PO_BOX_NO,
                STREET_NO: response.PatchAddressResponse.STREET_NO,
                DELETED_IND: 0,
                SUBURB: response.PatchAddressResponse.SUBURB,
                CITY: response.PatchAddressResponse.CITY,
                STATE: response.PatchAddressResponse.STATE,
                COUNTRY: response.PatchAddressResponse.COUNTRY,
                UPDATED_DATE: this._clientService.selectedClient.HomeAddress.UPDATED_DATE,
              };
              if (this._clientService.selectedClient) {
                this._clientService.selectedClient.HomeAddress = homeAddress;
              }

              this.homeAddress = homeAddress;
              this.setPatchAddress(response.PatchAddressResponse, false);
            }

            if (response.PatchPostalAddressResponse) {
              const postalAddress: AddressModel = {
                ID: this._clientService.selectedClient.HomeAddress.ID,
                POST_CODE: response.PatchPostalAddressResponse.POST_CODE,
                PO_BOX_NO: response.PatchPostalAddressResponse.PO_BOX_NO,
                STREET_NO: response.PatchPostalAddressResponse.STREET_NO,
                DELETED_IND: 0,
                SUBURB: response.PatchPostalAddressResponse.SUBURB,
                CITY: response.PatchPostalAddressResponse.CITY,
                STATE: response.PatchPostalAddressResponse.STATE,
                COUNTRY: response.PatchPostalAddressResponse.COUNTRY,
                UPDATED_DATE: this._clientService.selectedClient.HomeAddress.UPDATED_DATE,
              };

              if (this._clientService.selectedClient) {
                this._clientService.selectedClient.PostalAddress = postalAddress;
              }

              this.postalAddress = postalAddress;
              this.setPatchAddress(response.PatchPostalAddressResponse, true);

              this.disablePostalAddressForm = this.homeContactDetails.UNIQUE_POSTAL_ADDRESS_IND === 0;
            }

            if (response.CreateClientPostalAddressResponse) {
              const postalAddress = response.CreateClientPostalAddressResponse;

              if (this._clientService.selectedClient) {
                this._clientService.selectedClient.PostalAddress = postalAddress;
              }

              this.postalAddress = postalAddress;
              const patchPostalAddress: PatchAddressModel = {
                POST_CODE: postalAddress.POST_CODE,
                STREET_NO: postalAddress.STREET_NO,
                SUBURB: postalAddress.SUBURB,
                CITY: postalAddress.CITY,
                STATE: postalAddress.STATE,
                COUNTRY: postalAddress.COUNTRY,
              };
              this.setPatchAddress(patchPostalAddress, true);
            }

            if (response.PatchClientDetails) {
              if (this._clientService.selectedClient) {
                this._clientService.selectedClient.PersonalDetails = response.PatchClientDetails;
              }

              this.clientDetailInitialData.ClientDetails.PersonalDetails = response.PatchClientDetails;
              this.clientDHBId = response.PatchClientDetails.DHB_ID;
              this.setPatchClientDetails();
            }

            this.status = EditCardStatus.NORMAL;
            this.cardBeingEdited.emit(false);

            this.homeContactDetailsForm.enable();
            this.disableAddressForm = false;
          }
        },
        error: (err) => {
          console.log(err);
          this.homeContactDetailsForm.enable();
          this.disablePostalAddressForm = false;
          this.disableAddressForm = false;

          this.onCancel();
        },
      });
    }
  }

  onCancel(): void {
    const updatedHomeAddress: AddressModel = {
      ID: this.homeAddress.ID,
      POST_CODE: this.homeAddressFormValue.POST_CODE ?? null,
      PO_BOX_NO: this.homeAddressFormValue.PO_BOX_NO ?? null,
      STREET_NO: this.homeAddressFormValue.STREET_NO ?? null,
      DELETED_IND: this.homeAddress.DELETED_IND,
      SUBURB: this.homeAddressFormValue.SUBURB ?? null,
      CITY: this.homeAddressFormValue.CITY ?? null,
      STATE: this.homeAddressFormValue.STATE ?? null,
      COUNTRY: this.homeAddressFormValue.COUNTRY ?? null,
      UPDATED_DATE: this.homeAddress.UPDATED_DATE,
    };
    if (
      JSON.stringify(this.untouchedHomeContactDetailsFormData) !== JSON.stringify(this.homeContactDetailsForm.value) ||
      JSON.stringify(this.homeAddress) !== JSON.stringify(updatedHomeAddress)
    ) {
      const config = new MatDialogConfig();
      config.data = {
        title: "Changes unsaved",
        content: "You have changes unsaved. Are you sure you want to leave?",
        type: DialogType.YES_NO,
      };
      const dialogRef = this._dialog.open(DialogComponent, config);

      dialogRef.afterClosed().subscribe((result: DialogOutput) => {
        if (!result) {
          return;
        }

        this.close();
      });
    } else {
      this.close();
    }
  }

  onEdit(): void {
    if (!this.canEdit) {
      return;
    }

    this.status = EditCardStatus.EDIT;
    this.cardBeingEdited.emit(true);
  }

  onPostalAddressCheck(event: MatCheckboxChange): void {
    this.disablePostalAddressForm = event.checked;

    this.hideSearchAddressBox = !this.hideSearchAddressBox;

    if (!event.checked && this.homeContactDetails.POSTAL_ADDRESS_ID > 0) {
      this.postalAddress = this.clientDetailInitialData.ClientDetails.PostalAddress;
    } else {
      this.postalAddress = this.homeAddress;
    }
  }

  close(): void {
    this.homeContactDetailsForm.reset();
    this.innitFormControls();
    this.setInitialData();

    this.status = EditCardStatus.NORMAL;
    this.cardBeingEdited.emit(false);
  }
}
