import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TableItemAction } from "@components/table/mat/mat-table-wrapper.service";
import { ClientInvoicesFilterColumnService, ClientInvoicesServersideTableService } from "./client-invoices-table.service";
import { ClientSubInvoicesServersideTableService } from "./client-sub-table-service";

@Component({
  selector: "app-client-invoices",
  templateUrl: "./client-invoices.component.html",
  providers: [
    ClientInvoicesServersideTableService, ClientInvoicesFilterColumnService,
    ClientSubInvoicesServersideTableService],

})
export class ClientInvoicesComponent implements OnInit, AfterViewInit {
  @ViewChild('invoiceAmountTpl') invoiceAmoutTpl: TemplateRef<any>;
  @ViewChild("detailInfo") detailInfoTpl: TemplateRef<any>;
  clientId: string;

  isDataLoaded: boolean;

  constructor(
    private _activatedRoute: ActivatedRoute,
    public tableService: ClientInvoicesServersideTableService,
    public filterColumnService: ClientInvoicesFilterColumnService,
    public subTableService: ClientSubInvoicesServersideTableService,
  ) {
    const params = this._activatedRoute.snapshot.params;
    this.clientId = params['clientId'];

    this.tableService.clientId = this.clientId;

    this.isDataLoaded = false;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.filterColumnService.setInvoiceAmtTemplate(this.invoiceAmoutTpl);
      this.tableService.setDetailInfoTpl(this.detailInfoTpl);
      this.isDataLoaded = true;
    }, 500);

  }

  ngOnInit(): void {

  }

  onActionHandler(item: { rowItem: any; action: TableItemAction }): void {

  }
}
