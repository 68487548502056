<div>
  <div class="new_section flex items-center" *ngIf="false">
    <span class="hightlight_color font-medium mx-8 cursor-pointer" (click)="onNewPayment()" *ngIf="false">New Payment</span>
    <span class="hightlight_color font-medium cursor-pointer" (click)="onNewCashSale()" *ngIf="false">New Cash Sale</span>
  </div>
  <div class="selected_section flex items-center" *ngIf="selectedPayments && selectedPayments.length > 0">
    <mat-icon svgIcon="gpm_close_no_border" class="ml-4 cursor-pointer" (click)="resetSelectedItems()">
    </mat-icon>
    <span class="font-normal mr-8 ml-4">{{selectedPayments.length}} items selected</span>
    <span class="hightlight_color font-medium cursor-pointer" *ngIf="false" (click)="onViewMultiplePayment()">View multiple payment reciept</span>
  </div>
  <app-serverside-table (actionEvent)="onActionHandler($event)" (selectEvent)="selectPayments($event)"
    [serversideTableService]="tableService" [serversideFilterService]="filterColumnService" [isMultipleSelection]="true"
    [isNoBorder]="true">
  </app-serverside-table>
</div>
