import { Injectable } from "@angular/core";
import moment from "moment";
import { findIana } from "windows-iana";
import { CacheService } from "../cache/cache.service";
import { CacheableItems } from "../cache/cache.types";

// Defines service for formatting and generation of date
@Injectable({
  providedIn: "root",
})
export class DateTimeService {
  constructor(private _cacheService: CacheService) {}

  private defaultDateFormat = "DD/MM/YYYY";
  private defaultTimeFormat = "HH:mm";
  private defaultDateTimeMeridiemFormat = "DD/MM/YY hh:mm a";
  private defaultDayAndDateTimeMeridiemFormat = "ddd, DD/MM/YY hh:mm a";
  private defaultDayAndDate = "ddd, DD MMM YY";
  private defaultDayAndTimeFormat = "ddd DD MMM YYYY hh:mm a";
  private defaultServerDayAndTimeFormat = "MM/DD/YYYY hh:mm a";

  public toStartOfDay(startOfDay: Date): Date {
    startOfDay.setHours(0, 0, 0, 0);

    return startOfDay;
  }

  public toEndOfDay(endOfDay: Date): Date {
    endOfDay.setHours(23, 59, 59, 999);

    return endOfDay;
  }

  public getTodayMidNightDate(): Date {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  }

  public getTodayMidNightDateUnix(): string {
    return this.convertDateToEpoch(this.getTodayMidNightDate()).toString();
  }

  public getTomorrowMidNightDate(): Date {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    return tomorrow;
  }

  public getTomorrowMidNightDateUnix(): string {
    return this.convertDateToEpoch(this.getTomorrowMidNightDate()).toString();
  }

  public getHoursMinutesFromTime(time): string {
    const timeSplit = time.toString().split(".");
    return this.prependZeroToHours(timeSplit[0]) + ":" + (timeSplit.length > 1 ? this.appendZeroToMinutes(timeSplit[1]) : "00");
  }

  // Gets the date for 1 year ago, starting with the start of day.
  // e.g 01/01/2019 00:00:00:000
  public getYearAgoStartOfDayDate(): Date {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDate();

    const yearAgo = new Date(year - 1, month, day);
    yearAgo.setHours(0, 0, 0, 0);
    return yearAgo;
  }

  // Gets the time today just as day start
  // to ensure filtering captures any items created on today.
  // e.g item created today at 22:00 hours
  public getTodayStartOfDayDate(): Date {
    const startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);

    return startOfToday;
  }

  public getTodayPlus6MonthsEndOfDay(): Date {
    return this.addMonths(this.getTodayEndOfDayDate(), 6);
  }

  public getTodayMinus6MonthsStartOfDay(): Date {
    return this.addMonths(this.getTodayStartOfDayDate(), 6);
  }

  public addMonths(date: Date, months: number): Date {
    const newDate = moment(date).add(months, "month");
    return newDate.toDate();
  }

  // Gets the time today just before day ends and tomorrow starts
  // to ensure filtering captures any items created on today.
  // e.g item created today at 22:00 hours
  public getTodayEndOfDayDate(): Date {
    const endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 999);

    return endOfToday;
  }

  // Gets end of today in unix format.
  public getTodayEndOfDayDateUnix(): string {
    return this.convertDateToEpoch(this.getTodayEndOfDayDate()).toString();
  }

  public FormatDate(toFormat: string, format?: string): string {
    if (!format) {
      format = this.defaultDateFormat;
    }
    return this.format(toFormat, format);
  }

  public FormatToDefaultShortDate(toFormat: string) {
    return this.format(toFormat, this.defaultDateFormat);
  }

  public FormatToDateTime(toFormat: string): string {
    return this.format(toFormat, this.defaultDateTimeMeridiemFormat);
  }

  public FormatToDefaultDateLong(toFormat: string): string {
    return this.format(toFormat, this.defaultDayAndTimeFormat);
  }

  public FormatToDayAndDateTime(toFormat: string): string {
    return this.format(toFormat, this.defaultDayAndDateTimeMeridiemFormat);
  }

  public FormatToDayAndDate(toFormat: string): string {
    return this.format(toFormat, this.defaultDayAndDate);
  }

  public FormatToTime(toFormat: string): string {
    return this.format(toFormat, this.defaultTimeFormat);
  }

  public FormatToServerDateAndTime(toFormat: string): string {
    return this.format(toFormat, this.defaultServerDayAndTimeFormat);
  }

  public convertEpochToDate(epochDate: any, TimeZone?: string): Date {
    if (TimeZone) {
      return moment.unix(epochDate).tz(TimeZone).toDate();
    } else {
      return moment.unix(epochDate).toDate();
    }
  }

  public convertDateToEpoch(javascriptDate: any, TimeZone?: string): number {
    if (TimeZone === undefined) {
      TimeZone = moment.tz.guess();
    }

    if (javascriptDate instanceof moment) {
      const momentDate = javascriptDate as moment.Moment;
      javascriptDate = momentDate.toDate();
    } else if (javascriptDate instanceof String) {
      const stringDate = javascriptDate as string;
      javascriptDate = moment(stringDate, TimeZone).toDate();
    } else {
      javascriptDate = new Date(javascriptDate);
    }

    const obj = {
      year: javascriptDate.getFullYear(),
      month: javascriptDate.getMonth(),
      day: javascriptDate.getDate(),
      hour: javascriptDate.getHours(),
      minute: javascriptDate.getMinutes(),
      second: javascriptDate.getSeconds(),
    };
    const mdate = moment.tz(obj, TimeZone);
    return mdate.unix();
  }

  private convertEpochToString(epochDate: any, format: string, TimeZone?: string) {
    if (TimeZone) {
      return moment.unix(epochDate).tz(TimeZone).format(format);
    } else {
      return moment.unix(epochDate).format(format);
    }
  }

  private format(toFormat: string, formatToUse: string): string {
    try {
      // If its a integer its epoch
      if (Number.isInteger(toFormat)) {
        // switch commenting out to show whihc dates are epoch on front end to allow
        // removal later if desired
        // return this.convertEpochToString(toFormat, formatToUse) + "-Epoch";
        return this.convertEpochToString(toFormat, formatToUse);
      } else {
        const date = moment(toFormat);

        if (date) {
          return moment(toFormat).format(formatToUse);
        }
      }
    } catch {}

    // still here invalid date format passed.. show for now
    // for developers to notice.
    return toFormat + "-Invalid";
  }

  private prependZeroToHours(hour): string {
    if (hour < 10) {
      hour = "0" + hour;
    }
    return hour;
  }

  private appendZeroToMinutes(minutes): string {
    if (minutes < 10) {
      minutes = minutes + "0";
    }
    return minutes;
  }

  public convertToLocale(date: string | Date): Date {
    if (!date) {
      return;
    }

    if (typeof date !== "string") {
      date = date.toString();
    }

    const timeZoneId = this._cacheService.get(CacheableItems.SessionVendorTimeZone);
    const ianaTz = findIana(timeZoneId)[0];

    const formatedDate = moment.tz(date, ianaTz).toDate();

    return formatedDate;
  }

  public convertToISOUTC(date: Date): string {
    if (!date) {
      return;
    }

    if (date instanceof moment) {
      date = moment(date).toDate();
    }

    const utcDate = date.toUTCString();
    const formatedDate = new Date(utcDate).toISOString().split(".")[0] + "Z";
    return formatedDate;
  }

  convertToUtcDate(date: Date): Date {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
  }

  getDateDiffInDays(startDate: string, endDate: string): number {
    const start = moment(startDate);
    const end = moment(endDate);

    return end.diff(start, "days");
  }

  /**
   * This method will take in a date and return a date/time string
   * the date returned will be the start of the day passed in
   * if the server is in UTC then the date will be converted to UTC
   * @param Base date to proccess
   * @returns First second of the date in server time
   */
  convertToServerStartOfDay(date: Date): string {
    // convert to the servers timezone if required
    let dateInUtc = this.convertToServerDate(date);

    // return the datetime as a string
    return this.FormatToServerDateAndTime(dateInUtc.toString());
  }

  /**
   * This method will take in a date and return a date/time string
   * the date returned will be the end of the day passed in
   * if the server is in UTC then the date will be converted to UTC
   * @param date Base date to proccess
   * @returns Last second of the date in server time
   */
  convertToServerEndOfDay(date: Date): string {
    //add one day to the given date
    date.setDate(date.getDate() + 1);

    //subtract one second so we get the last second of the given date
    date.setSeconds(date.getSeconds() - 1);

    //convert to server time (By default is UTC)
    let dateInUtc = this.convertToServerDate(date);

    //convert to server formar date and time
    return this.FormatToServerDateAndTime(dateInUtc.toString());
  }

  convertToServerDate(date: Date) {
    return this.convertToUtcDate(date);
  }

  isServerUtc() {
    //TODO: Get this from configuration
    return true;
  }
}
