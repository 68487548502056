<div [formGroup]="autocompleteForm" class="flex items-center">
    <input #autoInput id="app-autocomplete-field" matInput [matAutocomplete]="auto" (focus)="onFocusIn($event)"
        (focusout)="onFocusOut($event)" (input)="_handleInput($event)" [formControlName]="'autoInput'">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="_onOptionSelected($event)"
        [displayWith]="displayWith">
        <mat-option *ngFor="let item of filteredItems" [value]="item" class="whitespace-normal leading-normal">
            {{ item.name }}
        </mat-option>
        <ng-container *ngIf="noItemsFetched && !isSearching">
            <mat-option class="whitespace-normal leading-normal" [value]="[]">
                No items were found
            </mat-option>
        </ng-container>
        <ng-container *ngIf="isSearching">
            <mat-option class="whitespace-normal leading-normal" [value]="[]">
                Searching...
            </mat-option>
        </ng-container>
    </mat-autocomplete>
    <button matSuffix (click)="clear()" *ngIf="autocompleteForm.get('autoInput').value">
        <mat-icon class="icon-size-3.5 rounded-lg hover:bg-hover" svgIcon="heroicons_outline:x"></mat-icon>
    </button>
</div>