import { Observable } from "rxjs";

import { Injectable } from "@angular/core";

import { APIService } from "../api/api.service";
import { EmployerByNameModel } from "../models/appointment/employer-by-name.model";
import { EmployerModel } from "../models/appointment/employer.model";
import { AddressModel } from "../models/appointment/address.model";

@Injectable({ providedIn: "root" })
export class EmployerService {
  constructor(private _apiService: APIService) {}

  public getEmployersByName(searchTerm: string): Observable<EmployerByNameModel[]> {
    return this._apiService.get(`/employers?command=search&SearchTerm=${searchTerm}`);
  }

  public getEmployerDetailsById(employerId: number): Observable<{ Employer: EmployerModel; EmployerAddress: AddressModel }> {
    return this._apiService.get(`/employers?command=details&id=${employerId}`);
  }
}
