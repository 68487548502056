import { Observable, Subject } from "rxjs";

import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl,
  ValidationErrors,
  Validator,
} from "@angular/forms";
import { MatFormFieldControl } from "@angular/material/form-field";

import { AutocompleteDataObject } from "./autocomplete-field.types";
import { debounceTime } from "rxjs/operators";
import { LoadingService } from "app/core/services/loading.service";

@Component({
  selector: "app-autocomplete-field",
  templateUrl: "autocomplete-field.component.html",
  styleUrls: ["./autocomplete-field.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: AutocompleteFieldComponent,
    },
  ],
  host: {
    "[class.floating]": "shouldLabelFloat",
    "[id]": "id",
  },
})
export class AutocompleteFieldComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit, MatFormFieldControl<string>, ControlValueAccessor, Validator {
  @HostBinding()
  id = `app-autocomplete-field-${AutocompleteFieldComponent.nextId++}`;

  @ViewChild("autoInput") areaInput: HTMLInputElement;

  autocompleteForm: FormGroup;

  stateChanges = new Subject<void>();
  static nextId = 0;

  focused: boolean;
  touched: boolean;

  onTouched = () => { };
  onChange = (v: string) => { };

  filteredItems: AutocompleteDataObject[];
  noItemsFetched: boolean;
  isSearching: boolean;

  @Input()
  get value(): string {
    return this.autocompleteForm.get("autoInput").value;
  }
  set value(v: string) {
    this.autocompleteForm?.get("autoInput").setValue(v);
    this.stateChanges.next();
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh: string) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;

  get empty() {
    return !this.value;
  }

  @HostBinding("class.floating")
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req: boolean) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _required: boolean;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.autocompleteForm.disable() : this.autocompleteForm.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  get errorState(): boolean {
    return !this.value && this.required && this.touched;
  }

  controlType: string = "app-autocomplete-field";

  @Input("aria-describedby") userAriaDescribedBy: string;

  @Input()
  public get items(): AutocompleteDataObject[] {
    return this._items;
  }
  public set items(v: AutocompleteDataObject[]) {
    this._items = v;
  }
  private _items: AutocompleteDataObject[];

  @Input()
  public get minLength(): number {
    return this._minLength;
  }
  public set minLength(v: number) {
    this._minLength = v;
  }
  private _minLength: number = 3;

  @Input()
  public get fetchDataFrom(): (args: any) => Observable<AutocompleteDataObject[]> {
    return this._fetchDataFrom;
  }
  public set fetchDataFrom(v: (args: any) => Observable<AutocompleteDataObject[]>) {
    this._fetchDataFrom = v;
  }
  private _fetchDataFrom: (args: any) => Observable<AutocompleteDataObject[]>;

  @Output() onOptionSelected = new EventEmitter<any>();
  @Output() isLoading = new EventEmitter<boolean>();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private _loadingService: LoadingService,
    private _formBuilder: FormBuilder,
    private _elementRef: ElementRef
  ) {
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }

    let autoFormControl: FormControl = new FormControl("");

    this.autocompleteForm = this._formBuilder.group({
      autoInput: autoFormControl,
    });
  }

  ngOnInit() {
    this._loadingService.visible$.subscribe((response) => {
      this.isLoading.emit(response);
    });

    this.filteredItems = this.items.slice();
  }

  ngAfterViewInit(): void {
    this.autocompleteForm.get("autoInput").valueChanges.pipe(debounceTime(600)).subscribe((data) => {
      if (typeof data === "object") {
        return;
      }

      if (!this.fetchDataFrom) {
        this.filteredItems = this._filter(data, this.items);
      } else {
        if (data.length === 0) {
          this.filteredItems = [];
          this.noItemsFetched = false;
        } else if (data.length >= this.minLength) {
          this.isSearching = true;
          this.markAsTouched();

          this.filteredItems = [];

          this.fetchDataFrom(data)
            .subscribe((response) => {
              this.filteredItems = response;
              this.stateChanges.next();

              this.noItemsFetched = response?.length === 0 && (data.length >= this.minLength);
              this.isSearching = false;
            });
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const items = changes.items;
    if (items && !items.firstChange && items.currentValue !== items.previousValue) {
      this.filteredItems = this._filter("", items.currentValue);
    }
  }

  // MatFormFieldControl Interface methods

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement!;

    controlElement.setAttribute("aria-describedby", ids.join(" "));
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).id != "app-autocomplete-field") {
      this._elementRef.nativeElement.focus();
    }
  }

  // ControlValueAccessor Interface methods

  writeValue(obj: any): void {
    if (this.autocompleteForm) {
      this.autocompleteForm.get("autoInput").setValue(obj);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // Validator Interface Method

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (this.required && !value) {
      return { required: true };
    } else {
      return null;
    }
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
      this.stateChanges.next();
    }
  }

  // Autocomplete logic methods

  displayWith(obj?: any): string | undefined {
    return obj ? obj.name : undefined;
  }

  private _filter(value: string, collectionToFilter: AutocompleteDataObject[]): AutocompleteDataObject[] {
    return collectionToFilter.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));
  }

  _onOptionSelected(event: any): void {
    this.onOptionSelected.emit(event);

    this.stateChanges.next();
  }

  _handleInput(event: any): void {
    this.onChange(this.value);
  }

  clear(): void {
    this.autocompleteForm.get("autoInput").reset();

    if (this.fetchDataFrom) {
      this.filteredItems = [];
    } else {
      this.filteredItems = this.items;
    }

    this.onOptionSelected.emit("");
    this.markAsTouched();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }
}
