import { Injectable } from "@angular/core";
import { interval, Observable, of, Subscription, throwError } from "rxjs";
import { Router } from "@angular/router";
import { catchError, map, switchMap, tap } from "rxjs/operators";

import { AuthErrorMessages } from "./enums/AuthErrorMessages";
import { CognitoErrors } from "./enums/CognitoErrors";

import { CacheService } from "../cache/cache.service";

import { TokenStorageService } from "./token-storage.service";
import { APIService } from "../api/api.service";

interface AuthData {
  token: string;
  refreshToken: string;
  refreshTokenExpiryTime: string;
}

@Injectable()
export class AuthService {
  private authenticationEndpoint = "/authenticate";
  private _authenticated: boolean = false;

  public tokenInterval: Subscription;

  constructor(
    private _router: Router,
    private _apiService: APIService,
    private _tokenStorageService: TokenStorageService
  ) {
    this.initTokenHeartbeat();
  }

  public isAuthenticated(): Observable<boolean> {
    return this._apiService.get<Record<string, any>>(this.authenticationEndpoint).pipe(
      map((_result) => {
        this._authenticated = true;
        return true;
      }),
      catchError((error) => {
        this._authenticated = false;
        return of(false);
      })
    );
  }

  public login(username: string, password: string, organisationName: string): Observable<AuthData> {
      // create a JSON object to represent the body data
      const body = { UserName: username, Password:  password, OrganisationName: organisationName };
      // Throw error, if the user is already logged in
      if (this._authenticated) {
        return throwError(() => new Error("User is already logged in."));
      }
      //clear local storage before signing in
      localStorage.clear();

      return this._apiService.post("/api/Authentication/authenticate",body)
      .pipe(
        tap((authData: AuthData) => {
          if (authData) {
            this._authenticated = true;
            this.saveAuthData(authData);
          }
          
        })
      );
  }


  public getAuthError(errorCode: string): string {
    let authErrorMessage: AuthErrorMessages;
    switch (errorCode) {
      case CognitoErrors.NotAuthorizedException: {
        authErrorMessage = AuthErrorMessages.NotAuthorized;
        break;
      }
      case CognitoErrors.UserNotFoundException: {
        authErrorMessage = AuthErrorMessages.UserNotFoundException;
        break;
      }
      default: {
        this._router.navigate(["internal-error"]);
      }
    }
    return authErrorMessage;
  }


  // New methods

  refreshToken() {
    if (this._tokenStorageService.getAccessToken() && this._tokenStorageService.getVendorsToken()) {
      this.tokenInterval.unsubscribe();
      console.log("Refreshing token");
      return this._tokenStorageService.getRefreshToken().pipe(
        switchMap((refresh_token: string) => {
          return this._apiService.post("api/token", `grant_type=refresh_token&refresh_token=${refresh_token}`);
        })
      );
    }
  }

  initTokenHeartbeat() {
    if (this._tokenStorageService.getAccessToken() && this._tokenStorageService.getVendorsToken()) {
      console.log("Init Token Heartbeat");
      const expireToken = (this._tokenStorageService.getExpireToken() * 900) / 4;
      console.log("Expire Token", expireToken);
      this.tokenInterval = interval(expireToken).subscribe((_) => {
        this.refreshToken().subscribe((authData: AuthData) => {
          this.saveAuthData(authData);
          this.tokenInterval.unsubscribe();
          this.initTokenHeartbeat();
        });
      });
    }
  }


  loginWithVendor(username: string, password: string, vendorId: string, organisationId: string): Observable<any> {
    // const clientId = environment.clientId;
    const clientId = 0;

    // const clientSecret = environment.clientSecret;
    const clientSecret = "";

    const payload =
      "grant_type=password&username=" +
      btoa(username) +
      "~" +
      vendorId +
      "@" +
      btoa(organisationId) +
      "&password=" +
      btoa(password) +
      "&client_id=" +
      clientId +
      "&client_secret=" +
      clientSecret;
    return this._apiService
      .post("api/token", payload, [
        {
          key: "Content-Type",
          value: "application/x-www-form-urlencoded",
        },
      ])
      .pipe(
        tap((authData: AuthData) => {
          this.saveAuthData(authData);
        })
      );
  }

  getVendorsForLogin(): Observable<any> {
    return this._apiService.get("api/v1/gpm/vendor/GetVendorBySystemUser");
  }

  cacheSelectedVendor(vendorId: number): Observable<any> {
    return this._apiService.get(`api/v1/gpm/cache/populate?vendorId=${vendorId}`).pipe(
      tap(() => {
        console.log("Successfully logged in");
        this._tokenStorageService.setVendorsToken();
        this._tokenStorageService.setLockscreenToken("false");
        this._tokenStorageService.setVendorId(vendorId);
        this.initTokenHeartbeat();
      })
    );
  }

  logout(): void {
    this._tokenStorageService.clearSession();
    this._router.navigate(["/login"]);
  }

  saveAuthData(authData: AuthData) {
    this._tokenStorageService
      .setAccessToken(authData.token)
      .setRefreshToken(authData.refreshToken)
      .setExpiresToken(authData.refreshTokenExpiryTime)
    this._tokenStorageService.setVendorsToken();
  }

  getLoggedUser(): Observable<any> {
    return this._apiService.get("api/v1/gpm/systemUser/current_user");
  }

  loginWithHash(hash: string, captchaResponse: string) {
    // const clientId = environment.clientId;
    const clientId = 0;

    // const clientSecret = environment.clientSecret;
    const clientSecret = "";

    const payload =
      "grant_type=password&hash=" +
      hash +
      "&captchaResponse=" +
      captchaResponse +
      "&client_id=" +
      clientId +
      "&client_secret=" +
      clientSecret;
    return this._apiService
      .post("api/forms/token", payload, [
        {
          key: "Content-Type",
          value: "application/x-www-form-urlencoded",
        },
      ])
      .pipe(
        tap((authData: AuthData) => {
          this.saveAuthData(authData);
        })
      );
  }

  loginWithTokenFromWindows(token: string) {
    // const clientId = environment.clientId;
    const clientId = 0;

    // const clientSecret = environment.clientSecret;
    const clientSecret = "";

    const payload = "grant_type=password&token=" + token + "&client_id=" + clientId + "&client_secret=" + clientSecret;
    return this._apiService
      .post("api/winlogin/token", payload, [
        {
          key: "Content-Type",
          value: "application/x-www-form-urlencoded",
        },
      ])
      .pipe(
        tap((authData: AuthData) => {
          this.saveAuthData(authData);
          this._tokenStorageService.setVendorsToken();
          this._tokenStorageService.setWinLogin();
        })
      );
  }

}
