export enum ResultType {
  Ok = 1,
  Info = 2,
  Error = 3,
  Question = 4,
}

export interface ResultsModel {
  Code: number;
  Result: ResultType;
  Reason: string;
}
