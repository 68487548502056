<ng-container>
  <mat-form-field appearance="outline" floatLabel="auto" class="amount_type">
    <mat-label>Restrict Amount</mat-label>
    <mat-select placeholder="Amout Type" [formControl]="amountTypeControl">
        <mat-option *ngFor="let item of config.invoiceTypes" [value]="item" >{{ item.name }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline" floatLabel="auto" class="text_amount">
    <mat-label>Amount</mat-label>
    <input matInput [formControl]="maximumNoControl" type="number" placeholder="Amount">
  </mat-form-field>
  <mat-form-field appearance="outline" floatLabel="auto" class="text_amount">
    <mat-label>Within</mat-label>
    <input matInput [formControl]="percentageNoControl" type="number" placeholder="+/- %">
  </mat-form-field>
</ng-container>
