import { Injectable } from "@angular/core";
import { ColumnItem, TableActionType, TableItemAction } from "@components/table/mat/mat-table-wrapper.service";
import { FilterColumnService, FilterItem } from "@components/table/table-column-filter/table-serverside-column-filter.service";
import { ServerSideTableService } from "@components/table/table-serverside.service";
import { SiteDBModel, SystemUserSiteDBModel } from "app/core/cache/cache.types";
import { IndexedDBService } from "app/core/cache/indexed-db.service";
import { ClientService } from "app/core/client/client.service";
import { DateTimeService } from "app/core/services/datetime.service";
import { forkJoin, Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";

const CLIENT_PAYMENTS_FIELDS = {
  DATE: "date_paid",
  SITE: "ID",
};

const retrieveFilterValueForNameItem = (name: string, filterItemList: Array<FilterItem>): any | null => {
  const itemFilter: FilterItem = filterItemList.find((item) => item.fieldName === name);
  return itemFilter ? itemFilter.filterValue : null;
};

@Injectable()
export class ClientPaymentsServersideTableService implements ServerSideTableService {
  public clientId: string;
  constructor(private _clientService: ClientService, private _dateTimeService: DateTimeService) {

  }

  getActionList(): TableItemAction[] {
    return [

    ];
  }
  getColumnDefinitions(): ColumnItem[] {
    return [
      {
        fieldName: "DATE_PAID",
        displayName: "Date Paid",
        config: {
          isDate: true,
        },
      },
      { fieldName: "SITE_NAME", displayName: "Site" },
      { fieldName: "PAYMENT_DETAILS", displayName: "Payment Details" },
      {
        fieldName: "TOTAL_AMOUNT",
        displayName: "Amount",
        config: {
          isCurrency: true,
        }
      },
      {
        fieldName: "DATE_RECEIPTED",
        displayName: "Taking Date",
        config: {
          isDate: true,
        },
      },
    ];
  }
  getData(selectedFilterItem: FilterItem[]): Observable<any[]> {
    const dateRangeFilterValue: any = retrieveFilterValueForNameItem(CLIENT_PAYMENTS_FIELDS.DATE, selectedFilterItem);

    const dateFrom: string = dateRangeFilterValue && dateRangeFilterValue.filterFrom ? this._dateTimeService.FormatDate(dateRangeFilterValue.filterFrom, 'MM/DD/yyyy') : "";

    const dateTo: Date | string = dateRangeFilterValue && dateRangeFilterValue.filterTo ? this._dateTimeService.FormatDate(dateRangeFilterValue.filterTo, 'MM/DD/yyyy') : "";

    const site: any = retrieveFilterValueForNameItem(CLIENT_PAYMENTS_FIELDS.SITE, selectedFilterItem);
    const siteId = site ? site.filterValue : "";

    return this._clientService.getClientPayments(this.clientId, 0, dateFrom, dateTo, siteId);
  }
}

@Injectable()
export class ClientPaymentsFilterColumnService implements FilterColumnService {
  constructor(
    private _indexedDbService: IndexedDBService
  ) {

  }

  getAllowedFilterFields(): Array<FilterItem> {
    return [
      {
        fieldName: CLIENT_PAYMENTS_FIELDS.DATE,
        displayName: "Date Paid",
        config: {
          isDateRange: true,
        },
      },
      {
        fieldName: CLIENT_PAYMENTS_FIELDS.SITE,
        displayName: "Site",
        config: {
          isSelect: true,
          selectData: "",
          placeholder: "Select a site",
          label: "Select",
          textField: "name",
          valueField: "id",
        },
      },
    ];
  }

  getSelectDataForFilterItem(item: FilterItem): Observable<any[]> {
    return forkJoin({
      sites: this._indexedDbService.getAllSites(),
      systemUserSites: this._indexedDbService.getAllSystemUserSites(),
    }).pipe(
      map((response) => {
        const sites = response.sites as SiteDBModel[];
        const systemUserSites = response.systemUserSites as SystemUserSiteDBModel[];

        const results: SiteDBModel[] = [];

        systemUserSites.forEach((systemUserSite) => {
          const site = sites.find((s) => s.id === systemUserSite.siteId);
          if (site) {
            results.push(site);
          }
        });

        return results;
      })
    );

  }

  getDataButtonText(): string {
    return "";
  }
  getPageTitle(): string {
    return "Payments";
  };
  getFilterServiceUniqueName(): string {
    return "Client_Payments_Filter";
  }
}
