export enum CacheableItems {
  GPMUser = "gpm_user",
  GPMSystemUserId = "gpm_system_user_id",
  GPMVersion = "gpm_version",
  GPMDarkMode = "gpm_darkmode",
  GPMOrgVendors = "gpm_org_vendors",
  GPMSessionVendorId = "gpm_session_vendor_id",
  CognitoIdToken = "id_token",
  GPMCacheDate = "gpm_cache_date",
  GenWSK = "gen_wsk",
  GPMSelectedClient = "gen_selected_client",
  GPMSelectedSite = "gen_selected_site",
  GPMPreferrdProviderForMobile = "gen_selected_provider_for_mobile",
  GPMPreferredHLFilter = "gen_selected_hl_filter",
  GPMAdditionalColumnsSelected = "gen_additional_table_columns",
  GenLocale = "gen_locale",
  GPMProduct = "gpm_product",
  SessionVendorTimeZone = "gpm_session_vendor_time_zone",
  AccessToken = "token",
  RefreshToken = "refreshToken",
  ExpiresToken = "refreshTokenExpiryTime"
}

export enum DBStores {
  Site = "site",
  SystemUserSite = "systemUserSite",
  Title = "title",
  PaymentType = "paymentType",
  AdminType = "adminType",
  Provider = "provider",
  PhysioProvider = "physioProvider",
  ProviderSite = "providerSite",
  SiteApptDetail = "siteApptDetail",
  Referrers = "referrers",
}

export interface SiteDBModel {
  id: number;
  organisationId: number;
  name: string;
  contactDetailsId: number | null;
  deleted: boolean;
  defaultBankAccountId: number | null;
  gstNumber: string;
  tradingName: string;
  abn: string;
  timezoneId: string;
  active: boolean;
}

export interface SystemUserSiteDBModel {
  id: number;
  systemUserId: number;
  siteId: number;
  deleted: boolean;
  ordering: number;
}

export interface SystemUserDBModel {
  id: number;
  name: string;
  deleted: boolean;
  organisationId: number;
  displayName: string;
  active: boolean;
  isSupport: boolean;
  email: string | null;
  lockTimeoutMins: number | null;
  bccSenderInd: boolean;
}

export interface TitleDBModel {
  id: number;
  name: string;
  sex: string;
  deleted: boolean;
}

export interface PaymentTypeDBModel {
  id: number;
  deleted: boolean;
  name: string;
  description: string;
  active: boolean;
}

export interface AdminTypeDBModel {
  id: number;
  deleted: boolean;
  active: boolean;
  name: string;
  description: string;
  adminTypeType: number;
}

export interface VendorPhysioDetailsDBModel {
  id: number;
  deleted: boolean;
  vendorId: number;
  organisationId: number;
  healthlinkMessagingInd: number;
  healthlinkEdiNumber: string;
  allowNZAsExtract: boolean;
  allowAccCscInd: boolean;
  allowEvoIntegration: boolean;
}

export interface ProviderDBModel {
  id: number;
  deleted: boolean;
  vendorId: number;
  active: boolean;
  titleId: number | null;
  firstname: string;
  lastname: string;
  contact_details_id: number | null;
  middlename: string;
  systemUserId: number | null;
}

export interface PhysioProviderDBModel {
  id: number;
  deleted: boolean;
  providerType: number;
  providerNo: string;
  providerId: number;
  vendorId: number;
}

export interface ProviderSiteDBModel {
  id: number;
  providerId: number;
  siteId: number;
  active: boolean;
  deleted: boolean;
  defatultApptDuration: number;
  defaultBillApptDuration: number;
  overrideApptNearestMins: number;
  apptNearestMins: number;
  overrideHourStartFrom: number;
  hourStartFrom: number;
  defaultApptSiteRoomId: number | null;
}

export interface SiteApptDetailsDBModel {
  id: number;
  deleted: boolean;
  siteId: number;
  displayStartTime: number;
  displayEndTime: number;
  addStartApptBuffer: number;
  addEndApptBuffer: number;
}

export interface ReferrerDBModel {
  id: number;
  firstName: string;
  lastName: string;
  clinicName: string;
  notes: string;
  medicalProviderType: number;
  contactDetailsId: number;
  clinicId: number;
  titleId: number;
  deleted: boolean;
  active: boolean;
  useClinicAddress: boolean;
  useClinicContactDetails: boolean;
}

export interface AppStateDBModel {
  id: number;
  label: string;
  url: string;
  state: any;
  isParent: boolean;
}

export interface EthnicGroupDBModel {
  id: number;
  name: string;
  accCode: string;
  description: string;
  deleted: boolean;
}

export interface VendorApptDetailsDBModel {
  id: number;
  vendorId: number;
  showDiabetesRisk: boolean;
  isReferralSourceMandatory: boolean;
}

export interface VendorDBModel {
  id: number;
  showInternalRef: boolean;
  timeZoneId: string;
}

export interface EmploymentIntensityDBModel {
  id: number;
  name: string;
  accCode: string;
  deleted: boolean;
}

export interface TeamDBModel {
  id: number;
  name: string;
  details: string;
}

export interface OrganisationPhysioDetailsDBModel {
  id: number;
  organisationId: number;
  allowSportData: boolean;
}

export interface LocationDBModel {
  id: number;
  name: string;
  accCode: string;
  deleted: boolean;
}

export interface SportDBModel {
  id: number;
  name: string;
  accCode: string;
  deleted: boolean;
}

export interface SceneDBModel {
  id: number;
  name: string;
  accCode: string;
  deleted: boolean;
}

export interface DHBDBModel {
  id: number;
  deleted: boolean;
  organisationId: number;
  name: string;
  code: string;
  description: string;
  active: boolean;
  dhbRegionId: number;
}
