import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { EMPTY, Observable, Subject, timer } from 'rxjs';
import {
    catchError,
    delayWhen,
    retryWhen,
    switchAll,
    tap,
} from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

export const WS_ENDPOINT = environment.wsEndPoint;
export const RECONNECT_INTERVAL = environment.reconnectInterval;

@Injectable({ providedIn: 'root' })
export class WebSocketService {
    private webSocket$: WebSocketSubject<any>;
    private hlWebSocket$: WebSocketSubject<any>;
    private messageSubject$ = new Subject();
    public messages$ = this.messageSubject$.pipe(
        switchAll(),
        catchError((err) => {
            throw err;
        })
    );

    private hlMessageSubject$ = new Subject();
    public hlMessages$ = this.hlMessageSubject$.pipe(
        switchAll(),
        catchError((err) => {
            throw err;
        })
    );

    constructor() {}

    public connect(
        genWSK: string,
        siteId: number,
        cfg: { reconnect: boolean } = { reconnect: false }
    ): void {
        if (!this.webSocket$ || this.webSocket$.closed) {
            this.webSocket$ = this.getNewWebSocket(genWSK, siteId);
            const messages = this.webSocket$.pipe(
                cfg.reconnect ? this.reconnect : o => o,
                tap({
                    error: error => console.log(error),
                }),
                catchError(_ => EMPTY)
            );

            this.messageSubject$.next(messages);
        }
    }

    public hlWebconnect(
        genWSK: string,
        vendorId:  number,
        cfg: { reconnect: boolean } = { reconnect: false }
    ): void {
        if (!this.hlWebSocket$ || this.hlWebSocket$.closed) {
            this.hlWebSocket$ = this.getNewHlWebSocket(genWSK, vendorId);
            const messages = this.hlWebSocket$.pipe(
                cfg.reconnect ? this.reconnect : o => o,
                tap((error: any) => console.log(error),),
                catchError(_ => EMPTY)
            );

            this.hlMessageSubject$.next(messages);
        }
    }

    private getNewHlWebSocket(
        genWSK: string,
        vendorId: number
    ): WebSocketSubject<any> {
        const encodedWSK = encodeURIComponent(genWSK);        
        const wsUrl = WS_ENDPOINT + `?genwsk=${encodedWSK}&type=healthlink&vendorId=${vendorId}`;
        return webSocket({
            url: wsUrl,
            openObserver: {
                next: () => {},
            },
            deserializer: data => data,
            closeObserver: {
                next: () => {
                    if (this.hlWebSocket$?.hasError){
                        this.hlWebSocket$ = undefined;
                        this.hlWebconnect(genWSK, vendorId, { reconnect: true });
                    }
                },
            },
        });
    }

    private getNewWebSocket(
        genWSK: string,
        siteId: number
    ): WebSocketSubject<any> {
        const encodedWSK = encodeURIComponent(genWSK);        
        const wsUrl = WS_ENDPOINT + `?genwsk=${encodedWSK}&type=calendar&siteId=${siteId}`;
        return webSocket({
            url: wsUrl,
            openObserver: {
                next: () => {},
            },
            deserializer: data => data,
            closeObserver: {
                next: () => {
                    if (this.webSocket$?.hasError){
                        this.webSocket$ = undefined;
                        this.connect(genWSK, siteId, { reconnect: true });
                    }
                },
            },
        });
    }

    private reconnect(observable: Observable<any>): Observable<any> {
        return observable.pipe(
            retryWhen(errors =>
                errors.pipe(
                    tap(val =>
                        console.log(
                            '[WebSocket Service] Trying to reconnect',
                            val
                        )
                    ),
                    delayWhen(_ => timer(RECONNECT_INTERVAL))
                )
            )
        );
    }

    public send(data: any): void {
        const payload = {
            action: 'calendar',
            message: data,
        };
        this.webSocket$.next(payload);
    }

    public close(): void {
        this.webSocket$?.complete();
        this.webSocket$ = undefined;
    }
    public closeHlWebSocket() : void{
        this.hlWebSocket$?.complete();
        this.hlWebSocket$ = undefined;
    }
}
