import { AdminTypeDBModel } from "app/core/cache/cache.types";
import { ClientService } from "app/core/client/client.service";
import { ClientPersonalDetailsModel } from "app/core/client/models/client-personal-details.model";

import { AfterViewInit, Component, EventEmitter, HostBinding, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { EditCardStatus } from "@components/card/card.types";

import { ClientDetailInitialData } from "../../../client.types";
import { debounceTime } from "rxjs/operators";
import { ClientDetailsPatchModel } from "app/core/client/models/client-details-patch.model";
import { compare } from "fast-json-patch";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { DialogType } from "@components/dialog/dialog.types";
import { DialogComponent } from "@components/dialog/dialog.component";
import { DialogOutput } from "@components/table/table-column-filter/table-serverside-column-filter.service";
import { LoadingService } from "app/core/services/loading.service";

@Component({
  selector: "app-client-athlete-details",
  templateUrl: "client-athlete-details.component.html",
  styleUrls: ["./client-athlete-details.component.scss"],
})
export class ClientAthleteDetailsComponent implements OnInit, AfterViewInit {
  @HostBinding("class") classes = "app-client-athlete-details flex flex-col";
  status: EditCardStatus;

  cardedAthleteNumberFC: FormControl;
  internalSportFC: FormControl;
  cardLevelFC: FormControl;
  allowHPSNZExportFC: FormControl;

  clientDetailsForm: FormGroup;

  clientPersonalDetails: ClientPersonalDetailsModel;

  patchClientDetails: ClientDetailsPatchModel;

  isLoading: boolean;

  showAllowHPSNZExport: boolean;
  showInternalSport: boolean;

  clientDetailInitialData: ClientDetailInitialData;
  internalSports: AdminTypeDBModel[];
  cardLevels: AdminTypeDBModel[];

  untouchedClientDetailsFormData: any;

  @Output() cardBeingEdited = new EventEmitter<boolean>();
  @Input() canEdit: boolean;

  constructor(
    private _loadingService: LoadingService,
    private _activatedRoute: ActivatedRoute,
    private _clientService: ClientService,
    private _formBuilder: FormBuilder,
    private _dialog: MatDialog
  ) {
    this.status = EditCardStatus.NORMAL;

    this.innitFormControls();
  }

  private innitFormControls() {
    this.cardedAthleteNumberFC = new FormControl();
    this.internalSportFC = new FormControl();
    this.cardLevelFC = new FormControl();
    this.allowHPSNZExportFC = new FormControl();

    this.clientDetailsForm = this._formBuilder.group({
      cardedAthleteNumber: this.cardedAthleteNumberFC,
      allowHPSNZExport: this.allowHPSNZExportFC,
      internalSport: this.internalSportFC,
      cardLevel: this.cardLevelFC,
    });
  }

  ngOnInit() {
    this._loadingService.visible$.subscribe((response) => {
      this.isLoading = response;
    });

    this._activatedRoute.data.subscribe((data: any) => {
      this.clientDetailInitialData = data.initialData as ClientDetailInitialData;
      this.clientPersonalDetails = this.clientDetailInitialData.ClientDetails.PersonalDetails;

      this.showAllowHPSNZExport = this.clientDetailInitialData.VendorPhysioDetails[0].allowNZAsExtract;

      this.showInternalSport = this.clientDetailInitialData.OrgPhysioDetails[0].allowSportData;

      this.internalSports = this.clientDetailInitialData.InternalSports;
      this.cardLevels = this.clientDetailInitialData.CardLevels;

      const defaultAdminType: AdminTypeDBModel = {
        id: 0,
        deleted: false,
        active: false,
        name: "None",
        description: "None",
        adminTypeType: 0,
      };
      this.internalSports.splice(0, 0, defaultAdminType);
      this.cardLevels.splice(0, 0, defaultAdminType);

      this.setInitialClientData();
      this.setPatchClientDetails();
    });
  }

  ngAfterViewInit(): void {
    this.untouchedClientDetailsFormData = this.clientDetailsForm.value;

    this.clientDetailsForm.valueChanges.pipe(debounceTime(200)).subscribe((data) => {
      if (JSON.stringify(this.untouchedClientDetailsFormData) === JSON.stringify(data)) {
        this.clientDetailsForm.markAsPristine();
      }
    });
  }

  private setInitialClientData() {
    if (this.clientPersonalDetails.CARDED_ATHLETE_NO) {
      this.cardedAthleteNumberFC.setValue(this.clientPersonalDetails.CARDED_ATHLETE_NO);
    }

    if (this.clientPersonalDetails.INTERNAL_SPORT_ID && this.showInternalSport) {
      this.internalSportFC.setValue(this.internalSports.find((is) => is.id === this.clientPersonalDetails.INTERNAL_SPORT_ID));
    }

    if (this.clientPersonalDetails.CARD_LEVEL_ID) {
      this.cardLevelFC.setValue(this.cardLevels.find((cl) => cl.id === this.clientPersonalDetails.CARD_LEVEL_ID));
    }

    if (this.clientPersonalDetails.ALLOW_NZAS_EXTRACT_IND !== null && this.showAllowHPSNZExport) {
      this.allowHPSNZExportFC.setValue(this.clientPersonalDetails.ALLOW_NZAS_EXTRACT_IND === 1);
    }
  }

  private setPatchClientDetails(): void {
    let patchClientDetails: ClientDetailsPatchModel = {
      CARDED_ATHLETE_NO: this.clientPersonalDetails.CARDED_ATHLETE_NO,
      ALLOW_NZAS_EXTRACT_IND: this.clientPersonalDetails.ALLOW_NZAS_EXTRACT_IND,
      INTERNAL_SPORT_ID: this.clientPersonalDetails.INTERNAL_SPORT_ID,
      CARD_LEVEL_ID: this.clientPersonalDetails.CARD_LEVEL_ID,
    };

    this.patchClientDetails = patchClientDetails;
  }

  onSave(): void {
    if (!this.clientDetailsForm.touched) {
      return;
    }

    let clientDetailsData: ClientDetailsPatchModel = {
      CARDED_ATHLETE_NO: this.cardedAthleteNumberFC.value || null,
      ALLOW_NZAS_EXTRACT_IND: this.allowHPSNZExportFC.value === true ? 1 : 0,
      INTERNAL_SPORT_ID: (this.internalSportFC.value as AdminTypeDBModel)?.id || null,
      CARD_LEVEL_ID: (this.cardLevelFC.value as AdminTypeDBModel)?.id || null,
    };
    const clientDetailsPatchOperations = compare(this.patchClientDetails, clientDetailsData, true);

    this.clientDetailsForm.disable();

    this._clientService.patchClientDetails(this.clientPersonalDetails.CLIENT_ID, clientDetailsPatchOperations).subscribe(
      (response) => {
        if (response) {
          if (this._clientService.selectedClient) {
            this._clientService.selectedClient.PersonalDetails = response;
          }
          this.clientDetailInitialData.ClientDetails.PersonalDetails = response;
          this.untouchedClientDetailsFormData = this.clientDetailsForm.value;
          this.setPatchClientDetails();

          this.status = EditCardStatus.NORMAL;
          this.cardBeingEdited.emit(false);
          this.clientDetailsForm.enable();
        }
      },
      (err) => {
        console.log(err);
        this._loadingService.hide();
        this.clientDetailsForm.enable();
      }
    );
  }

  onCancel(): void {
    if (JSON.stringify(this.untouchedClientDetailsFormData) !== JSON.stringify(this.clientDetailsForm.value)) {
      const config = new MatDialogConfig();
      config.data = {
        title: "Changes unsaved",
        content: "You have changes unsaved. Are you sure you want to leave?",
        type: DialogType.YES_NO,
      };
      const dialogRef = this._dialog.open(DialogComponent, config);

      dialogRef.afterClosed().subscribe((result: DialogOutput) => {
        if (!result) {
          return;
        }

        this.close();
      });
    } else {
      this.close();
    }
  }

  onEdit(): void {
    if (!this.canEdit) {
      return;
    }

    this.status = EditCardStatus.EDIT;
    this.cardBeingEdited.emit(true);
  }

  close(): void {
    this.clientDetailsForm.reset();
    this.innitFormControls();
    this.setInitialClientData();

    this.status = EditCardStatus.NORMAL;
    this.cardBeingEdited.emit(false);
  }
}
