import { Injectable } from "@angular/core";
import { SchedulerEvent } from "@progress/kendo-angular-scheduler";
import { APPOINTMENT, AppointmentProductsResponse, AppointmentServicesResponse, CreateApptRequestModel, Student } from "app/modules/appointment/appointment.types";
import { AppointmentStatusResponse } from "app/modules/calendar/calendar.types";
import { CalendarEventModel } from "app/modules/calendar/models/calendar-event.model";
import { Observable } from "rxjs";
import { APIService } from "../api/api.service";
import { ClientAppointmentProductModel } from "../models/appointment/client-appointment-product.model";
import { CompanyModel } from "../models/organisation/company.model";
import { LedgerAccountModel } from "../models/organisation/ledger-account.model";
import { PhysioAppointmentModel } from "../models/physio/physio-appointment.model";

@Injectable({ providedIn: "root" })
export class AppointmentService {
  constructor(private _apiService: APIService) { }

  createQuickAppointment(createApptModel: CreateApptRequestModel): Observable<SchedulerEvent> {
    return this._apiService.post(`/appointments?command=createQuickAppt`, createApptModel);
  }

  createPhysioAppointment(createApptModel: CreateApptRequestModel): Observable<SchedulerEvent> {
    return this._apiService.post(`/appointments?command=createPhysioAppt`, createApptModel);
  }

  getApptProductsDebtors(debtorType: number, apptId = 0): Observable<AppointmentProductsResponse> {
    return this._apiService.get(`/appointments?command=getAppointmentProductsDebtors&id=${apptId}&DebtorType=${debtorType}`);
  }

  getApptServicesInitialData(debtorType: number, apptDate: string): Observable<AppointmentServicesResponse> {
    return this._apiService.get(`/appointments?command=getApptServicesInitialData&id=${debtorType}&ApptDate=${apptDate}`);
  }

  getNextAvailableApptStatus(apptId: number, currentStatus: number, apptType: number): Observable<AppointmentStatusResponse[]> {
    return this._apiService.get(
      `/appointments?command=getNextAvailableApptStatus&id=${apptId}&CurrentStatus=${currentStatus}&ApptType=${apptType}`
    );
  }

  updateAppointmentStatus(apptId: number, statusId: number) {
    var request = {
      Id: apptId,
      StatusId: statusId,
    };
    return this._apiService.put(`/appointments?command=updateApptStatus`, request);
  }

  getActiveStudents(atDate: string, siteId: number): Observable<Student[]> {
    return this._apiService.get(`/appointments?command=getActiveStudents&atDate=${atDate}&siteId=${siteId}`);
  }

  getAppointmentById(apptId: number): Observable<APPOINTMENT> {
    return this._apiService.get(`/appointments?command=getAppointmentById&apptId=${apptId}`);
  }

  getPhysioAppointmentByAppointmentId(apptId: number): Observable<PhysioAppointmentModel> {
    return this._apiService.get(`/appointments?command=getPhysioAppointmentByAppointmentId&apptId=${apptId}`);
  }

  getNextClientInternalRefNo(): Observable<number>{
    return this._apiService.get(`/appointments?command=getNextClientInternalRefNo`);
  }
}
