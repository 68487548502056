import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import {
    filterClientData,
    filterClinicData,
    filterProviderData,
    filterReferrerData,
    healthlinkData
} from './data';

@Injectable({
    providedIn: 'root'
})
export class HealthlinkMockApi {
    constructor(private _fuseMockApiService: FuseMockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    /**
     * Register Mock API handlers
     */
     registerHandlers(): void
    {
        this._fuseMockApiService.onGet('api/v1/gpm/healthlink/getAllHlMessages/')
            .reply(() => {
                // Return the response
                const data = healthlinkData;
                return [200, data];
            });
        this._fuseMockApiService.onGet('api/v1/gpm/client/get_clients_by_name/')
            .reply(() => {
                const data = filterClientData;
                return [200, data];
            });
        this._fuseMockApiService.onGet('api/v1/gpm/clinic/getClinics')
            .reply(() => {
                const data = filterClinicData;
                return [200, data];
            });

        this._fuseMockApiService.onGet('api/v1/gpm/providers/getActivePhysioProviderByVendor/')
            .reply(() => {
                const data = filterProviderData;
                return [200, data];
            });

        this._fuseMockApiService.onGet('api/v1/gpm/referrer/filterReferrers/')
            .reply(() => {
                const data = filterReferrerData;
                return [200, data];
            });
    }
}
