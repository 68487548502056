import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ColumnItem, TableActionType, TableItemAction } from "@components/table/mat/mat-table-wrapper.service";
import {
  FilterColumnService,
  FilterItem,
  FilterItemHelper,
} from "@components/table/table-column-filter/table-serverside-column-filter.service";
import { ServerSideTableService } from "@components/table/table-serverside.service";
import { ClientService } from "app/core/client/client.service";
import { VfUploadsModel } from "app/core/models/organisation/vf-uploads.model";
import { DateTimeService } from "app/core/services/datetime.service";
import { UploadService } from "app/core/services/upload.service";
import { Observable, of, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { UploadFileType, UploadsRequestModel } from "../letters-uploads.types";

const CLIENT_LETTERS_FIELDS = {
  DOCUMENT_DATE: "document_date",
  UPLOADED_DATE: "uploaded_date",
  FILE_TYPE: "file_type",
};

@Injectable()
export class UploadsTableService implements ServerSideTableService {
  protected ownerId: number;

  constructor(protected clientsService: ClientService, private _datetimeService: DateTimeService) {}

  set OwnerId(id: number) {
    this.ownerId = id;
  }

  getActionList(): TableItemAction[] {
    return [];
  }
  getColumnDefinitions(): ColumnItem[] {
    return [
      {
        fieldName: "DOCUMENT_DATE",
        displayName: "Document Date",
        config: {
          isDate: true,
        },
      },     
      { fieldName: "FILE_TYPE_DESCRIPTION", displayName: "File Type" },
      { fieldName: "ORIGINAL_FILENAME", displayName: "File Name" },
      { fieldName: "DETAILS", displayName: "Details" },
      { fieldName: "UPLOADED_BY", displayName: "Uploaded By" },
      {
        fieldName: "LAST_UPLOADED_DATE",
        displayName: "Uploaded Date",
        config: {
          isDateTime: true,
        },
      },
    ];
  }
  getData(selectedFilterItem: FilterItem[]): Observable<VfUploadsModel[]> {
    const docDateFilterValue: any = FilterItemHelper.retrieveFilterValueForNameItem(CLIENT_LETTERS_FIELDS.DOCUMENT_DATE, selectedFilterItem);

    const docDateFrom =
        docDateFilterValue && docDateFilterValue.filterFrom
        ? this._datetimeService.FormatDate(docDateFilterValue.filterFrom, "MM/DD/yyyy")
        : "";
    const docDateTo =
        docDateFilterValue && docDateFilterValue.filterTo
        ? this._datetimeService.FormatDate(docDateFilterValue.filterTo, "MM/DD/yyyy")
        : "";

    const uploadedDateFilterValue: any = FilterItemHelper.retrieveFilterValueForNameItem(CLIENT_LETTERS_FIELDS.UPLOADED_DATE, selectedFilterItem);

    const uploadedDateFrom =
        uploadedDateFilterValue && uploadedDateFilterValue.filterFrom
        ? this._datetimeService.FormatDate(uploadedDateFilterValue.filterFrom, "MM/DD/yyyy")
        : "";
    const uploadedDateTo =
        uploadedDateFilterValue && uploadedDateFilterValue.filterTo
        ? this._datetimeService.FormatDate(uploadedDateFilterValue.filterTo, "MM/DD/yyyy")
        : "";

    const fileTypeFilter: any = FilterItemHelper.retrieveFilterValueForNameItem(CLIENT_LETTERS_FIELDS.FILE_TYPE, selectedFilterItem);
    const fileTypeId = fileTypeFilter ? fileTypeFilter.filterValue : "";

    const requestModel: UploadsRequestModel = {
      ownerId: this.ownerId,
      docDateFrom: docDateFrom,
      docDateTo: docDateTo,
      uploadedDateFrom: uploadedDateFrom,
      uploadedDateTo: uploadedDateTo,
      fileTypeId: fileTypeId,
    };

    return this.clientsService.getClientUploads(requestModel).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}

@Injectable()
export class UploadsFilterColumnService implements FilterColumnService {
  constructor(private _uploadService: UploadService) {}

  getAllowedFilterFields(): Array<FilterItem> {
    return [
      {
        fieldName: CLIENT_LETTERS_FIELDS.DOCUMENT_DATE,
        displayName: "Document Date",
        config: {
          isDateRange: true,
          label: "Document Date"
        },
      },
      {
        fieldName: CLIENT_LETTERS_FIELDS.UPLOADED_DATE,
        displayName: "Uplaoded Date",
        config: {
          isDateRange: true,
          label: "Uploaded Date"
        },
      },
      {
        fieldName: CLIENT_LETTERS_FIELDS.FILE_TYPE,
        displayName: "Document(File) Type",
        config: {
          isSelect: true,
          selectData: "",
          placeholder: "Select file type",
          label: "Select file type",
          textField: "Name",
          valueField: "Id",
        },
      },
    ];
  }

  getSelectDataForFilterItem(item: FilterItem, params: any): Observable<any[]> | null {
    let results: Observable<any> = of([]);
    const { selectedFilters } = params;
    switch (item.fieldName) {
      case CLIENT_LETTERS_FIELDS.FILE_TYPE:
        results = this.getUploadFileTypes();
        break;
    }
    return results;
  }

  getUploadFileTypes(): Observable<UploadFileType[]> {
    return this._uploadService.getUploadFileTypes().pipe(
      map((fileTypes: any) => {
        const uploadFileTypes: Array<any> = fileTypes && fileTypes.length > 0 ? fileTypes : [];
        return uploadFileTypes;
      })
    );
  }

  getDataButtonText(): string {
    // return "Get Client Letters";
    return "";
  }
  
  getPageTitle(): string {
    return "Uploads";
  };

  getFilterServiceUniqueName(): string {
    return "Uploads_Filter";
  }
}
