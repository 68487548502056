import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TableItemAction } from "@components/table/mat/mat-table-wrapper.service";
import { AppointmentUsageFilterService, AppointmentUsageTableService } from "./appointment-usage-table.service";



@Component({
  selector: "app-appointment-usage",
  templateUrl: "./appointment-usage.component.html",
  providers: [AppointmentUsageTableService, AppointmentUsageFilterService],
})
export class MembershipAppointmentUsageComponent implements OnInit {
  clientId: string;
  constructor(
    private _activatedRoute: ActivatedRoute,
    public tableService: AppointmentUsageTableService,
    public filterService: AppointmentUsageFilterService,
  ) {
    const params = this._activatedRoute.snapshot.params;
    this.clientId = params['clientId'];

    this.tableService.clientId = this.clientId;
  }

  ngOnInit(): void {

  }

  onActionHandler(item: { rowItem: any; action: TableItemAction }): void {

  }
}
