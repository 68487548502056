<mat-card class="card-info-content m-0 p-4" [class.hoverable-item]="navigationVisible" [style.height.px]="fixedHeight"
    [class.cursor-pointer]="navigationVisible" (click)="onExpandHandler()">
  <mat-icon *ngIf="navigationVisible" class="hovered-content card-info-content-expand-icon"
    svgIcon="gpm_arrow_navigate_to" matTooltip="{{expandTooltip}}"></mat-icon>
  <mat-card-header class="card-info-header">
    <mat-card-title>{{ title }}</mat-card-title>
  </mat-card-header>
  <mat-card-content class="card-info-content-body">
    <ng-content></ng-content>
  </mat-card-content>
  <mat-card-actions class="card-info-content-actions">
  </mat-card-actions>
</mat-card>
