import { ColumnItem } from './mat-table-wrapper.service';

export enum CellStatus {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

export enum CellDataConfig {
  TEXT = 'TEXT',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  TIME = 'TIME',
  DAY = 'DAY',
  YES_NO = 'YES_NO',
  CURRENCY = 'CURRENCY',
}

// for text theme and color
export enum TextTheme {
  RUBY = 'RUBY',
  GREY = 'GREY',
}

export enum CellAlignment {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export interface CellTextStyle {
  theme: TextTheme;
  isUnderline: boolean;
  alignment: CellAlignment;
  displayTooltip: boolean;
}

// event for edit mode of mat-table-cell. Right now in edit mode, we only handle the input
export interface MatableContentDataChange {
  columnItemDef: ColumnItem;
  newRowData: any;
  rowData: any;
}

// cell config for mat-table-cell. MatTable of angular material does not provide way to have configuration for mat-cell
// For example we want to display some red text base on the dynamic data of the row.
// CellConfig give the outsider a way to determine what need to render in the cell. At the moment, we only solve the warning
// message with red color for the cell, but it can extend to a be anything base on data of the cell.
export interface CellConfigProvider {
  getStyleConfigForCell: (rowData: any, columnDef: ColumnItem) => CellTextStyle;
}


