import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BreadcrumbsService } from "@components/breadcrumbs/breadcrumbs.service";
import { TableItemAction } from "@components/table/mat/mat-table-wrapper.service";
import { CacheService } from "app/core/cache/cache.service";
import { AppStateDBModel, CacheableItems } from "app/core/cache/cache.types";
import { EventTypeModel } from "app/core/models/physio/event-type.model";
import { UtilService } from "app/core/services/util.service";
import { ClientDetailsSideMenuActions, ClientEventsInitialData } from "../client.types";
import { ClientEventsFilterColumnService, ClientEventsServerSideTableService } from "./client-events-table.service";
import { ActionToItem } from "./client-events.types";

@Component({
  selector: "app-client-events",
  templateUrl: "client-events.component.html"
})
export class ClientEventsComponent implements OnInit, AfterViewInit {
  @ViewChild("subjectDetailsTpl") subjectDetailsTpl: TemplateRef<any>;

  clientId: number;
  pageTitle: string;

  ClientDetailsSideMenuActions = ClientDetailsSideMenuActions;

  isDataLoaded: boolean;

  constructor(
    public tableService: ClientEventsServerSideTableService,
    public filterColumnService: ClientEventsFilterColumnService,
    private _utilService: UtilService,
    private _activatedRoute: ActivatedRoute,
    private _cacheService: CacheService,
    private _router: Router
  ) {
    const params = this._activatedRoute.snapshot.params;
    const clientId = params["clientId"];
    this.clientId = +clientId;
    this.tableService.clientId = clientId;

    this.isDataLoaded = false;
  }

  ngOnInit() {
    this._activatedRoute.data.subscribe((data: any) => {
      const initialData = data.initialData as ClientEventsInitialData;
      const clientPersonalDetails = data.initialData.ClientDetails;
      // Page Title
      this.pageTitle = this._utilService.getFullNameDob(clientPersonalDetails.FIRST_NAME, clientPersonalDetails.LAST_NAME, clientPersonalDetails.DOB);

      const eventTypes = initialData.EventTypes;
      let defaultEventType: EventTypeModel = {
        Id: 0,
        Name: "All event types",
        IsCustom: false,
      };
      eventTypes.splice(0, 0, defaultEventType);

      this.filterColumnService.eventTypes = eventTypes;
      this.tableService.eventTypes = eventTypes;

      let systemUsers = initialData.ActiveSystemUsers;
      const userId = this._cacheService.get(CacheableItems.GPMSystemUserId);
      const user = systemUsers.find((su) => su.id === userId);

      if (!user?.isSupport) {
        systemUsers = systemUsers.filter((su) => !su.isSupport);
      }

      this.tableService.systemUsers = systemUsers;

      let teams = initialData.ActiveTeams;

      let actionToItems: ActionToItem[] = [];

      if (systemUsers && systemUsers.length > 0) {
        systemUsers.forEach((user) => {
          let actionToItem: ActionToItem = {
            id: user.id,
            name: user.displayName,
            isTeam: false,
          };
          actionToItems.push(actionToItem);
        });
      }

      if (teams && teams.length > 0) {
        teams.forEach((team) => {
          let actionToItem: ActionToItem = {
            id: team.id,
            name: `Any in User Group - ${team.name}`,
            isTeam: true,
          };
          actionToItems.push(actionToItem);
        });
      }

      let defaultActionTo: ActionToItem = {
        id: 0,
        name: "All users",
        isTeam: false,
      };
      actionToItems.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      actionToItems.splice(0, 0, defaultActionTo);

      this.filterColumnService.actionToItems = actionToItems;
      this.tableService.actionToItems = actionToItems;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.filterColumnService.subjectDetailsTpl = this.subjectDetailsTpl;
      this.isDataLoaded = true;
    }, 500);
  }

  onActionHandler(item: { rowItem: any; action: TableItemAction }): void {}

  navigateToPage(url: string): void {
    this._router.navigate([url]);
  }
}
