
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DateTimeService } from "./datetime.service";
import { EntityTypeEnum } from "../enums/physio-enums";
import { EmailAttachmentType, EmailEnums, EmailTemplateType } from "../enums/email.enums";
import { APIService } from "../api/api.service";
import { Observable } from "rxjs";
import { EmailTemplateModel } from "../models/organisation/email-template.model";
import { VendorSesEmailModel } from "../models/organisation/vendor-ses-email.model";
import { EmailData } from "@components/email-compose/email.types";

export interface AttachmentType {
  id: number;
  text: string;
}

export interface TemplateType {
  id: number;
  text: string;
}

@Injectable()
export class EmailService {

  constructor(
    private _apiService: APIService,
    private _datetimeService: DateTimeService
  ) {}

  getAttachmentTypesByEntityType(entityType: EntityTypeEnum): AttachmentType[] {
    const attachmentTypes: AttachmentType[] = [];
    // attachmentTypes.push({
    //   id: EmailAttachmentType.LocalFile,
    //   text: EmailEnums.getAttachmentTypeDescription(
    //     EmailAttachmentType.LocalFile
    //   )
    // });
    switch (entityType) {
      case EntityTypeEnum.DEBTOR:
        attachmentTypes.push({
          id: EmailAttachmentType.Letter,
          text: EmailEnums.getAttachmentTypeDescription(
            EmailAttachmentType.Letter
          )
        });
        attachmentTypes.push({
          id: EmailAttachmentType.Upload,
          text: EmailEnums.getAttachmentTypeDescription(
            EmailAttachmentType.Upload
          )
        });
        return attachmentTypes;
      case EntityTypeEnum.CLIENT:
        attachmentTypes.push({
          id: EmailAttachmentType.Letter,
          text: EmailEnums.getAttachmentTypeDescription(
            EmailAttachmentType.Letter
          )
        });
        attachmentTypes.push({
          id: EmailAttachmentType.Upload,
          text: EmailEnums.getAttachmentTypeDescription(
            EmailAttachmentType.Upload
          )
        });
        attachmentTypes.push({
          id: EmailAttachmentType.CustomFormEntry,
          text: EmailEnums.getAttachmentTypeDescription(
            EmailAttachmentType.CustomFormEntry
          )
        });
        attachmentTypes.push({
          id: EmailAttachmentType.MedicalNotes,
          text: EmailEnums.getAttachmentTypeDescription(
            EmailAttachmentType.MedicalNotes
          )
        });
        return attachmentTypes;
      // TODO: add more entity types as we go  if required
    }
  }

  getEmailTemplateTypesByEntityType(entityType: EntityTypeEnum): TemplateType[] {
    const emailTemplateTypes: TemplateType[] = [];
    switch (entityType) {
      case EntityTypeEnum.INVOICE:
        emailTemplateTypes.push({
          id: EmailTemplateType.InvoiceRecovery,
          text: EmailEnums.getEmailTemplateTypeDescription(
            EmailTemplateType.InvoiceRecovery
          )
        });
        return emailTemplateTypes;
      case EntityTypeEnum.BUSINESS_PAYMENT:
        emailTemplateTypes.push({
          id: EmailTemplateType.BusinessPayment,
          text: EmailEnums.getEmailTemplateTypeDescription(
            EmailTemplateType.BusinessPayment
          )
        });
        return emailTemplateTypes;
      case EntityTypeEnum.CASH_SALE:
        emailTemplateTypes.push({
          id: EmailTemplateType.CashSale,
          text: EmailEnums.getEmailTemplateTypeDescription(
            EmailTemplateType.CashSale
          )
        });
        return emailTemplateTypes;
      case EntityTypeEnum.DEBTOR_STATEMENT:
        emailTemplateTypes.push({
          id: EmailTemplateType.DebtorStatement,
          text: EmailEnums.getEmailTemplateTypeDescription(
            EmailTemplateType.DebtorStatement
          )
        });
        return emailTemplateTypes;
      case EntityTypeEnum.CREDITOR_PAYMENT:
        emailTemplateTypes.push({
          id: EmailTemplateType.CreditorPayment,
          text: EmailEnums.getEmailTemplateTypeDescription(
            EmailTemplateType.CreditorPayment
          )
        });
        return emailTemplateTypes;
      case EntityTypeEnum.EXPENSE:
        emailTemplateTypes.push({
          id: EmailTemplateType.Expense,
          text: EmailEnums.getEmailTemplateTypeDescription(
            EmailTemplateType.Expense
          )
        });
        return emailTemplateTypes;
      case EntityTypeEnum.CLIENT:
        emailTemplateTypes.push({
          id: EmailTemplateType.Client,
          text: EmailEnums.getEmailTemplateTypeDescription(
            EmailTemplateType.Client
          )
        });
        return emailTemplateTypes;
      case EntityTypeEnum.APPOINTMENT:
        emailTemplateTypes.push({
          id: EmailTemplateType.ClientAppointment,
          text: EmailEnums.getEmailTemplateTypeDescription(
            EmailTemplateType.ClientAppointment
          )
        });
        emailTemplateTypes.push({
          id: EmailTemplateType.AppointmentReminder,
          text: EmailEnums.getEmailTemplateTypeDescription(
            EmailTemplateType.AppointmentReminder
          )
        });
        emailTemplateTypes.push({
          id: EmailTemplateType.TelehealthAppointmentReminder,
          text: EmailEnums.getEmailTemplateTypeDescription(
            EmailTemplateType.TelehealthAppointmentReminder
          )
        });
        return emailTemplateTypes;
      case EntityTypeEnum.INSURER_SCHEDULE:
        emailTemplateTypes.push({
          id: EmailTemplateType.InvoiceRecovery,
          text: EmailEnums.getEmailTemplateTypeDescription(
            EmailTemplateType.InvoiceRecovery
          )
        });
        return emailTemplateTypes;
      case EntityTypeEnum.CLIENT_CONDITION:
        emailTemplateTypes.push({
          id: EmailTemplateType.Condition,
          text: EmailEnums.getEmailTemplateTypeDescription(
            EmailTemplateType.Condition
          )
        });
        return emailTemplateTypes;
      case EntityTypeEnum.CUSTOM_CONTRACT:
        emailTemplateTypes.push({
          id: EmailTemplateType.Contract,
          text: EmailEnums.getEmailTemplateTypeDescription(
            EmailTemplateType.Contract
          )
        });
        return emailTemplateTypes;

      default:
        return emailTemplateTypes;
      // TODO: add more entity types as we go if required
    }
  }
  
  public getEmailSignature(siteId: number): Observable<string> {
    return this._apiService.get(`/emails?command=getEmailSignature&SiteId=${siteId}`);
  }
  public getEmailTemplates(types: number[]): Observable<EmailTemplateModel[]>{
    return this._apiService.get(`/emails?command=getEmailTemplates&TemplateTypes=${types}`);
  }
  public getEmailSenders(): Observable<VendorSesEmailModel[]>{
    return this._apiService.get(`/emails?command=getEmailSenders`);
  }

  public sendClientEmail(emailData: EmailData) : Observable<any> {
    return this._apiService.post(`/emails?command=sendEmail`, emailData);
  }
  
}
