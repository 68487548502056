<div class="w-full h-full">
    
    <app-title-header pageTitle={{pageTitle}}></app-title-header>
    
    <div class="workspace">

        <div class="flex flex-col sm:flex-row items-start gap-5">
             <app-client-details-side-menu class="w-full sm:w-auto" [option]="ClientDetailsSideMenuActions.ClientDetail" [clientId]="clientId">
            </app-client-details-side-menu>
            
            <div class="w-full">
                <div class="workspace-body">
                    <mat-card class="flex flex-col sm:flex-1">
                        <app-client-personal-info (cardBeingEdited)="checkCanEditCard($event)" [canEdit]="canEditCard">
                        </app-client-personal-info>
                        <app-client-other-information (cardBeingEdited)="checkCanEditCard($event)" [canEdit]="canEditCard">
                        </app-client-other-information>
                        <app-client-employment-detail (cardBeingEdited)="checkCanEditCard($event)" [canEdit]="canEditCard">
                        </app-client-employment-detail>
                        <app-client-home-contact-details (cardBeingEdited)="checkCanEditCard($event)" [canEdit]="canEditCard">
                        </app-client-home-contact-details>
                        <app-client-groups (cardBeingEdited)="checkCanEditCard($event)" [canEdit]="canEditCard">
                    </app-client-groups>
                    <ng-container *ngIf="isGPMNZ">
                            <app-client-athlete-details (cardBeingEdited)="checkCanEditCard($event)" [canEdit]="canEditCard">
                            </app-client-athlete-details>
                        </ng-container>
                        <app-client-notes (cardBeingEdited)="checkCanEditCard($event)" [canEdit]="canEditCard"></app-client-notes>
                    </mat-card>
                </div>
            </div>

        </div>

    </div>
    
</div>