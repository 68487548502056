<div class="column-filter-wrapper check-dialog">
  <mat-toolbar class="mat-primary m-0">
    <mat-toolbar-row class="flex flex-row justify-between items-center">
      <span class="title dialog-title">{{ data.column.displayName }}</span>
      <button mat-button (click)="matDialogRef.close()" aria-label="Close dialog" class="close-button">
        <mat-icon svgIcon="gpm_close"></mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <div *ngIf="data.column.config.checkAllOption" class="check-all">
    <mat-checkbox [checked]="selectAll" (change)="checkAll(!selectAll)">
      All
    </mat-checkbox>
  </div>
  <div class="content">
    <div class="field-group" >
      <button *ngFor="let item of data.column.config.selectData" mat-menu-item>
        <mat-checkbox [checked]="item.value" (change)="toggleItem($event, item)">{{ item.label }}</mat-checkbox>
      </button>
    </div>
  </div>
  <div class="actions">
    <button mat-raised-button color="basic" class="apply-button" (click)="applyFilter()" aria-label="Apply filter">
      Apply
    </button>
  </div>
</div>
