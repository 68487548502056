import {
    EditHealthlinkMessageData,
    HealthlinkMessagesRequestModel,
    HlNotificationDetails,
    NewHealthlinkMessageModel
} from 'app/modules/healthlink/healthlink.types';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { APIService } from '../api/api.service';
import { HealthlinkAttachmentModel } from '../models/organisation/healthlink/healthlink-attachment.model';
import { HealthLinkMessageModel } from '../models/organisation/healthlink/healthlink-message.model';
import { AccReferrerModel } from '../models/physio/acc-referrer.model';
import { HLMatchEntityDataModel } from '../models/physio/match-referrer-data.model';
import { MatchingEntity } from '../enums/healthlink-enums';

@Injectable({ providedIn: 'root' })
export class HealthlinkService {
    constructor(private _apiService: APIService) {}

    public getHeathlinkMessageById(
        messageId: number
    ): Observable<HealthLinkMessageModel> {
        return this._apiService.get(
            `/healthlink?command=messageById&id=${messageId}`
        );
    }

    public getHeathlinkMessageAndStatusById(
        messageId: number
    ): Observable<EditHealthlinkMessageData> {
        return this._apiService.get(
            `/healthlink?command=getMessageAndStatusById&id=${messageId}`
        );
    }

    public getHealthlinkMessages(
        model: HealthlinkMessagesRequestModel
    ): Observable<HealthLinkMessageModel[]> {
        const params = `FromDate=${model.DateFrom}&ToDate=${model.DateTo}&ClientId=${model.ClientId}&ReferrerId=${model.ReferrerId}&ProviderId=${model.ProviderId}&ClinicId=${model.ClinicId}&Status=${model.Status}`;
        return this._apiService.get(
            `/healthlink?command=healthlinkMessages&${params}`
        );
    }

    public saveMessage(model: NewHealthlinkMessageModel): Observable<number> {
        return this._apiService.post('/healthlink?command=saveMessage', model);
    }

    public editMessage(id: number, patchOperations: any[]): Observable<boolean> {
        return this._apiService.patch(`/healthlink?command=editMessage&id=${id}`, patchOperations);
    }

    public saveAndConfirm(hlRequest): Observable<boolean> {
        return this._apiService.put(`/healthlink?command=saveAndConfirm`, hlRequest);
    }

    sendMessage(model: NewHealthlinkMessageModel) {
        return this._apiService.post('/healthlink?command=sendMessage', model);
    }
     
    public deleteMessage(id: number): Observable<number> {
        const requestBody = { Id : id};
        return this._apiService.put(`/healthlink?command=deleteMessage`, requestBody);
    }

    public getHealthlinkAttachments(
        id: number
    ): Observable<HealthlinkAttachmentModel[]> {
        return this._apiService.get(
            `/healthlink?command=healthlinkAttachments&id=${id}`
        );
    }

    public getAccReferrers(): Observable<AccReferrerModel[]> {
        return this._apiService.get(`/healthlink?command=getACCReferrers`);
    }

    public getMatchEntityData(
        messageId: number,
        matchingEntity: MatchingEntity
    ): Observable<HLMatchEntityDataModel> {
        return this._apiService.get(
            `/healthlink?command=healthlinkMatchEntityData&id=${messageId}&heathlinkPersonType=${matchingEntity}`
        );
    }

    public confirmMessage(id: number) : Observable<boolean>{
        const requestBody = { Id : id};
        return this._apiService.put(`/healthlink?command=confirmMessage`, requestBody);
    }

    public unconfirmMessage(id: number) : Observable<boolean>{
        const requestBody = { Id : id};
        return this._apiService.put(`/healthlink?command=unconfirmMessage`, requestBody);
    }

    public getHlNotificationDetails(): Observable<HlNotificationDetails> {
        return this._apiService.get(`/healthlink?command=getHlNotificationDetails`);
    }
    public updateLastHealthlinkCheckTime(): Observable<boolean> {
        return this._apiService.put(`/healthlink?command=updateLastHealthlinkCheckTime`, {});
    }
}
