<div class="max-h-140 sm:h-auto">
    <div class="mat-dialog-header">
        <h2 mat-dialog-title>Vaccination Status for {{data.clientName}} DOB: {{data.clientDOB | formatDate}}</h2>
    </div>
    <div mat-dialog-content class="flex flex-col gap-4">

        <!-- Status set by -->
        <mat-form-field class="w-full mt-1 pointer-events-none" appearance="outline">
            <mat-label>Status set by</mat-label>
            <input matInput readonly [formControl]="setByFC">
        </mat-form-field>

        <!-- Status set Date -->
        <mat-form-field class="w-full pointer-events-none" appearance="outline">
            <mat-label>Status set Date</mat-label>
            <input matInput readonly [formControl]="setDateFC">
        </mat-form-field>

        <!-- Vaccination Status -->
        <mat-form-field class="w-full" appearance="outline">
            <mat-label>Vaccination Status</mat-label>
            <mat-select [formControl]="statusFC">
                <mat-option (onSelectionChange)="onStatusSelect($event)" *ngFor="let status of vaxStatuses"
                    [value]="status">{{status.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-- Status Date -->
        <ng-container *ngIf="this.statusFC.value?.id">
            <div class="flex flex-col gap-1">
                <mat-form-field class="w-full" appearance="outline">
                    <mat-label>Status Date</mat-label>
                    <input readonly matInput [matDatepicker]="statusDatePicker" [formControl]="statusDateFC"
                        [max]="today">
                    <mat-datepicker-toggle matSuffix [for]="statusDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #statusDatePicker></mat-datepicker>
                </mat-form-field>
                <p>{{dateDiff > 0 ? dateDiff === 1 ? dateDiff + ' day ago' : dateDiff + ' days ago' : 'Today'}}</p>
            </div>
        </ng-container>

        <!-- Status Notes -->
        <mat-form-field class="w-full" appearance="outline">
            <mat-label>Status Notes</mat-label>
            <textarea matInput [formControl]="notesFC"></textarea>
        </mat-form-field>
    </div>

    <div mat-dialog-actions>
        <button mat-button [disabled]="isLoading" [color]="'accent'" (click)="save()">Apply</button>
        <button mat-button [disabled]="isLoading" [color]="'primary'" (click)="close()" cdkFocusInitial>Cancel</button>
    </div>
</div>