<div class="w-full h-full">

    <app-title-header pageTitle={{pageTitle}}></app-title-header>
    
    <div class="workspace">

        <div class="flex flex-col sm:flex-row items-start gap-5">
                
            <app-client-details-side-menu class="w-full sm:w-auto" [option]="ClientDetailsSideMenuActions.LettersUploads" [clientId] = "clientId">
            </app-client-details-side-menu>
            
            <div class="w-full">
                <div class="workspace-body">
                    <mat-card class="flex flex-col sm:flex-1 p-0">
                        <app-letters-uploads-component [ownerId]="clientId"></app-letters-uploads-component>
                    </mat-card>
                </div>
            </div>

        </div>

    </div>
</div>
