import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TableItemAction } from "@components/table/mat/mat-table-wrapper.service";
import { ClientMembershipFilterService, ClientMembershipsTableService } from "./memberships-table.service";


@Component({
  selector: "app-memberships",
  templateUrl: "./memberships.component.html",
  providers: [ClientMembershipsTableService,
  ClientMembershipFilterService],
})
export class MembershipsComponent implements OnInit {
  clientId: string;
  constructor(
    private _activatedRoute: ActivatedRoute,
    public tableService: ClientMembershipsTableService,
    public filterService: ClientMembershipFilterService
  ) {
    const params = this._activatedRoute.snapshot.params;
    this.clientId = params['clientId'];

    this.tableService.clientId = this.clientId;
  }

  ngOnInit(): void {

  }

  onActionHandler(item: { rowItem: any; action: TableItemAction }): void {

  }
}
