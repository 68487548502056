import { Injectable } from "@angular/core";
import { UtilService } from "../services/util.service";
import { environment } from "@environments/environment";
import { CacheableItems } from "../cache/cache.types";
import { Observable, of } from "rxjs";
import { IndexedDBService } from "../cache/indexed-db.service";

@Injectable()
export class TokenStorageService {
  constructor(private _utilService: UtilService, private _indexedDbService: IndexedDBService) {}

  getAccessToken(): string {
    return localStorage.getItem(CacheableItems.AccessToken);
  }

  setAccessToken(accessToken: string): TokenStorageService {
    localStorage.setItem(CacheableItems.AccessToken, accessToken);
    return this;
  }

  getRefreshToken(): Observable<string> {
    const refreshToken: string = localStorage.getItem(CacheableItems.RefreshToken);
    return of(refreshToken);
  }

  setRefreshToken(refreshToken: string): TokenStorageService {
    localStorage.setItem(CacheableItems.RefreshToken, refreshToken);
    return this;
  }

  getExpireToken(): number {
    return parseInt(localStorage.getItem(CacheableItems.ExpiresToken), 10);
  }

  setExpiresToken(expiresToken: string): TokenStorageService {
    localStorage.setItem(CacheableItems.ExpiresToken, expiresToken);
    return this;
  }

  getVendorId(): number {
    return JSON.parse(localStorage.getItem("vendorId"));
  }

  setVendorId(vendorId: number): TokenStorageService {
    localStorage.setItem("vendorId", String(vendorId));
    return this;
  }

  getLastVendorId(): number {
    return JSON.parse(sessionStorage.getItem("lastVendorId"));
  }

  setLastVendorId(vendorId: number): TokenStorageService {
    sessionStorage.setItem("lastVendorId", String(vendorId));
    return this;
  }

  getVendorsToken(): object {
    return JSON.parse(localStorage.getItem("vendors_token"));
  }

  setVendorsToken(): TokenStorageService {
    localStorage.setItem("vendors_token", "true");
    return this;
  }

  getLockscreenToken(): object {
    return JSON.parse(localStorage.getItem("lockscreen_token"));
  }

  setLockscreenToken(lockscreenToken: string): TokenStorageService {
    localStorage.setItem("lockscreen_token", lockscreenToken);
    return this;
  }

  clearSession(): void {
    // Clear all localstorage vars and only maintain login details
    const lastUser = localStorage.getItem("user_name");
    const lastOrg = localStorage.getItem("organisation_name");
    sessionStorage.clear();
    localStorage.clear();
    if (lastUser !== null) {
      localStorage.setItem("user_name", lastUser);
    }
    if (lastOrg !== null) {
      localStorage.setItem("organisation_name", lastOrg);
    }

  }

  clearTabsData(): void {
    sessionStorage.removeItem("tab");
    sessionStorage.removeItem("vld");
  }

  // user preferences
  getUserSchedulerProvider(siteId: number): object {
    return JSON.parse(localStorage.getItem("selected_provider_" + siteId + "_" + this.getVendorId()));
  }

  setUserSchedulerProvider(selectedProvider: object, siteId: number): TokenStorageService {
    localStorage.setItem("selected_provider_" + siteId + "_" + this.getVendorId(), JSON.stringify(selectedProvider));
    return this;
  }

  getUserSchedulerSite(): object {
    return JSON.parse(localStorage.getItem("selected_site_" + this.getVendorId()));
  }

  getUserSchedulerView(): object {
    return JSON.parse(localStorage.getItem("selected_view_" + this.getVendorId()));
  }

  getUserSchedulerDate(): object {
    return JSON.parse(localStorage.getItem("selected_date_" + this.getVendorId()));
  }

  setUserSchedulerSites(sites: object): TokenStorageService {
    localStorage.setItem("sites" + this.getVendorId(), JSON.stringify(sites));
    return this;
  }

  getUserSchedulerSites(): object {
    return JSON.parse(localStorage.getItem("sites" + this.getVendorId()));
  }

  setUserSchedulerSite(selectedSite: any): TokenStorageService {
    localStorage.setItem("selected_site_" + this.getVendorId(), JSON.stringify(selectedSite));
    return this;
  }

  getUserDefaultSite(): object {
    // if there is already a site selected for this user, use it otherwise get the first site from the list of user sites
    let selectedSite = this.getUserSchedulerSite();
    if (!selectedSite) {
      selectedSite = this.getUserSchedulerSites()[0];
    }

    return selectedSite;
  }

  setUserSchedulerView(selectedView: any): TokenStorageService {
    localStorage.setItem("selected_view_" + this.getVendorId(), selectedView);
    return this;
  }

  setUserSchedulerDate(selectedDate: any): TokenStorageService {
    localStorage.setItem("selected_date_" + this.getVendorId(), selectedDate);
    return this;
  }

  setMenuPrivileges(privileges: any): TokenStorageService {
    localStorage.setItem("menu_privileges", JSON.stringify(privileges));
    return this;
  }

  setUserPermissions(permissions: any): TokenStorageService {
    localStorage.setItem("user_permissions", JSON.stringify(permissions));
    return this;
  }

  getUserPermissions(): object {
    return JSON.parse(localStorage.getItem("user_permissions"));
  }

  getMenuPrivileges(): object {
    return JSON.parse(localStorage.getItem("menu_privileges"));
  }

  getSelectedClient(): object {
    const vendorId = this.getVendorId();
    return JSON.parse(localStorage.getItem("selected_client_" + vendorId));
  }

  setSelectedClient(selectedClient: any): TokenStorageService {
    localStorage.setItem("selected_client_" + this.getVendorId(), JSON.stringify(selectedClient));
    return this;
  }

  clearSelectedClient(): any {
    localStorage.removeItem("selected_client_" + this.getVendorId());
  }

  setCurrentUser(currentUser: any): TokenStorageService {
    sessionStorage.setItem("current_user", JSON.stringify(currentUser));
    return this;
  }

  setDesktopVersion(isDesktop: boolean): any {
    localStorage.setItem("desktop_version", isDesktop.toString());
  }

  isDesktopVersion(): boolean {
    const isDesktop = localStorage.getItem("desktop_version");
    if (isDesktop != null) {
      return isDesktop === "true";
    } else {
      return false;
    }
  }

  getCurrentUser(): object {
    return JSON.parse(sessionStorage.getItem("current_user"));
  }

  getNewAppointmentDuration(): number {
    const newApptDuration = localStorage.getItem("new_appt_duration");
    if (newApptDuration != null) {
      return +newApptDuration;
    } else {
      return 5;
    }
  }

  setNewAppointmentDuration(newApptDuration: any): void {
    localStorage.setItem("new_appt_duration", newApptDuration);
  }

  getFillFormAccess(): object {
    return JSON.parse(sessionStorage.getItem("FillFormAccess"));
  }

  setFillFormAccess(): TokenStorageService {
    sessionStorage.setItem("FillFormAccess", "TRUE");
    return this;
  }

  getWinLogin(): object {
    return JSON.parse(sessionStorage.getItem("WinLogin"));
  }

  setWinLogin(): TokenStorageService {
    sessionStorage.setItem("WinLogin", "TRUE");
    return this;
  }

  getCacheProviderSchedule(providerId: number, siteId: number): object {
    return JSON.parse(sessionStorage.getItem("pSchedule" + "_" + providerId + "_" + siteId));
  }

  setCacheProviderSchedule(providerId: number, siteId: number, providerSchedule: object): void {
    sessionStorage.setItem("pSchedule" + "_" + providerId + "_" + siteId, JSON.stringify(providerSchedule));
  }

  setLockTime(lockTime: number): void {
    sessionStorage.setItem("locktime", String(lockTime));
  }

  getLockTime(): number {
    const ltime = Number.parseInt(sessionStorage.getItem("locktime"), 10);
    if (isNaN(ltime) || ltime === 0 || this._utilService.isMobile()) {
      return environment.locktime;
    } else {
      return ltime;
    }
  }
}
