import { Component, OnInit, Inject } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpinnerService } from 'app/core/services/spinner.service';
import { DialogInput, } from '../table-serverside-column-filter.service';

@Component({
  selector: 'app-check-dialog-component',
  templateUrl: './check-dialog.component.html',
  styleUrls: ['./check-dialog.component.scss']
})
export class CheckDialogComponent implements OnInit {
  selectedItems: any[] = [];
  filterText = '';
	selectAll = false;
  constructor(
    private spinnerService: SpinnerService,
    public matDialogRef: MatDialogRef<CheckDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogInput
  ) {}

  ngOnInit(): void {
    const filterValue: any = this.data.column && this.data.column.filterValue
      ? this.data.column.filterValue.filterValue
      : null;

    if (filterValue != null) {
      filterValue.forEach((element) => {
        this.selectedItems.push(element);
      });
    } else {
      this.data.column.config.selectData.forEach((element) => {
        element.value = false;
      });
    }

		this.setSelectAll();
  }

  toggleItem(event: MatCheckboxChange, item: any): void {
    item.value = event.checked;
		if (item.value) {
			this.selectedItems.push(item);
			this.setSelectAll();
		}
		else {
      this.selectedItems.forEach((element, index) => {
				if (element === item) {this.selectedItems.splice(index, 1);}
      });
			this.selectAll = false;
    }
  }

	setSelectAll(){
		this.selectAll = this.selectedItems.length === this.data.column.config.selectData.length;
	}

  checkAll(value: boolean){
		this.selectAll = value;
		this.selectedItems = [];
      this.data.column.config.selectData.forEach((element) => {
      element.value = value;
      if (value) {this.selectedItems.push(element);}
    });
  }

  applyFilter() {
    let filterData = {
      filterText: '',
      filterValue: null,
    };

    if (this.selectedItems.length > 0) {
			if (this.selectAll && this.data.column.config.checkAllOption) {
				filterData.filterText = 'All';
			}
			else {
				filterData.filterText = this.selectedItems
					.map(item => item.label)
					.join(', ');
			}

      filterData.filterValue = this.selectedItems;
    } else {
      filterData = null;
    }

    this.matDialogRef.close({
      item: this.data.column,
      filterValue: filterData
    });
  }
}
