import { Component, HostBinding, OnInit, OnDestroy, ViewEncapsulation, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ClientService } from "app/core/client/client.service";
import { ClientCreateDialogActions } from "app/core/enums/client.create-enums";
import { ClientByNameModel } from "app/core/models/clients-by-name.model";
import { NewClientRequestModel } from "../../client.types";

@Component({
  selector: "duplicate-clients-dialog",
  templateUrl: "./duplicate-clients-dialog.component.html",
  styleUrls: ["./duplicate-clients-dialog.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DuplicateClientsDialogComponent implements OnInit, OnDestroy {
  @HostBinding("class") classes = "duplicate-clients-dialog";

  public ClientInfo: NewClientRequestModel;
  public Duplicates: ClientByNameModel[];

  constructor(
    public dialogRef: MatDialogRef<DuplicateClientsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _clientService: ClientService,
    private _router : Router
  ) {
    this.ClientInfo = this.data.client;
    this.Duplicates = this.data.duplicates;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  close(action: ClientCreateDialogActions): void {
    if (action === null) {
      action = ClientCreateDialogActions.StayOpened;
    }
    this.dialogRef.close(action);
  }

  selectClientOnHeader(client: ClientByNameModel): void {
    const clientByName: ClientByNameModel = {
      CLIENT_ID: client.CLIENT_ID,
      VENDOR_ID: client.VENDOR_ID,
      FIRST_NAME: client.FIRST_NAME,
      KNOWN_AS: "",
      FIRST_NAMES: "",
      LAST_NAME: client.LAST_NAME,
      MAIDEN_NAME: "",
      DOB: client.DOB ? client.DOB : null,
    };
    this._clientService.setSelectedHeaderClient(clientByName);
    this.close(ClientCreateDialogActions.CloseDialog);
    this._router.navigate([`/client-details/${client.CLIENT_ID}/client`]);
  }

  CreateClient(): void {
    this.close(ClientCreateDialogActions.CreateClient);
  }
}
