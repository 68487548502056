import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogType } from './dialog.types';
import { DialogDataModel } from './models/dialog-data.model';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
})
export class DialogComponent implements OnInit {
    title: string;
    content: string;
    firstButtonText: string;
    secondButtonText: string;
    type: DialogType;
    showSecondButton: boolean;
    constructor(
        private _dialogRef: MatDialogRef<DialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: DialogDataModel
    ) {
        this.title = data.title;
        this.content = data.content;
        this.firstButtonText = data.firstButtonText;
        this.secondButtonText = data.secondButtonText;
        this.type = data.type;
        this.showSecondButton =
            this.type != DialogType.OK &&
            this.type != DialogType.CUSTOM_ONE_BUTTON;
        if (data.type == DialogType.OK) {
            this.firstButtonText = 'OK';
        } else if (data.type == DialogType.OK_CANCEL) {
            this.firstButtonText = 'OK';
            this.secondButtonText = 'Cancel';
        } else if (data.type == DialogType.YES_NO) {
            this.firstButtonText = 'YES';
            this.secondButtonText = 'NO';
        }
    }

    ngOnInit() {}
    firstButtonAction(): void {
        this._dialogRef.close(true);
    }
    secondButtonAction(): void {
        this._dialogRef.close(false);
    }
}
