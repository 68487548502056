<mat-card-content class="editable hoverable relative" [ngClass]="{expanded : status === 'EDIT'}">
  <div class="inner sm:px-4 sm:pt-4" [@growShrink]="(status === 'EDIT')? 'grow' : 'shrink'"
    [ngClass]="{grow: status === 'EDIT'}">
    <div class="form-content client-form">
      <ng-content></ng-content>
    </div>
    <div *ngIf="status === 'EDIT'" class="flex flex-row justify-end content-between nav-container">
      <mat-divider></mat-divider>
      <div class="w-full flex flex-row justify-end py-2">
        <button mat-button color="primary" (click)="selectCancel()" [disabled]="disableActions">Cancel</button>
        <button mat-button color="accent" (click)="selectSave()"
          [disabled]="disableSave || disableActions">Save</button>
      </div>
    </div>
    <ng-container *ngIf="!disableActions">
      <mat-icon class="expand-btn" svgIcon="gpm_expand_card" (click)="selectEdit()"></mat-icon>
    </ng-container>
  </div>
</mat-card-content>