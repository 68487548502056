import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class SpinnerService {
    private isLoading = new BehaviorSubject<boolean>(false);

    constructor() {}

    getValue(): Observable<boolean> {
      return this.isLoading.asObservable();
    }

    setValue(data: boolean): void {
      this.isLoading.next(data);
    }

    show(): void {
      this.setValue(true);
    }

    hide(): void {
      this.setValue(false);
    }
  }
