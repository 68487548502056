import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { LettersFilterColumnService, LettersTableService } from "./letters-table.service";

@Component({
    selector: 'app-letters-component',
    templateUrl: './letters.component.html',
    styleUrls: [],
    encapsulation: ViewEncapsulation.None,
    providers: [
        LettersFilterColumnService,
        LettersTableService,
    ],
  })

  export class LettersComponent implements OnInit {

    @Input() ownerId: number;

    constructor(public tableService: LettersTableService,
      public tableFilterService: LettersFilterColumnService)
        {
            
        }
      
    ngOnInit(): void {
      this.tableService.OwnerId = this.ownerId;
    }

    onActionHandler($event){

    }
  }
