import { PipeTransform, Pipe, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'formatYesNo'
})
export class YesNoFormatterPipe implements PipeTransform {
  constructor() {}

  transform(toConvert: number): string {
    if (!toConvert) {
      toConvert = 0;
    }

    if (toConvert === 1) {
      return 'Yes';
    }
    return 'No';
  }
}
