
<div *ngIf="!isMobile" class="workspace-left-nav flex flex-row sm:flex-col flex-wrap">
    <div class="nav_item" [ngClass]="{'selected': option === ClientDetailsSideMenuActions.ClientSummary}" (click)="_onOptionClick(ClientDetailsSideMenuActions.ClientSummary)">
        <mat-icon svgIcon="mat_outline:person"></mat-icon>
        <p class="label">Client Summary</p>
    </div>
    <div class="nav_item" [ngClass]="{'selected': option === ClientDetailsSideMenuActions.ClientDetail}" (click)="_onOptionClick(ClientDetailsSideMenuActions.ClientDetail)">
        <mat-icon svgIcon="mat_outline:person"></mat-icon>
        <p class="label">Client Detail</p>
    </div>
    <div class="nav_item" [ngClass]="{'selected': option === ClientDetailsSideMenuActions.Appointments}" (click)="_onOptionClick(ClientDetailsSideMenuActions.Appointments)">
        <mat-icon svgIcon="heroicons_solid:calendar"></mat-icon>
        <p class="label">Appointments</p>
    </div>
    <div class="nav_item" [ngClass]="{'selected': option === ClientDetailsSideMenuActions.Events}" (click)="_onOptionClick(ClientDetailsSideMenuActions.Events)">
        <mat-icon svgIcon="event"></mat-icon>
        <p class="label">Events</p>
    </div>
    <div class="nav_item" [ngClass]="{'selected': option === ClientDetailsSideMenuActions.Conditions}" (click)="_onOptionClick(ClientDetailsSideMenuActions.Conditions)">
        <mat-icon svgIcon="recent_actors"></mat-icon>
        <p class="label">Conditions</p>
    </div>
    <div class="nav_item" [ngClass]="{'selected': option === ClientDetailsSideMenuActions.Memberships}" (click)="_onOptionClick(ClientDetailsSideMenuActions.Memberships)">
        <mat-icon>card_membership</mat-icon>
        <p class="label">Memberships</p>
    </div>
    <div class="nav_item" [ngClass]="{'selected': option === ClientDetailsSideMenuActions.LettersUploads}" (click)="_onOptionClick(ClientDetailsSideMenuActions.LettersUploads)">
        <mat-icon svgIcon="description"></mat-icon>
        <p class="label">Documents</p>
    </div>
    <div class="nav_item" [ngClass]="{'selected': option === ClientDetailsSideMenuActions.Account}" (click)="_onOptionClick(ClientDetailsSideMenuActions.Account)">
        <mat-icon svgIcon="account_box"></mat-icon>
        <p class="label">Account</p>
    </div>
    <div class="nav_item" [ngClass]="{'selected': option === ClientDetailsSideMenuActions.Healthlink}" (click)="_onOptionClick(ClientDetailsSideMenuActions.Healthlink)">
        <mat-icon svgIcon="gpm_healthlink"></mat-icon>
        <p class="label">Healthlink</p>
    </div>
    <div class="nav_item" [ngClass]="{'selected': option === ClientDetailsSideMenuActions.FullHistory}" (click)="_onOptionClick(ClientDetailsSideMenuActions.FullHistory)">
        <mat-icon>history</mat-icon>
        <p class="label">Full History</p>
    </div>
    <div class="nav_item" [ngClass]="{'selected': option === ClientDetailsSideMenuActions.Relationships}" (click)="_onOptionClick(ClientDetailsSideMenuActions.Relationships)">
        <mat-icon svgIcon="account_box"></mat-icon>
        <p class="label">Relationships</p>
    </div>
</div>

<!-- Mobile Menu -->
<div class="sm:hidden flex flex-row justify-between">        
    <div class="workspace-mobile-menu-wrapper">
        <div class="workspace-mobile-menu">
            <mat-icon svgIcon="heroicons_solid:calendar"
                (click)="_onOptionClick(option)"></mat-icon>
            <p class="label">{{option}}</p>        
        </div>
        <button mat-icon-button class="workspace-mobile-menu-btn" [matMenuTriggerFor]="actionMobile" data-cy='mobile-client-menu'>
            <mat-icon svgIcon="arrow_drop_down"></mat-icon>
        </button>
    </div>
   
    <mat-menu #actionMobile="matMenu" overlapTrigger="false" class="mobile-menu-action">
        <div>
            <button *ngIf="option !== ClientDetailsSideMenuActions.ClientDetail" mat-menu-item class="flex justify-between" 
                (click)="_onOptionClick(ClientDetailsSideMenuActions.ClientDetail)">
                    Client Details
            </button>
            <button *ngIf="option !== ClientDetailsSideMenuActions.Appointments" mat-menu-item class="flex justify-between" 
            (click)="_onOptionClick(ClientDetailsSideMenuActions.Appointments)">
                Appointments
            </button>
            <button *ngIf="option !== ClientDetailsSideMenuActions.Events" mat-menu-item class="flex justify-between"
            (click)="_onOptionClick(ClientDetailsSideMenuActions.Events)">
                Events
            </button>
            <button *ngIf="option !== ClientDetailsSideMenuActions.Conditions" mat-menu-item class="flex justify-between"
            (click)="_onOptionClick(ClientDetailsSideMenuActions.Conditions)">
                Conditions
            </button>
            <button *ngIf="option !== ClientDetailsSideMenuActions.Memberships" mat-menu-item class="flex justify-between"
            (click)="_onOptionClick(ClientDetailsSideMenuActions.Memberships)">
                Memberships
            </button>
            <button *ngIf="option !== ClientDetailsSideMenuActions.LettersUploads" mat-menu-item class="flex justify-between"
            (click)="_onOptionClick(ClientDetailsSideMenuActions.LettersUploads)">
                Documents
            </button>
            <button *ngIf="option !== ClientDetailsSideMenuActions.Account" mat-menu-item class="flex justify-between"
            (click)="_onOptionClick(ClientDetailsSideMenuActions.Account)">
                Account
            </button>
            <button *ngIf="option !== ClientDetailsSideMenuActions.Healthlink" mat-menu-item class="flex justify-between"
            (click)="_onOptionClick(ClientDetailsSideMenuActions.Healthlink)">
                Healthlink
            </button>
            <button *ngIf="option !== ClientDetailsSideMenuActions.FullHistory" mat-menu-item class="flex justify-between"
            (click)="_onOptionClick(ClientDetailsSideMenuActions.FullHistory)">
                Full History
            </button>
            <button *ngIf="option !== ClientDetailsSideMenuActions.Relationships" mat-menu-item class="flex justify-between"
            (click)="_onOptionClick(ClientDetailsSideMenuActions.Relationships)">
                Relationships
            </button>
        </div>
    </mat-menu>

</div>
