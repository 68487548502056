import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Inject,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnDestroy,
  ElementRef,
  ViewChild,
  AfterViewInit,
  DoCheck
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

import { MatDialog, MatDialogRef } from "@angular/material/dialog";

import * as DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";


import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { map, startWith, takeUntil } from "rxjs/operators";
import { merge, Observable, of, Subject } from "rxjs";
import { AttachmentType, EmailService } from "app/core/services/email.service";
import { AttachmentTypeData } from "app/modules/healthlink/healthlink.types";
import { VendorSesEmailModel } from "app/core/models/organisation/vendor-ses-email.model";
import { MatSelectChange } from "@angular/material/select";
import { EmailData, EmailMasterData, EmailSuggestion, EmailSuggestItem } from "./email.types";
import { EmailAttachmentType } from "app/core/enums/email.enums";
import { MapDictionaryToArrayPipe } from "app/core/pipes/map-dictionary-to-array.pipe";
import { SystemUserModel } from "app/core/models/organisation/system-user.model";
import { SystemUserDBModel } from "app/core/cache/cache.types";

const flatFun = function(data: any[]): any[] {
  return data.reduce((acc: any[], val: any) => acc.concat(val), []);
};

@Component({
  selector: "app-email-compose",
  templateUrl: "./email-compose.component.html",
  styleUrls: ["./email-compose.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class EmailComposeComponent
  implements OnInit, OnDestroy, AfterViewInit, DoCheck {
  isLoading = false;

  public destroySubscriptions: Subject<boolean> = new Subject<boolean>();
  public CkEditor = DecoupledEditor;
  @Input() public readOnly: boolean;

  showCcField: boolean;
  showBccField: boolean;
  panelOpenState = false;

  public composeForm: FormGroup;

  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public bccSender: boolean;
  public createEvent = true;
  public highPriority = false;
  public selectedAttachmentType: EmailAttachmentType;

  public emailSuggestionControl = new FormControl();
  public suggestedEmails: EmailSuggestion[] = [];

  @Input() data: EmailData;
  @Input() masterEmailData: EmailMasterData;

  @Output() closeEmailForm = new EventEmitter<EmailData>();
  @Output() minimizeEvent = new EventEmitter<EmailData>();

  // Note these elements below is for setting padding-top value for mail-body element
  // The idea is that we use mutationObserver Web-API(it's mostly supported by all the browsers)
  // to list the event when children elements of mail-body get changed, then calculate the height of topArea
  // padding-top value is as much as height of topArea.
  @ViewChild("topArea", { static: false }) topAreaRef: ElementRef;
  public mutationObserver: MutationObserver;
  mailBodyTopPos: number;
  private topPos: number;

  _attachmentTypeListForAttachmentDialog: Array<AttachmentType>;
  emailSuggestionRecipientGroup:  Observable<EmailSuggestion[]>;

  constructor(
    public _dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private _emailService: EmailService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _mapDictionaryToArrayPipe: MapDictionaryToArrayPipe
  ) {}
  ngDoCheck(): void {
    if (this.topPos !== this.mailBodyTopPos) {
      this.mailBodyTopPos = this.topPos;
      this._changeDetectorRef.detectChanges();
    }
  }

  ngOnInit() {
    this.showCcField = false;
    this.showBccField = false;   
    this.data.EmailsTo.push(this.data.RecipientEmail);   

    console.log("Data", this.data);
    // convert the email suggestion dictionary to array and push that as EmailSuggestion type
    if(this.masterEmailData.suggestedEmails){
      const emailArray = this._mapDictionaryToArrayPipe.transform(this.masterEmailData.suggestedEmails);
      emailArray.forEach(emailGroup => {
        this.suggestedEmails.push({recipientType: emailGroup.key, emails: emailGroup.value})
      });      
    }
    
    this.createForm();

    const currentUser: SystemUserDBModel = this.masterEmailData.currentUser;

    console.log("currentUser", currentUser);
    this.bccSender = true // currentUser?.bccSenderInd;
    this.composeForm.controls["bccSender"].setValue(this.bccSender);
    this.composeForm.controls["messageText"].setValue(this.data.MessageBody);

    // // remove local file type
    // this._attachmentTypeListForAttachmentDialog = this.masterEmailData.attachmentTypes.filter(
    //   item => item.id !== EmailAttachmentType.LocalFile
    // );

    // this.emailSuggestionRecipientGroup = merge(this.composeForm.get('emailTo').valueChanges,
    // this.composeForm.get('emailCc').valueChanges,
    // this.composeForm.get('emailBcc').valueChanges)
    // .pipe(
    //   startWith(''),
    //   map(value => this._filterSuggestionGroup(value))
    // );
  }

  private _filterSuggestionGroup(value: string): EmailSuggestion[] {
    if (value) {
      return this.suggestedEmails 
        .map(group => ({recipientType: group.recipientType, emails: this._filter(group.emails, value)}))
        .filter(group => group.emails.length > 0);
    }

    return this.suggestedEmails;
  }

  private _filter(opt: EmailSuggestItem[], value: string): EmailSuggestItem[] {
    const filterValue = value.toLowerCase();
  
    return opt.filter(item => item.Email.toLowerCase().indexOf(filterValue) > -1 
    || item.DisplayName.toLowerCase().indexOf(filterValue) > -1 || item.RecipientType.toLowerCase().indexOf(filterValue) > -1)
  };

  public onSelected(email, emailRecipientType){
    const emailList = this.getEmailListByType(emailRecipientType); 

    if ((email.Email || "").trim()) {
      emailList.push(email.Email.trim());
    }
    // refresh the list after selection
    this.emailSuggestionRecipientGroup = of(this.suggestedEmails);
  }

  public onReady(editor) {
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
    editor.isReadOnly = this.readOnly;
  }

  createForm() {
    const selectedEmailFrom = this.data.SelectedEmailFrom;
    this.composeForm = this._formBuilder.group({
      emailTo: ["", Validators.required],
      emailCc: [""],
      emailBcc: [""],
      subject: [this.data.Subject, Validators.required],
      selectedEmailFrom: [selectedEmailFrom, Validators.required],
      messageText: [this.data.MessageBody],
      bccSender: [this.bccSender],
      createEvent: [this.createEvent],
      highPriority: [this.highPriority]
    });
  }

  showExtraToFields(): void {
    this.toggleCcField();
    this.toggleBccField();
  }

  toggleCcField(): void {
    this.showCcField = !this.showCcField;
  }

  toggleBccField(): void {
    this.showBccField = !this.showBccField;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    const name = event.input.name;

    const emailList = this.getEmailListByType(name); 

    if ((value || "").trim()) {
      emailList.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }
  }

  remove(emailType: string, email: string): void {
    const emailList = this.getEmailListByType(emailType);  
    const index = emailList.indexOf(email);

    if (index >= 0) {
      emailList.splice(index, 1);
    }
  }

  getEmailListByType(emailType: string) : string[]{
    let emailList: string[];
    switch (emailType) {
      case "emailTo":
        emailList = this.data.EmailsTo;
        break;
      case "emailCc":
        emailList = this.data.EmailsCc;
        break;
      case "emailBcc":
        emailList = this.data.EmailsBcc;
        break;
      default:
        emailList = this.data.EmailsTo;
    }

    return emailList;
  }

  public applyTemplate($event: any) {
    this.isLoading = true;

    const template = this.masterEmailData.emailTemplates.filter(f => f.ID === $event.value)[0];
    // TODO: reconsider the below statement when we have proper pages to call this component from

    const entityId = this.data.AppointmentId ? this.data.AppointmentId : this.data.ClientId;
    // this.emailService.createEmailFromTemplate(this.data.siteId, template.TEMPLATE_TYPE,
    //   template.ID, entityId
    // )
    // .pipe(takeUntil(this.destroySubscriptions))
    // .subscribe((response: any) => {
    //   this.data.subject = response.Subject;
    //   this.data.messageText = response.Body + this.masterEmailData.emailSignature;
    //   this.composeForm.controls["subject"].setValue(this.data.subject);
    //   this.composeForm.controls["messageText"].setValue(
    //     this.data.messageText
    //   );

    //   this.isLoading = false;
    // });
  }


  chooseClose() {
    this.closeEmailForm.emit(this.data);
  }

  sendEmail() {
    // Adding this for now as subject not populated for data.subject
    // and messageBody not being updated when changed
    // Can be sorted by front end dev.
    this.data.AppointmentId = this.data.AppointmentId ? this.data.AppointmentId : 0;
    this.data.Subject = this.composeForm.controls["subject"].value;
    this.data.MessageBody = this.composeForm.controls["messageText"].value;
    this.data.BccSender = this.composeForm.controls["bccSender"].value;
    this.data.CreateAnEvent = this.composeForm.controls["createEvent"].value;
    this.data.HighPriority = this.composeForm.controls["highPriority"].value;
    console.log(this.data);
    this._emailService.sendClientEmail(this.data).subscribe(
      data => {
        console.log(data);
        this.chooseClose();
      },
      err => console.log("HTTP Error", err),
      () => console.log("HTTP request completed.")
    );
  }

  selectEmailFromChange(event: MatSelectChange) {
    const selectedEmailValue: number = event.value;
    this.data.SelectedEmailFrom = this.masterEmailData.emailSenders.find(
      item => item.ID === selectedEmailValue
    );
  }

  ngAfterViewInit() {
    const boundary: DOMRect = this.topAreaRef.nativeElement.getBoundingClientRect();
    this.topPos = boundary.height;

    const observerOption = {
      childList: true
    };
    this.mutationObserver = new MutationObserver(
      this.changeChildrenInTopArea.bind(this)
    );
    this.mutationObserver.observe(
      this.topAreaRef.nativeElement,
      observerOption
    );
  }

  changeChildrenInTopArea(
    mutationList: Array<any>,
    observer: MutationObserver
  ) {
    const boundary: DOMRect = this.topAreaRef.nativeElement.getBoundingClientRect();
    this.mailBodyTopPos = boundary.height;
  }

  ngOnDestroy() {
    // remove the listener from mutationObserver.
    this.mutationObserver.disconnect();
  }
}
