<div class="mail-content-wrapper" [ngClass]="{'minimise': data.IsMinimize}">
  <mat-toolbar class="mat-primary m-0 mail-toolbar">
    <mat-toolbar-row class="flex flex-row space-x-4 items-center">
      <span class="title dialog-title">New Message</span>
      <div class="right-action">
        <div class="button minimize-button" (click)="data.IsMinimize = !data.IsMinimize">
          <mat-icon>{{ data.IsMinimize ? 'maximize' : 'minimize' }}</mat-icon>
        </div>
        <div class="button minimize-button" (click)="chooseClose()">
          <mat-icon>close</mat-icon>
        </div>
      </div>

    </mat-toolbar-row>
  </mat-toolbar>

  <div class="m-0 content-container">
    <form name="composeForm" [formGroup]="composeForm" class="compose-form">

      <section class="top-area" #topArea>
        <!-- TO FIELD -->
        <mat-form-field class="email-chip-list" appearance="fill">
          <mat-chip-list #chipList>

            <span matPrefix>To &nbsp;</span>

            <mat-chip *ngFor="let email of data.EmailsTo" (removed)="remove('emailTo', email)">
              {{ email }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input name="emailTo" formControlName="emailTo" placeholder=""
              [matChipInputFor]="chipList" [matAutocomplete]="autoGroup" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">

          </mat-chip-list>
          <mat-autocomplete #autoGroup="matAutocomplete" (optionSelected) = "onSelected($event.option.value, 'emailTo')">
            <mat-optgroup *ngFor="let emailGroup of emailSuggestionRecipientGroup | async" [label]="emailGroup.recipientType">
              <mat-option *ngFor="let email of emailGroup.emails" [value]="email">
                {{email.DisplayName}} {{email.Email}}
              </mat-option>
            </mat-optgroup>            
          </mat-autocomplete>
          <span class="extra-fields" matSuffix (click)="showExtraToFields()"
            *ngIf="!showCcField && !showBccField">Cc/Bcc</span>
          <span class="extra-fields" matSuffix (click)="toggleCcField()"
            *ngIf="!showCcField && showBccField">Cc</span>
          <span class="extra-fields" matSuffix (click)="toggleBccField()"
            *ngIf="showCcField && !showBccField">Bcc</span>

        </mat-form-field>
        <!-- CC FIELD -->
        <mat-form-field class="email-chip-list" appearance="fill" *ngIf="showCcField">
          <mat-chip-list #chipListCc>
            <span matPrefix>Cc &nbsp;</span>
            <mat-chip *ngFor="let email of data.EmailsCc" (removed)="remove('emailCc', email)">
              {{ email }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input name="emailCc" formControlName="emailCc" [matAutocomplete]="autoCcGroup" placeholder="" [matChipInputFor]="chipListCc"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="add($event)">
          </mat-chip-list>
          <mat-autocomplete #autoCcGroup="matAutocomplete" (optionSelected) = "onSelected($event.option.value, 'emailCc')">
            <mat-optgroup *ngFor="let emailGroup of emailSuggestionRecipientGroup | async" [label]="emailGroup.recipientType">
              <mat-option *ngFor="let email of emailGroup.emails" [value]="email">
                {{email.DisplayName}} {{email.Email}}
              </mat-option>
            </mat-optgroup>            
          </mat-autocomplete>
          <span matSuffix class="extra-fields" (click)="toggleCcField()" *ngIf="showCcField">Hide</span>

        </mat-form-field>
        <!-- BCC FIELD -->
        <mat-form-field class="email-chip-list" appearance="fill" *ngIf="showBccField">
          <mat-chip-list #chipListBcc>
            <span matPrefix>Bcc &nbsp;</span>
            <mat-chip *ngFor="let email of data.EmailsBcc" (removed)="remove('emailBcc', email)">
              {{ email }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input name="emailBcc" formControlName="emailBcc" [matAutocomplete]="autoBccGroup" placeholder="" [matChipInputFor]="chipListBcc"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="add($event)">
          </mat-chip-list>
          <mat-autocomplete #autoBccGroup="matAutocomplete" (optionSelected) = "onSelected($event.option.value, 'emailBcc')">
            <mat-optgroup *ngFor="let emailGroup of emailSuggestionRecipientGroup | async" [label]="emailGroup.recipientType">
              <mat-option *ngFor="let email of emailGroup.emails" [value]="email">
                {{email.DisplayName}} {{email.Email}}
              </mat-option>
            </mat-optgroup>            
          </mat-autocomplete>
          <span matSuffix class="extra-fields" (click)="toggleBccField()" *ngIf="showBccField">Hide</span>

          <!-- <div class="hide-extra-fields" (click)="toggleBccField()">
            <span [fxShow]="showBccField">Hide</span>
          </div> -->
        </mat-form-field>

        <!-- SUBJECT FIELD -->
        <mat-form-field appearance="fill" class="email-subject">
          <!-- <span matPrefix>Subject &nbsp;</span> -->
          <input placeholder="Subject" matInput name="subject" formControlName="subject" autocomplete="off" />
        </mat-form-field>
      </section>

      <div class="mail-body" [style.padding-top.px]="mailBodyTopPos">
        <!-- SHOW OPTIONS -->
        <section class="options-area">
          <div class="flex visible md:invisible">
            <mat-accordion>
              <mat-expansion-panel hideToggle (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{panelOpenState ? 'Hide options' : 'More options'}}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="options-area-inner">
                  <mat-form-field appearance="outline">
                    <mat-select placeholder="Select sender" formControlName="selectedEmailFrom"
                      (selectionChange)="selectEmailFromChange($event)">
                      <mat-option *ngFor="let email of masterEmailData.emailSenders" [value]="email.ID">
                        {{email.SENDER_DISPLAY_NAME}} &lt;{{email.SENDER_EMAIL}}&gt;
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-select placeholder="Choose a template" (selectionChange)="applyTemplate($event)">
                      <mat-option *ngFor="let template of masterEmailData.emailTemplates" [value]="template.ID">
                        {{ template.NAME }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="more-options-checkboxes ">
                  <mat-checkbox formControlName="bccSender">Bcc sender</mat-checkbox>
                  <mat-checkbox formControlName="createEvent">Create event</mat-checkbox>
                  <mat-checkbox formControlName="highPriority">High priority</mat-checkbox>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>

          <div class="flex flex-col invisible md:visible">
            <div class="options-area-inner flex flex-row">
              <mat-form-field appearance="outline">
                <mat-select placeholder="Select sender" formControlName="selectedEmailFrom"
                  (selectionChange)="selectEmailFromChange($event)">
                  <mat-option *ngFor="let email of masterEmailData.emailSenders" [value]="email.ID">
                    {{email.SENDER_DISPLAY_NAME}} &lt;{{email.SENDER_EMAIL}}&gt;
                  </mat-option>
                </mat-select>
                <!-- <mat-hint class="error-hint" *ngIf="errorSender()">Must select a sender</mat-hint> -->
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-select placeholder="Choose a template" (selectionChange)="applyTemplate($event)">
                  <mat-option *ngFor="let template of masterEmailData.emailTemplates" [value]="template.ID">
                    {{ template.NAME }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="more-options-checkboxes">
              <mat-checkbox formControlName="bccSender">Bcc sender</mat-checkbox>
              <mat-checkbox formControlName="createEvent">Create event</mat-checkbox>
              <mat-checkbox formControlName="highPriority">High priority</mat-checkbox>
            </div>
          </div>
        </section>

        <!-- MESSAGE FIELD -->
        <section fxFlex class="message-area">
          <ckeditor [data]="data.MessageBody" [editor]="CkEditor" (ready)="onReady($event)"
            formControlName="messageText">
          </ckeditor>
        </section>

        <!-- ATTACHMENT AREA -->
        <!-- <section class="attachments-area">
          <ng-container *ngIf="data.attachments && data.attachments.length > 0">
            <ng-container *ngFor="let attachmentTypeData of data.attachments">
              <div class="attachment-item" *ngFor="let attachment of attachmentTypeData.selectedData">
                <p class="attachment-item-label" (click)="navigateToAttachment(attachment)">
                  {{attachmentTypeData.attachmentType.text}} : {{ attachment.Name }}{{ attachment.Size}}</p>
                <mat-icon class="remove-btn" (click)="removeAttachment(attachmentTypeData, attachment)">cancel</mat-icon>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="_localFileAttachment.length > 0">
            <div class="attachment-item" *ngFor="let attachment of _localFileAttachment">
              <p class="attachment-item-label" (click)="navigateToAttachment(attachment)">
                Local : {{ attachment.Name }}{{ attachment.Size}}</p>
              <mat-icon class="remove-btn" (click)="removeLocalAttachment(attachment)">cancel</mat-icon>
            </div>
          </ng-container>
        </section> -->
      </div>
    </form>
  </div>

  <!-- action bar-->
  <div class="action-container">
    <div class="footer-shadow"></div>
    <div class="action-container-content">   
      <button mat-raised-button color="accent" class="save-button" (click)="sendEmail()" aria-label="Send mail">
        Send
      </button>
    </div>
  </div>
</div>
