import { Component, OnInit } from "@angular/core";
import { CacheService } from "app/core/cache/cache.service";
import { CacheableItems } from "app/core/cache/cache.types";
import { UtilService } from "app/core/services/util.service";

@Component({
    selector: "app-client-info-header",
    templateUrl: "client-info-header.component.html"
  })
  export class ClientInfoHeaderComponent implements OnInit{
    public clientName: string;
    public clientDob: string;
    constructor(private _cacheService: CacheService,
        private _utilService: UtilService){}
    ngOnInit(): void {
        const client = this._cacheService.get(CacheableItems.GPMSelectedClient);
        if(client){
            this.clientName = this._utilService.getFullName(client.FIRST_NAME, client.LAST_NAME);
            this.clientDob = client.DOB?.toString();
        }

    }

  }