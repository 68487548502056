import { Observable, of } from 'rxjs';
import moment from 'moment-timezone';
import { FilterOutput, GeneralFilterDialogInput, GeneralFilterDialogOutput } from "./dialogs/general-filter-dialog.service";
import { MatDialogRef } from "@angular/material/dialog";
import { FormControl } from "@angular/forms";

// FilterType of the Filter. Base on FilterType, we display different dialog for user to input
// filter value
export enum FilterType {
  TEXT_FILTER,
  DATE_RANGE_FILTER,
  DATE_FILTER,
  SELECT_FILTER,
  AUTOCOMPLETE_FILTER,
  CHECKBOX_GROUP_FILTER,
  SINGLE_CHECK_FILTER,
  SELECT_DATE_FILTER,
  CUSTOM_FILTER,
}

// Input Data structure which is used in table-column-filter.component
export interface FilterItem {
  fieldName: string;  // unique name which associates with data structure displayed in the tables
  displayName: string; // label for fieldName
  sortIndex?: number; // sortIndex to allow sorting FilterItem
  config?: any; // additional data which can be carried by FilterItem
  filterValue?: any;  // the data which is input by the user, or original data.
  filterSortIndex?: number; // optional for the filter ordering display
  initialValue?: any; // optional to set initial value for the filter without filtering the first time
}

// Configuration for the Custom Filter Item
export interface CustomFilterConfig {
  isCustom: boolean;
  templateRef: any;
  retrieveDataFun: (control: FormControl, config: FilterItem) => FilterOutput;
}

// Data which is used to display filter item in the html template of table-column-filter.component
export interface DisplayFilterItem {
  fieldName: string;
  displayName: string;
  displayValue: string;
  sortIndex: number; // it's the same value with filterSortIndex
  filterType: FilterType
}

// ==================== Class Type for Dialog ==================================
// Input type for Dialog
export interface DialogInput {
  column: FilterItem;
  serviceCallback: (item: FilterItem, parameter: any) => Observable<Array<any>>;
}

export interface SelectDateFilterSelectObject {
  name: string;
  id: number;
  isSingleDate?: boolean;
}

export interface SelectDateFilterValue {
  dateFrom: Date;
  dateTo: Date;
  dateType: SelectDateFilterSelectObject;
}

// Output for dialog - the value is itemFilter with filterValue
// filterValue structure depends on the type of dialog
// For example:
// + TextDialog has filterValue is  { filterValue: '' }
// + DateDialog has filterValue is { filterFrom: '', filterTo: ''}
// + SelectDialog and AutoCompleteDialog has filterValue is {filterText: '', filterValue: ''}
export interface DialogOutput {
  item: FilterItem;
  filterValue: any;
}

export interface TextDialogOutput extends DialogOutput {
  item: FilterItem;
  filterValue: {
    filterValue: string;
  };
}

export interface SelectDialogOutput extends DialogOutput {
  item: FilterItem;
  filterValue: {
    filterText: string;
		filterValue: string;
  };
}

export interface DateDialogOutput extends DialogOutput {
  item: FilterItem;
  filterValue: {
    filterFrom: Date;
		filterTo: Date;
  };
}

export interface AutoCompleteOutput extends DialogOutput {
  item: FilterItem;
  filterValue: {
    filterText: string;
		filterValue: string;
  };
}

export interface CheckDialogOutput extends DialogOutput {
  item: FilterItem;
  filterValue: {
    filterText: string;
    filterValue: string;
  };
}

// =====================================================================

/**
 * This class is to define logic business on the data structure above.
 * All the methods defined below is merely converting between data or how to check FilterItem is selected or not
 */
export const FilterItemHelper = {
  // lamda function to check if filterItem is selected
  checkIfFilterItemIsSelected: (item: FilterItem): boolean => !!item.filterValue,
  // retrieve selected filter list from exisiting list
  retrieveSelectedFilterList: (filterList: Array<FilterItem>): Array<FilterItem> => filterList.filter(item => FilterItemHelper.checkIfFilterItemIsSelected(item)),
  retieveNonSelectedFilterList: (filterList: Array<FilterItem>): Array<FilterItem> => filterList.filter(item => !FilterItemHelper.checkIfFilterItemIsSelected(item)),

  // find filterItem by dialogData
  findFilterItemByOutputDialogFromList: (filterList: Array<FilterItem>, dialogData: DialogOutput): FilterItem|null => {
    // find filterItem base on the fieldName. fieldName should be unique.
    const foundFieldNameList: Array<FilterItem> = filterList.filter(item => item.fieldName === dialogData.item.fieldName);
    return foundFieldNameList.length > 0 ? foundFieldNameList[0] : null;
  },

  copyFilterValueFromDialogOutputToFilterItem(filterItem: FilterItem, dialogData: DialogOutput): void {
    // copy filterValue from dialog to filterItem
    filterItem.filterValue = dialogData.filterValue;
  },

  // carry data of default value to existing filter list
  // and return selected filter list
  markFilterItemListByDefaultValue: (filterItem: Array<FilterItem>, defaultValueList: Array<DialogOutput>): Array<FilterItem> => {
    const convertedList: Array<{
      filter: FilterItem;
      dialog: DialogOutput;
    }> = defaultValueList.map(dialog => ({
        filter: FilterItemHelper.findFilterItemByOutputDialogFromList(filterItem, dialog),
        dialog: dialog,
      }));

    // remove the underfined filter
    const foundList: Array<{
      filter: FilterItem;
      dialog: DialogOutput;
    }> = convertedList.filter(item => !!item.filter);

    // update filterValue for filter
    foundList.forEach(item => FilterItemHelper.copyFilterValueFromDialogOutputToFilterItem(item.filter, item.dialog));

    return foundList.map(item => item.filter);
  },

  // get DisplayItem from filterItem
  getDisplayItemFromFilterItem: (item: FilterItem): DisplayFilterItem => {
    const defaultDateFormat = 'DD/MM/YY';
    const filterType: FilterType = FilterItemHelper.getFilterTypeFromFilterItem(item);
    let displayValue = '';
    switch (filterType) {
      case FilterType.DATE_RANGE_FILTER:
        const fromDate = moment(item.filterValue.filterFrom).format(defaultDateFormat);
        const toDate = moment(item.filterValue.filterTo).format(defaultDateFormat);
        displayValue = `${fromDate} - ${toDate}`;
        break;
      case FilterType.DATE_FILTER:
        const selectedDate = moment(item.filterValue.filterDate).format(defaultDateFormat);
        displayValue = `${selectedDate}`;
        break;
      case FilterType.CHECKBOX_GROUP_FILTER:
        displayValue = item.filterValue.filterText;
        break;
      case FilterType.SINGLE_CHECK_FILTER:
          displayValue = item.filterValue.filterValue;
          break;
      case FilterType.TEXT_FILTER:
      case FilterType.SELECT_FILTER:
      case FilterType.AUTOCOMPLETE_FILTER:
        displayValue = item.filterValue.filterText || item.filterValue.filterValue;
        break;
      case FilterType.SELECT_DATE_FILTER:
        const filterValue = item.filterValue as SelectDateFilterValue;
        if (filterValue.dateType.isSingleDate) {
          const fromDate = moment(filterValue.dateFrom).format(defaultDateFormat);
          displayValue = `${filterValue.dateType.name} ${fromDate}`;
        } else {
          const fromDate = moment(filterValue.dateFrom).format(defaultDateFormat);
          const toDate = moment(filterValue.dateTo).format(defaultDateFormat);
          displayValue = `${filterValue.dateType.name} ${fromDate} - ${toDate}`;
        }
        break;
      case FilterType.CUSTOM_FILTER:
        displayValue = item.filterValue.filterText;
      break;
    }

    return {
      fieldName: item.fieldName,
      displayName: item.displayName,
      displayValue,
      sortIndex: item.filterSortIndex,
      filterType: filterType
    };
  },

  // get filterType from filterItem
  getFilterTypeFromFilterItem: (item: FilterItem): FilterType => {
    const isDateType: boolean = item && item.config && item.config.isDate;
    const isDateRangeType: boolean = item && item.config && item.config.isDateRange;
    const isSelectType: boolean = item && item.config && item.config.isSelect;
    const isCheckBoxGroupType: boolean = item && item.config && item.config.isCheckBoxGroup;
    const isSingleCheckType: boolean = item && item.config && item.config.isSingleCheck;
    const isAutocomplete: boolean = item && item.config && item.config.isAutocompleteSelect;
    const isSelectDate: boolean = item && item.config && item.config.isSelectDate;
    const isCustomField: boolean = item && item.config && item.config.isCustom;

    const type: FilterType
      = isDateType ? FilterType.DATE_FILTER
      : isDateRangeType ? FilterType.DATE_RANGE_FILTER
      : isSelectType ? FilterType.SELECT_FILTER
      : isCheckBoxGroupType ? FilterType.CHECKBOX_GROUP_FILTER
      : isSingleCheckType ? FilterType.SINGLE_CHECK_FILTER
      : isAutocomplete ? FilterType.AUTOCOMPLETE_FILTER
      : isSelectDate ? FilterType.SELECT_DATE_FILTER
      : isCustomField ? FilterType.CUSTOM_FILTER
      : FilterType.TEXT_FILTER;

    return type;
  },
  retrieveFilterValueForNameItem: (name: string, filterItemList: Array<FilterItem>): any | null => {
    const itemFilter: FilterItem = filterItemList.find(
      item => item.fieldName === name
    );
    return itemFilter ? itemFilter.filterValue : null;
  },
  sortFilterItem: (a: FilterItem, b: FilterItem) => {
    // Attempt to sort by sortIndex.
    // Cope with columns having no sort index
    // Columns with sort index sorted first then those after sorted by name
    // If 2 columns have same sort index default back to sorting by name for them
    const bothSame =
      (a.sortIndex === undefined && b.sortIndex === undefined) ||
      a.sortIndex === b.sortIndex;
    let result = 0;

    if (bothSame) {
      // Sort by name.
      if (a.displayName < b.displayName) {result = -1;}
      if (a.displayName > b.displayName) {result = 1;}
    } else {
      if (a.sortIndex !== undefined && b.sortIndex !== undefined) {
        // use sort index
        result = a.sortIndex < b.sortIndex ? -1 : 1;
      } else {
        // Sort one which is undefined after one which is not.
        result = b.sortIndex === undefined ? -1 : 1;
      }
    }

    return result;
  }
};



export interface FilterColumnService {
  getAllowedFilterFields: () => Array<FilterItem>;
  // get selected data for filter item in case of type is select
  getSelectDataForFilterItem: (
    item: FilterItem,
    params: any
  ) => Observable<Array<any>> | null;
  getDataButtonText: () => string;
  getFilterServiceUniqueName: () => string;
  getPageTitle: () => string;

  // optional functionalities
  customOutputForDialog?: (result: GeneralFilterDialogOutput) => GeneralFilterDialogOutput
}

export class AbstractFilterColumnServer implements FilterColumnService {
  getAllowedFilterFields(): Array<FilterItem> {
    return [];
  }
  getSelectDataForFilterItem(
    item: FilterItem,
    params: any
  ): Observable<Array<any>> {
    return of([]);
  }
  getDataButtonText(): string {
    return '';
  }

  // give a unique name for the filter service to use it as the cache key for the selected filters
  getFilterServiceUniqueName(): string {
    return '';
  }

  // get the title of the page
  getPageTitle(): string {
    return '';
  }
}

// interface for filterdialog to integrate with table-serverside-column-filter component
// The service should provide a method displayFilterDialog with below parameters:
//  + containerElement: the setFilter button where the clicked event triggers.
//  + inputData: filter element definition list and the callback from the FilterService to retrieve the master data to display in the filter dialog.
// When the cusomized dialog finish collecting data, then it should use dialogRef.close with the input as below:
//  + result with type GeneralFilterDialogOutput. It contains the filterValue with each FilterItem definition. From there, the table-serverside-column-filter know how to display it.
// Please refer to the general-filter-dialog.component.ts for an example.
export interface CustomFilterDialogService {
  displayFilterDialog(containerElement: HTMLElement, inputData: GeneralFilterDialogInput): Observable<MatDialogRef<any>>;
}
