<div class="mat-dialog-header">
    <h2 mat-dialog-title>{{ title }}</h2>
</div>
<mat-dialog-content class="mat-typography">
    <div class="admin-type-dialog-content">
        <mat-form-field class="w-full pb-4" appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput [formControl] = "adminTypeNameFC" />
        </mat-form-field>
        <mat-form-field class="w-full" appearance="outline">
            <mat-label>Description</mat-label>
            <textarea matInput [formControl] = "descriptionFC"></textarea>
        </mat-form-field>  
    </div>   
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="cancel()" color="primary">
        Cancel
    </button>
    <button mat-button (click)="save()">
        Save
    </button>
</mat-dialog-actions>
