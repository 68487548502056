<app-edit-card-comp class="flex-1 info-section" [status]="status" (editEvent)="onEdit()" (saveEvent)="onSave()"
    (cancelEvent)="onCancel()" [disableSave]="clientDetailsForm.pristine" [disableActions]="isLoading">
    <div class="flex md:flex-row flex-col" (click)="onEdit()">
        <mat-card-subtitle>Employment Details</mat-card-subtitle>

        <!-- Edit View -->
        <div class="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 flex-1" *ngIf="status === 'EDIT'">
            <!-- Occupation -->
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>Occupation</mat-label>
                <ng-container *ngIf="isGPMNZ">
                    <app-autocomplete-field [formControl]="occupationFC" [items]="occupations"
                        (isLoading)="isLoading = $event"
                        (onOptionSelected)="occupationFC.setValue($event.option?.value || $event); clientDetailsForm.markAsDirty()"
                        [fetchDataFrom]="fetchOccupations">
                    </app-autocomplete-field>
                </ng-container>

                <ng-container *ngIf="isGPMAU">
                    <input matInput [formControl]="occupationFC" />
                </ng-container>
            </mat-form-field>

            <!-- Employment Type -->
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>Employment Type</mat-label>
                <mat-select [formControl]="employmentTypeFC" (selectionChange)="selectedEmploymentType = $event.value">
                    <ng-container *ngIf="employmentTypes && employmentTypes.length> 0 else noEmploymentTypes">
                        <mat-option *ngFor="let employmentType of employmentTypes" [value]="employmentType">{{
                            employmentType.NAME }}</mat-option>
                    </ng-container>

                    <ng-template #noEmploymentTypes>
                        <mat-option>No employment types</mat-option>
                    </ng-template>
                </mat-select>
            </mat-form-field>

            <!-- Employer & Work Intensity -->
            <ng-container *ngIf="displayEmployerAndWorkIntensity()">
                <!-- Employer -->
                <mat-form-field class="w-full" appearance="outline">
                    <mat-label>Employer</mat-label>
                    <app-autocomplete-field class="w-full" [formControl]="employerFC" [items]="employers"
                        (isLoading)="isLoading = $event" [fetchDataFrom]="fetchEmployers"
                        (onOptionSelected)="employerFC.setValue($event.option?.value || $event);clientDetailsForm.markAsDirty()">
                    </app-autocomplete-field>
                </mat-form-field>

                <!-- Work Intensity -->
                <mat-form-field class="w-full" appearance="outline">
                    <mat-label>Work Intensity</mat-label>
                    <mat-select [formControl]="employmentIntensityFC">
                        <ng-container
                            *ngIf="employmentIntensities && employmentIntensities.length > 0 else noEmploymentIntensities">
                            <mat-option *ngFor="let employmentIntensity of employmentIntensities"
                                [value]="employmentIntensity">{{
                                employmentIntensity.name }}</mat-option>
                        </ng-container>

                        <ng-template #noEmploymentIntensities>
                            <mat-option>No work intensities set</mat-option>
                        </ng-template>
                    </mat-select>
                </mat-form-field>
            </ng-container>
        </div>

        <!-- Display View -->
        <div class="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 flex-1" *ngIf="status !== 'EDIT'">
            <div class="w-full element">
                <div class="title">Occupation</div>
                <div class="info">{{ isGPMNZ && occupationFC.value?.name ? occupationFC.value.name : isGPMAU &&
                    occupationFC.value ? occupationFC.value : "-" }}</div>
            </div>
            <div class="w-full element">
                <div class="title">Employment Type</div>
                <div class="info">{{ employmentTypeFC.value?.NAME || "-" }}</div>
            </div>
            <!-- -1 is Not in Paid Employment -->
            <ng-container *ngIf="selectedEmploymentType && selectedEmploymentType.ID !== -1">
                <div class="w-full element">
                    <div class="title">Employer</div>
                    <div class="info">{{ employerFC.value?.name || '-' }}</div>
                </div>
                <div class="w-full element">
                    <div class="title">Work Intensity</div>
                    <div class="info">{{ employmentIntensityFC.value?.name || "-" }}</div>
                </div>
            </ng-container>
        </div>
    </div>
</app-edit-card-comp>