
import { Observable, of, Subject } from 'rxjs';
import { FilterItem } from './table-column-filter/table-serverside-column-filter.service';
import { CellConfigProvider } from './mat/mat-table-cell-content.service';
import { SummaryItemDefinition } from './mat/mat-table-summary-service';
import { ColumnGroupConfig, ColumnItem, RowItemConfig, TableActionType, TableItemAction } from './mat/mat-table-wrapper.service';

export enum ServerSideTableStatus {
    NORMAL = 'NORMAL',
    FETCHING = 'FETCHING',
    NO_DATA = 'NO_DATA',
}

/**
 * This service defines data provider for ServerSideTable Component
 * List of data needed:
 * 		+ Columns Definition
 * 		+ Display Columns
 * 		+ Action list
 * 		+ Actuall data in the table
 */
// Service to take care of business logic for table serverside
export interface ServerSideTableService {
  getActionList(): Array<TableItemAction>;
  getColumnDefinitions(): Array<ColumnItem>;
  // refreshing data by calling server with selectedFilters
  getData(selectedFilterList: Array<FilterItem>): Observable<Array<any>>;
  // Refreshing data locally by selectedFilters
  filterOfflineData?(
    currentSelectedFilter: FilterItem,
    selectedFilterList: Array<FilterItem>,
    currentData: Array<any>
  ): Array<any>;
  getDisplayColumnConfig?(): ColumnGroupConfig; // get display column group configuration. If not provided, the all the column definition is used
  getInitialSelectedData?(originalData: Array<any>, selectedFilterList: Array<FilterItem>): Observable<Array<any>>;  // optional method to retrieve initial selected data. This data should be the same instances with data return from getData method.

  getSummaryDefinition?(): Array<SummaryItemDefinition>;
  getCellConfigProvider?(): CellConfigProvider;
  getRowConfig?(): RowItemConfig;
}

/**
 * Abstract Service to use in the TableServerside component. It's injected by default into TableServerside
 * Any page, components want to use TableServerside Component need to implement ServerSideTableService interface
 * and inject it to replace AbstractServersideTableService.
 */
export class AbstractServersideTableService implements ServerSideTableService {
  getActionList(): Array<TableItemAction> {
    return [];
  }
  getColumnDefinitions(): Array<ColumnItem> {
    return [];
  }
  getDisplayColumnConfig?(): ColumnGroupConfig {
    return null;
  }
  getData(selectedFilterList: Array<FilterItem>): Observable<Array<any>> {
    return of([]);
  }

  getSummaryDefinition?(): Array<SummaryItemDefinition> {
    return [];
  }

  filterOfflineData(
    currentSelectedFilter: FilterItem,
    selectedFilterList: Array<FilterItem>,
    currentData: Array<any>
  ): Array<any> {
    return [];
  }
}
