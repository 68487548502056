import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CardInfoComponent } from './card-info/card-info.component';
import { EditCardComponent } from './edit-card/edit-card.component';

@NgModule({
    imports: [
        MatCardModule,
        MatIconModule,
        MatTooltipModule,
        MatDividerModule,
        MatButtonModule,
        CommonModule,
    ],
    exports: [CardInfoComponent, EditCardComponent],
    declarations: [CardInfoComponent, EditCardComponent],
    providers: [],
})
export class CardModule {}
