import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  HostBinding,
  ContentChildren,
  QueryList,
  AfterViewInit,
  ViewChildren,
  ElementRef,
  AfterViewChecked,
  ViewEncapsulation,
  OnDestroy
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { Platform } from '@angular/cdk/platform';
import { Subscription } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import {
  ColumnGroupConfig, ColumnGroupDisplay, ColumnGroupHelper,
  ColumnItem, ColumnItemDisplay, RowItemConfig, RowSubData, SelectedAdditionalColumns, TableActionType, TableDataChangeEvent,
  TableItemAction, TableStatus
} from './mat-table-wrapper.service';
import { CdkColumnDef } from '@angular/cdk/table';
import { MatTableSummaryComponent, SummaryItemDefinition } from './mat-table-summary-service';
import { CellAlignment, CellConfigProvider, MatableContentDataChange, TextTheme } from './mat-table-cell-content.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { animate, state, style, transition, trigger } from "@angular/animations";
import { CacheableItems } from "app/core/cache/cache.types";
import { CacheService } from "app/core/cache/cache.service";
import { Router } from "@angular/router";
import { MAT_MENU_DEFAULT_OPTIONS } from '@angular/material/menu';

const COLUMN_SETTING = 'Action';
const COLUMN_MULTIPLE_SELECTION = 'Select';
const COLUMN_WARNING = 'Warning';
const COLUMN_DETAIL_ITEM = "DetailItem";


@Component({
  selector: 'app-mat-table-wrapper',
  templateUrl: './mat-table-wrapper.component.html',
  styleUrls: ['./mat-table-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
	  {
		  provide: MAT_MENU_DEFAULT_OPTIONS,
		  useValue: {
			  overlayPanelClass: 'tb-action-menu-overlay-pane',
		  },
	  },
  ],
})

/**
 * Purpose:
 *  - This component is a wrapper of MatTable component.
 *  - Use this compoenent if we don't have any filters to use
 *  - It's used across the whole application
 *  - It allows the parent to pass columns data structure dynamically, and allow to have displaying group setting for columns.
 *  - It allows to multiple selection of rows. It allows to pass the initial selected rows
 *  - It allows sorting for each columns.
 *  - It has different layout in browser and mobile
 *  - We might need to add some footer information relates to table components.
 * Usage:
 *  - Pass data for data, columns, actionList
 *  - disable paginator, or set nobackground.
 */
export class MatTableWrapperComponent extends MatTableSummaryComponent implements OnInit, OnChanges, AfterViewInit, AfterViewChecked, OnDestroy {
  @ViewChild('rootEle', { static: false }) rootElement: ElementRef<HTMLElement>;
  @HostBinding('class') classes = 'mat-table-wrapper';


  // configurable for appearance of components
  @Input() isNoBackground = false;
  @Input() isNoPaginator = false;
  @Input() isMultipleSelection = false;
  @Input() isDisplaySubElementForRow = false;

  // for summary
  @ViewChildren(CdkColumnDef) _contentColumnDefs: QueryList<CdkColumnDef>;
  @Input() summaryDefs: Array<SummaryItemDefinition>;

  // for editable configuration
  @Input() editable = true; // parent can set the table is edit mode or view mode.
  @Input() editItemList: Array<any>;  // allow list of items is in edit mode.
  @Output() dataChanged: EventEmitter<TableDataChangeEvent> = new EventEmitter();

  // data input for table
  @Input() columns: Array<ColumnItem> = []; // column definition
  @Input() actionList: Array<TableItemAction> = []; // action data list
  private _data: Array<any> = []; // table data
  @Input() set data(value: Array<any>) {
	  this._data = value || [];
	  this.updateData();
  }
  get data(): Array<any> {
	  return this._data;
  }
  @Input() displayColumnConfig: ColumnGroupConfig;
  @Input() initialSelectedItem: Array<any>; // initialSelectedItem should be the same instances with data source

  // input for custom row configuration
  @Input() cellConfigProvider: CellConfigProvider;
  @Input() rowConfig: RowItemConfig; // row configuration

  // status
  _tableStatus: TableStatus;
  tableStatusEnum: any;

  // property for tables
  _displayedColumns: Array<string> = [];
  _settingColumns: Array<ColumnItemDisplay | ColumnGroupDisplay>;

  _dataSource: MatTableDataSource<Element[]>;
  _selection: SelectionModel<any>;

  // component instances in view
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('tableSort') tableSort = new MatSort();

  public isMobile = false;
  public isTabletPortrait = false;
  private sizeSubscription: Subscription;
  private currentUrl : string;

  // private methods
  private _dataSubscription: Subscription;
  _noElementPerPage = 10;

  // original method of table we want to customize
  private _sortingDataAccessor: Function;

  // output events
  @Output() actionEvent = new EventEmitter<{
	rowItem: any;
	action: TableItemAction;
  }>();
  @Output() selectEvent = new EventEmitter<{
	selectedList: Array<any>;
	actionElement: any;
  }>();

  // property for manage sub-element for row
  renderedSubData: Array<RowSubData>;

  constructor(
	private _breakpointObserver: BreakpointObserver,
	private platform: Platform,
	private _cacheService: CacheService,
	private _changeDetector: ChangeDetectorRef,
	private _router : Router
  ) {
	super();

	// this.isMobile = this.platform.IOS || this.platform.ANDROID;
  
	
	this.isMobile = this._breakpointObserver.isMatched(Breakpoints.XSmall);
	this.isTabletPortrait = this._breakpointObserver.isMatched(Breakpoints.TabletPortrait);

	this._selection = new SelectionModel<any>(true, []);

	this.tableStatusEnum = TableStatus;

	this.renderedSubData = [];

	this.currentUrl = this._router.url;    
  }

  ngAfterViewInit() {

	// apply default width in percentage for certain column types if there is no width provided
	this.columns.forEach(col => {
	  if (col.config?.isDateTime) {
		col.width = col.width ? col.width : 15;
	  }
	  if (col.config?.isDate) {
		col.width = col.width ? col.width : 10;
	  }
	  if (col.config?.isCurrency) {
		col.width = col.width ? col.width : 10;
		col.textStyle = col.textStyle
		  ? col.textStyle
		  : {
			  alignment: CellAlignment.RIGHT,
			  theme: TextTheme.GREY,
			  isUnderline: false,
			  displayTooltip: false,
			};
	  }

	  // Force sorting to be true so we have default value
	  // According to definition of business logic, all columns are allowed to be sorted if dev doesn't specify that
	  if (col.isSorting == undefined){
		col.isSorting = true;
	  }

	  this._changeDetector.detectChanges();
	})
  }

  ngAfterViewChecked() {
	const maxWidth: number = this.rootElement.nativeElement.getBoundingClientRect().width;

	this.setupSummaryItems(maxWidth, this._displayedColumns, this.columns, this.summaryDefs || []);
  }

  ngOnInit() {
	this.sizeSubscription = this._breakpointObserver
		.observe([Breakpoints.XSmall])
		.subscribe(() => {
			this.isMobile = this._breakpointObserver.isMatched(Breakpoints.XSmall);
		});
	// default value of selection is not select anything
	this._settingColumns = ColumnGroupHelper.getColumnSettingList(this.displayColumnConfig, this.columns);

	// check if we have anything stored in the cache
	const selectedColumnsFromCache = this._cacheService.get(CacheableItems.GPMAdditionalColumnsSelected)
	if(selectedColumnsFromCache){
	  const selectedColumnsForCurrentUrl = selectedColumnsFromCache.find(s => s.tableUrl === this.currentUrl);
	  if(selectedColumnsForCurrentUrl){
		selectedColumnsForCurrentUrl.selectedColumns.forEach(col => {
		  const colItems = this._settingColumns as ColumnItemDisplay[];
		  const selectedColumn = colItems.find(c => c.fieldName === col);
		  if(selectedColumn){
			selectedColumn.isAdded = true;
		  }        
		});
	  }     
	}

	// set initial selected data
	const initialSelectedData: Array<any> = this.isMultipleSelection && this.initialSelectedItem ? this.initialSelectedItem : [];
	this._selection = new SelectionModel<any>(true, initialSelectedData);

	this._tableStatus = this.editable ? TableStatus.EDIT : TableStatus.VIEW;
	this.editItemList = this.editItemList || [];

	this.setupColumns();
  }

  ngOnDestroy(): void {
	this.sizeSubscription?.unsubscribe();
  }

  private updateData(): void {
	if (this._dataSubscription) {
	  this._dataSubscription.unsubscribe();
	}
	  this._dataSource = new MatTableDataSource(this._data);
	  this._dataSource.paginator = !this.isNoPaginator ? this.paginator : null;         
	  this._dataSource.sort = this.tableSort;
	  this._sortingDataAccessor = this._dataSource.sortingDataAccessor;
	  this._dataSource.sortingDataAccessor = this.convertDataToValue;
  
	  this._dataSubscription = this._dataSource.connect().subscribe(this.dataSourceChanged.bind(this));
	  this._changeDetector.detectChanges();   
  }

  private setupColumns(): void {
	this._displayedColumns = this.displayColumnConfig ? this.displayColumnConfig.defaultColumns : this.columns.map(column => column.fieldName);
	const additionalColumnSelected = ColumnGroupHelper.findColumnGroupNameSelected(this._settingColumns);
	this._displayedColumns = this.displayColumnConfig ? this._displayedColumns.concat(additionalColumnSelected) : this._displayedColumns;

	// check if the component recieve actionList, then add to displaying in the table column
	this._displayedColumns = this.actionList
	  ? this._displayedColumns.concat([COLUMN_SETTING])
	  : this._displayedColumns;

	this.isDisplaySubElementForRow = this.rowConfig?.subRowElemengConfig?.needSubElement;

	this._displayedColumns = this.isDisplaySubElementForRow ? [COLUMN_DETAIL_ITEM].concat(this._displayedColumns) : this._displayedColumns;

	// check if allow multiple selection
	this._displayedColumns = this.isMultipleSelection ? [COLUMN_MULTIPLE_SELECTION].concat(this._displayedColumns) : this._displayedColumns;

	// check if need to add warning column
	this._displayedColumns = this.rowConfig && this.rowConfig.displayWarning
	  ? [COLUMN_WARNING].concat(this._displayedColumns)
	  : this._displayedColumns;

	// remove duplicated column list
	this._displayedColumns = this._displayedColumns.filter((x, i, a) => a.indexOf(x) === i);
  }

  public selectActionHandler(rowItem: any, action: TableItemAction) {
	this.actionEvent.emit({
	  rowItem,
	  action
	});
  }

  selectWarningHandler(rowItem: any): void {
	this.actionEvent.emit({
		rowItem,
		action: {
			actionType: TableActionType.WARNING,
			label: 'Warning',
		}
	});
  }

  dataSourceChanged(newData: Array<any>): void {
	// console.log("newData ", newData);
  }

  // customization of converting column data from material library to data before sorting
  convertDataToValue: ((data: any, sortHeaderId: string) => string|number) =
	(data: any, sortHeaderId: string): string|number => {
	const originalValue = (data as {[key: string]: any})[sortHeaderId];

	const isStringType: boolean = typeof originalValue === 'string';

	return isStringType ? originalValue.toUpperCase() : this._sortingDataAccessor(data, sortHeaderId);
  };

  // method for multiple selection
  isAllSelected() {
	const numSelected = this._selection.selected.length;
	const numRows = this._dataSource.data.length;
	return numSelected === numRows;
  }

  checkboxLabel(row?: any): string {
	if (!row) {
	  return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
	}
	return `${this._selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  masterToggle(event: any) {
	if (event) {
	  this.isAllSelected() ? this._selection.clear() : this._dataSource.data.forEach(row => this._selection.select(row));

	  this.selectEvent.next({
		selectedList: this.getAllSelectedElement(),
		actionElement: null,
	  });
	}
  }

  childToggle(event: any, element: any) {
	if (event) {
	  this._selection.toggle(element);
	  this.selectEvent.next({
		selectedList: this.getAllSelectedElement(),
		actionElement: element,
	  });
	}
  }

  private getAllSelectedElement(): Array<any> {
	return this._data.filter(item => this._selection.isSelected(item));
  }

  // for select group of columns
  toggleSelectGroupOfColumns(event: any, item: ColumnItemDisplay | ColumnGroupDisplay) {
	item.isAdded = !item.isAdded;
	const needAddedColumnList: Array<ColumnItemDisplay> = ColumnGroupHelper.isColumnGroup(item) ? (item as ColumnGroupDisplay).columns : [];

	needAddedColumnList.forEach((columnItem) => {
	  columnItem.isAdded = !columnItem.isAdded;
	});
	this.setupColumns();
	this.saveAdditionalColumnsToCache();
  }

  saveAdditionalColumnsToCache(){
	const additionalColumnSelected = ColumnGroupHelper.findColumnGroupNameSelected(this._settingColumns);
	const cachedSelectedColumns = this._cacheService.get(CacheableItems.GPMAdditionalColumnsSelected);
	const selectedColumnsForTables : SelectedAdditionalColumns[] = cachedSelectedColumns ? cachedSelectedColumns : [];
	// find out if we have data for the current url already in the cache
	let selectedColumnsForCurrentUrl = selectedColumnsForTables.find(s => s.tableUrl === this.currentUrl);          
	const index = selectedColumnsForTables.findIndex(s => s.tableUrl === this.currentUrl);
	// if user selects additional column, then save it to the cache 
	if(additionalColumnSelected.length > 0){
	  if(selectedColumnsForCurrentUrl){
		  // then replace it with the new one        
		  selectedColumnsForCurrentUrl = {tableUrl: this.currentUrl, selectedColumns: additionalColumnSelected};
		  selectedColumnsForTables.splice(index, 1,selectedColumnsForCurrentUrl)
	  }         
	  else{
		// add new one and push it to the cache
		selectedColumnsForTables.push({tableUrl: this.currentUrl, selectedColumns: additionalColumnSelected});
	  }
	   
	  this._cacheService.set(CacheableItems.GPMAdditionalColumnsSelected, selectedColumnsForTables);
	  
	}
	else{
	  if(selectedColumnsForCurrentUrl){
		// means user has removed/unchecked column, so we remove it from the cache too
		selectedColumnsForTables.splice(index, 1);
		this._cacheService.set(CacheableItems.GPMAdditionalColumnsSelected, selectedColumnsForTables);
	  } 
	}
  }

  checkToDisplayNavigator(): boolean {
	const noOfRow: number = this.data ? this.data.length : 0;
	return noOfRow > this._noElementPerPage;
  }

  pageChange(pageEvent: PageEvent) {
	// console.log("pageEvent ", pageEvent);
  }

  ngOnChanges(changes: SimpleChanges) {
	// only allow initialSelectedItem get updated from outsider
	// NOTE: be careful of duplication of logic code here. First time component receive data, the method is also invoked.
	// console.log("ngOnChanges >> ", changes);
	const initialSelectedItemChange: boolean = changes.initialSelectedItem && !changes.initialSelectedItem.firstChange;
	if (initialSelectedItemChange) {
	  // console.log("initialSelectedItemChange >> ", changes);
	  this._selection = new SelectionModel<any>(true, changes.initialSelectedItem.currentValue);
	}

	const columnChanged: boolean = changes.columns && !changes.columns.firstChange;
	if (columnChanged) {
	  this.setupColumns();
	}

	const editableChanged: boolean = changes.editable && !changes.editable.firstChange;
	if (editableChanged) {
	  this._tableStatus = changes.editable.currentValue ? TableStatus.EDIT : TableStatus.VIEW;
	}

	const summaryDefinitionChanged: boolean = changes.summaryDefs && !changes.summaryDefs.firstChange;
	if (summaryDefinitionChanged) {
	  this.requestNewUpdateForSummary();
	}
  }

  rowMouseOver(event: Event, row: any) {
	row.hovered = true;
	const isHtmlElement = event.currentTarget instanceof HTMLElement;
	const rowELement: HTMLElement = event.currentTarget as HTMLElement;
  }

  rowMouseOut(event: any, row: any) {
	row.hovered = false;
	const rowELement: HTMLElement = event.currentTarget as HTMLElement;
  }

  dataContentChange(data: MatableContentDataChange) {
	// console.log("data ", data);
	data.rowData[data.columnItemDef.fieldName] = data.newRowData;
	this.dataChanged.emit({
	  newData: this._data,
	});
  }


  // public method from outsider to update something new from the data inside but not the whole data. For example: summarry template can be change, but summary definition still keep the same
  requestNewUpdateForSummary() {
	// update the summary for configuration
	const maxWidth: number = this.rootElement.nativeElement.getBoundingClientRect().width;

	this.updateSummaryDef(maxWidth, this._displayedColumns, this.columns, this.summaryDefs || []);
  }

  // allows to unselect checkboxes from parent
  public resetSelectedItems(){
	if (this._selection.selected.length > 0) {
	  this._selection.clear();
	  this.selectEvent.next({
		selectedList: this.getAllSelectedElement(),
		actionElement: null,
	  });
	}
  }

  // logic for sub element
  initiatedEventForSub(row: any): void {
	const isRowExist: boolean = !!this.renderedSubData.find(e => e.row === row);
	if (!isRowExist) {
	  this.renderedSubData.push({
		row,
		data: null,
		dataInitiated: false,
	  })
	}
  }

  destroyedEventForSub(row: any): void {
	const index = this.renderedSubData.findIndex(e => e.row === row);
	const isRowExist: boolean = index !== -1;
	if (isRowExist) {
	  this.renderedSubData = this.renderedSubData.slice(index, index + 1);
	}
  }

  dataInitiationEventForSub(subDataForRow: {
	row: any,
	data: any,
  }): void {
	// find row and store data and mark it as data initiated
	const renderRowSub = this.renderedSubData.find(e => e.row === subDataForRow.row);
	if (renderRowSub) {
	  renderRowSub.dataInitiated = true;
	  renderRowSub.data = subDataForRow.data;
	}
  }

  requestExpandForRow(row: any): void {
	const activeRowSub = this.renderedSubData.find(e => e.row.isExpanded);
	if (activeRowSub) {
	  // check if that row already has data
	  if (activeRowSub.dataInitiated) {
		activeRowSub.row.isExpanded = false;
		row.isExpanded = true;
		// reset expanded and set the target row to expand
	  }
	} else {
	  row.isExpanded = true;
	}
  }

  requestCollapseForRow(row: any): void {
	row.isExpanded = false;
  }
}