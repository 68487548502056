import { NgModule } from "@angular/core";
import { MatTabsModule } from "@angular/material/tabs";
import { TableModule } from "@components/table/table.module";
import { SharedModule } from "@shared/shared.module";
import { LettersUploadsComponent } from "./letters-and-uploads.component";
import { LettersComponent } from "./letters/letters.component";
import { UploadsComponent } from "./uploads/uploads.component";

@NgModule({
    imports: [
        MatTabsModule,
        TableModule
    ],
    exports: [LettersUploadsComponent],
    declarations: [LettersUploadsComponent, LettersComponent, UploadsComponent],
    providers: [],
})
export class LettersUploadsModule {}