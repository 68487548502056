import { NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { RouterModule } from '@angular/router';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSelectModule} from '@angular/material/select';
import {MatPaginatorModule} from '@angular/material/paginator';

import { SharedModule } from 'app/shared/shared.module';
import { TableServerSideComponent } from './table-serverside.component';
import { TableServerSideColumnFilterComponent } from './table-column-filter/table-serverside-column-filter.component';
import { TextDialogComponent } from './table-column-filter/dialogs/text-dialog.component';
import { SelectDialogComponent } from './table-column-filter/dialogs/select-dialog.component';
import { DateRangeDialogComponent } from './table-column-filter/dialogs/date-range-dialog.component';
import { DateDialogComponent } from './table-column-filter/dialogs/date-dialog.component';
import { CheckDialogComponent } from './table-column-filter/dialogs/check-dialog.component';
import { AutoCompleteDialogComponent } from './table-column-filter/dialogs/autocomplete-dialog.component';
import { MatTableWrapperComponent } from './mat/mat-table-wrapper.component';
import { MatTableContentCellWrapperComponent } from './mat/mat-table-cell-content.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { YesNoFormatterPipe } from 'app/core/pipes/yesno-formatter.pipe';
import { GeneralFilterDialogComponent, GeneralFilterDialogService } from './table-column-filter/dialogs/general-filter-dialog.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableRowSubInfoWrapperComponent } from "./mat/mat-table-row-sub-info.component";
import { MatSortModule } from "@angular/material/sort";


@NgModule({
	declarations: [
		// filter components
		TextDialogComponent,
		SelectDialogComponent,
		DateRangeDialogComponent,
		DateDialogComponent,
		CheckDialogComponent,
		AutoCompleteDialogComponent,
		GeneralFilterDialogComponent,

		TableServerSideColumnFilterComponent,

		// table component
		MatTableContentCellWrapperComponent,
		MatTableRowSubInfoWrapperComponent,
		MatTableWrapperComponent,
		TableServerSideComponent,
	],
	imports: [
		RouterModule,
		OverlayModule,
		PortalModule,
		ReactiveFormsModule,

		MatProgressSpinnerModule,
		MatTooltipModule,
		MatButtonModule,
		MatIconModule,
		MatMenuModule,
		MatChipsModule,
		MatTableModule,
		MatFormFieldModule,
		MatToolbarModule,
		MatDatepickerModule,
		MatRadioModule,
		MatCheckboxModule,
		MatAutocompleteModule,
		MatSelectModule,
		MatPaginatorModule,
		MatSortModule,
		MatDialogModule,
		MatInputModule,
		MatDividerModule,

		SharedModule,
	],
	exports: [
		MatTableWrapperComponent,
		TableServerSideColumnFilterComponent,
		TableServerSideComponent,
	],
	providers: [
		GeneralFilterDialogService,
	],

})
export class TableModule {}