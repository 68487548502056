import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APIService } from "../api/api.service";
import { EmploymentTypeModel } from "../models/physio/employment-type.model";

export interface GetEmploymentTypesResponseModel {
  EmploymentTypes: EmploymentTypeModel[];
  SelectedEmploymentType: number;
}

@Injectable({ providedIn: "root" })
export class EmploymentTypesService {
  constructor(private _apiService: APIService) {}

  public getEmployersByName(clientId: number): Observable<GetEmploymentTypesResponseModel> {
    return this._apiService.get(`/employment-types?command=employmentTypes&id=${clientId}`);
  }
}
