import { Observable } from "rxjs";

import { Injectable } from "@angular/core";

import { APIService } from "../api/api.service";
import { DebtorByNameModel } from "../models/appointment/debtor-by-name.model";
import { DebtorModel } from "../models/appointment/debtor.model";
import { Operation } from "fast-json-patch";
import { DebtorPhysioDetailsModel } from "../models/debtor-physio-details.model";
import { ContactDetailsObjectModel } from "../models/contact-details-object.model";
import { LovItemModel } from "../models/LovItemModel";

@Injectable({ providedIn: "root" })
export class DebtorService {
  constructor(private _apiService: APIService) {}

  public getDebtorsByName(searchTerm: string): Observable<DebtorByNameModel[]> {
    return this._apiService.get(`/debtors?command=search&SearchTerm=${searchTerm}`);
  }

  public getDebtorPhysioDetails(id: number): Observable<DebtorPhysioDetailsModel> {
    return this._apiService.get(`/debtors?command=getDebtorPhysioDetails&id=${id}`);
  }

  public patchDebtor(debtorId: number, operations: Operation[]): Observable<DebtorModel> {
    return this._apiService.patch(`/debtors?command=debtor&id=${debtorId}`, operations);
  }

  public getInsurerContactDetails(debtorId: number, caseManagerId?: number): Observable<ContactDetailsObjectModel> {
    let query = `/debtors?command=getInsurerContactDetails&id=${debtorId}`;

    if (caseManagerId) {
      query += `&caseManagerId=${caseManagerId}`;
    }
    return this._apiService.get(query);
  }

  public getInsurerCaseManagers(debtorId: number): Observable<LovItemModel[]> {
    return this._apiService.get(`/debtors?command=getInsurerCaseManagers&id=${debtorId}`);
  }

  public getCaseManagerContactDetails(caseManagerId: number): Observable<ContactDetailsObjectModel> {
    return this._apiService.get(`/debtors?command=getCaseManagerContactDetails&id=${caseManagerId}`);
  }
}
