<ng-container>
    <div class="flex flex-col">
        <!-- Subject -->
        <mat-form-field appearance="outline" floatLabel="auto">
            <mat-label>Subject contains</mat-label>
            <input matInput (input)="onSubjectInput($event)" [formControl]="subjectFC">
        </mat-form-field>

        <!-- Details -->
        <mat-form-field appearance="outline" floatLabel="auto" class="mt-2">
            <mat-label>Details contains</mat-label>
            <input matInput (input)="onDetailsnput($event)" [formControl]="detailsFC">
        </mat-form-field>
    </div>
</ng-container>