<span class="mobile-label">{{ colmnDefinition.displayName }}</span>
<ng-container *ngIf="_cellStatus === 'VIEW'">
  <span class="tb-cell-value ellipsis" [ngSwitch]="_dataConfig" [matTooltip]="_textStyle.displayTooltip ? rowElementData[colmnDefinition.fieldName]: null">
    <ng-container *ngSwitchCase="'TEXT'">{{ rowElementData[colmnDefinition.fieldName] }}</ng-container>
    <ng-container *ngSwitchCase="'DATE'">{{ rowElementData[colmnDefinition.fieldName] | formatDate }}</ng-container>
    <ng-container *ngSwitchCase="'DATE_TIME'">{{ rowElementData[colmnDefinition.fieldName] | formatDateTime }}</ng-container>
    <ng-container *ngSwitchCase="'TIME'">{{ rowElementData[colmnDefinition.fieldName] | date:'HH:mm' }}</ng-container>
    <ng-container *ngSwitchCase="'DAY'">{{ rowElementData[colmnDefinition.fieldName] | date:'EEEE' }}</ng-container>
    <ng-container *ngSwitchCase="'YES_NO'">{{ rowElementData[colmnDefinition.fieldName] | formatYesNo }}</ng-container>
    <ng-container *ngSwitchCase="'CURRENCY'">{{ rowElementData[colmnDefinition.fieldName] | currency}}</ng-container>
  </span>
</ng-container>
<ng-container *ngIf="_cellStatus === 'EDIT'">
  <span class="tb-cell-value edit" [ngClass]="{'edit-aligment-right': _textStyle.alignment === 'RIGHT'}">
    <mat-icon class="edit-dollar-sign" svgIcon="gpm_dollar"></mat-icon>
    <input matInput [ngModel]="_data" (change)="changeData($event)">
  </span>
</ng-container>
