import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TableItemAction } from "@components/table/mat/mat-table-wrapper.service";
import { TableServerSideComponent } from "@components/table/table-serverside.component";
import { ClientPaymentsFilterColumnService, ClientPaymentsServersideTableService } from "./client-payments-table.service";

@Component({
  selector: "app-client-payments",
  templateUrl: "./client-payments.component.html",
  styleUrls: ["./client-payments.component.scss"],
  providers: [ClientPaymentsServersideTableService, ClientPaymentsFilterColumnService],
})
export class ClientPaymentsComponent implements OnInit {
  @ViewChild(TableServerSideComponent) tableServerSideComponent: TableServerSideComponent;

  selectedPayments: Array<any> = [];
  clientId: string;

  constructor(
    private _activatedRoute: ActivatedRoute,
    public tableService: ClientPaymentsServersideTableService,
    public filterColumnService: ClientPaymentsFilterColumnService
  ) {
    const params = this._activatedRoute.snapshot.params;
    this.clientId = params['clientId'];

    this.tableService.clientId = this.clientId;
  }

  ngOnInit(): void {

  }

  onActionHandler(item: { rowItem: any; action: TableItemAction }): void {

  }

  onNewPayment(): void {

  }

  onNewCashSale(): void {

  }

  onViewMultiplePayment(): void {

  }

  resetSelectedItems(): void {
    this.tableServerSideComponent.resetSelectedItems();
  }

  selectPayments(data: { selectedList: Array<any>; actionElement: any }): void {
    this.selectedPayments = data.selectedList;
  }
}
