import { Injectable } from '@angular/core';
import { ColumnGroupConfig, ColumnItem, MASTER_GROUP_ID, RowItemConfig, TableActionType, TableItemAction } from '@components/table/mat/mat-table-wrapper.service';
import { AutoCompleteSelectItem } from '@components/table/table-column-filter/dialogs/autocomplete-dialog.service';
import { FilterColumnService, FilterItem, FilterItemHelper } from '@components/table/table-column-filter/table-serverside-column-filter.service';
import { ServerSideTableService } from '@components/table/table-serverside.service';
import { IndexedDBService } from 'app/core/cache/indexed-db.service';
import { ClientService } from 'app/core/client/client.service';
import { HealthlinkEnums, HealthlinkMessageStatus } from 'app/core/enums/healthlink-enums';
import { HealthLinkMessageModel } from 'app/core/models/organisation/healthlink/healthlink-message.model';
import { ClinicService } from 'app/core/services/clinic.service';
import { DateTimeService } from 'app/core/services/datetime.service';
import { HealthlinkService } from 'app/core/services/healthlink.service';
import { UtilService } from 'app/core/services/util.service';
import { HealthlinkMessagesRequestModel } from 'app/modules/healthlink/healthlink.types';
import { DefaultStatusCount, HealthLinkStatus } from 'app/modules/healthlink/table/healthlink-table-filter.service';
import { Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';


export enum HEALTHLINK_COLS {
    ALL = 'ALL',
    MESSAGE_DATE = 'MESSAGE_DATE',
    FROM_NAME = 'FROM_NAME',
    TO_NAME = 'TO_NAME',
    CLIENT_NAME = 'CLIENT_NAME',
    SUBJECT = 'SUBJECT',
    INTERNAL_NOTES = 'INTERNAL_NOTES',
    STATUS_NAME = 'STATUS_NAME',
    MESSAGE_TYPE_NAME = 'MESSAGE_TYPE_NAME',
    CLINIC_NAME = 'CLINIC_NAME'
}

const HEALTHLINK = {
    BETWEEN: 'BETWEEN',
    STATUS: 'STATUS',
    CLINIC: 'CLINIC',
    PHYSIOPROVIDER: 'PHYSIOPROVIDER',
    REFERRER: 'REFERRER',
    CLIENT: 'CLIENT',
};


@Injectable()
export class ClientHealthLinkServersideTableService implements ServerSideTableService {
    public destroySubscriptions: Subject<boolean> = new Subject<boolean>();

    public clientId: number;

    constructor(
        private _healthlinkService: HealthlinkService,
        private _dateTimeService: DateTimeService
    ) {}

    getActionList(): Array<TableItemAction> {
        return [];
    }

    getColumnDefinitions(): ColumnItem[] {
        return [
            {
                fieldName: HEALTHLINK_COLS.MESSAGE_DATE,
                displayName: 'Date',
                config: {
                    isDate: true,
                    format: 'dd/MM/yyyy',
                },
                isSorting: true,
            },
            {
                fieldName: HEALTHLINK_COLS.FROM_NAME,
                displayName: 'From',
                isSorting: true,
            },
            {
                fieldName: HEALTHLINK_COLS.TO_NAME,
                displayName: 'To',
                isSorting: true,
            },
            {
                fieldName: HEALTHLINK_COLS.SUBJECT,
                displayName: 'Subject',
                isSorting: true,
            },
            {
                fieldName: HEALTHLINK_COLS.INTERNAL_NOTES,
                displayName: 'Internal Notes',
                isSorting: true,
            },
            {
                fieldName: HEALTHLINK_COLS.STATUS_NAME,
                displayName: 'Status',
                config: {
                    isDropdown: true,
                },
                width: 10,
                isSorting: true,
            },
            {
                fieldName: HEALTHLINK_COLS.MESSAGE_TYPE_NAME,
                displayName: 'Type',
                isSorting: true,
                width: 10,
            },
            {
                fieldName: HEALTHLINK_COLS.CLINIC_NAME,
                displayName: 'Clinic',
                isSorting: true,
            },
        ];
    }

    getDisplayColumnConfig(): ColumnGroupConfig {
        return {
            defaultColumns: [
                HEALTHLINK_COLS.MESSAGE_DATE,
                HEALTHLINK_COLS.FROM_NAME,
                HEALTHLINK_COLS.TO_NAME,
                HEALTHLINK_COLS.SUBJECT,
                HEALTHLINK_COLS.STATUS_NAME,
            ],
            groupColumnDefinition: [
                {
                    groupID: MASTER_GROUP_ID,
                    groupName: '',
                    columnNames: [
                        HEALTHLINK_COLS.INTERNAL_NOTES,
                        HEALTHLINK_COLS.MESSAGE_TYPE_NAME,
                        HEALTHLINK_COLS.CLINIC_NAME
                    ],
                },
            ],
            displayGroup: [MASTER_GROUP_ID],
        };
    }

    checkUnMatching(rowData: any, fieldName: string): boolean {
        const isNeedToCheckUnmatching =
            fieldName === HEALTHLINK_COLS.FROM_NAME ||
            fieldName === HEALTHLINK_COLS.TO_NAME ||
            fieldName === HEALTHLINK_COLS.CLIENT_NAME;
        if (isNeedToCheckUnmatching) {
            const healthlinkMsg: HealthLinkMessageModel =
                rowData as HealthLinkMessageModel;
            const isFromUnMatching =
                fieldName === HEALTHLINK_COLS.FROM_NAME &&
                !healthlinkMsg.REFERRER_ID;
            const isToUnMatching =
                fieldName === HEALTHLINK_COLS.TO_NAME &&
                !healthlinkMsg.PROVIDER_ID;
            const isClientUnMatching =
                fieldName === HEALTHLINK_COLS.CLIENT_NAME &&
                !healthlinkMsg.CLIENT_ID;

            return isFromUnMatching || isToUnMatching || isClientUnMatching;
        }
        return false;
    }

    getRowConfig(): RowItemConfig {
        return {
            displayWarning: true,
            isRowWarning: (rowData: any): boolean => {
                const isFromUnMatching = this.checkUnMatching(
                    rowData,
                    HEALTHLINK_COLS.FROM_NAME
                );
                const isToUnMatching = this.checkUnMatching(
                    rowData,
                    HEALTHLINK_COLS.TO_NAME
                );
                const isClientUnMatching = this.checkUnMatching(
                    rowData,
                    HEALTHLINK_COLS.CLIENT_NAME
                );
                const isFromToClientUnMatching =
                    isFromUnMatching || isToUnMatching || isClientUnMatching;
                return isFromToClientUnMatching;
            },
        };
    }

    // for retrieving data from server or refreshing data from selectedFilter
    getData(selectedFilterList: FilterItem[]): Observable<any[]> {
        const providerFilterValue: any = FilterItemHelper.retrieveFilterValueForNameItem(
                HEALTHLINK.PHYSIOPROVIDER,
                selectedFilterList
            );
        const providerId = providerFilterValue ? providerFilterValue.filterValue: '';

        const clinicFilterValue: any = FilterItemHelper.retrieveFilterValueForNameItem(
                HEALTHLINK.CLINIC,
                selectedFilterList
            );
        const clinicId = clinicFilterValue ? clinicFilterValue.filterValue : '';

        const referrerFilterValue = FilterItemHelper.retrieveFilterValueForNameItem(
                HEALTHLINK.REFERRER,
                selectedFilterList
            );
        const referrerId = referrerFilterValue ? referrerFilterValue.filterValue: '';

        const betweenFilterValue: any = FilterItemHelper.retrieveFilterValueForNameItem(
                HEALTHLINK.BETWEEN,
                selectedFilterList
            );
        const dateFrom = betweenFilterValue && betweenFilterValue.filterFrom
            ? this._dateTimeService.FormatDate(betweenFilterValue.filterFrom, 'MM/DD/yyyy')
            : '';
        const dateTo = betweenFilterValue && betweenFilterValue.filterTo
            ? this._dateTimeService.FormatDate(betweenFilterValue.filterTo, 'MM/DD/yyyy')
            : '';

        const statusFilterValue: any = FilterItemHelper.retrieveFilterValueForNameItem(
            HEALTHLINK.STATUS,
            selectedFilterList
        );
        const status = statusFilterValue ? statusFilterValue.filterValue : '';

        const hlRequestModel: HealthlinkMessagesRequestModel = {
            ProviderId: providerId,
            ClinicId: clinicId,
            ReferrerId: referrerId,
            ClientId: this.clientId,
            DateFrom: dateFrom,
            DateTo: dateTo,
            Status: status,
        };

        return this._healthlinkService
            .getHealthlinkMessages(hlRequestModel)
            .pipe(
                takeUntil(this.destroySubscriptions),
                map((hlMessages: Array<HealthLinkMessageModel>) => {
                    this.formatData(hlMessages);

                    return hlMessages;
                })
            );
    }

    protected formatData(data: Array<HealthLinkMessageModel>): void {
        data.forEach((item) => {
            item.STATUS_NAME = HealthlinkMessageStatus[item.STATUS];
            if (item.MESSAGE_TYPE) {
                item.MESSAGE_TYPE_NAME = HealthlinkEnums.getHlMessageSpecificationDescription(item.MESSAGE_TYPE);
            }
        });
    }
}

@Injectable()
export class ClientHealthLinkFilterColumnService implements FilterColumnService {
    public destroySubscriptions: Subject<boolean> = new Subject<boolean>();
    public selectedVendorId = -1;
    public isUserAProvider: boolean;

    private healthlinkStatusList: Array<HealthLinkStatus>;

    constructor(
        private _utilService: UtilService,
        private _clinicService: ClinicService,
        private _clientService: ClientService,
        private _indexedDbService: IndexedDBService
    ) {
        this.healthlinkStatusList = this.getDataForStatus();
    }


    getObservableDataForStatus(): Observable<any[]> {
        const statusData = this.getDataForStatus();
        return of(statusData);
    }

    getDataForStatus(): Array<HealthLinkStatus> {
        const items: Array<HealthLinkStatus> = [];
        for (const key in HealthlinkMessageStatus) {
            if (parseInt(key, 10) >= 0) {
                items.push({
                    key: key,
                    value: HealthlinkMessageStatus[key],
                    count: DefaultStatusCount,
                });
            }
        }

        return items;
    }

    getAllowedFilterFields(): Array<FilterItem> {
        return [
            {
                fieldName: HEALTHLINK.BETWEEN,
                displayName: 'Between',
                config: {
                    label: "Message Date",
                    isDateRange: true,
                },
            },
            {
                fieldName: HEALTHLINK.CLINIC,
                displayName: 'Clinic',
                config: {
                    isSelect: true,
                    selectData: '',
                    placeholder: 'Select a Clinic',
                    label: 'Select a Clinic',
                    textField: 'NAME',
                    valueField: 'ID',
                },
            },
            {
                fieldName: HEALTHLINK.PHYSIOPROVIDER,
                displayName: 'Provider',
                config: {
                    isSelect: true,
                    selectData: '',
                    placeholder: 'Select a Provider',
                    label: 'Select a Provider',
                    textField: 'DisplayText',
                    valueField: 'id',
                },
            },
            {
                fieldName: HEALTHLINK.REFERRER,
                displayName: 'Referrer',
                config: {
                    isAutocompleteSelect: true,
                    selectData: '',
                    placeholder: 'Type to find a referrer',
                    noDataText: 'No refferers found',
                    label: 'Select',
                    textField: 'DisplayText',
                    valueField: 'id',
                },
            },
            {
                fieldName: HEALTHLINK.STATUS,
                displayName: 'Status',
                config: {
                    isSelect: true,
                    selectData: '',
                    placeholder: 'Select Status',
                    label: 'Select Status',
                    textField: 'value',
                    valueField: 'key',
                },
            }
        ];
    }

    getSelectDataForFilterItem(
        item: FilterItem,
        params: any
    ): Observable<any[]> | null {
        let results: Observable<any> = of([]);
        const { selectedFilters } = params;
        switch (item.fieldName) {
            case HEALTHLINK.STATUS:
                results = this.getObservableDataForStatus();
                break;
            case HEALTHLINK.CLINIC:
                results = this.getDataForClinics();
                break;
            case HEALTHLINK.PHYSIOPROVIDER:
                results = this.getDataForProviders();
                break;
            case HEALTHLINK.REFERRER:
                results = this.getDataForReferrer(params);
                break;
            case HEALTHLINK.STATUS:
                results = this.getObservableDataForStatus();
                break;
        }

        return results;
    }

    getDataForClinics(): Observable<any[]> {
        return this._clinicService.getClinics().pipe(
            takeUntil(this.destroySubscriptions),
            map((response: any) => response || [])
        );
    }

    getDataForProviders(): Observable<any[]> {
        return this._indexedDbService.getAllProviders().pipe(
            takeUntil(this.destroySubscriptions),
            map((response: any) => {
                const providerList: Array<any> = response || [];
                providerList.forEach((x) => {
                    x.DisplayText = this._utilService.getFullName(
                        x.firstname,
                        x.lastname
                    );
                });
                return providerList;
            })
        );
    }

    getDataForReferrer(params: any): Observable<any[]> {
        const { text } = params;
        if (!text ||text && text.length < 3) return null;

        return this._indexedDbService.getAllReferrersByNameSearch(text).pipe(
            map((referrersFound: any) => {
                const referrerList: Array<any> =
                    referrersFound && referrersFound.length > 0
                        ? referrersFound
                        : [];
                return referrerList.map((item: any) => {
                    const displayText = this._utilService.getFullName(
                        item.firstname,
                        item.lastname
                    );
                    return new AutoCompleteSelectItem(item.id, displayText);
                });
            })
        );
    }

    getDataButtonText(): string {
        return '';
    }

    getPageTitle(): string {
        return "Healthlink";
      };

    getFilterServiceUniqueName(): string {
        return "Client_Healthlink_Filter";
    }
}
