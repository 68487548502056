<div class="w-full h-full">
    
    <app-title-header pageTitle={{pageTitle}}></app-title-header>
    
    <div class="workspace">

        <div class="flex flex-col sm:flex-row items-start gap-5">

            <app-client-details-side-menu class="w-full sm:w-auto" [option]="clientDetailsSideMenuActions.Memberships" [clientId]="clientId">
            </app-client-details-side-menu>
            
            <div class="w-full">
                <div class="workspace-body">
                    <mat-card class="flex flex-col sm:flex-1 p-0 rounded-tl-lg">
                        <mat-tab-group class="rounded-tl rounded-tr overflow-hidden" animationDuration="0ms">
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <div class="flex flex-row items-center">
                                        <span>Membership</span>
                                    </div>
                                </ng-template>
                                <app-memberships></app-memberships>
                            </mat-tab>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <div class="flex flex-row items-center">
                                        <span>Appointment Usage</span>
                                    </div>
                                </ng-template>
                                <app-appointment-usage></app-appointment-usage>
                            </mat-tab>            
                        </mat-tab-group>
                    </mat-card>
                </div>
            </div>

        </div>

    </div>
</div>
  