import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'mapDictionaryToArray'})
export class MapDictionaryToArrayPipe implements PipeTransform {
  transform(value) : any {
    const arr = [];
    for (const key of Object.keys(value)) {
      arr.push({key: key, value: value[key]});
    }
    return arr;
  }
}