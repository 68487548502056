import { Component, HostBinding, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BreadcrumbsService } from "@components/breadcrumbs/breadcrumbs.service";
import { AppStateDBModel } from "app/core/cache/cache.types";
import { UtilService } from "app/core/services/util.service";
import { ClientDetailsSideMenuActions } from "../client.types";

@Component({
  selector: "app-client-accounts",
  templateUrl: "./client-accounts.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class ClientAccountsComponent implements OnInit {
  @HostBinding("class") classes = "app-client-accounts";

  clientDetailsSideMenuActions = ClientDetailsSideMenuActions;
  clientId: number;
  pageTitle: string;

  constructor(private _router: Router, private _activatedRoute: ActivatedRoute, private _utilService: UtilService) {
    const params = this._activatedRoute.snapshot.params;
    this.clientId = params["clientId"];
  }

  ngOnInit(): void {
    this._activatedRoute.data.subscribe((data: any) => {
      const clientPersonalDetails = data.initialData.ClientDetails.PersonalDetails;
      // Page Title
      this.pageTitle = this._utilService.getFullNameDob(clientPersonalDetails.FIRST_NAME, clientPersonalDetails.LAST_NAME, clientPersonalDetails.DOB);
    });
  }

  navigateToPage(url: string): void {
    this._router.navigate([url]);
  }
   
}
