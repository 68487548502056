import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { EMPTY, from, Observable, of } from "rxjs";
import { concatMap, map, switchMap, tap } from "rxjs/operators";

import { ProviderSiteModel } from "app/core/models/appointment/provider-site.model";
import { SiteApptDetailsModel } from "app/core/models/appointment/site-appt-details.model";
import { ReferrerModel } from "app/core/models/appointment/referrer.model";
import { ProviderModel } from "app/core/models/appointment/provider.model";
import { CacheModel } from "./models/cache.model";

import { APIService } from "../api/api.service";
import {
  SiteApptDetailsDBModel,
  PhysioProviderDBModel,
  ProviderSiteDBModel,
  PaymentTypeDBModel,
  AdminTypeDBModel,
  ReferrerDBModel,
  ProviderDBModel,
  CacheableItems,
  TitleDBModel,
  SiteDBModel,
  SystemUserSiteDBModel,
  VendorPhysioDetailsDBModel,
  AppStateDBModel,
  SystemUserDBModel,
  EthnicGroupDBModel,
  VendorApptDetailsDBModel,
  VendorDBModel,
  EmploymentIntensityDBModel,
  TeamDBModel,
  OrganisationPhysioDetailsDBModel,
  LocationDBModel,
  SportDBModel,
  SceneDBModel,
  DHBDBModel,
} from "./cache.types";
import { CacheItemOptions } from "@aws-amplify/cache/lib-esm/types";
import { CacheService } from "./cache.service";

import Dexie from "dexie";

import { HubChannels } from "app/shared/common/hub-channels";
import { Hub } from "@aws-amplify/core";

import { UtilService } from "../services/util.service";
import { SiteModel } from "../models/organisation/site.model";
import { TitleModel } from "../models/organisation/title.model";
import { AdminTypeModel } from "../models/organisation/admin-type.model";
import { PaymentTypeModel } from "../models/organisation/payment-type.model";
import { PhysioProviderModel } from "../models/physio/physio-provider.model";
import { SystemUserSiteModel } from "../models/organisation/system-user-site";
import { SystemUserModel } from "../models/organisation/system-user.model";
import { EthnicGroupModel } from "../models/organisation/ethnic-group.model";
import { AdminTypesEnum } from "../enums/physio-enums";

export interface IndexDetails {
  indexName: string;
  order: string;
}

@Injectable({ providedIn: "root" })
export class IndexedDBService {
  table: Dexie.Table<SiteDBModel, number>;
  db: any;
  dbVersion: number;
  updatingCache: boolean;

  cacheOptions: CacheItemOptions = {
    expires: new Date(2050, 1, 1).getTime(),
  };

  constructor(
    private _cacheService: CacheService,
    private _apiService: APIService,
    private _router: Router,
    private _utilService: UtilService
  ) {
    this.createDB();

    Hub.listen(HubChannels.UpdatesChannel, (data) => {
      const gpmVersion = data.payload.data;
      this._utilService.setGPMVersion(gpmVersion);
      this._cacheService.remove(CacheableItems.GPMCacheDate);
      if (gpmVersion) {
        this.deleteDB().subscribe(() => {
          document.location.reload();
        }),
          (err: any) => {
            console.error(err);
            this._router.navigate(["/sign-out"]);
          };
      }
    });
  }

  private createDB() {
    this.dbVersion = +this._utilService.getGPMVersion()?.replaceAll(".", "").trim();
    if (!this.db && this.dbVersion) {
      this.db = new Dexie("GPM-DB");
      this.db.version(this.dbVersion).stores({
        sites: `id, name, contactDetailsId, deleted, defaultBankAccountId, gstNumber, tradingName, abn, timezoneId, active`,
        systemUserSites: `id, systemUserId, siteId, deleted, ordering`,
        activeSystemUsers: `id, name, deleted, organisationId, displayName, active, isSupport, bccSenderInd`,
        adminTypes: `id, deleted, active, name, description, adminTypeType`,
        vendorPhysioDetails: `id, deleted, vendorId, organisationId, healthlinkMessagingInd, healthlinkEdiNumber, allowNZAsExtract`,
        paymentTypes: `id, deleted, name, description, active`,
        physioProviders: `id, deleted, providerType, providerNo, providerId, vendorId`,
        providers: `id, deleted, vendorId, active, titleId, firstname, lastname, contact_details_id, middlename, systemUserId`,
        titles: `id, name, sex, deleted`,
        providerSites: `id, providerId, siteId, active, deleted, defatultApptDuration, defaultBillApptDuration, overrideApptNearestMins, apptNearestMins, overrideHourStartFrom, hourStartFrom, defaultApptSiteRoomId`,
        referrers: `id, firstname, lastname, clinicName, notes, medicalProviderType, contactDetailsId, clinicId, titleId, deleted, active, useClinicAddress, useClinicContactDetails`,
        siteApptDetails: `id, deleted, siteId, displayStartTime, displayEndTime, addStartApptBuffer, addEndApptBuffer`,
        appState: `id, label, url, state`,
        ethnicGroups: `id, name, accCode, description, deleted`,
        vendorApptDetails: `id, vendorId, showDiabetesRisk, isReferralSourceMandatory`,
        vendors: `id, showInternalRef`,
        employmentIntensities: `id, name, accCode, deleted`,
        activeTeams: `id, name, details`,
        orgPhysioDetails: `id, organisationId, allowSportData`,
        locations: `id, name, accCode, deleted`,
        sports: `id, name, accCode, deleted`,
        scenes: `id, name, accCode, deleted`,
        dhbs: `id, deleted, organisationId, name, code, description, active, dhbRegionId`,
      });
    }
  }

  public clearIndexedDB(): void {
    if (!this.db) {
      return;
    }
    this.db.tables.forEach((table) => {
      table.clear();
    });
  }

  public deleteDB(): Observable<any> {
    if (!this.db) {
      return EMPTY;
    }

    return from(this.db.delete()).pipe(
      tap(() => {
        this.db = null;
      })
    );
  }

  public getInitialCache(): Observable<any> {
    const since = new Date().toJSON();

    if (this._cacheService.get(CacheableItems.GPMCacheDate)) {
      return of(null);
    } else {
      return this._apiService.get(`/cache?command=get&GetAll=${true}&Since=${since}`).pipe(
        concatMap((response: CacheModel) => {
          if (response) {
            this.createDB();

            // Store Cache Date
            this._cacheService.set(CacheableItems.GPMCacheDate, response.CacheDate);

            const dbSites: SiteDBModel[] = [];
            response.Sites.forEach((site) => {
              dbSites.push({
                id: site.ID,
                organisationId: site.ORGANISATION_ID,
                name: site.NAME,
                contactDetailsId: site.CONTACT_DETAILS_ID,
                deleted: site.DELETED_IND === 1,
                defaultBankAccountId: site.DEFAULT_BANK_ACCOUNT_ID,
                gstNumber: site.GST_NUMBER,
                tradingName: site.TRADING_NAME,
                abn: site.ABN_NO,
                timezoneId: site.TIMEZONE_ID,
                active: site.ACTIVE_IND === 1,
              });
            });

            const dbSystemUserSites: SystemUserSiteDBModel[] = [];
            response.SystemUserSites.forEach((systemUserSite) => {
              dbSystemUserSites.push({
                id: systemUserSite.ID,
                systemUserId: systemUserSite.SYSTEM_USER_ID,
                siteId: systemUserSite.SITE_ID,
                deleted: systemUserSite.DELETED_IND === 1,
                ordering: systemUserSite.ORDERING,
              });
            });

            const dbActiveSystemUsers: SystemUserDBModel[] = [];
            response.ActiveSystemUsers.forEach((systemUser) => {
              dbActiveSystemUsers.push({
                id: systemUser.ID,
                name: systemUser.NAME,
                deleted: systemUser.DELETED_IND === 1,
                organisationId: systemUser.ORGANISATION_ID,
                displayName: systemUser.DISPLAY_NAME,
                active: systemUser.ACTIVE_IND === 1,
                isSupport: systemUser.SUPPORT_IND === 1,
                email: systemUser.EMAIL,
                lockTimeoutMins: systemUser.LOCK_TIMEOUT_MINS,
                bccSenderInd: systemUser.BCC_SENDER_IND === 1,
              });
            });

            const dbAdminTypes: AdminTypeDBModel[] = [];
            response.AdminTypes.forEach((adminType) => {
              dbAdminTypes.push({
                id: adminType.ID,
                deleted: adminType.DELETED_IND === 1,
                active: adminType.ACTIVE_IND === 1,
                name: adminType.NAME,
                description: adminType.DESCRIPTION,
                adminTypeType: adminType.ADMIN_TYPE_TYPE,
              });
            });

            const dbVendorPhysioDetails: VendorPhysioDetailsDBModel[] = [];
            if (response.VendorPhysioDetails) {
              dbVendorPhysioDetails.push({
                id: response.VendorPhysioDetails.ID,
                deleted: response.VendorPhysioDetails.DELETED_IND === 1,
                vendorId: response.VendorPhysioDetails.VENDOR_ID,
                organisationId: response.VendorPhysioDetails.ORGANISATION_ID,
                healthlinkMessagingInd: response.VendorPhysioDetails.HEALTHLINK_MESSAGING_IND,
                healthlinkEdiNumber: response.VendorPhysioDetails.HEALTHLINK_EDI_NUMBER,
                allowNZAsExtract: response.VendorPhysioDetails.ALLOW_NZAS_EXTRACT_IND === 1,
                allowAccCscInd: response.VendorPhysioDetails.ALLOW_ACC_CSC_IND === 1,
                allowEvoIntegration: response.VendorPhysioDetails.EVO_INTEGRATION_IND === 1,
              });
            }

            const dbPaymentTypes: PaymentTypeDBModel[] = [];
            response.PaymentTypes.forEach((paymentType) => {
              dbPaymentTypes.push({
                id: paymentType.ID,
                deleted: paymentType.DELETED_IND === 1,
                name: paymentType.NAME,
                description: paymentType.DESCRIPTION,
                active: paymentType.ACTIVE_IND === 1,
              });
            });

            const dbPhysioProviders: PhysioProviderDBModel[] = [];
            response.PhysioProviders.forEach((physioProvider) => {
              dbPhysioProviders.push({
                id: physioProvider.ID,
                deleted: physioProvider.DELETED_IND === 1,
                providerType: physioProvider.PROVIDER_TYPE,
                providerNo: physioProvider.PROVIDER_NO,
                providerId: physioProvider.PROVIDER_ID,
                vendorId: physioProvider.VENDOR_ID,
              });
            });

            const dbProviders: ProviderDBModel[] = [];
            response.Providers.forEach((provider) => {
              dbProviders.push({
                id: provider.ID,
                deleted: provider.DELETED_IND === 1,
                vendorId: provider.VENDOR_ID,
                active: provider.ACTIVE_IND === 1,
                titleId: provider.TITLE_ID,
                firstname: provider.FIRST_NAME,
                lastname: provider.LAST_NAME,
                contact_details_id: provider.CONTACT_DETAILS_ID,
                middlename: provider.MIDDLE_NAME,
                systemUserId: provider.SYSTEM_USER_ID,
              });
            });

            const dbTitles: TitleDBModel[] = [];
            response.Titles.forEach((title) => {
              dbTitles.push({
                id: title.ID,
                name: title.NAME,
                sex: title.SEX,
                deleted: title.DELETED_IND === 1,
              });
            });

            const dbProviderSites: ProviderSiteDBModel[] = [];
            response.ProviderSites.forEach((providerSite) => {
              dbProviderSites.push({
                id: providerSite.ID,
                providerId: providerSite.PROVIDER_ID,
                siteId: providerSite.SITE_ID,
                active: providerSite.ACTIVE_IND === 1,
                deleted: providerSite.DELETED_IND === 1,
                defatultApptDuration: providerSite.DEFAULT_APPT_DURATION,
                defaultBillApptDuration: providerSite.DEFAULT_BILL_APPT_DURATION,
                overrideApptNearestMins: providerSite.OVERRIDE_APPT_NEAREST_MINS,
                apptNearestMins: providerSite.APPT_NEAREST_MINS,
                overrideHourStartFrom: providerSite.OVERRIDE_HOUR_START_FROM,
                hourStartFrom: providerSite.HOUR_START_FROM,
                defaultApptSiteRoomId: providerSite.DEFAULT_APPT_SITE_ROOM_ID,
              });
            });

            const dbSiteApptDetails: SiteApptDetailsDBModel[] = [];
            response.SiteApptDetails.forEach((siteApptDetails) => {
              dbSiteApptDetails.push({
                id: siteApptDetails.ID,
                deleted: siteApptDetails.DELETED_IND === 1,
                siteId: siteApptDetails.SITE_ID,
                displayStartTime: siteApptDetails.DISPLAY_START_TIME,
                displayEndTime: siteApptDetails.DISPLAY_END_TIME,
                addStartApptBuffer: siteApptDetails.ADD_START_APPT_BUFFER,
                addEndApptBuffer: siteApptDetails.ADD_END_APPT_BUFFER,
              });
            });

            const dbReferrers: ReferrerDBModel[] = [];
            response.Referrers.forEach((referrer) => {
              dbReferrers.push({
                id: referrer.ID,
                firstName: referrer.FIRST_NAME,
                lastName: referrer.LAST_NAME,
                active: referrer.ACTIVE_IND === 1,
                clinicId: referrer.CLINIC_ID,
                clinicName: referrer.CLINIC_NAME,
                contactDetailsId: referrer.CONTACT_DETAILS_ID,
                deleted: referrer.DELETED_IND === 1,
                medicalProviderType: referrer.MEDICAL_PROVIDER_TYPE_ID,
                notes: referrer.NOTES,
                titleId: referrer.TITLE_ID,
                useClinicAddress: referrer.USE_CLINIC_ADDRESS_IND === 1,
                useClinicContactDetails: referrer.USE_CLINIC_CONTACT_DETAILS_IND === 1,
              });
            });

            const dbEthnicGroups: EthnicGroupDBModel[] = [];
            response.EthnicGroups.forEach((ethnicGroup) => {
              dbEthnicGroups.push({
                id: ethnicGroup.ID,
                name: ethnicGroup.NAME,
                accCode: ethnicGroup.ACC_CODE,
                description: ethnicGroup.DESCRIPTION,
                deleted: ethnicGroup.DELETED_IND === 1,
              });
            });

            const dbVendorApptDetails: VendorApptDetailsDBModel[] = [];
            response.VendorApptDetails.forEach((vendorApptDetail) => {
              dbVendorApptDetails.push({
                id: vendorApptDetail.ID,
                vendorId: vendorApptDetail.VENDOR_ID,
                showDiabetesRisk: vendorApptDetail.SHOW_DIABETES_RISK_IND === 1,
                isReferralSourceMandatory: vendorApptDetail.REFERRAL_CLIENT_MANDATORY === 1,
              });
            });

            const dbVendors: VendorDBModel[] = [];
            response.Vendors.forEach((vendor) => {
              dbVendors.push({
                id: vendor.ID,
                showInternalRef: vendor.USE_INTERNAL_REF_IND === 1,
                timeZoneId: vendor.TIMEZONE_ID,
              });
            });

            const dbEmploymentIntensities: EmploymentIntensityDBModel[] = [];
            response.EmploymentIntensities.forEach((employmentIntensity) => {
              dbEmploymentIntensities.push({
                id: employmentIntensity.ID,
                name: employmentIntensity.NAME,
                accCode: employmentIntensity.ACC_CODE,
                deleted: employmentIntensity.DELETED_IND === 1,
              });
            });

            const dbTeams: TeamDBModel[] = [];
            response.ActiveTeams.forEach((team) => {
              dbTeams.push({
                id: team.ID,
                name: team.NAME,
                details: team.DETAILS,
              });
            });

            const dbOrgPhysioDetails: OrganisationPhysioDetailsDBModel[] = [];
            dbOrgPhysioDetails.push({
              id: response.OrgPhysioDetails.ID,
              organisationId: response.OrgPhysioDetails.ORGANISATION_ID,
              allowSportData: response.OrgPhysioDetails.ALLOW_SPORT_DATA == 1,
            });

            const dbLocations: LocationDBModel[] = [];
            response.Locations.forEach((location) => {
              dbLocations.push({
                id: location.ID,
                name: location.NAME,
                accCode: location.ACC_CODE,
                deleted: location.DELETED_IND === 1,
              });
            });

            const dbSports: SportDBModel[] = [];
            response.Sports.forEach((sport) => {
              dbSports.push({
                id: sport.ID,
                name: sport.NAME,
                accCode: sport.ACC_CODE,
                deleted: sport.DELETED_IND === 1,
              });
            });

            const dbScenes: SceneDBModel[] = [];
            response.Scenes.forEach((scene) => {
              dbScenes.push({
                id: scene.ID,
                name: scene.NAME,
                accCode: scene.ACC_CODE,
                deleted: scene.DELETED_IND === 1,
              });
            });

            const dbDHBs: DHBDBModel[] = [];
            response.DHBs.forEach((dhb) => {
              dbDHBs.push({
                id: dhb.ID,
                deleted: dhb.DELETED_IND === 1,
                organisationId: dhb.ORGANISATION_ID,
                name: dhb.NAME,
                code: dhb.CODE,
                description: dhb.DESCRIPTION,
                active: dhb.ACTIVE_IND === 1,
                dhbRegionId: dhb.DHB_REGION_ID,
              });
            });

            return of(
              Promise.all([
                this.db.sites.bulkAdd(dbSites),
                this.db.systemUserSites.bulkAdd(dbSystemUserSites),
                this.db.activeSystemUsers.bulkAdd(dbActiveSystemUsers),
                this.db.adminTypes.bulkAdd(dbAdminTypes),
                this.db.vendorPhysioDetails.bulkAdd(dbVendorPhysioDetails),
                this.db.paymentTypes.bulkAdd(dbPaymentTypes),
                this.db.physioProviders.bulkAdd(dbPhysioProviders),
                this.db.providers.bulkAdd(dbProviders),
                this.db.titles.bulkAdd(dbTitles),
                this.db.providerSites.bulkAdd(dbProviderSites),
                this.db.siteApptDetails.bulkAdd(dbSiteApptDetails),
                this.db.referrers.bulkAdd(dbReferrers),
                this.db.ethnicGroups.bulkAdd(dbEthnicGroups),
                this.db.vendorApptDetails.bulkAdd(dbVendorApptDetails),
                this.db.vendors.bulkAdd(dbVendors),
                this.db.employmentIntensities.bulkAdd(dbEmploymentIntensities),
                this.db.activeTeams.bulkAdd(dbTeams),
                this.db.orgPhysioDetails.bulkAdd(dbOrgPhysioDetails),
                this.db.locations.bulkAdd(dbLocations),
                this.db.sports.bulkAdd(dbSports),
                this.db.scenes.bulkAdd(dbScenes),
                this.db.dhbs.bulkAdd(dbDHBs),
              ])
            );
          }
        })
      );
    }
  }

  public getCacheDelta(): Observable<CacheModel | { VersionError: string }> {
    if (this.updatingCache) {
      return EMPTY;
    }

    const cacheDate = new Date(this._cacheService.get(CacheableItems.GPMCacheDate));
    const refDate = new Date();

    if (addMinutes(cacheDate, 5) < refDate) {
      this.updatingCache = true;
      return from(
        this._apiService.get<CacheModel | { VersionError: string }>(`/cache?command=get&GetAll=${false}&Since=${cacheDate.toJSON()}`).pipe(
          tap((response) => {
            const error = response as { VersionError: string };
            if (error?.VersionError) {
              console.error(error.VersionError);
              this._router.navigate(["/sign-out"]);
            }

            const result = response as CacheModel;
            this.updateDBSites(result);
            this.updateDBSystemUserSites(result);
            this.updateDBActiveSystemUsers(result);
            this.udpateDBTitles(result);
            this.udpateDBAdminTypes(result);
            this.udpateDBVendorPhysioDetails(result);
            this.udpateDBPaymentTypes(result);
            this.udpateDBProviders(result);
            this.updateDBPhysioProviders(result);
            this.updateDBProviderSites(result);
            this.updateDBSiteApptDetails(result);
            this.updateDBReferrers(result);
            this.updateDBEthnicGroups(result);
            this.updateDBVendorApptDetails(result);
            this.updateDBVendors(result);
            this.updateDBEmploymentIntensities(result);
            this.updateDBActiveTeams(result);
            this.udpateDBOrgPhysioDetails(result);
            this.updateDBLocations(result);
            this.updateDBSports(result);
            this.updateDBScenes(result);
            this.updateDBDHBs(result);

            // Store Cache Date
            this._cacheService.set(CacheableItems.GPMCacheDate, result.CacheDate);

            this.updatingCache = false;
          })
        )
      );
    }

    return EMPTY;
  }

  private updateDBSites(response: CacheModel): void {
    if (response.Sites) {
      response.Sites.forEach((site: SiteModel) => {
        this.getDBSiteById(site.ID).subscribe((response) => {
          if (response) {
            this.db.sites.update(
              site.ID,
              site.NAME,
              site.CONTACT_DETAILS_ID,
              site.DELETED_IND === 1,
              site.DEFAULT_BANK_ACCOUNT_ID,
              site.GST_NUMBER,
              site.TRADING_NAME,
              site.ABN_NO,
              site.TIMEZONE_ID,
              site.ACTIVE_IND === 1
            );
          } else {
            this.db.sites.add({
              id: site.ID,
              name: site.NAME,
              contactDetailsId: site.CONTACT_DETAILS_ID,
              deleted: site.DELETED_IND === 1,
              defaultBankAccountId: site.DEFAULT_BANK_ACCOUNT_ID,
              gstNumber: site.GST_NUMBER,
              tradingName: site.TRADING_NAME,
              abn: site.ABN_NO,
              timezoneId: site.TIMEZONE_ID,
              active: site.ACTIVE_IND === 1,
            });
          }
        }),
          (err: any) => {
            console.log(err);
            this.updatingCache = false;
          };
      });
    }
  }

  private updateDBSystemUserSites(response: CacheModel): void {
    if (response.SystemUserSites !== null) {
      response.SystemUserSites.forEach((systemUserSite: SystemUserSiteModel) => {
        this.getDBSystemUserSiteById(systemUserSite.ID).subscribe((response) => {
          if (response) {
            this.db.systemUserSites.update(
              systemUserSite.ID,
              systemUserSite.SYSTEM_USER_ID,
              systemUserSite.SITE_ID,
              systemUserSite.DELETED_IND === 1,
              systemUserSite.ORDERING
            );
          } else {
            this.db.systemUserSites.add({
              id: systemUserSite.ID,
              systemUserId: systemUserSite.SYSTEM_USER_ID,
              siteId: systemUserSite.SITE_ID,
              deleted: systemUserSite.DELETED_IND === 1,
              ordering: systemUserSite.ORDERING,
            });
          }
        }),
          (err: any) => {
            console.log(err);
            this.updatingCache = false;
          };
      });
    }
  }

  private updateDBActiveSystemUsers(response: CacheModel): void {
    if (response.ActiveSystemUsers !== null) {
      response.ActiveSystemUsers.forEach((systemUser: SystemUserModel) => {
        this.getDBActiveSystemUserById(systemUser.ID).subscribe((response) => {
          if (response) {
            this.db.activeSystemUsers.update(
              systemUser.ID,
              systemUser.NAME,
              systemUser.DELETED_IND === 1,
              systemUser.ORGANISATION_ID,
              systemUser.DISPLAY_NAME,
              systemUser.ACTIVE_IND === 1,
              systemUser.EMAIL,
              systemUser.BCC_SENDER_IND === 1
            );
          } else {
            this.db.activeSystemUsers.add({
              id: systemUser.ID,
              name: systemUser.NAME,
              deleted: systemUser.DELETED_IND === 1,
              organisationId: systemUser.ORGANISATION_ID,
              displayName: systemUser.DISPLAY_NAME,
              active: systemUser.ACTIVE_IND === 1,
              email: systemUser.EMAIL,
              bccSenderInd: systemUser.BCC_SENDER_IND === 1,
            });
          }
        }),
          (err: any) => {
            console.log(err);
            this.updatingCache = false;
          };
      });
    }
  }

  private udpateDBTitles(response: CacheModel): void {
    if (response.Titles !== null) {
      response.Titles.forEach((title: TitleModel) => {
        this.getDBTitleById(title.ID).subscribe((response) => {
          if (response) {
            this.db.titles.update(title.ID, title.NAME, title.SEX, title.DELETED_IND === 1);
          } else {
            this.db.titles.add({
              id: title.ID,
              name: title.NAME,
              sex: title.SEX,
              deleted: title.DELETED_IND === 1,
            });
          }
        }),
          (err: any) => {
            console.log(err);
            this.updatingCache = false;
          };
      });
    }
  }

  private udpateDBAdminTypes(response: CacheModel): void {
    if (response.AdminTypes !== null) {
      response.AdminTypes.forEach((adminType: AdminTypeModel) => {
        this.getDBAdminTypeById(adminType.ID).subscribe((response) => {
          if (response) {
            this.db.adminTypes.update(
              adminType.ID,
              adminType.DELETED_IND === 1,
              adminType.ACTIVE_IND === 1,
              adminType.NAME,
              adminType.DESCRIPTION,
              adminType.ADMIN_TYPE_TYPE
            );
          } else {
            this.db.adminTypes.add({
              id: adminType.ID,
              deleted: adminType.DELETED_IND === 1,
              active: adminType.ACTIVE_IND === 1,
              name: adminType.NAME,
              description: adminType.DESCRIPTION,
              adminTypeType: adminType.ADMIN_TYPE_TYPE,
            });
          }
        }),
          (err: any) => {
            console.log(err);
            this.updatingCache = false;
          };
      });
    }
  }

  public updateDBAdminType(adminType: AdminTypeDBModel): void {
    // to update the existing row or add new if it doesn't exist
    this.db.adminTypes.put(
      {
        id: adminType.id,
        deleted: adminType.deleted,
        active: adminType.active,
        name: adminType.name,
        description: adminType.description,
        adminTypeType: adminType.adminTypeType,
      },
      adminType.id
    );
  }

  private udpateDBVendorPhysioDetails(response: CacheModel): void {
    if (response.VendorPhysioDetails !== null) {
      const vendorDetails = response.VendorPhysioDetails;
      this.getDBVendorPhysioDetailsById(vendorDetails.ID).subscribe((response) => {
        if (response) {
          this.db.vendorPhysioDetails.update(
            vendorDetails.ID,
            vendorDetails.DELETED_IND === 1,
            vendorDetails.VENDOR_ID === 1,
            vendorDetails.ORGANISATION_ID,
            vendorDetails.HEALTHLINK_MESSAGING_IND,
            vendorDetails.HEALTHLINK_EDI_NUMBER,
            vendorDetails.EVO_INTEGRATION_IND === 1
          );
        } else {
          this.db.vendorPhysioDetails.add({
            id: vendorDetails.ID,
            deleted: vendorDetails.DELETED_IND === 1,
            vendorId: vendorDetails.VENDOR_ID === 1,
            organisationId: vendorDetails.ORGANISATION_ID,
            healthlinkMessagingInd: vendorDetails.HEALTHLINK_MESSAGING_IND,
            healthlinkEdiNumber: vendorDetails.HEALTHLINK_EDI_NUMBER,
            allowEvoIntegration: vendorDetails.EVO_INTEGRATION_IND === 1,
          });
        }
      }),
        (err: any) => {
          console.log(err);
          this.updatingCache = false;
        };
    }
  }

  private udpateDBPaymentTypes(response: CacheModel): void {
    if (response.PaymentTypes !== null) {
      response.PaymentTypes.forEach((paymentType: PaymentTypeModel) => {
        this.getDBAdminTypeById(paymentType.ID).subscribe((response) => {
          if (response) {
            this.db.paymentTypes.update(
              paymentType.ID,
              paymentType.DELETED_IND === 1,
              paymentType.NAME,
              paymentType.DESCRIPTION,
              paymentType.ACTIVE_IND === 1
            );
          } else {
            this.db.paymentTypes.add({
              id: paymentType.ID,
              deleted: paymentType.DELETED_IND === 1,
              name: paymentType.NAME,
              description: paymentType.DESCRIPTION,
              active: paymentType.ACTIVE_IND === 1,
            });
          }
        }),
          (err: any) => {
            console.log(err);
            this.updatingCache = false;
          };
      });
    }
  }

  private udpateDBProviders(response: CacheModel): void {
    if (response.Providers !== null) {
      response.Providers.forEach((provider: ProviderModel) => {
        this.getDBProviderById(provider.ID).subscribe((response) => {
          if (response) {
            this.db.providers.update(
              provider.ID,
              provider.DELETED_IND === 1,
              provider.VENDOR_ID,
              provider.ACTIVE_IND === 1,
              provider.TITLE_ID,
              provider.FIRST_NAME,
              provider.LAST_NAME,
              provider.CONTACT_DETAILS_ID,
              provider.MIDDLE_NAME,
              provider.SYSTEM_USER_ID
            );
          } else {
            this.db.providers.add({
              id: provider.ID,
              deleted: provider.DELETED_IND === 1,
              vendorId: provider.VENDOR_ID,
              active: provider.ACTIVE_IND === 1,
              titleId: provider.TITLE_ID,
              firstname: provider.FIRST_NAME,
              lastname: provider.LAST_NAME,
              contact_details_id: provider.CONTACT_DETAILS_ID,
              middlename: provider.MIDDLE_NAME,
              systemUserId: provider.SYSTEM_USER_ID,
            });
          }
        }),
          (err: any) => {
            console.log(err);
            this.updatingCache = false;
          };
      });
    }
  }

  private updateDBPhysioProviders(response: CacheModel): void {
    if (response.PhysioProviders !== null) {
      response.PhysioProviders.forEach((physioProvider: PhysioProviderModel) => {
        this.getDBPhysioProviderById(physioProvider.ID).subscribe((response) => {
          if (response) {
            this.db.physioProviders.update(
              physioProvider.ID,
              physioProvider.DELETED_IND === 1,
              physioProvider.PROVIDER_TYPE,
              physioProvider.PROVIDER_NO,
              physioProvider.PROVIDER_ID,
              physioProvider.VENDOR_ID
            );
          } else {
            this.db.physioProviders.add({
              id: physioProvider.ID,
              deleted: physioProvider.DELETED_IND === 1,
              providerType: physioProvider.PROVIDER_TYPE,
              providerNo: physioProvider.PROVIDER_NO,
              providerId: physioProvider.PROVIDER_ID,
              vendorId: physioProvider.VENDOR_ID,
            });
          }
        }),
          (err: any) => {
            console.log(err);
            this.updatingCache = false;
          };
      });
    }
  }

  private updateDBProviderSites(response: CacheModel): void {
    if (response.ProviderSites !== null) {
      response.ProviderSites.forEach((providerSite: ProviderSiteModel) => {
        this.getDBProviderSiteById(providerSite.ID).subscribe((response) => {
          if (response) {
            this.db.providerSites.update(
              providerSite.ID,
              providerSite.PROVIDER_ID,
              providerSite.SITE_ID,
              providerSite.ACTIVE_IND === 1,
              providerSite.DELETED_IND === 1,
              providerSite.DEFAULT_APPT_DURATION,
              providerSite.DEFAULT_BILL_APPT_DURATION,
              providerSite.OVERRIDE_APPT_NEAREST_MINS,
              providerSite.APPT_NEAREST_MINS,
              providerSite.OVERRIDE_HOUR_START_FROM,
              providerSite.HOUR_START_FROM,
              providerSite.DEFAULT_APPT_SITE_ROOM_ID
            );
          } else {
            this.db.providerSites.add({
              id: providerSite.ID,
              providerId: providerSite.PROVIDER_ID,
              siteId: providerSite.SITE_ID,
              active: providerSite.ACTIVE_IND === 1,
              deleted: providerSite.DELETED_IND === 1,
              defatultApptDuration: providerSite.DEFAULT_APPT_DURATION,
              defaultBillApptDuration: providerSite.DEFAULT_BILL_APPT_DURATION,
              overrideApptNearestMins: providerSite.OVERRIDE_APPT_NEAREST_MINS,
              apptNearestMins: providerSite.APPT_NEAREST_MINS,
              overrideHourStartFrom: providerSite.OVERRIDE_HOUR_START_FROM,
              hourStartFrom: providerSite.HOUR_START_FROM,
              defaultApptSiteRoomId: providerSite.DEFAULT_APPT_SITE_ROOM_ID,
            });
          }
        }),
          (err: any) => {
            console.log(err);
            this.updatingCache = false;
          };
      });
    }
  }

  private updateDBSiteApptDetails(response: CacheModel): void {
    if (response.SiteApptDetails !== null) {
      response.SiteApptDetails.forEach((siteApptDetails: SiteApptDetailsModel) => {
        this.getDBSiteApptDetailsById(siteApptDetails.ID).subscribe((response) => {
          if (response) {
            this.db.siteApptDetails.update(
              siteApptDetails.ID,
              siteApptDetails.DELETED_IND === 1,
              siteApptDetails.SITE_ID,
              siteApptDetails.DISPLAY_START_TIME,
              siteApptDetails.DISPLAY_END_TIME,
              siteApptDetails.ADD_START_APPT_BUFFER,
              siteApptDetails.ADD_END_APPT_BUFFER
            );
          } else {
            this.db.siteApptDetails.add({
              id: siteApptDetails.ID,
              deleted: siteApptDetails.DELETED_IND === 1,
              siteId: siteApptDetails.SITE_ID,
              displayStartTime: siteApptDetails.DISPLAY_START_TIME,
              displayEndTime: siteApptDetails.DISPLAY_END_TIME,
              addStartApptBuffer: siteApptDetails.ADD_START_APPT_BUFFER,
              addEndApptBuffer: siteApptDetails.ADD_END_APPT_BUFFER,
            });
          }
        }),
          (err: any) => {
            console.log(err);
            this.updatingCache = false;
          };
      });
    }
  }

  private updateDBReferrers(response: CacheModel): void {
    if (response.Referrers !== null) {
      response.Referrers.forEach((referrer: ReferrerModel) => {
        this.getDBReferrerById(referrer.ID).subscribe((response) => {
          if (response) {
            this.db.referrers.update(
              referrer.ID,
              referrer.FIRST_NAME,
              referrer.LAST_NAME,
              referrer.CLINIC_NAME,
              referrer.NOTES,
              referrer.MEDICAL_PROVIDER_TYPE_ID,
              referrer.CONTACT_DETAILS_ID,
              referrer.CLINIC_ID,
              referrer.TITLE_ID,
              referrer.DELETED_IND === 1,
              referrer.ACTIVE_IND === 1,
              referrer.USE_CLINIC_ADDRESS_IND === 1,
              referrer.USE_CLINIC_CONTACT_DETAILS_IND === 1
            );
          } else {
            this.db.referrers.add({
              id: referrer.ID,
              firstname: referrer.FIRST_NAME,
              lastname: referrer.LAST_NAME,
              clinicName: referrer.CLINIC_NAME,
              notes: referrer.NOTES,
              medicalProviderType: referrer.MEDICAL_PROVIDER_TYPE_ID,
              contactDetailsId: referrer.CONTACT_DETAILS_ID,
              clinicId: referrer.CLINIC_ID,
              titleId: referrer.TITLE_ID,
              deleted: referrer.DELETED_IND === 1,
              active: referrer.ACTIVE_IND === 1,
              useClinicAddress: referrer.USE_CLINIC_ADDRESS_IND === 1,
              useClinicContactDetails: referrer.USE_CLINIC_CONTACT_DETAILS_IND === 1,
            });
          }
        }),
          (err: any) => {
            console.log(err);
            this.updatingCache = false;
          };
      });
    }
  }

  private updateDBEthnicGroups(response: CacheModel): void {
    if (response.EthnicGroups !== null) {
      response.EthnicGroups.forEach((ethnicGroup: EthnicGroupModel) => {
        this.getDBEthnicGroupById(ethnicGroup.ID).subscribe((response) => {
          if (response) {
            this.db.ethnicGroups.update(
              ethnicGroup.ID,
              ethnicGroup.NAME,
              ethnicGroup.ACC_CODE,
              ethnicGroup.DESCRIPTION,
              ethnicGroup.DELETED_IND === 1
            );
          } else {
            this.db.ethnicGroups.add({
              id: ethnicGroup.ID,
              name: ethnicGroup.NAME,
              accCode: ethnicGroup.ACC_CODE,
              description: ethnicGroup.DESCRIPTION,
              deleted: ethnicGroup.DELETED_IND === 1,
            });
          }
        }),
          (err: any) => {
            console.log(err);
            this.updatingCache = false;
          };
      });
    }
  }

  private updateDBVendorApptDetails(response: CacheModel): void {
    if (response.VendorApptDetails !== null) {
      response.VendorApptDetails.forEach((vendorApptDetail) => {
        this.getDBVendorApptDetailsById(vendorApptDetail.ID).subscribe((data) => {
          if (data) {
            this.db.vendorApptDetails.update(
              vendorApptDetail.ID,
              vendorApptDetail.VENDOR_ID,
              vendorApptDetail.SHOW_DIABETES_RISK_IND === 1,
              vendorApptDetail.REFERRAL_CLIENT_MANDATORY === 1
            );
          } else {
            let dbVendorApptDetails: VendorApptDetailsDBModel = {
              id: vendorApptDetail.ID,
              vendorId: vendorApptDetail.VENDOR_ID,
              showDiabetesRisk: vendorApptDetail.SHOW_DIABETES_RISK_IND === 1,
              isReferralSourceMandatory: vendorApptDetail.REFERRAL_CLIENT_MANDATORY === 1,
            };
            this.db.vendorApptDetails.add(dbVendorApptDetails);
          }
        }),
          (err: any) => {
            console.log(err);
            this.updatingCache = false;
          };
      });
    }
  }

  private updateDBVendors(response: CacheModel): void {
    if (response.Vendors !== null) {
      response.Vendors.forEach((vendor) => {
        this.getDBVendorById(vendor.ID).subscribe((data) => {
          if (data) {
            this.db.vendors.update(vendor.ID, vendor.USE_INTERNAL_REF_IND === 1);
          } else {
            let dbVendor: VendorDBModel = {
              id: vendor.ID,
              showInternalRef: vendor.USE_INTERNAL_REF_IND === 1,
              timeZoneId: vendor.TIMEZONE_ID,
            };
            this.db.vendors.add(dbVendor);
          }
        }),
          (err: any) => {
            console.log(err);
            this.updatingCache = false;
          };
      });
    }
  }

  private updateDBEmploymentIntensities(response: CacheModel): void {
    if (response.EmploymentIntensities !== null) {
      response.EmploymentIntensities.forEach((employmentIntensity) => {
        this.getDBEmploymentIntensityById(employmentIntensity.ID).subscribe((data) => {
          if (data) {
            this.db.employmentIntensities.update(
              employmentIntensity.ID,
              employmentIntensity.NAME,
              employmentIntensity.ACC_CODE,
              employmentIntensity.DELETED_IND === 1
            );
          } else {
            let dbEmploymentIntensity: EmploymentIntensityDBModel = {
              id: employmentIntensity.ID,
              name: employmentIntensity.NAME,
              accCode: employmentIntensity.ACC_CODE,
              deleted: employmentIntensity.DELETED_IND === 1,
            };
            this.db.employmentIntensities.add(dbEmploymentIntensity);
          }
        }),
          (err: any) => {
            console.log(err);
            this.updatingCache = false;
          };
      });
    }
  }

  private updateDBActiveTeams(response: CacheModel): void {
    if (response.ActiveTeams !== null) {
      response.ActiveTeams.forEach((team) => {
        this.getDBActiveTeamById(team.ID).subscribe((data) => {
          if (data) {
            this.db.activeTeams.update(team.ID, team.NAME, team.DETAILS);
          } else {
            let dbTeam: TeamDBModel = {
              id: team.ID,
              name: team.NAME,
              details: team.DETAILS,
            };
            this.db.activeTeams.add(dbTeam);
          }
        }),
          (err: any) => {
            console.log(err);
            this.updatingCache = false;
          };
      });
    }
  }

  private udpateDBOrgPhysioDetails(response: CacheModel): void {
    if (response.OrgPhysioDetails !== null) {
      const orgDetails = response.OrgPhysioDetails;
      this.getDBOrgPhysioDetailsById(orgDetails.ID).subscribe((response) => {
        if (response) {
          this.db.orgPhysioDetails.update(orgDetails.ID, orgDetails.ORGANISATION_ID, orgDetails.ALLOW_SPORT_DATA === 1);
        } else {
          this.db.orgPhysioDetails.add({
            id: orgDetails.ID,
            organisationId: orgDetails.ORGANISATION_ID,
            allowSportData: orgDetails.ALLOW_SPORT_DATA === 1,
          });
        }
      }),
        (err: any) => {
          console.log(err);
          this.updatingCache = false;
        };
    }
  }

  private updateDBLocations(response: CacheModel): void {
    if (response.Locations !== null) {
      response.Locations.forEach((location) => {
        this.getDBLocationById(location.ID).subscribe((data) => {
          if (data) {
            this.db.locations.update(location.ID, location.NAME, location.ACC_CODE, location.DELETED_IND === 1);
          } else {
            let dbLocation: LocationDBModel = {
              id: location.ID,
              name: location.NAME,
              accCode: location.ACC_CODE,
              deleted: location.DELETED_IND === 1,
            };
            this.db.locations.add(dbLocation);
          }
        }),
          (err: any) => {
            console.log(err);
            this.updatingCache = false;
          };
      });
    }
  }

  private updateDBSports(response: CacheModel): void {
    if (response.Sports !== null) {
      response.Sports.forEach((sports) => {
        this.getDBSportById(sports.ID).subscribe((data) => {
          if (data) {
            this.db.sports.update(sports.ID, sports.NAME, sports.ACC_CODE, sports.DELETED_IND === 1);
          } else {
            let dbSport: SportDBModel = {
              id: sports.ID,
              name: sports.NAME,
              accCode: sports.ACC_CODE,
              deleted: sports.DELETED_IND === 1,
            };
            this.db.sports.add(dbSport);
          }
        }),
          (err: any) => {
            console.log(err);
            this.updatingCache = false;
          };
      });
    }
  }

  private updateDBScenes(response: CacheModel): void {
    if (response.Scenes !== null) {
      response.Scenes.forEach((scenes) => {
        this.getDBSceneById(scenes.ID).subscribe((data) => {
          if (data) {
            this.db.scenes.update(scenes.ID, scenes.NAME, scenes.ACC_CODE, scenes.DELETED_IND === 1);
          } else {
            let dbScene: SceneDBModel = {
              id: scenes.ID,
              name: scenes.NAME,
              accCode: scenes.ACC_CODE,
              deleted: scenes.DELETED_IND === 1,
            };
            this.db.scenes.add(dbScene);
          }
        }),
          (err: any) => {
            console.log(err);
            this.updatingCache = false;
          };
      });
    }
  }

  private updateDBDHBs(response: CacheModel): void {
    if (response.DHBs !== null) {
      response.DHBs.forEach((dhb) => {
        this.getDBDHBById(dhb.ID).subscribe((data) => {
          if (data) {
            this.db.dhbs.update(
              dhb.ID,
              dhb.DELETED_IND === 1,
              dhb.ORGANISATION_ID,
              dhb.NAME,
              dhb.CODE,
              dhb.DESCRIPTION,
              dhb.ACTIVE_IND === 1,
              dhb.DHB_REGION_ID
            );
          } else {
            const dbDHB: DHBDBModel = {
              id: dhb.ID,
              deleted: dhb.DELETED_IND === 1,
              organisationId: dhb.ORGANISATION_ID,
              name: dhb.NAME,
              code: dhb.CODE,
              description: dhb.DESCRIPTION,
              active: dhb.ACTIVE_IND === 1,
              dhbRegionId: dhb.DHB_REGION_ID,
            };
            this.db.dhbs.add(dbDHB);
          }
        }),
          (err: any) => {
            console.log(err);
            this.updatingCache = false;
          };
      });
    }
  }

  public getDBSiteById(siteId: number): Observable<SiteDBModel> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.sites.get(siteId)).pipe(map((response: SiteDBModel) => response));
  }

  public getDBSystemUserSiteById(id: number): Observable<SystemUserSiteDBModel> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.systemUserSites.get(id)).pipe(map((response: SystemUserSiteDBModel) => response));
  }

  public getDBTitleById(titleId: number): Observable<TitleDBModel> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.titles.get(titleId)).pipe(map((response: TitleDBModel) => response));
  }

  public getDBAdminTypeById(adminTypeId: number): Observable<AdminTypeDBModel> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.adminTypes.get(adminTypeId)).pipe(map((response: AdminTypeDBModel) => response));
  }

  public getDBVendorPhysioDetailsById(id: number): Observable<VendorPhysioDetailsDBModel> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.vendorPhysioDetails.get(id)).pipe(map((response: VendorPhysioDetailsDBModel) => response));
  }

  public getDBPaymentTypeById(paymentTypeId: number): Observable<PaymentTypeDBModel> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.paymentTypes.get(paymentTypeId)).pipe(map((response: PaymentTypeDBModel) => response));
  }

  public getDBProviderById(providerId: number): Observable<ProviderDBModel> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.providers.get(providerId)).pipe(map((response: ProviderDBModel) => response));
  }

  public getDBPhysioProviderById(id: number): Observable<PhysioProviderDBModel> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.physioProviders.get(id)).pipe(map((response: PhysioProviderDBModel) => response));
  }

  public getDBPhysioProviderByProviderId(physioProviderId: number): Observable<PhysioProviderDBModel> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.physioProviders.where("providerId").equals(physioProviderId).toArray()).pipe(
      map((response: PhysioProviderDBModel) => response)
    );
  }

  public getDBProviderSiteById(providerSiteId: number): Observable<ProviderSiteDBModel> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.providerSites.get(providerSiteId)).pipe(map((response: ProviderSiteDBModel) => response));
  }

  public getDBSiteApptDetailsById(siteApptDetailsId: number): Observable<SiteApptDetailsDBModel> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.siteApptDetails.get(siteApptDetailsId)).pipe(map((response: SiteApptDetailsDBModel) => response));
  }

  public getDBReferrerById(referrerId: number): Observable<ReferrerDBModel> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.referrers.get(referrerId)).pipe(map((response: ReferrerDBModel) => response));
  }

  public getDBEthnicGroupById(ethnicGroupId: number): Observable<EthnicGroupDBModel> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.ethnicGroups.get(ethnicGroupId)).pipe(map((response: EthnicGroupDBModel) => response));
  }

  public getDBVendorApptDetailsById(vendorApptDetailId: number): Observable<VendorApptDetailsDBModel> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.vendorApptDetails.get(vendorApptDetailId)).pipe(map((response: VendorApptDetailsDBModel) => response));
  }

  public getDBVendorById(vendorId: number): Observable<VendorDBModel> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.vendors.get(vendorId)).pipe(map((response: VendorDBModel) => response));
  }

  public getDBEmploymentIntensityById(employmentIntensityId: number): Observable<EmploymentIntensityDBModel> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.employmentIntensities.get(employmentIntensityId)).pipe(map((response: EmploymentIntensityDBModel) => response));
  }

  public getDBActiveTeamById(teamId: number): Observable<TeamDBModel> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.activeTeams.get(teamId)).pipe(map((response: TeamDBModel) => response));
  }

  public getDBOrgPhysioDetailsById(id: number): Observable<OrganisationPhysioDetailsDBModel> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.orgPhysioDetails.get(id)).pipe(map((response: OrganisationPhysioDetailsDBModel) => response));
  }

  public getDBLocationById(locationId: number): Observable<LocationDBModel> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.locations.get(locationId)).pipe(map((response: LocationDBModel) => response));
  }

  public getDBSportById(sportId: number): Observable<SportDBModel> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.sports.get(sportId)).pipe(map((response: SportDBModel) => response));
  }

  public getDBSceneById(sceneId: number): Observable<SceneDBModel> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.scenes.get(sceneId)).pipe(map((response: SceneDBModel) => response));
  }

  public getDBDHBById(dhbId: number): Observable<DHBDBModel> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.dhbs.get(dhbId)).pipe(map((response: DHBDBModel) => response));
  }

  public getDBActiveSystemUserById(activeSystemuser: number): Observable<SystemUserDBModel> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.activeSystemUsers.get(activeSystemuser)).pipe(map((response: SystemUserDBModel) => response));
  }

  public getAllSites(): Observable<SiteDBModel[]> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.sites.orderBy("name").toArray()).pipe(map((response: SiteDBModel[]) => response.map((s) => s)));
  }

  public getAllSystemUserSites(): Observable<SystemUserSiteDBModel[]> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.systemUserSites.toArray()).pipe(map((response: SystemUserSiteDBModel[]) => response.map((s) => s)));
  }

  public getAllActiveSystemUsers(): Observable<SystemUserDBModel[]> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.activeSystemUsers.orderBy("name").toArray()).pipe(map((response: SystemUserDBModel[]) => response.map((s) => s)));
  }

  public getAllTitles(): Observable<TitleDBModel[]> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.titles.toArray()).pipe(map((response: TitleDBModel[]) => response.map((s) => s)));
  }

  public getAllAdminTypes(): Observable<AdminTypeDBModel[]> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.adminTypes.toArray()).pipe(map((response: AdminTypeDBModel[]) => response.map((s) => s)));
  }

  public getAllAdminTypesByType(type: AdminTypesEnum): Observable<AdminTypeDBModel[]> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.adminTypes.toArray()).pipe(map((response: AdminTypeDBModel[]) => response.filter((s) => s.adminTypeType === type)));
  }

  public getVendorPhysioDetails(): Observable<VendorPhysioDetailsDBModel> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.vendorPhysioDetails.toArray()).pipe(map((response: VendorPhysioDetailsDBModel) => response));
  }

  public getAllPaymentTypes(): Observable<PaymentTypeDBModel[]> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.paymentTypes.toArray()).pipe(map((response: PaymentTypeDBModel[]) => response.map((s) => s)));
  }

  public getAllPhysioProviders(): Observable<PhysioProviderDBModel[]> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.physioProviders.toArray()).pipe(map((response: PhysioProviderDBModel[]) => response.map((s) => s)));
  }

  public getAllProviders(): Observable<ProviderDBModel[]> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.providers.orderBy("firstname").toArray()).pipe(map((response: ProviderDBModel[]) => response.map((s) => s)));
  }

  public getProvidersByIds(keys: number[]): Observable<ProviderDBModel[] | any> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.providers.bulkGet(keys)).pipe(
      map((response: ProviderDBModel[]) => {
        if (response) {
          return response.map((s) => s);
        }

        return of(null);
      })
    );
  }

  // get provider by system user id
  public getProviderBySystemUserId(systemUserId: number): Observable<ProviderDBModel[]> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.providers.where("systemUserId").equals(systemUserId).toArray()).pipe(
      map((response: ProviderDBModel[]) => response.map((s) => s))
    );
  }

  public getOrgPhysioDetails(): Observable<OrganisationPhysioDetailsDBModel> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.orgPhysioDetails.toArray()).pipe(map((response: OrganisationPhysioDetailsDBModel) => response));
  }

  public getAllProviderSites(): Observable<ProviderSiteDBModel[]> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.providerSites.toArray()).pipe(map((response: ProviderSiteDBModel[]) => response.map((s) => s)));
  }

  public getAllSiteApptDetails(): Observable<SiteApptDetailsDBModel[]> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.siteApptDetails.toArray()).pipe(map((response: SiteApptDetailsDBModel[]) => response.map((s) => s)));
  }

  public getAllReferrers(): Observable<ReferrerDBModel[]> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.referrers.toArray()).pipe(map((response: ReferrerDBModel[]) => response.map((s) => s)));
  }

  public getAllEthnicGroups(): Observable<EthnicGroupDBModel[]> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.ethnicGroups.toArray()).pipe(map((response: EthnicGroupDBModel[]) => response.map((s) => s)));
  }

  public getAllVendorApptDetails(): Observable<VendorApptDetailsDBModel[]> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.vendorApptDetails.toArray()).pipe(map((response: VendorApptDetailsDBModel[]) => response.map((s) => s)));
  }

  public getAllVendors(): Observable<VendorDBModel[]> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.vendors.toArray()).pipe(map((response: VendorDBModel[]) => response.map((s) => s)));
  }

  public getAllEmploymentIntensities(): Observable<EmploymentIntensityDBModel[]> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.employmentIntensities.toArray()).pipe(map((response: EmploymentIntensityDBModel[]) => response.map((s) => s)));
  }

  public getAllActiveTeams(): Observable<TeamDBModel[]> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.activeTeams.toArray()).pipe(map((response: TeamDBModel[]) => response.map((s) => s)));
  }

  public getAllLocations(): Observable<LocationDBModel[]> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.locations.toArray()).pipe(map((response: LocationDBModel[]) => response.map((s) => s)));
  }

  public getAllSports(): Observable<SportDBModel[]> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.sports.toArray()).pipe(map((response: SportDBModel[]) => response.map((s) => s)));
  }

  public getAllScenes(): Observable<SceneDBModel[]> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.scenes.toArray()).pipe(map((response: SceneDBModel[]) => response.map((s) => s)));
  }

  public getAllDHBs(): Observable<DHBDBModel[]> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(this.db.dhbs.toArray()).pipe(map((response: DHBDBModel[]) => response.map((d) => d)));
  }

  public getAllReferrersByNameSearch(searchTerm: string): Observable<ReferrerDBModel[]> {
    // Update cache async if needed
    this.getCacheDelta().subscribe();

    return from(
      this.db.referrers.where("firstname").startsWithIgnoreCase(searchTerm).or("lastname").startsWithIgnoreCase(searchTerm).toArray()
    ).pipe(map((response: ReferrerDBModel[]) => response.map((s) => s)));
  }

  public addToAppState(label: string, url: string, state: any, isParent: boolean): Observable<any> {
    let id = Date.now();
    let appState: AppStateDBModel = {
      id: id,
      label: label,
      url: url,
      state: state,
      isParent: isParent,
    };

    return from(this.db.appState.add(appState));
  }

  public clearAppState(includeUrl: string = ""): Observable<any> {
    return this.getAppState().pipe(
      switchMap((response) => {
        if (response && response.length > 0) {
          let keys: number[] = response.filter((as) => as.isParent === false || as.url === includeUrl).map((as) => as.id);
          return from(this.db.appState.bulkDelete(keys));
        }

        return of(null);
      })
    );
  }

  public getAppState(): Observable<AppStateDBModel[]> {
    return from(this.db.appState.orderBy("id").toArray()).pipe(map((response: AppStateDBModel[]) => response.map((s) => s)));
  }

  public getAppStateByUrl(url: string): Observable<AppStateDBModel[]> {
    return from(this.db.appState.where("url").startsWithIgnoreCase(url).toArray()).pipe(
      map((response: any) => {
        if (response && response.length > 0) {
          return response.map((s) => s);
        }

        return null;
      })
    );
  }

  public getAppStateById(id: number): Observable<AppStateDBModel> {
    return from(this.db.appState.get(id)).pipe(map((response: AppStateDBModel) => response));
  }

  public deleteAppStateFrom(id: number): Observable<any> {
    return from(this.db.appState.where("id").above(id).delete());
  }

  public deleteAppStateById(id: number): Observable<any> {
    return from(this.db.appState.where("id").equals(id).delete());
  }

  public updateAppStateById(id: number, state: any): Observable<any> {
    return from(this.db.appState.update(id, { state: state }));
  }
}

function addMinutes(date: Date, minutes: number): Date {
  return new Date(date.getTime() + minutes * 60000);
}
