import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
  } from "@angular/core";
  import { FormControl } from "@angular/forms";
import { LoadingService } from "app/core/services/loading.service";
import { GPMProductPhysioCountry } from "app/core/enums/gpm-products.enum";
import { AddressSuggestItem } from "app/core/models/organisation/address-suggest.model";
import { AddressSearchService } from "app/core/services/address-search.service";
import { UtilService } from "app/core/services/util.service";
  import { Observable } from "rxjs";
  import { map } from "rxjs/operators";
  
  @Component({
    selector: "app-address-search",
    templateUrl: "./address-search.component.html",
    encapsulation: ViewEncapsulation.None
  })
  export class AddressSearchComponent implements OnInit {
    public filteredItems: Observable<AddressSuggestItem[]>;
    public loadingData = false;
    public myControl = new FormControl("");
    public physioCountry : string;
  
    @Input() public textLength = 3;
    @Input() initialValue = "";
    @Output() selectedChanged = new EventEmitter<AddressSuggestItem>(); // Fired when user selecteds an item from select.
    @Output() loadingDataChanged = new EventEmitter<boolean>(); // Fired when control starting or finishing loading data.
    destroySubscriptions: any;
  
    constructor(
      private loadingService: LoadingService,
      private _addressSearchService: AddressSearchService, 
      private _utilService: UtilService
    ) {}
  
    ngOnInit() {
      this.myControl.setValue(this.initialValue);
      this.physioCountry = this._utilService.getAppPhysioCountry();
     
    }
  
    private _filter(value: string): Observable<any> {
      return this._addressSearchService.suggestAddress(this.physioCountry, value).pipe(
        map((response: any) =>
          response.filter(option => {
            const filterValue = this._normalizeValue(value);
            return this._normalizeValue(option.DisplayAddress);
          })
        )
      );
    }
  
    public displayAddressDetails(item: AddressSuggestItem): string {
      return "";
    }
  
    private _normalizeValue(value: string): string {
      return value.toLowerCase().replace(/\s/g, "");
    }
  
    public getAddresses($event) {
      this.loadingService.show();
      if ($event.target.value.length > this.textLength) {
        const searchVal = $event.target.value;
        this.filteredItems = this._filter(searchVal);
      }
      this.loadingService.hide();
    }
  
    public optionSelected(item: AddressSuggestItem): void {
      this.myControl.setValue(item.DisplayAddress);
      // if it is AU, we have to call the api to get additional info of the address
      if(this.physioCountry === GPMProductPhysioCountry.AU){
        this._addressSearchService.getAddressDetails(this.physioCountry, item.AddressIdentifier).subscribe((updatedItem : AddressSuggestItem) => {
          item.Postcode = updatedItem.Postcode;
          item.State = updatedItem.State;
          item.Country = updatedItem.Country
          this.emitSelectedChanged(item);
        });
      }
      // just simply emit the selected address
      else{
        this.emitSelectedChanged(item as AddressSuggestItem);
      }
      
    }
  
    // Handles emitting to parent control that this control is either starting or finishing loading data.
    protected emitLoadingDataChanged(value: boolean): void {
      this.loadingData = value;
      if (this.loadingDataChanged) {
        this.loadingDataChanged.emit(value);
      }
    }
  
    // Handles emitting to parent control that selected item has changed.
    protected emitSelectedChanged(value: AddressSuggestItem): void {
      if (this.selectedChanged) {
        this.selectedChanged.emit(value);
      }
    }
  }
  