import { ProviderDBModel, SiteDBModel } from 'app/core/cache/cache.types';
import { ProviderModel } from 'app/core/models/appointment/provider.model';

export type Layout =
    | 'empty'
    // Vertical
    | 'thin';

export interface ClientHeaderModel {
  CLIENT_ID: number;
  FIRST_NAME: string;
  LAST_NAME: string;
  KNOWN_AS: string;
  HOME_SITE_ID: number;
  HIGHLIGHT_ID: number | null;
  HIGHLIGHT_CLIENT_IND: number;
  HOME_PHONE: string;
  HOME_MOBILE: string;
  WORK_PHONE: string;
  WORK_MOBILE: string;
  HOME_EMAIL: string;
  WORK_EMAIL: string;
  DOB: string | null;
}

export interface ClientConditionHeaderModel {
    ID: number;
    DATE_OF_INJURY: Date | null;
    PROVIDER_ID: number;
    INJURY_DESC: string;
}

export interface ClientAppointmentHeaderModel {
    ID: number;
    PROVIDER_ID: number;
    SITE_ID: number;
    START_DATETIME: Date;
    PROVIDER_DURATION: number | null;
    STATUS: number | null;
}

export enum ClientPhoneNumbers {
    HOME_PHONE = 'Home Phone',
    HOME_MOBILE = 'Home Mobile',
    WORK_PHONE = 'Work Phone',
    WORK_MOBILE = 'Work Mobile',
}

export enum ClientEmails {
    HOME_EMAIL = 'Home Email',
    WORK_EMAIL = 'Work Email',
}

export interface ClientContactData {
    value: string;
    name: ClientPhoneNumbers | ClientEmails;
}

export interface ConditionData {
    Provider: ProviderDBModel;
    LocalInjuryDateTime: Date;
    Condition: ClientConditionHeaderModel;
}

export interface AppointmentData {
    Provider: ProviderDBModel;
    Site: SiteDBModel;
    Appointment: ClientAppointmentHeaderModel;
    LocaleStartDateTime: Date;
}
