import { NgModule } from "@angular/core";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { SharedModule } from "@shared/shared.module";
import { AddressSearchComponent } from "./address-search/address-search.component";

import { AddressComponent } from "./address.component";

@NgModule({
  imports: [SharedModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatAutocompleteModule],
  exports: [AddressComponent, AddressSearchComponent],
  declarations: [AddressComponent, AddressSearchComponent],
  providers: [],
})
export class AddressModule {}
