export enum ClientEventRestricToEnum {
  DUE_AS_AT = 1,
  COMPLETED_FROM = 2,
  CREATED_FROM = 3,
  DUE_FROM = 4,
}

export interface ActionToItem {
  id: number;
  name: string;
  isTeam: boolean;
}
