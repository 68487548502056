<div class="mat-dialog-header">
    <h2 mat-dialog-title>{{ title }}</h2>
</div>
<mat-dialog-content class="mat-typography">
    <p>{{ content }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button
        mat-raised-button
        color="primary"
        (click)="firstButtonAction()"
    >
        {{ firstButtonText }}
    </button>
    <button
        mat-stroked-button
        mat-dialog-close
        color="primary"
        (click)="secondButtonAction()"
        *ngIf="showSecondButton"
    >
        {{ secondButtonText }}
    </button>
</mat-dialog-actions>
