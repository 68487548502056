<div class="mat-dialog-header">
    <h2 mat-dialog-title>Advanced Search</h2>
    <button mat-icon-button class="mat-dialog-close" (click)="close()">
        <span class="sr-only">Close dialog</span>
        <mat-icon svgIcon="gpm_close"></mat-icon>
    </button>
</div>
<mat-dialog-content>
    <form>
        <div class="grid sm:grid-cols-2 grid-cols-1 gap-4">
            <mat-form-field class="w-full" appearance="outline" floatLabel="auto">
                <mat-label>First Name</mat-label>
                <input matInput [formControl] = "firstNameFC"/>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline" floatLabel="auto">
                <mat-label>Surname</mat-label>
                <input matInput [formControl] = "lastNameFC"/>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline" floatLabel="auto">
                <mat-label>DOB</mat-label>
                <input matInput [matDatepicker]="picker" [formControl] = "dobFC">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline" floatLabel="auto">
                <mat-label>NHI Number</mat-label>
                <input matInput [formControl] = "nhiNumberFC"/>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline" floatLabel="auto">
                <mat-label>Phone No.</mat-label>
                <input matInput [formControl] = "phoneFC"/>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline" floatLabel="auto">
                <mat-label>Mobile No.</mat-label>
                <input matInput [formControl] = "mobileFC"/>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline" floatLabel="auto">
                <mat-label>Email</mat-label>
                <input matInput [formControl] = "emailFC"/>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline" floatLabel="auto">
                <mat-label>Internal Ref.</mat-label>
                <input matInput [formControl] = "internalRefFC"/>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" (click)="onSearch()" >Search</button>
</mat-dialog-actions>