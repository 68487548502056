import { LayoutComponent } from 'app/layout/layout.component';
import { EmptyLayoutModule } from 'app/layout/layouts/empty/empty.module';
import { ThinLayoutModule } from 'app/layout/layouts/vertical/thin/thin.module';
import { ClientModule } from 'app/modules/client/client.module';
import { SharedModule } from 'app/shared/shared.module';

import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoadingBarModule } from '@components/loading-bar/loading-bar.module';
import { EmailModule } from "@components/email-compose/email.module";

const layoutModules = [
    // Empty
    EmptyLayoutModule,

    // Vertical navigation
    ThinLayoutModule,
];

@NgModule({
    declarations: [
        LayoutComponent
    ],
    imports: [
        MatIconModule,
        MatTooltipModule,
        MatDividerModule,
        MatCardModule,
        MatSidenavModule,
        MatButtonModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatRippleModule,
        SharedModule,

        ClientModule,
        EmailModule,

        LoadingBarModule,
        ...layoutModules,
    ],
    providers: [MatDatepickerModule],
    exports: [LayoutComponent, ...layoutModules],
})
export class LayoutModule {}
