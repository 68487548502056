import { AfterViewInit, Component, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatOptionSelectionChange } from "@angular/material/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { LoadingService } from "app/core/services/loading.service";
import { AdminTypeDBModel } from "app/core/cache/cache.types";
import { AdminTypesEnum } from "app/core/enums/physio-enums";
import { DateTimeService } from "app/core/services/datetime.service";
import { Subject } from "rxjs";
import { ClientVaxStatusData, ClientVaxStatusInitialData } from "./client-vax-status-dialog.types";

@Component({
  selector: "app-client-vax-status",
  templateUrl: "client-vax-status-dialog.component.html",
})
export class ClientVaxStatusDialogComponent implements OnInit, AfterViewInit {
  isLoading: boolean;

  setByFC: FormControl;
  setDateFC: FormControl;
  statusFC: FormControl;
  statusDateFC: FormControl;
  notesFC: FormControl;

  vaxStatuses: AdminTypeDBModel[];
  dateDiff: number;
  today: Date = new Date();
  setDate: string;

  public destroySubscriptions: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ClientVaxStatusInitialData,
    private _dialogRef: MatDialogRef<ClientVaxStatusDialogComponent>,
    private _loadingService: LoadingService,
    private _dateTimeService: DateTimeService
  ) {
    this.setByFC = new FormControl("");
    this.setDateFC = new FormControl("");
    this.statusFC = new FormControl("");
    this.statusDateFC = new FormControl("");
    this.notesFC = new FormControl("");
  }

  ngOnInit() {
    this._loadingService.visible$.subscribe((result) => {
      this.isLoading = result;
    });

    if (!this.vaxStatuses) {
      let statuses = [...this.data.vaxStatuses];
      statuses.unshift({ id: null, deleted: false, active: true, name: "", description: "", adminTypeType: AdminTypesEnum.VaxStatus });
      this.vaxStatuses = statuses;
    }

    this.setByFC.setValue(this.data.setBy ? this.data.systemUser.displayName : null);

    this.setDate = this.data.setDate ?? null;
    this.setDateFC.setValue(this.setDate ? this._dateTimeService.FormatDate(this.setDate) : null);
    this.statusFC.setValue(this.data.status ? this.data.vaxStatuses.find((vs) => vs.id === this.data.status.id) : this.vaxStatuses[0]);
    this.statusDateFC.setValue(this.data.statusDate);
    this.notesFC.setValue(this.data.notes);
  }

  ngAfterViewInit(): void {
    this.statusDateFC.valueChanges.subscribe((result) => {
      if (result) {
        this.dateDiff = Math.abs(this._dateTimeService.getDateDiffInDays(new Date().toLocaleDateString(), result));
      }
    });
  }

  onStatusSelect(event: MatOptionSelectionChange): void {
    if (event && event.isUserInput && event.source.value.id) {
      this.setByFC.setValue(this.data.systemUser.displayName);
      this.setDateFC.setValue(this._dateTimeService.FormatDate(new Date().toLocaleDateString()));
      this.statusDateFC.setValue(new Date());

      this.setDate = new Date().toLocaleDateString();
    } else if (event && event.isUserInput && !event.source.value.id) {
      this.setByFC.setValue(null);
      this.setDateFC.setValue(null);
      this.statusDateFC.setValue(null);

      this.setDate = null;
    }
  }

  save(): void {
    const dialogData: ClientVaxStatusData = {
      setDate: this.setDate ?? null,
      status: this.statusFC.value ?? null,
      statusDate: this.statusDateFC.value ?? null,
      notes: this.notesFC.value?.trim() ?? null,
      setBy: this.statusFC.value?.id ? this.data.systemUser.id : null,
    };
    this._dialogRef.close(dialogData);
  }

  close(): void {
    this._dialogRef.close();
  }
}
