import * as rawFilterClientJsonData from './healthlink_filter_client_mock_data.json';
import * as rawFilterClinicJsonData from './healthlink_filter_clinic_mock_data.json';
import * as rawFilterProviderJsonData from './healthlink_filter_provider_mock_data.json';
import * as rawFilterReferrerJsonData from './healthlink_filter_referrer_mock_data.json';
import * as rawHealthlinkJsonData from './healthlink_mock_data.json';


const filterClientJsonData: any = rawFilterClientJsonData as any;
const filterClientData: Array<any> = filterClientJsonData.default;

const filterClinicJsonData: any = rawFilterClinicJsonData as any;
const filterClinicData: Array<any> = filterClinicJsonData.default;

const filterProviderJsonData: any = rawFilterProviderJsonData as any;
const filterProviderData: Array<any> = filterProviderJsonData.default;

const filterReferrerJsonData: any = rawFilterReferrerJsonData as any;
const filterReferrerData: Array<any> = filterReferrerJsonData.default;

const healthlinkJsonData: any = rawHealthlinkJsonData as any;
const healthlinkData: Array<any> = healthlinkJsonData.default;


export {
    filterClientData,
    filterClinicData,
    filterProviderData,
    filterReferrerData,
    healthlinkData,
};
