export enum HealthlinkMessageStatus {
    Inbox = 0,
    Confirmed = 1,
    Draft = 2,
    Outbox = 3,
    Failed = 4,
    Succeeded = 5,
    Deleted = 6
  }

  export enum HlSendToType {
    ACC32 = 1,
    Other = 2
  }
  
  export enum HlMessageSpecification {
    HL7v2point3 = 1,
    HealthDocs = 2,
    HL7v2point4 = 3,
    HL7v2point3point1 = 4
  }
  
  export enum AttachmentType {
    ACC32 = 1,
    OutcomeMeasures = 2,
    MedNotes = 3,
    Letter = 4,
    Uploads = 5,
    Discharge = 6,
    Message = 7,
    ConditionReview = 8
  }
  
  
  export enum MatchingEntity {
    Provider = 1,
    Referrer = 2,
    Client = 3
  }

  export enum HealthlinkReferrerType {
    ACC = "ACC Referrers",
    EDI = "EDI Referrers"
  }
  export enum HealthlinkAttachmentStatus {
    DELETED = "DELETED",
    EDIT = "EDIT",
    NEW = "NEW"
  }
  
  
  export class HealthlinkEnums {
    static HealthlinkMessageStatusTitleDescription = [
      { value: HealthlinkMessageStatus.Inbox, label: "Message Received" },
      { value: HealthlinkMessageStatus.Confirmed, label: "Message Confirmed" },
      { value: HealthlinkMessageStatus.Draft, label: "Draft Created" },
      { value: HealthlinkMessageStatus.Outbox, label: "Sent to Outbox" },
      { value: HealthlinkMessageStatus.Failed, label: "Failed" },
      { value: HealthlinkMessageStatus.Succeeded, label: "Succeeded" },
      { value: HealthlinkMessageStatus.Deleted, label: "Deleted" }
    ];
  
    static HlMessageSpecificationDescriptions = [
      { value: HlMessageSpecification.HL7v2point3, label: "HL7 v2.3" },
      { value: HlMessageSpecification.HealthDocs, label: "Health Docs" },
      { value: HlMessageSpecification.HL7v2point4, label: "HL7 v2.4" },
      { value: HlMessageSpecification.HL7v2point3point1, label: "HL7 v2.3.1" }
    ];
  
    static AttachmentTypeDescription = [
      {
        value: AttachmentType.ACC32,
        label: "ACC32 Request",
        sendingLabel: "ACC32"
      },
      {
        value: AttachmentType.OutcomeMeasures,
        label: "Outcome Measures",
        sendingLabel: "OUTCOM"
      },
      {
        value: AttachmentType.MedNotes,
        label: "Medical Notes",
        sendingLabel: "MEDNOT"
      },
      { value: AttachmentType.Letter, label: "Letter", sendingLabel: "LETTER" },
      { value: AttachmentType.Uploads, label: "Uploads", sendingLabel: "UPLOAD" },
      {
        value: AttachmentType.Discharge,
        label: "Discharge Report",
        sendingLabel: "DISCHARGE"
      },
      {
        value: AttachmentType.Message,
        label: "Previous HealthLink Message for Client",
        sendingLabel: "Previous HealthLink Message for Client"
      },
      {
        value: AttachmentType.ConditionReview,
        label: "Clain Reviews",
        sendingLabel: "Clain Reviews"
      }
    ];
  
    static getHealthlinkMessageStatusTitleDescription(
      type: HealthlinkMessageStatus
    ) {
      return this.HealthlinkMessageStatusTitleDescription.find(
        x => x.value === type
      ).label;
    }
  
    static getHlMessageSpecificationDescription(type: HlMessageSpecification) {
      return this.HlMessageSpecificationDescriptions.find(x => x.value === type)
        .label;
    }
  
    static getHlAttachmentTypeDescription(type: AttachmentType) {
      return this.AttachmentTypeDescription.find(x => x.value === type).label;
    }
    static getHlAttachmentTypeDescriptionForSending(type: AttachmentType) {
      return this.AttachmentTypeDescription.find(x => x.value === type)
        .sendingLabel;
    }
}