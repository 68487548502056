<ng-container>
    <div class="mat-dialog-header">
        <h2 mat-dialog-title>Set Filters</h2>
        <button mat-icon-button class="mat-dialog-close" (click)="cancel()">
            <span class="sr-only">Close dialog</span>
            <mat-icon svgIcon="gpm_close"></mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <div class="content-container grid sm:grid-cols-2 grid-cols-1 gap-4">
            <ng-container *ngTemplateOutlet="bodyContent; context:{
                formDataList: formDataList
            }"> </ng-container>

        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="p-3 flex flex-row justify-end">
        <button mat-raised-button color="primary" class="cursor-pointer" (click)="apply()">
            Apply
        </button>
    </mat-dialog-actions>
</ng-container>

<ng-template #bodyContent let-formDataList="formDataList">
    <ng-container *ngFor="let formDataItem of formDataList">
        <div class="element" *ngIf="formDataItem.type === filterType.SELECT_FILTER">
            <ng-container *ngTemplateOutlet="selectEle; context:{
                formDataItem: formDataItem
            }"></ng-container>
        </div>
        <div class="element" *ngIf="formDataItem.type === filterType.TEXT_FILTER">
            <ng-container *ngTemplateOutlet="textEle; context:{
                formDataItem: formDataItem
            }"> </ng-container>
        </div>
        <div class="element" *ngIf="formDataItem.type === filterType.DATE_FILTER">
            <ng-container *ngTemplateOutlet="dateEle; context:{
                formDataItem: formDataItem
            }"> </ng-container>
        </div>
        <div class="element" *ngIf="formDataItem.type === filterType.DATE_RANGE_FILTER">
            <ng-container *ngTemplateOutlet="dateRangeEle; context:{
                formDataItem: formDataItem
            }"> </ng-container>
        </div>
        <div class="element" *ngIf="formDataItem.type === filterType.AUTOCOMPLETE_FILTER">
            <ng-container *ngTemplateOutlet="autoCompleteEle; context:{
                formDataItem: formDataItem
            }"> </ng-container>
        </div>
        <div class="element" *ngIf="formDataItem.type === filterType.CHECKBOX_GROUP_FILTER">
          <ng-container *ngTemplateOutlet="checkboxGroupEle; context:{
              formDataItem: formDataItem
          }"> </ng-container>
        </div>
        <div class="element" *ngIf="formDataItem.type === filterType.SINGLE_CHECK_FILTER">
            <ng-container *ngTemplateOutlet="singleCheckEle; context:{
                formDataItem: formDataItem
            }"> </ng-container>
          </div>
        <div class="element" *ngIf="formDataItem.type === filterType.SELECT_DATE_FILTER">
            <ng-container *ngTemplateOutlet="selectDateEle; context:{
                formDataItem: formDataItem
            }"> </ng-container>
        </div>
        <div class="element" *ngIf="formDataItem.type === filterType.CUSTOM_FILTER">
            <ng-container [ngTemplateOutlet]="formDataItem.config.config.templateRef"
                [ngTemplateOutletContext]="{formDataItem: formDataItem}">
            </ng-container>
        </div>
    </ng-container>
</ng-template>

<!-- Text element template -->
<ng-template #textEle let-formDataItem="formDataItem">
    <mat-form-field appearance="outline" floatLabel="auto">
        <mat-label>{{ formDataItem.config?.displayName }}</mat-label>
        <input matInput [formControl]="formDataItem.control" placeholder="{{ formDataItem.config?.config?.placeholder }}">
    </mat-form-field>
</ng-template>

<!-- Select element template -->
<ng-template #selectEle let-formDataItem="formDataItem">
    <mat-form-field appearance="outline" floatLabel="auto">
        <mat-label>{{ formDataItem.config.config.label }}</mat-label>
        <mat-select placeholder="{{ formDataItem.config.config.placeholder }}"
            [formControl]="formDataItem.control">
            <mat-option *ngFor="let item of formDataItem.config.config.selectData" [value]="item[formDataItem.config.config.valueField]">{{ item[formDataItem.config.config.textField] }}</mat-option>
        </mat-select>
    </mat-form-field>
</ng-template>
<!-- Date element template -->
<ng-template #dateEle let-formDataItem="formDataItem">
    <mat-form-field appearance="outline">
        <mat-label>Choose a date</mat-label>
        <input matInput [matDatepicker]="datepicker" [formControl]="formDataItem.control">
        <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker></mat-datepicker>
    </mat-form-field>
</ng-template>
<!-- Date element template -->
<ng-template #dateRangeEle let-formDataItem="formDataItem">
    <mat-form-field appearance="outline">
        <mat-label>{{ formDataItem.config.config.label }}</mat-label>
        <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="Start date" [formControl]="formDataItem.control">
            <input matEndDate placeholder="End date" [formControl]="formDataItem.additionalControl">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
</ng-template>
<!-- AutoComplete element template -->
<ng-template #autoCompleteEle let-formDataItem="formDataItem">
    <mat-form-field appearance="outline" floatLabel="auto" class="w-full">
        <mat-label>{{ formDataItem.config.config.placeholder }}</mat-label>
        <input matInput placeholder="{{ formDataItem.config.config.placeholder }}"
            [matAutocomplete]="auto"
            [formControl]="formDataItem.control">
        <mat-autocomplete #auto="matAutocomplete"
            [displayWith]="displayAutocompleteItem" >
            <mat-option *ngFor="let item of formDataItem.config.config.selectData" [value]="item">
                <span>{{ item.displayText }}</span>
            </mat-option>
            <mat-option disabled *ngIf="formDataItem.config.config.selectData?.length === 0">
                <span>{{ formDataItem.config.config.noDataText}}</span>
            </mat-option>
            <mat-option disabled *ngIf="false">
                <mat-spinner diameter="35"></mat-spinner>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</ng-template>
<!-- Check box group element template -->
<ng-template #checkboxGroupEle let-formDataItem="formDataItem">
  <mat-form-field appearance="outline" floatLabel="auto" class="w-full">
    <mat-label>{{formDataItem.config.config.label}}</mat-label>
        <mat-select placeholder="{{formDataItem.selectedCaption}}" [formControl]="formDataItem.control " multiple>
            <mat-select-trigger>{{formDataItem.selectedCaption}}</mat-select-trigger>
            <mat-optgroup label="{{formDataItem.selectedCaption}}">            
                <mat-option *ngIf="formDataItem.config.config.selectData.length > 1" [value]="selectAllValue" (click)="toggleAll(formDataItem)">
                    {{ !formDataItem.isSelectAll ? 'Select All' : 'Select None' }}
                </mat-option>
                <mat-option *ngFor="let item of formDataItem.config.config.selectData" [value]="item" (click)="toggleItem(formDataItem)">
                {{ item[formDataItem.config.config.textField] }}
                </mat-option>
            </mat-optgroup>
        </mat-select>
  </mat-form-field>
</ng-template>
<!-- Single Check box element template -->
<ng-template #singleCheckEle let-formDataItem="formDataItem">
      <mat-checkbox  class="mt-2.5" [formControl]="formDataItem.control" (change)="toggleSingleCheckbox($event, formDataItem)">{{ formDataItem.config.config.label }}</mat-checkbox>
  </ng-template>
<!-- Select Date element template -->
<ng-template #selectDateEle let-formDataItem="formDataItem">
    <!-- Select -->
    <mat-form-field appearance="outline" floatLabel="auto">
        <mat-label>{{ formDataItem.config.config.label }}</mat-label>
        <mat-select placeholder="{{ formDataItem.config.config.placeholder }}" [formControl]="formDataItem.control"
            (selectionChange)="onSelectDateRestrictionChange($event)">
            <mat-option *ngFor="let item of formDataItem.config.config.selectData" [value]="item">{{
                item[formDataItem.config.config.textField] }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <!-- Date Range -->
    <ng-container *ngIf="showSelectDateFilterDateRange;else singleDate">
        <mat-form-field class="mt-2" appearance="outline">
            <mat-label>Choose a date range</mat-label>
            <mat-date-range-input [rangePicker]="dateRangePicker">
                <input matStartDate placeholder="Start date" [formControl]="formDataItem.additionalControl">
                <input matEndDate placeholder="End date" [formControl]="formDataItem.secondAdditionalControl">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
            <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
        </mat-form-field>
    </ng-container>

    <!-- Single Date -->
    <ng-template #singleDate>
        <mat-form-field class="mt-2" appearance="outline">
            <mat-label>Choose a date</mat-label>
            <input matInput [matDatepicker]="datePicker" [formControl]="formDataItem.additionalControl">
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>
    </ng-template>
</ng-template>