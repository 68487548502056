import { Injectable, TemplateRef } from "@angular/core";
import { FormControl } from "@angular/forms";
import { BrowserStorageCache } from "@aws-amplify/cache";
import { CellAlignment, TextTheme } from "@components/table/mat/mat-table-cell-content.service";
import { ColumnItem, RowItemConfig, TableActionType, TableItemAction } from "@components/table/mat/mat-table-wrapper.service";
import { FilterOutput, GeneralFilterDialogOutput } from "@components/table/table-column-filter/dialogs/general-filter-dialog.service";
import { CustomFilterConfig, FilterColumnService, FilterItem } from "@components/table/table-column-filter/table-serverside-column-filter.service";
import { ServerSideTableService } from "@components/table/table-serverside.service";
import { SiteDBModel, SystemUserSiteDBModel } from "app/core/cache/cache.types";
import { IndexedDBService } from "app/core/cache/indexed-db.service";
import { ClientService } from "app/core/client/client.service";
import { ExistingInvoiceModel } from "app/core/models/appointment/existing-invoice.model";
import { DateTimeService } from "app/core/services/datetime.service";
import { forkJoin, Observable, of } from "rxjs";
import { delay, map, tap } from "rxjs/operators";
import { ClientInvoiceType } from "./client-invoice.types";
import { AmountRangeData, AmountRangeFilterConfig } from "./invoice-amount-range.service";

const CLIENT_INVOICES_FIELDS = {
  DATE: "invoice_date",
  SITE: "SITE_ID",
  BILLING_REFERENCE: "BILLING_REFERENCE",
  INVOICE_NO: "INVOICE_NO",
  PAYMENT_REFERENCE: "PAYMENT_REFERENCE",
  UNPAID_INVOICE : "UNPAID_INVOICE",
  ITEM_DETAILS: "ITEM_DETAILS",
  INVOICE_AMOUNT_RANGE: "INVOICE_AMOUNT_RANGE",
};

const FILTER_CHECK_UNPAID_INVOICE_ID_VALUE = "FILTER_CHECK_UNPAID_INVOICE";
const FILTER_PAYMENT_OPTION_KEY = "id";

const ALL_SITE_ID = "ALL_SITE_ID";

const retrieveFilterValueForNameItem = (name: string, filterItemList: Array<FilterItem>): any | null => {
  const itemFilter: FilterItem = filterItemList.find((item) => item.fieldName === name);
  return itemFilter ? itemFilter.filterValue : null;
};

@Injectable()
export class ClientInvoicesServersideTableService implements ServerSideTableService {
  public clientId: string;
  protected detailInfoTpl: TemplateRef<any>;
  constructor(
    private _clientService: ClientService, private _dateTimeService: DateTimeService,
  ) {}

  setDetailInfoTpl(tpl: TemplateRef<any>): void {
    this.detailInfoTpl = tpl;
  }

  getActionList(): TableItemAction[] {
    return [];
  }
  getColumnDefinitions(): ColumnItem[] {
    return [
      {
        fieldName: "INVOICE_DATE",
        displayName: "Invoice Date",
        config: {
          isDate: true,
        },
      },
      {
        fieldName: "DUE_DATE",
        displayName: "Due Date",
        config: {
          isDate: true,
        },
      },
      { fieldName: "SITE_NAME", displayName: "Site" },
      { fieldName: "DEBTOR_NAME", displayName: "Bill To Debtor" },
      {
        fieldName: "INVOICE_NO",
        displayName: "Invoice No",
        width: 10,
      },
      {
        fieldName: "TOTAL",
        displayName: "Invoice Total",
        config: {
          isCurrency: true,
        },
      },
      {
        fieldName: "AMOUNT_OWING",
        displayName: "Outstanding",
        config: {
          isCurrency: true,
        },
      },
    ];
  }
  getData(selectedFilterItem: FilterItem[]): Observable<ExistingInvoiceModel[]> {
    const dateRangeFilterValue: any = retrieveFilterValueForNameItem(CLIENT_INVOICES_FIELDS.DATE, selectedFilterItem);

    const dateFrom: string = dateRangeFilterValue && dateRangeFilterValue.filterFrom ? this._dateTimeService.FormatDate(dateRangeFilterValue.filterFrom, 'MM/DD/yyyy') : "";

    const dateTo: Date | string = dateRangeFilterValue && dateRangeFilterValue.filterTo ? this._dateTimeService.FormatDate(dateRangeFilterValue.filterTo, 'MM/DD/yyyy') : "";

    const site: any = retrieveFilterValueForNameItem(CLIENT_INVOICES_FIELDS.SITE, selectedFilterItem);
    const isAllSites: boolean = site && site.filterValue === ALL_SITE_ID;
    const siteId = (!isAllSites && site) ? site.filterValue : "";

    const invoice: any = retrieveFilterValueForNameItem(CLIENT_INVOICES_FIELDS.INVOICE_NO, selectedFilterItem);
    const invoiceNo = invoice ? invoice.filterValue : "";

    const billing: any = retrieveFilterValueForNameItem(CLIENT_INVOICES_FIELDS.BILLING_REFERENCE, selectedFilterItem);
    const billingRef = billing ? billing.filterValue : "";

    const payment: any = retrieveFilterValueForNameItem(CLIENT_INVOICES_FIELDS.PAYMENT_REFERENCE, selectedFilterItem);
    const paymentRef = payment ? payment.filterValue : "";

    const showUnpaidInvoicesFilter: any = retrieveFilterValueForNameItem(CLIENT_INVOICES_FIELDS.UNPAID_INVOICE, selectedFilterItem);
    const showUnpaidInvoices = showUnpaidInvoicesFilter ? showUnpaidInvoicesFilter.filterValue : false;

    const itemDetails: any = retrieveFilterValueForNameItem(CLIENT_INVOICES_FIELDS.ITEM_DETAILS, selectedFilterItem);
    const itemDetailsValue = itemDetails ? itemDetails.filterValue : "";

    const amountRange: any = retrieveFilterValueForNameItem(CLIENT_INVOICES_FIELDS.INVOICE_AMOUNT_RANGE, selectedFilterItem);
    const amountRangeData: AmountRangeData = amountRange ? amountRange.filterValue as AmountRangeData : null; 
    const amountType: number = amountRangeData && amountRangeData.invoiceType ? amountRangeData.invoiceType.id : 0;
    const middleAmount = amountRangeData && amountRangeData.lowerNo ? amountRangeData.lowerNo : "";
    const percentage =  amountRangeData && amountRangeData.withinPercentage ? amountRangeData.withinPercentage : "";

    return this._clientService.getClientInvoices(this.clientId, 0, dateFrom, dateTo, siteId, invoiceNo, billingRef, paymentRef, showUnpaidInvoices, itemDetailsValue, amountType, middleAmount, percentage);
  }

  getRowConfig?(): RowItemConfig {
    return {
      subRowElemengConfig: {
        needSubElement: false,
        retrieveDataForSubElement: this.getDataForSubElement.bind(this),
        subElementTemp: this.detailInfoTpl,
      }
    };
  }

  getDataForSubElement(rowData: any): Observable<any> {
    return of({
      data: "test"
    }).pipe(
      delay(2000),
    );
  }
}


// FilterColumnService should know how to retrieve data for AmountRange Filter
const retrieveOutputDataForAmount = (formControl: FormControl, config: FilterItem): FilterOutput => {
  const data = formControl.value as AmountRangeData;
  let filterValue = null;

  if (data && data.invoiceType && data.lowerNo && data.withinPercentage) {
    const filterText = data.invoiceType.name + "=\"$" + data.lowerNo + " +/- " + data.withinPercentage + "%\"";

    filterValue = {
      filterText,
      filterValue: data,
    };
  }

  return {
    config,
    filterValue,
  };
};

@Injectable()
export class ClientInvoicesFilterColumnService implements FilterColumnService {
  private invoiceAmtRangeTpl: TemplateRef<any>;

  constructor(private _indexedDbService: IndexedDBService) {

  }

  public setInvoiceAmtTemplate(tmp: TemplateRef<any>): void {
    this.invoiceAmtRangeTpl = tmp;
  }

  getAllowedFilterFields(): Array<FilterItem> {
    const configForAmoutRange: AmountRangeFilterConfig = {
      isCustom: true,
      templateRef: this.invoiceAmtRangeTpl,
      retrieveDataFun: retrieveOutputDataForAmount,
      invoiceTypes: [{
        name: "Invoice Total",
        id: ClientInvoiceType.INVOICE_TOTAL,
      }, {
        name: "Outstanding",
        id: ClientInvoiceType.OUTSTANDING,
      }]
    };

    const configFilters: Array<FilterItem> =  [
      {
        fieldName: CLIENT_INVOICES_FIELDS.DATE,
        displayName: "Invoice Date",
        config: {
          isDateRange: true,
          label: "Restrict Invoice Date",
        },
      },
      {
        fieldName: CLIENT_INVOICES_FIELDS.UNPAID_INVOICE,
        displayName: 'Show Unpaid Invoices',
        config: {
            label: "Show Unpaid Invoices",
            isSingleCheck: true,
            value : false
        },
      },        
      {
        fieldName: CLIENT_INVOICES_FIELDS.SITE,
        displayName: "Sites",
        config: {
          isSelect: true,
          selectData: "",
          placeholder: "All Sites",
          label: "Restrict To Sites",
          textField: "name",
          valueField: "id",
        },
      },
      {
        fieldName: CLIENT_INVOICES_FIELDS.BILLING_REFERENCE,
        displayName: "Billing Ref",
        config: {
          label: "Contains Billing Ref",
          placeholder: "Billing Ref",
        }
      },
      {
        fieldName: CLIENT_INVOICES_FIELDS.INVOICE_NO,
        displayName: "Invoice No",
        config: {
          label: "Contains Invoice No",
          placeholder: "Invoice No",
        }
      },
      {
        fieldName: CLIENT_INVOICES_FIELDS.PAYMENT_REFERENCE,
        displayName: "Payment Ref",
        config: {
          label: "Contains Payment Ref",
          placeholder: "Payment Ref",
        }
      },
      {
        fieldName: CLIENT_INVOICES_FIELDS.ITEM_DETAILS,
        displayName: "Item Details",
        config: {
          label: "Contains Item Details",
          placeholder: "Item Details",
        }
      },
      {
        fieldName: CLIENT_INVOICES_FIELDS.INVOICE_AMOUNT_RANGE,
        displayName: "",
        config: configForAmoutRange,
      },
    ];

    // get stored custom filters from cache if any
    const storedCustomFilters = BrowserStorageCache.getItem("Custom-" + this.getFilterServiceUniqueName()) as FilterItem[];
    if (storedCustomFilters) {
      configFilters.filter(f => f.config?.isCustom).forEach((item) => {
        const storedFilter = storedCustomFilters.find(f => f.fieldName === item.fieldName);
        if(storedFilter){
          const filterText = storedFilter.filterValue.filterText;
          const data = storedFilter.filterValue.filterValue;
          item.filterValue = {filterText : filterText, filterValue : data};
        }                    
      });
    }
    return configFilters;
  
  }

  getSelectDataForFilterItem(item: FilterItem, params: any): Observable<Array<any>> {
    let results: Observable<any> = of([]);
    const { selectedFilters } = params;
    switch (item.fieldName) {
      case CLIENT_INVOICES_FIELDS.SITE:
        results = this.getObservableDataForSites();
        break;
      case CLIENT_INVOICES_FIELDS.UNPAID_INVOICE:
        results = this.getObservableDataForUnpaid();
        break;
    }

    return results;
  }

  getObservableDataForUnpaid(): Observable<Array<any>> {
    return of([{
      name: "Show Unpaid Invoices",
      id: FILTER_CHECK_UNPAID_INVOICE_ID_VALUE
    }]);
  }

  getObservableDataForSites(): Observable<Array<any>> {
    return forkJoin({
      sites: this._indexedDbService.getAllSites(),
      systemUserSites: this._indexedDbService.getAllSystemUserSites(),
    }).pipe(
      map((response) => {
        const sites = response.sites as SiteDBModel[];
        const systemUserSites = response.systemUserSites as SystemUserSiteDBModel[];

        let results: any[] = [];

        systemUserSites.forEach((systemUserSite) => {
          const site = sites.find((s) => s.id === systemUserSite.siteId);
          if (site) {
            results.push(site);
          }
        });

        const selectAllOption = {
          name: "All Sites",
          id: ALL_SITE_ID
        };

        results = [selectAllOption].concat(results);

        return results;
      })
    );
  }

  getDataButtonText(): string {
    return "Get Client Invoices";
  }

  getPageTitle(): string {
    return "Invoices";
  };

  getFilterServiceUniqueName(): string {
    return "Client_Invoice_Filter";
} 

  customOutputForDialog(result: GeneralFilterDialogOutput): GeneralFilterDialogOutput {
    if (result.filterDataList) {
      const showUnPaidItemFilter = result.filterDataList.find(e => e.config && e.config.fieldName === CLIENT_INVOICES_FIELDS.UNPAID_INVOICE);

      if (showUnPaidItemFilter) {
        const includeUnpaidItem: Array<any> = showUnPaidItemFilter.filterValue?.filterValue || false;

        if (includeUnpaidItem) {
          // remove the date range
          const invoiceDateItem = result.filterDataList.find(e => e.config && e.config.fieldName === CLIENT_INVOICES_FIELDS.DATE);
          if (invoiceDateItem) {
            // reset filter value for invoice date
            invoiceDateItem.filterValue = null;
          }
        }
      }
    }

    return result;
  }
}
