import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CellAlignment, TextTheme } from "@components/table/mat/mat-table-cell-content.service";
import { ColumnGroupConfig, ColumnItem, MASTER_GROUP_ID, TableActionType, TableItemAction } from "@components/table/mat/mat-table-wrapper.service";
import { FilterColumnService, FilterItem, FilterItemHelper } from "@components/table/table-column-filter/table-serverside-column-filter.service";
import { ServerSideTableService } from "@components/table/table-serverside.service";
import { SystemUserDBModel } from "app/core/cache/cache.types";
import { IndexedDBService } from "app/core/cache/indexed-db.service";
import { ClientService } from "app/core/client/client.service";
import { LetterTemplateModel } from "app/core/models/organisation/letter-template.model";
import { VfDocumentsModel } from "app/core/models/organisation/vf-documents.model";
import { DateTimeService } from "app/core/services/datetime.service";
import { LetterService } from "app/core/services/letter.service";
import { Observable, of, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { LettersRequestModel } from "../letters-uploads.types";

const CLIENT_LETTERS_FIELDS = {   
  DATE: "date_created",
  ACTIONED_TO : "actioned_to",
  LETTER_TEMPLATE: "letter_template"
};


@Injectable()
export class LettersTableService implements ServerSideTableService {
  protected ownerId: number;      

  constructor(protected clientsService: ClientService, 
    private _datetimeService: DateTimeService) {                            
  }    

  set OwnerId(id: number) {
    this.ownerId = id;
  }
  
  getActionList(): TableItemAction[] {
    return [];
  }
  getColumnDefinitions(): ColumnItem[] {
    return [
      {
        fieldName: "DATE_CREATED",
        displayName: "Date",
        config: {
          isDate: true
        }
      },
      { fieldName: "DISPLAY_NAME", displayName: "Created By", width:10 },
      { fieldName: "DETAILS", displayName: "Details" },
      { fieldName: "ACTIONED_TO", displayName: "Action To" },
      { fieldName: "ACTIONED_NOTES", displayName: "Notes",
        textStyle: {
          alignment: CellAlignment.LEFT,
          theme: TextTheme.RUBY,
          isUnderline: true,
          displayTooltip: true,
        } 
      },
      { fieldName: "LETTER_TEMPLATE_NAME", displayName: "Template" },
      { fieldName: "OWNER_NAME", displayName: "Client", width:10 },
    ];
  }

  getDisplayColumnConfig(): ColumnGroupConfig {
    return {
        defaultColumns: [
            "DATE_CREATED",
            "LETTER_TEMPLATE_NAME",           
            "DETAILS",
            "ACTIONED_TO",
            "ACTIONED_NOTES"
        ],
        groupColumnDefinition: [
            {
                groupID: MASTER_GROUP_ID,
                groupName: '',
                columnNames: [
                  "DISPLAY_NAME",
                  "OWNER_NAME",
                ],
            },
        ],
        displayGroup: [MASTER_GROUP_ID],
    };
}
   getData(selectedFilterItem: FilterItem[]): Observable<VfDocumentsModel[]> {                      
    const dateRangeFilterValue: any = FilterItemHelper.retrieveFilterValueForNameItem(CLIENT_LETTERS_FIELDS.DATE, selectedFilterItem);

    const dateFrom = dateRangeFilterValue && dateRangeFilterValue.filterFrom ? this._datetimeService.FormatDate(dateRangeFilterValue.filterFrom, 'MM/DD/yyyy') : '';
    const dateTo = dateRangeFilterValue && dateRangeFilterValue.filterTo ? this._datetimeService.FormatDate(dateRangeFilterValue.filterTo, 'MM/DD/yyyy') : '';

    const actionedToFilter: any = FilterItemHelper.retrieveFilterValueForNameItem( CLIENT_LETTERS_FIELDS.ACTIONED_TO, selectedFilterItem);
    const actionedToId = actionedToFilter ? actionedToFilter.filterValue : '';

    const templateFilter: any = FilterItemHelper.retrieveFilterValueForNameItem( CLIENT_LETTERS_FIELDS.LETTER_TEMPLATE, selectedFilterItem);
    const templateId = templateFilter ? templateFilter.filterValue : '';

    const requestModel : LettersRequestModel = { 
        ownerId : this.ownerId,
        dateFrom : dateFrom,
        dateTo : dateTo,
        actionedToId: actionedToId,
        templateId: templateId
    };
    
        
    return this.clientsService.getClientDocuments(requestModel)
    .pipe(
        map((response: any) => { 
          return response;
        })
    );
   }

}

@Injectable()
export class LettersFilterColumnService implements FilterColumnService {  
  
  constructor(
    private _indexedDbService: IndexedDBService,
    private _letterService: LetterService
){}
  getAllowedFilterFields(): Array<FilterItem> {
    return [
      {
        fieldName: CLIENT_LETTERS_FIELDS.DATE,
        displayName: "Date Created",
        config: {
          isDateRange: true,
          label: 'Date Created',
        }
      },
      {
        fieldName: CLIENT_LETTERS_FIELDS.ACTIONED_TO,
        displayName: "Actioned To",
        config: {
          isSelect: true,
          selectData: '',
          placeholder: 'Documents actioned to',
          label: 'Documents actioned to',
          textField: 'displayName',
          valueField: 'id',
        }
      },
      {
        fieldName: CLIENT_LETTERS_FIELDS.LETTER_TEMPLATE,
        displayName: "Letter Template",
        config: {
          isSelect: true,
          selectData: '',
          placeholder: 'Restrict to template',
          label: 'Restrict to template',
          textField: 'NAME',
          valueField: 'ID',
      },
    }
    ];
  }

  getSelectDataForFilterItem(
    item: FilterItem,
    params: any
): Observable<any[]> | null {
    let results: Observable<any> = of([]);
    const { selectedFilters } = params;
    switch (item.fieldName) {
        case CLIENT_LETTERS_FIELDS.ACTIONED_TO:
            results = this.getActiveSystemUsers();
            break;        
    }

    switch (item.fieldName) {
      case CLIENT_LETTERS_FIELDS.LETTER_TEMPLATE:
          results = this.getLetterTemplates();
          break;        
  }

    return results;
}


  getActiveSystemUsers() : Observable<SystemUserDBModel[]>{
    return this._indexedDbService.getAllActiveSystemUsers().pipe(
      map((activeUsers: any) => {
          const activeSystemUsers: Array<any> =
          activeUsers && activeUsers.length > 0
                  ? activeUsers
                  : [];
              return activeSystemUsers;
          }));
    
  }
  
  getLetterTemplates(): Observable<LetterTemplateModel[]>{
    return this._letterService.getLetterTemplates().pipe(
      map((templates: any) => {
          const letterTemplates: Array<any> = templates && templates.length > 0 
                  ? templates
                  : [];
              return letterTemplates;
          }));
    
  }

  getDataButtonText(): string {
    return "";
  };

  getPageTitle(): string {
    return "Letters";
  };

  getFilterServiceUniqueName(): string {
    return "Letter_Filter";
  }
}
