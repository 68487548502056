<ng-container *ngIf="previousStates">
    <div class="flex items-center breadcrumbs-container py-4 pl-5">
        <div class="flex items-center ml-2" *ngFor="let state of previousStates">
            <mat-icon class="h-4" svgIcon="gpm_red_flag" *ngIf="state.isParent"></mat-icon>
            <mat-divider [vertical]="true" class="self-stretch" *ngIf="!state.isParent"></mat-divider>
            <a class="cursor-pointer hover:underline title ml-2"
                [ngClass]="{'cursor-default pointer-events-none last': checkIfLast(state)}"
                (click)="navigateToBreadcrumb(state.url)" *ngIf="!isLoading">{{state.label}}</a>
            <span class="title ml-2" *ngIf="isLoading">{{state.label}}</span>
        </div>
    </div>
</ng-container>