import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UtilService } from "app/core/services/util.service";
import { DateDayTimeFormatterPipe } from "app/core/pipes/daydatetime-formatter.pipe";
import { DateFormatterPipe } from "app/core/pipes/date-formatter.pipe";
import { DateTimeFormatterPipe } from "app/core/pipes/datetime-formatter.pipe";
import { DayDateFormatterPipe } from "app/core/pipes/daydate-formatter.pipe";
import { YesNoFormatterPipe } from "app/core/pipes/yesno-formatter.pipe";
import { ClientInfoHeaderComponent } from "app/modules/client/client-details/client-info-header/client-info-header.component";
import { MapDictionaryToArrayPipe } from "app/core/pipes/map-dictionary-to-array.pipe";
import { CurrencyFormatterPipe } from "app/core/pipes/currency-formatter.pipe";
import { EnumToArrayPipe } from "app/core/pipes/enum-to-array.pipe";
import { TitleHeaderComponent } from "@components/title-header/title-header.component";

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DateFormatterPipe,
    DayDateFormatterPipe,
    EnumToArrayPipe,
    DateTimeFormatterPipe,
    DateDayTimeFormatterPipe,
    YesNoFormatterPipe,
    MapDictionaryToArrayPipe,
    CurrencyFormatterPipe,
    ClientInfoHeaderComponent,
    TitleHeaderComponent
  ],
  providers: [
    UtilService,
    DateFormatterPipe,
    DayDateFormatterPipe,
    EnumToArrayPipe,
    DateTimeFormatterPipe,
    DateDayTimeFormatterPipe,
    CurrencyFormatterPipe,
    MapDictionaryToArrayPipe,
  ],
  declarations: [
    DateFormatterPipe,
    DayDateFormatterPipe,
    EnumToArrayPipe,
    DateTimeFormatterPipe,
    DateDayTimeFormatterPipe,
    YesNoFormatterPipe,
    MapDictionaryToArrayPipe,
    CurrencyFormatterPipe,
    ClientInfoHeaderComponent,
    TitleHeaderComponent
  ],
})
export class SharedModule {}
