import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { ComponentRef, Injectable, Injector, StaticProvider } from "@angular/core";
import { EmailComposerContainerComponent, EMAIL_COMPOSE_DATA } from "./email-compose-container/email-compose-container.component";


/**
 * - This service is used to open Email Composer. Call openEmailComposer top open new email composer.
 * - Each time openEmailComposer is executed, it will call addNewEmailData method of MailComposerContainerComponent.
 * - MailComposerContainerComponent handle the layout of multiple email composer(minimized, maximized status of each mail composer)
 * - In mobile, MailComposerContainerComponent only allow open one mail composer.
 * - In case of wanting to close all the email composers, call closeOverlay method of this class.
 */
@Injectable()
export class EmailComposeService {
  private mailOverlayRef: OverlayRef;
  private containerRef: EmailComposerContainerComponent;

  constructor(private overlay: Overlay, private _injector: Injector) {

  }

  _createOverlay(): OverlayRef {
    const emailOverlayRef = this.overlay.create({
      panelClass: "mail-overlay"
    });

    return emailOverlayRef;
  }

  openEmailComposer(config: any) {
    if (!this.mailOverlayRef) {
      this.mailOverlayRef = this._createOverlay();

      const componentPortal = new ComponentPortal(EmailComposerContainerComponent, undefined, this._createInjector(config));
      const container: ComponentRef<EmailComposerContainerComponent> = this.mailOverlayRef.attach(componentPortal);
      this.containerRef = container.instance;
    }

    this.containerRef.addNewEmailData();
  }

  _createInjector(data: any): Injector {
    const providers: StaticProvider[] = [
      { provide: EMAIL_COMPOSE_DATA, useValue: data }
    ];
    return Injector.create({
      parent: this._injector,
      providers
    });
  }

  onCloseEmailComposer() {

  }

  closeOverlay() {
    this.mailOverlayRef.dispose();
    this.mailOverlayRef = null;
  }
}
