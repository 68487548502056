import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { AppointmentStatus, GetAppointmentStatusDescription } from "app/core/enums/appointment-enums";
import { ClientConditionModel } from "app/core/models/appointment/client-condition.model";
import { DocumentModel } from "app/core/models/appointment/document.model";
import { EventModel } from "app/core/models/appointment/event.model";
import { UploadFileModel } from "app/core/models/appointment/upload-file.model";
import { ClientDetailsDataModel } from "app/core/client/models/client-details-data.model";
import { ClientDetailsDrawerDataModel } from "app/core/client/models/client-details-drawer-data.model";

import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { ClientRelationships, ClientSummaryViewStatus } from "../client.types";
import { Subscription } from "rxjs";

import { PhysioAppointmentModel } from "app/core/models/physio/physio-appointment.model";
import { UtilService } from "app/core/services/util.service";
import { ExistingInvoiceModel } from "app/core/models/appointment/existing-invoice.model";
import { BusinessPaymentModel } from "app/core/models/appointment/business-payment.model";
import { AppStateDBModel, SiteDBModel } from "app/core/cache/cache.types";
import { ActivatedRoute, Router } from "@angular/router";
import { BreadcrumbsService } from "@components/breadcrumbs/breadcrumbs.service";
import { Location } from "@angular/common";
import { PhysioClientFullHistoryModel } from "app/core/models/physio/physio-client-full-history.model";
import { VfClientMembershipsModel } from "app/core/models/appointment/vf-client-memberships.model";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { DialogType } from "@components/dialog/dialog.types";
import { DialogComponent } from "@components/dialog/dialog.component";
import { ClientService } from "app/core/client/client.service";
import { ResultType } from "app/core/models/results.model";
import { Hub } from "@aws-amplify/core";
import { HubChannels, HubEvents } from "@shared/common/hub-channels";
import { HealthLinkMessageModel } from "app/core/models/organisation/healthlink/healthlink-message.model";

@Component({
  selector: "app-client-summary",
  templateUrl: "./client-summary.component.html",
  styleUrls: ["./client-summary.component.scss"],
})
export class ClientSummaryComponent implements OnInit, OnChanges {
  @HostBinding("class") classes = "w-full h-full";

  @Input() clientDetailsDrawerData: ClientDetailsDrawerDataModel;
  @Output() onClose = new EventEmitter<boolean>();

  previousState: AppStateDBModel[] = [];

  today: Date = new Date();

  isSmallScreen: boolean;
  sizeSubscription: Subscription;
  clientSummaryStatus: ClientSummaryViewStatus;

  clientDetailsData: ClientDetailsDataModel;
  clientHomeSite: SiteDBModel;
  clientTitle: any;
  clientHomeAddress: string;
  lastAppointment: PhysioAppointmentModel;
  lastCondition: ClientConditionModel;
  lastEvent: EventModel;
  lastLetter: DocumentModel;
  lastUpload: UploadFileModel;
  lastMembership: VfClientMembershipsModel;
  lastAppointmentStatus: string;
  latestAppointmentDate: Date;
  lastInvoice: ExistingInvoiceModel;
  lastPayment: BusinessPaymentModel;
  lastInvoiceDueDate: Date;
  clientHistories: PhysioClientFullHistoryModel[];
  lastHlMessage: HealthLinkMessageModel;
  membershipStartDate: Date;
  membershipEndDate: Date;
  relationships: ClientRelationships;
  pageTitle:string;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _breakpointObserver: BreakpointObserver,
    private _utilService: UtilService,
    private _breadCrumbService: BreadcrumbsService,
    private _router: Router,
    private _location: Location,
    private _dialog: MatDialog,
    private _clientService: ClientService
  ) {
    this.isSmallScreen = this._breakpointObserver.isMatched(Breakpoints.XSmall);
  }

  ngOnInit(): void {
    this.sizeSubscription = this._breakpointObserver.observe([Breakpoints.XSmall]).subscribe(() => {
      this.isSmallScreen = this._breakpointObserver.isMatched(Breakpoints.XSmall);
    });

    this.clientSummaryStatus = ClientSummaryViewStatus.CLIENT_SUMMARY;
    this._activatedRoute.data.subscribe((data: any) => {
      this.clientDetailsDrawerData = data.initialData;
      const clientPersonalDetails = this.clientDetailsDrawerData.ClientDetails.PersonalDetails;
      this.pageTitle = this._utilService.getFullNameDob(clientPersonalDetails.FIRST_NAME, clientPersonalDetails.LAST_NAME, clientPersonalDetails.DOB); 
    });

    this.setClientData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.clientDetailsDrawerData.previousValue &&
      changes.clientDetailsDrawerData.previousValue !== changes.clientDetailsDrawerData.currentValue
    ) {
      this.clientDetailsDrawerData = changes.clientDetailsDrawerData.currentValue;
      this.setClientData();
    }
  }

  navigateToPage(url: string): void {
    this._router.navigate([url]);
  }

  private setClientData(): void {
    this.clientDetailsData = this.clientDetailsDrawerData.ClientDetails;
    this.clientHomeSite = this.clientDetailsDrawerData.HomeSite;
    this.clientTitle = this.clientDetailsDrawerData.Title;
    this.clientHomeAddress = this._utilService.getFullAddress(this.clientDetailsData.HomeAddress);
    this.lastAppointment = this.clientDetailsData.Appointments[0];
    this.lastCondition = this.clientDetailsData.Conditions[0];
    this.lastEvent = this.clientDetailsData.Events[0];
    this.lastLetter = this.clientDetailsData.Letters[0];
    this.lastUpload = this.clientDetailsData.Uploads[0];
    this.lastMembership = this.clientDetailsData.Memberships[0];
    this.lastInvoice = this.clientDetailsData.Invoices[0];
    this.lastPayment = this.clientDetailsData.Payments[0];
    this.clientHistories = this.clientDetailsData.Histories;
    this.lastHlMessage = this.clientDetailsData.HLMessages[0];
    this.relationships = this.clientDetailsData.Relationships[0];

    if (this.lastMembership) {
      this.membershipStartDate = new Date(this.lastMembership.START_DATE);
      this.membershipEndDate = new Date(this.lastMembership.EXPIRES_ON);
    }

    if (this.lastInvoice) {
      this.lastInvoiceDueDate = new Date(this.lastInvoice.DUE_DATE);
    }

    if (this.lastAppointment) {
      this.latestAppointmentDate = new Date(this.lastAppointment.START_DATETIME);
      if (this.lastAppointment.STATUS) {
        const apptStatuses = this._utilService.getEnumValues(AppointmentStatus);

        apptStatuses.forEach((apptStatus) => {
          if (this.lastAppointment.STATUS === apptStatus) {
            this.lastAppointmentStatus = GetAppointmentStatusDescription(apptStatus);
          }
        });
      }
    }
  }

  onNavigateToClientDetail(): void {
    const clientId = this.clientDetailsDrawerData.ClientDetails.Client.ID;
    const url = `/client-details/${clientId}/detail`;

    this._router.navigate([url]);
  }

  onNavigateToClientSummary(): void {
    this.clientSummaryStatus = ClientSummaryViewStatus.CLIENT_SUMMARY;
  }

  onNavigateToClientAccounts(): void {
    const clientId = this.clientDetailsDrawerData.ClientDetails.Client.ID;
    const url = `/client-details/${clientId}/accounts`;
    this._router.navigate([url]);
  }

  onNavigateToClientAppointments(): void {
    const clientId = this.clientDetailsDrawerData.ClientDetails.Client.ID;
    const url = `/client-details/${clientId}/appointments`;
    this._router.navigate([url]);
  }

  onNavigateToClientDocuments(): void {
    const clientId = this.clientDetailsDrawerData.ClientDetails.Client.ID;
    const url = `/client-details/${clientId}/documents`;

    this._router.navigate([url]);
  }

  onNavigateToClientEvents(): void {
    const clientId = this.clientDetailsDrawerData.ClientDetails.Client.ID;
    const url = `/client-details/${clientId}/events`;
    this._router.navigate([url]);
  }

  onNavigateToClientConditions(): void {
    const clientId = this.clientDetailsDrawerData.ClientDetails.Client.ID;
    const url = `/client-details/${clientId}/conditions`;
    this._router.navigate([url]);
  }

  onNavigateToFullHistory(): void {
    const clientId = this.clientDetailsDrawerData.ClientDetails.Client.ID;
    const url = `/client-details/${clientId}/full-history`;

    this._router.navigate([url]);
  }

  onNavigateToMemberships(): void {
    const clientId = this.clientDetailsDrawerData.ClientDetails.Client.ID;
    const url = `/client-details/${clientId}/memberships`;
    this._router.navigate([url]);
  }
  onNavigateToHealthlink(): void {
    const clientId = this.clientDetailsDrawerData.ClientDetails.Client.ID;
    const url = `/client-details/${clientId}/healthlink`;
    this._router.navigate([url]);
  }

  onNavigateToRelationships(): void {
    const clientId = this.clientDetailsDrawerData.ClientDetails.Client.ID;
    const url = `/client-details/${clientId}/relationships`;
    this._router.navigate([url]);
  }

  onDeleteClient(): void {
    const config = new MatDialogConfig();
    config.data = {
      title: "Deleting the Client",
      content: "You are about to delete the client. Are you sure about this? This is a permanent action and cannot be undone",
      type: DialogType.YES_NO,
    };
    const dialogRef = this._dialog.open(DialogComponent, config);

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      this._clientService.deleteClient(this.clientDetailsData.Client.ID).subscribe((response) => {
        if (response.Result === ResultType.Ok) {
          this._location.back();
          Hub.dispatch(HubChannels.ClientUpdatesChannel, {
            event: HubEvents.ClientDeleted,
            data: null,
            message: "",
          });
        }

        if (response.Result === ResultType.Error) {
          const config = new MatDialogConfig();
          config.data = {
            title: "Error deleting the client",
            content: response.Reason,
            type: DialogType.OK,
          };
          this._dialog.open(DialogComponent, config);
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.sizeSubscription.unsubscribe();
  }
}
