import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LoadingBarComponent } from 'app/components/loading-bar/loading-bar.component';
import { LoadingInterceptor } from 'app/components/loading-bar/loading-bar.interceptor';

@NgModule({
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true
        }
    ],
    declarations: [
        LoadingBarComponent
    ],
    imports: [
        CommonModule,
        MatProgressBarModule
    ],
    exports: [
        LoadingBarComponent
    ]
})

export class LoadingBarModule {
}