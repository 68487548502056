import { Injectable } from "@angular/core";
import { ColumnItem, TableItemAction } from "@components/table/mat/mat-table-wrapper.service";
import { FilterColumnService, FilterItem } from "@components/table/table-column-filter/table-serverside-column-filter.service";
import { ServerSideTableService } from "@components/table/table-serverside.service";
import { ClientService } from "app/core/client/client.service";
import { VfClientMembershipsModel } from "app/core/models/appointment/vf-client-memberships.model";
import { DateTimeService } from "app/core/services/datetime.service";
import { Observable, of } from "rxjs";


@Injectable()
export class ClientMembershipsTableService implements ServerSideTableService {
  public clientId: string;
  constructor(private _clientService: ClientService, private _dateTimeService: DateTimeService) {}

  getActionList(): TableItemAction[] {
    return [ ];
  }
  getColumnDefinitions(): ColumnItem[] {
    return [
      { fieldName: "MEMBERSHIP_TYPE_NAME", displayName: "Membership Type", width:20 },      
      {
        fieldName: "START_DATE",
        displayName: "Starts On",
        config: {
          isDate: true,
        },
      },
      {
        fieldName: "EXPIRES_ON",
        displayName: "Expires On",
        config: {
          isDate: true,
        },
      }, 
      { fieldName: "UNITS_ALLOWED", displayName: "Allowed" },
      { fieldName: "PREVIOUS_VISITS", displayName: "Previous" },
      { fieldName: "UNITS_USED", displayName: "Used" },          
      { 
        fieldName: "CLIENT_CHARGE", 
        displayName: "Client Charge",
        config: {
          isCurrency: true,
        },        
      },
      { 
        fieldName: "PAID_WO_INV_AMOUNT", 
        displayName: "Paid/ Inv/ WO",
        config: {
          isCurrency: true,
        } 
      },    
    ];
  }
  
  getData(): Observable<VfClientMembershipsModel[]> {   
    return this._clientService.getClientMemberships(this.clientId);
  }
}

@Injectable({ providedIn: "root" })
export class ClientMembershipFilterService implements FilterColumnService {
  constructor() {}
  getAllowedFilterFields(): Array<FilterItem> {
    return [];
  }

  getSelectDataForFilterItem(item: FilterItem, params: any): Observable<any[]> {
    let results: Observable<any[]>;
    return results;
  }

  getDataButtonText(): string {
    return "";
  }
  getPageTitle(): string {
    return "Memberships";
  };

  getFilterServiceUniqueName(): string {
    return "Client_Memberships_Filter";
  }
}

