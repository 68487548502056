import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APIService } from "../api/api.service";
import { EventTypeModel } from "../models/physio/event-type.model";

@Injectable({ providedIn: "root" })
export class EventsService {
  constructor(private _apiService: APIService) {}

  public getAllEventTypes(): Observable<EventTypeModel[]> {
    return this._apiService.get(`/events?command=eventTypes`);
  }
}
