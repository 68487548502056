import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { AuthService } from "app/core/auth/auth.service";
import { TokenStorageService } from "./token-storage.service";
import { IndexedDBService } from "../cache/indexed-db.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Constructor
   */
  constructor(private _authService: AuthService, private _tokenStorageService: TokenStorageService,
     private _indexedDBService: IndexedDBService
    ) {}

  /**
   * Intercept
   *
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this._tokenStorageService.getAccessToken()) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this._tokenStorageService.getAccessToken()}`,
        },
      });
    }

    return next
      .handle(request)
      .pipe(
        tap((event) => {
          if (event instanceof HttpResponse) {
          }
        })
      )
      .pipe(
        catchError((err) => {
          if (err.status === 401) {
            // auto logout if 401 response returned from api
            this._authService.logout();
            this._indexedDBService.deleteDB();
          }
          return throwError(() => new Error(err));
        })
      );
  }
}
