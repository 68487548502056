<div class="column-filter-container" #rootElement>
	<div class="shrink-0">
		<span class="column-filter-title text-xl font-medium">{{getPageTitle}}</span>
	</div>
	<div class="filter-chip-list">
		<mat-chip-list #chipList>
			<!-- list of active filters -->
			<mat-chip *ngFor="let filterColumn of filterColumns" class="filter-chip cursor-pointer" [selectable]="selectable"
				[removable]="removable" (removed)="removeFilter(filterColumn)" (click)="editSelectedFilterItem($event, filterColumn)">
                <div *ngIf="filterColumn.filterType !== filterType.SINGLE_CHECK_FILTER" class="chip-content-wraper" [matTooltip]="getDisplayText(filterColumn)">
                  {{  filterColumn.displayName
                    ? filterColumn.displayName + ": " + "\"" + filterColumn.displayValue + "\""
                    : filterColumn.displayValue
                  }}</div>
				  <div *ngIf="filterColumn.filterType == filterType.SINGLE_CHECK_FILTER" class="chip-content-wraper" [matTooltip]="getDisplayText(filterColumn)">
					{{ filterColumn.displayName  ? filterColumn.displayName  : "" }}
				</div>
				<mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
			</mat-chip>

			<!-- the add filter chip -->
            <mat-chip #addFilter *ngIf="availableColumns.length > 0 && isUsingIndividualFilterDialog" class="ghost-chip cursor-pointer" [matMenuTriggerFor]="columnMenu">
				<mat-icon svgIcon="gpm_add_filter"></mat-icon>
				Add a filter
			</mat-chip>
            <mat-chip #setFilter *ngIf="availableColumns.length > 0 && !isUsingIndividualFilterDialog" class="ghost-chip cursor-pointer" (click)="setFilterItemsHandler()">
				<mat-icon svgIcon="gpm_add_filter"></mat-icon>
				Set Filters
			</mat-chip>

			<!-- dropdown of avail fields -->
			<mat-menu #columnMenu="matMenu" overlapTrigger="true" closed="clickz" class="filter-menu">
				<div class="mat-menu-label">Add filter</div>
				<button *ngFor="let column of availableColumns" mat-menu-item (click)="openDialog(column)">
					{{ column.displayName }}
				</button>
			</mat-menu>
		</mat-chip-list>
	</div>
	<!-- <button *ngIf= "getDataButtonText" class="" mat-raised-button color="accent" (click)="onGetData()">{{getDataButtonText}}</button> -->
    <button mat-icon-button (click)="onGetData()">
        <mat-icon svgIcon="gpm_refresh"></mat-icon>
    </button>
</div>
