<div *ngIf="isDataLoaded">
  <app-serverside-table
    (actionEvent)="onActionHandler($event)"
    [serversideTableService]="tableService"
    [serversideFilterService]="filterColumnService"
    [isNoBorder]="true">
  </app-serverside-table>
</div>

<ng-template #invoiceAmountTpl let-formDataItem="formDataItem">
  <app-client-invoices-amount-range-filter [formDataItem]="formDataItem">

  </app-client-invoices-amount-range-filter>
</ng-template>

<ng-template #detailInfo let-rowData="rowData" let-subData="subData">
  <!-- <div class="row row-expansion-container" [@detailExpand]="rowData.isExpanded ? 'expanded' : 'collapsed'"> -->
  <!-- <div class="row row-expansion-container" *ngIf="rowData.isExpanded && subData">
    adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa
    dadsa da adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa
    dadsa da adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa
    dadsa da adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa
    dadsa da adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa
    dadsa da adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa
    dadsa da adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa
    dadsa da adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa
    dadsa da adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa
    dadsa da adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa adsadsa
    {{subData.data}}
  </div> -->
  <div class="row row-expansion-container" *ngIf="rowData.isExpanded && subData">
    <app-serverside-table
    [serversideTableService]="subTableService"
    [serversideFilterService]="null"
    [isNoBorder]="true" [isNoPaginator]="true" [hideFilter]="true" *ngIf="rowData.ID === 196918958068">
    </app-serverside-table>
    <div class="row row-expansion-container" *ngIf="rowData.ID !== 196918958068">
      Custom Layout Here. Custom Layout Here. Custom Layout Here. Custom Layout Here. Custom Layout Here. Custom Layout Here.
      Custom Layout Here. Custom Layout Here. Custom Layout Here. Custom Layout Here.
      Custom Layout Here. Custom Layout Here. Custom Layout Here.Custom Layout Here.Custom Layout Here.Custom Layout Here.Custom Layout Here. <br/>Display Data relates to row: VENDOR_NAME
      {{rowData.VENDOR_NAME}}
    </div>
  </div>

</ng-template>
